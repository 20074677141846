import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import AddBestCaptain from "../modal/AddBestCaptain";
import { getTLStructureDetail } from "../../../data-api/tl-structure";

function TabTLStructureDetail(props) {
  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const [showAddCaptain, setShowAddCaptain] = useState(false);
  // function handleShowAddCaptain(value) {
  //   setShowAddCaptain(value);
  // }

  function handleSuccessModal(success, message) {
    setShowAddCaptain(false);
    props.handleSuccessModal(success, message);
  }

  useEffect(() => {
    getTLStructureDetail(props.data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      // setIsLoading(false);
    });
  }, []);

  return (
    <>
      <div className="container container-width custom-text-14 mt-4">
        <table className="table table-borderless table-sm">
          <tbody>
            <tr>
              <td>NIK</td>
              <td>{data.nik}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{data.name}</td>
            </tr>
            <tr>
              <td>Role</td>
              <td>{data.role}</td>
            </tr>
            <tr>
              <td>Join Date</td>
              <td>{moment(data.join_date).format("DD-MMM-YYYY")}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>
                {data.phone_number}{" "}
                <a
                  href={"https://wa.me//" + data.phone_number}
                  target="_blank"
                  className="link-primary"
                  rel="noreferrer"
                >
                  Whatsapp
                </a>
              </td>
            </tr>
            <tr>
              <td>Client</td>
              <td>{data.client}</td>
            </tr>
            <tr>
              <td>Group</td>
              <td>{data.group}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex flex-row-reverse bd-highlight">
        <button
          className="btn btn-danger mb-3 ms-1 me-1 mt-3 fw-bold shadow px-5 btn-sm"
          onClick={() => {
            setShowAddCaptain(true);
          }}
          disabled={!props.accessMenu.is_create}
        >
          Set Best Captain
        </button>
      </div>

      <Modal
        show={showAddCaptain}
        onHide={() => setShowAddCaptain(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <AddBestCaptain data={data} handleSuccessModal={handleSuccessModal} />
      </Modal>
    </>
  );
}

export default TabTLStructureDetail;
