import React, { useEffect, useState } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import {
  getListDropdown,
  getTimesheetById,
  putTimesheetBast,
} from "../../../data-api/timesheet";
import ModalMessage from "../../template/ModalMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { InvalidInput } from "../../../utils/ReactSelect";
import Select from "react-select";

function ModalEdit(props) {
  const [modalTitle, setModalTitle] = useState("");
  const [detailTimesheet, setDetailTimesheet] = useState([]);
  const [modalMessage, setModalMessage] = useState([]);
  const [check, setCheck] = useState(false);

  const [isFetch, setIsFetch] = useState(false);

  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // All Placement
  const [placements, setPlacements] = useState([]);
  // All Category
  const [categories, setCategories] = useState([]);

  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  useEffect(() => {
    getListDropdown().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code !== 200) {
        setMsg(result.meta.message);
        return;
      }

      setPlacements(result.data.placements);
      setCategories(result.data.categories);

      getTimesheetById(props.data.id).then((resultDetail) => {
        if (resultDetail.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (resultDetail.meta.code !== 200) {
          setMsg(resultDetail.meta.message);
          return;
        }

        setDetailTimesheet(resultDetail.data);
        setCutOff(resultDetail.cut_off);

        let getCategory = result.data.categories.find(
          (item) => item.value === resultDetail.data.category
        );
        setCategory(getCategory === undefined ? "" : getCategory);

        let getPlacement = result.data.placements.find(
          (item) => item.value === resultDetail.data.client_id
        );
        setPlacement(getPlacement === undefined ? "" : getPlacement);

        setModalTitle(props.data.title);
        handleShow();
      });
    });
  }, []);

  const [placement, setPlacement] = useState("");
  const [category, setCategory] = useState("");
  const [cut_off, setCutOff] = useState("");

  const [Msg, setMsg] = useState("");

  const ConfirmTimesheet = async (e) => {
    setIsFetch(true);
    e.preventDefault();
    setCutOff(e.target.cut_off.value);
    setMsg("");
    setModalTitle("Confirm Edit Timesheet/BAST");
    setIsFetch(false);
  };

  const UpdateTimesheet = async () => {
    setIsFetch(true);
    const data = {
      client_id: parseInt(placement.value),
      category: parseInt(category.value),
      cut_off: cut_off,
    };

    const resp = await putTimesheetBast(props.data.id, data);
    if (resp.meta.code === 401) {
      window.location.reload();
      return;
    }
    const message = {
      code: resp.meta.code,
      message: resp.meta.message,
    };
    setModalMessage(message);
    setCheck(true);
    setIsFetch(false);
    handleClose();
  };

  function ModalUpdate() {
    return (
      <>
        <Form onSubmit={ConfirmTimesheet} className="custom-text-14">
          <div className="container">
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Employee</Form.Label>
              <Form.Control
                className="w-75 form-control-sm"
                id="employee"
                name="employee"
                aria-describedby="basic-addon1"
                value={detailTimesheet.nik + " - " + detailTimesheet.name}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Placement</Form.Label>
              <div className="w-75">
                <Select
                  className="w-100 fw-normal custom-text-14"
                  options={placements.map((data) => ({
                    label: data.label,
                    value: data.value,
                  }))}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  isClearable
                  placeholder={"-- Select Placement --"}
                  defaultValue={placement}
                  onChange={(e) => setPlacement(e)}
                  required
                  ariaLiveMessages={"Please Select Placement"}
                  components={{
                    Input: invalidInput,
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Category</Form.Label>
              <div className="w-75">
                <Select
                  className="w-100 fw-normal custom-text-14"
                  options={categories.map((data) => ({
                    label: data.label,
                    value: data.value,
                  }))}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  isClearable
                  placeholder={"-- Select Category --"}
                  defaultValue={category}
                  onChange={(e) => setCategory(e)}
                  required
                  ariaLiveMessages={"Please Select Category"}
                  components={{
                    Input: invalidInput,
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Cut Off</Form.Label>
              <Form.Control
                type="text"
                id="cut_off"
                name="cut_off"
                className="w-75 form-control-sm"
                defaultValue={detailTimesheet.cut_off}
                onInvalid={(e) =>
                  e.target.setCustomValidity("Please fill out Cut Off")
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              ></Form.Control>
            </Form.Group>
            <div className="d-flex flex-row-reverse bd-highlight">
              <button
                className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                disabled={isFetch}
              >
                {isFetch ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </Form>
      </>
    );
  }

  function ModalConfirm() {
    return (
      <>
        <div className="container custom-text-14">
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Employee</Form.Label>
            <Form.Label className="mt-2">
              {detailTimesheet.nik + " - " + detailTimesheet.name}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Placement</Form.Label>
            <Form.Label className="mt-2">{placement.label}</Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Category</Form.Label>
            <Form.Label className="mt-2">{category.label}</Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Cut Off</Form.Label>
            <Form.Label className="mt-2">{cut_off}</Form.Label>
          </Form.Group>
          <div className="d-flex flex-row-reverse bd-highlight">
            <button
              className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              disabled={isFetch}
              onClick={() => UpdateTimesheet()}
            >
              {isFetch ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              onClick={() => setModalTitle(props.data.title)}
            >
              Back
            </button>
          </div>
        </div>
      </>
    );
  }

  if (check === false) {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        {Msg === "" ? null : (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <strong>{Msg}</strong>
          </Alert>
        )}
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalTitle === props.data.title ? <ModalUpdate /> : <ModalConfirm />}
        </Modal.Body>
      </Modal>
    );
  } else {
    return <ModalMessage data={modalMessage} click={props.click} />;
  }
}

export default ModalEdit;
