import { useRef, useState } from "react";
import { useEffect } from "react";
import { Datasets } from "./Datasets";
import { Modal } from "react-bootstrap";
import ModalChartDetail from "./ModalChartDetail.js";
import { useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsClapping } from "@fortawesome/free-solid-svg-icons";
import DashboardSuperadmin from "./role-charts/DashboardSuperadmin";
import DashboardBOD from "./role-charts/DashboardBOD";
import DashboardPSManager from "./role-charts/DashboardPSManager";
import DashboardFinance from "./role-charts/DashboardFinance";
import DashboardHC from "./role-charts/DashboardHC";
import { getCharts } from "../../data-api/dashboard";
import "./dashboard.css";
import ZoomChart from "./ZoomChart";

function NewDashboard() {
  const outletContext = useOutletContext();
  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Dashboard"
  );
  const accessChart = access.sub_menu.find((data) => data.is_read === true);
  const [table, setTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dataFetchedRef = useRef(false);

  const [chartDetail, setChartDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);

  const [data, setData] = useState([]);
  const [charts, setCharts] = useState([]);

  const [showZoom, setShowZoom] = useState(false);
  const [zoomChartData, setZoomChartData] = useState("");

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getCharts().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data.tables === null || result.data.tables === undefined
        ? setTable([])
        : setTable(result.data.tables);
      result.data.charts === null || result.data.charts === undefined
        ? setData([])
        : addCharts(result.data.charts);
    });
  }, []);

  function handleShowZoom(data) {
    setZoomChartData(data);
    setShowZoom(true);
  }

  function detailChart(
    title,
    detailUrl,
    config,
    isHorizontal,
    chartType,
    isStacked
  ) {
    setChartDetail({
      title: title,
      detailUrl: detailUrl,
      // dataPointIndex:
      //   isHorizontal === true && chartType === "bar"
      //     ? config.seriesIndex
      //     : config.dataPointIndex,
      // seriesIndex:
      //   isHorizontal === false && chartType === "bar"
      //     ? config.dataPointIndex
      //     : config.seriesIndex,
      dataPointIndex:
        !isHorizontal && chartType === "bar" && !isStacked
          ? config.seriesIndex
          : config.dataPointIndex,
      seriesIndex:
        !isHorizontal && chartType === "bar" && !isStacked
          ? config.dataPointIndex
          : config.seriesIndex,
      // dataPointIndex: config.dataPointIndex,
      // seriesIndex: config.seriesIndex,
    });
    setShowDetail(true);
  }

  function addCharts(dashboard) {
    dashboard.forEach((item, i) => {
      var state;

      //line chart
      if (item.chart_type.type === "line") {
        state = {
          size: 100,
          chart_detail: item.chart_detail,
          chart_type: "line",
          options: {
            chart: {
              id: item.chart_name,
              type: item.chart_type.type,
              stacked: item.chart_type.is_stacked,
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 500,
                },
              },
            },
            xaxis: {
              categories: Object.keys(item.labels).map(
                (key) => item.labels[key].label
              ),
            },
            colors: ["#d93333", "#f9c332", "#aaaaaa"],
            stroke: {
              curve: "smooth",
            },
          },
          series: Object.keys(item.datasets).map(
            (key) =>
              new Datasets(
                item.datasets[key].series_name,
                Object.keys(item.datasets[key].data).map(
                  (data) => item.datasets[key].data[data].value
                )
              )
          ),
        };
      }

      //pie chart
      if (item.chart_type.type === "pie") {
        state = {
          chart_detail: item.chart_detail,
          chart_type: item.chart_name === "BAST Chart" ? "donut" : "pie",
          series: Object.keys(item.datasets).map((key) =>
            Object.keys(item.datasets[key].data)
              .filter(
                (label) => item.datasets[key].data[label].label !== "Subtotal"
              )
              .map((data) => item.datasets[key].data[data].value)
          )[0],
          options: {
            chart: {
              id: item.chart_name,
              width: 380,
              type: item.chart_type.type,
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  if (
                    item.chart_name === "Talent Onboard Status" ||
                    item.chart_name === "Length Of Work" ||
                    item.chart_name === "Idle Chart" ||
                    item.chart_name === "Religion Chart" ||
                    item.chart_name === "Vendor Employee" ||
                    item.chart_name === "Vendor Employee Source Talent" ||
                    item.chart_name === "Vendor Employee Client"
                  ) {
                    detailChart(
                      item.chart_name,
                      item.chart_detail,
                      config,
                      item.chart_type.is_horizontal,
                      item.chart_type.type,
                      item.chart_type.is_stacked
                    );
                  }
                },
              },
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: [],
                },
                export: {
                  csv: {
                    filename: undefined,
                    columnDelimiter: ",",
                    headerCategory: "category",
                    headerValue: "value",
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toDateString();
                    },
                  },
                  svg: {
                    filename: undefined,
                  },
                  png: {
                    filename: undefined,
                  },
                },
                autoSelected: "zoom",
              },
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 500,
                },
              },
              zoom: {
                enabled: true,
                type: "x",
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                  },
                  stroke: {
                    color: "#0D47A1",
                    opacity: 0.4,
                    width: 1,
                  },
                },
              },
            },
            legend: {
              position: "bottom",
            },
            colors:
              item.chart_name === "Length Of Work"
                ? ["#d93333", "#7b0000", "#300000"]
                : ["#d93333", "#f9c332", "#aaaaaa"],
            labels: Object.keys(item.labels)
              .filter((label) => item.labels[label].label !== "Subtotal")
              .map((key) => item.labels[key].label),
            // responsive: [
            //   {
            //     breakpoint: 480,
            //     options: {
            //       chart: {
            //         width: 200,
            //       },
            //       legend: {
            //         position: "bottom",
            //       },
            //     },
            //   },
            // ],
          },
          subTotal: Object.keys(item.datasets).map((key) =>
            Object.keys(item.datasets[key].data)
              .filter(
                (label) => item.datasets[key].data[label].label === "Subtotal"
              )
              .map((data) => item.datasets[key].data[data].value)
          )[0][0],
          subTotalOnClick: function () {
            detailChart(
              item.chart_name,
              item.chart_detail,
              {
                seriesIndex: 0,
                dataPointIndex: item.labels.length - 1,
              },
              item.chart_type.is_horizontal,
              item.chart_type.type,
              item.chart_type.is_stacked
            );
          },
          zoomOnClick: function () {
            handleShowZoom(state);
          },
        };
      }

      //bar chart
      if (item.chart_type.type === "bar") {
        state = {
          size: 100,
          chart_detail: item.chart_detail,
          chart_type: item.chart_type.type,
          options: {
            chart: {
              id: item.chart_name,
              type: item.chart_type.type,
              stacked: item.chart_type.is_stacked,
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  detailChart(
                    item.chart_name,
                    item.chart_detail,
                    config,
                    item.chart_type.is_horizontal,
                    item.chart_type.type,
                    item.chart_type.is_stacked
                  );
                },
              },
              animations: {
                enabled: true,
              },
            },
            plotOptions: {
              bar: {
                horizontal: item.chart_type.is_horizontal,
                borderRadius: 7,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
              },
            },
            xaxis: {
              categories: Object.keys(item.labels).map(
                (key) => item.labels[key].label
              ),
            },
            colors: ["#d93333", "#f9c332", "#aaaaaa"],
            legend: {
              show: item.chart_name === "Employee by Department" ? false : true,
            },
          },
          series: Object.keys(item.datasets).map(
            (key) =>
              new Datasets(
                item.datasets[key].series_name,
                Object.keys(item.datasets[key].data).map(
                  (data) => item.datasets[key].data[data].value
                )
              )
          ),
          zoomOnClick: function () {
            handleShowZoom(state);
          },
        };
      }

      //donut chart
      if (item.chart_type.type === "donut") {
        state = {
          chart_detail: item.chart_detail,
          chart_type: "donut",
          series: Object.keys(item.datasets).map((key) =>
            Object.keys(item.datasets[key].data).map(
              (data) => item.datasets[key].data[data].value
            )
          )[0],
          options: {
            chart: {
              id: item.chart_name,
              width: 380,
              type: item.chart_type.type,
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: [],
                },
                export: {
                  csv: {
                    filename: undefined,
                    columnDelimiter: ",",
                    headerCategory: "category",
                    headerValue: "value",
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toDateString();
                    },
                  },
                  svg: {
                    filename: undefined,
                  },
                  png: {
                    filename: undefined,
                  },
                },
                autoSelected: "zoom",
              },
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 500,
                },
              },
            },
            legend: {
              position: "bottom",
            },
            colors: ["#d93333", "#f9c332", "#aaaaaa"],
            labels: Object.keys(item.labels).map(
              (key) => item.labels[key].label
            ),
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          zoomOnClick: function () {
            handleShowZoom(state);
          },
        };
      }
      setCharts((charts) => [...charts, state]);
    });
    setIsLoading(false);
  }

  if (isLoading)
    return (
      <>
        {/* <div className="container align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> */}
        <div className="w-100">
          <div className="row">
            <div className="flex col-sm-12">
              <div className="shine-photo shine"></div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="flex col-sm-4">
              <div className="shine-photo shine"></div>
            </div>
            <div className="flex col-sm-4">
              <div className="shine-photo shine"></div>
            </div>
            <div className="flex col-sm-4">
              <div className="shine-photo shine"></div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="w-100">
        <div className="text-start">
          <p className="custom-text-20">
            Hi, {outletContext.profile.employee_name}
          </p>
          <p className="custom-text-24 fw-bold" style={{ marginTop: "-20px" }}>
            Welcome Back
            <FontAwesomeIcon
              icon={faHandsClapping}
              color="#d6a35c"
              className="ms-2"
            />
          </p>
        </div>
        <div className="d-flex align-content-start flex-wrap">
          {/* <p>{dashboard.role}</p> */}
          {isLoading ? (
            <span>Loading...</span>
          ) : accessChart.sub_menu === "Charts Superadmin" ? (
            <DashboardSuperadmin
              data={data}
              detailChart={detailChart}
              charts={charts}
              dataTable={table}
              zoom={handleShowZoom}
            />
          ) : accessChart.sub_menu === "Charts PS Manager" ? (
            <DashboardPSManager
              data={data}
              detailChart={detailChart}
              dataTable={table}
              charts={charts}
            />
          ) : accessChart.sub_menu === "Charts HC" ? (
            <DashboardHC
              data={data}
              detailChart={detailChart}
              charts={charts}
            />
          ) : accessChart.sub_menu === "Charts Finance" ? (
            <DashboardFinance
              data={data}
              detailChart={detailChart}
              charts={charts}
            />
          ) : accessChart.sub_menu === "Charts BOD" ? (
            <DashboardBOD
              data={data}
              detailChart={detailChart}
              charts={charts}
            />
          ) : null}
        </div>
        {/* {table.length !== 0 ? (
          <div className="m-auto">
            <TableDashboard data={table[0]} />
          </div>
        ) : null} */}
      </div>

      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        size="xl"
        // aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        // backdrop="static"
        // centered
      >
        <ModalChartDetail title={"talent"} data={chartDetail} />
      </Modal>

      <Modal
        show={showZoom}
        onHide={() => setShowZoom(false)}
        size="lg"
        // aria-labelledby="contained-modal-title-vcenter"
        aria-labelledby="example-custom-modal-styling-title"
        // backdrop="static"
        // centered
      >
        <ZoomChart data={zoomChartData} />
      </Modal>
    </>
  );
}
export default NewDashboard;
