import { useState } from "react";
import TableDetailTalentP from "../table/TableDetailTalentP";
import { Modal } from "react-bootstrap";

function ModalDetailTalentP(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>History Vendor - {props.data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container custom-text-14">
          {showAlert ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessage}</strong>
              <button
                className="btn btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
          ) : null}
          <div>
            <TableDetailTalentP
              data={props.data}
              refreshTable={props.refreshTable}
              error={showAlertError}
              close={props.close}
              show={props.show}
              accessMenu={props.accessMenu}
            />
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalDetailTalentP;
