import {
  faAnglesLeft,
  faAnglesRight,
  faEnvelope,
  faMagnifyingGlass,
  faPlusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import "../Superadmin.css";
import { Image, Modal } from "react-bootstrap";
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import ModalAddUser from "./modal/ModalAddUser";
import ModalEditRole from "./modal/ModalEditRole";
import ModalSendMessage from "./modal/ModalSendMessage";
import ModalDeleteUser from "./modal/ModalDeleteUser";
import { useOutletContext } from "react-router-dom";
import { getSuperadminUser } from "../../../data-api/master-user";
import moment from "moment";

function IntegralsUser() {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Master User"
  ).sub_menu;
  const accessMenu = access.find((item) => item.sub_menu === "Integrals User");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditRole, setShowEditRole] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const [modalData, setModalData] = useState({});

  function resetTable() {
    fetchSuperadminUser();
    setShowAddUser(false);
    setShowSuccess(false);
  }

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowAddUser(false);
    setShowEditRole(false);
    setShowDeleteUser(false);
    setShowEmail(false);
    setShowSuccess(true);
  }

  function handleCloseDelete() {
    setShowDeleteUser(false);
  }

  useEffect(() => {
    fetchSuperadminUser();
  }, []);

  function fetchSuperadminUser() {
    setIsLoading(true);
    getSuperadminUser().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "avatar_url",
        Cell: (props) => {
          return (
            <div>
              <Image
                style={{ width: "3rem" }}
                roundedCircle
                src={props.value}
                alt="profile"
              />
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-email">{props.value}</span>
          );
        },
      },
      {
        Header: "Position",
        accessor: "position",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
      },
    ],
    []
  );

  // add custom new cell for button detail & renewal
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "edit",
        Header: "",
        Cell: ({ row }) => (
          <button
            className="btn"
            onClick={() => {
              setModalData(row);
              setShowEditRole(true);
            }}
            disabled={
              !accessMenu.is_update ||
              (row.original.role === "Superadmin" &&
                outletContext.profile.role_id !== "1")
            }
          >
            <FontAwesomeIcon
              size="xl"
              icon={faPenToSquare}
              style={{ color: "#dc3545" }}
            />
          </button>
        ),
      },
      {
        id: "send_email",
        Header: "",
        Cell: ({ row }) => (
          <button
            className="btn"
            onClick={() => {
              setModalData(row);
              setShowEmail(true);
            }}
            disabled={
              !accessMenu.is_create ||
              (row.original.role === "Superadmin" &&
                outletContext.profile.role_id !== "1")
            }
          >
            <FontAwesomeIcon
              size="xl"
              icon={faEnvelope}
              style={{ color: "#dc3545" }}
            />
          </button>
        ),
      },
      {
        id: "delete",
        Header: "",
        Cell: ({ row }) => (
          <button
            className="btn"
            onClick={() => {
              setModalData(row);
              setShowDeleteUser(true);
            }}
            disabled={
              !accessMenu.is_delete ||
              (row.original.role === "Superadmin" &&
                outletContext.profile.role_id !== "1")
            }
          >
            <FontAwesomeIcon
              size="xl"
              icon={faTrashCan}
              style={{ color: "#dc3545" }}
            />
          </button>
        ),
      },
    ]);
  };

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination,
    tableHooks
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="w-100 tabs-content rounded">
        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttablecustom"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  if (e.target.value === "") {
                    searchItem();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchVal(e.target.value);
                    searchItem();
                  }
                }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>
            <button
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={() => setShowAddUser(true)}
              disabled={!accessMenu.is_create}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginRight: "10px" }}
              />
              Add Access
            </button>
          </div>
        </div>

        <div className="overflow-x-scroll">
          <table className="table-custom-superadmin">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                  {headerGroup.headers.map((column, key) => (
                    <th
                      {...column.getHeaderProps(column)}
                      scope="col"
                      key={key}
                      className="text-start text-danger"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <td colSpan={headers.length}>No data available in table</td>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className=" text-start">
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={key}>
                      {row.cells.map((cell, key) => {
                        return (
                          <td {...cell.getCellProps()} key={key}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>
          {""}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div
          className="d-flex justify-content-end mb-2 me-2"
          style={{ color: "#525555" }}
        >
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        show={showAddUser}
        onHide={() => setShowAddUser(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalAddUser handleSuccessModal={handleSuccessModal} />
      </Modal>

      <Modal
        show={showEditRole}
        onHide={() => setShowEditRole(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalEditRole
          data={modalData}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showDeleteUser}
        onHide={() => setShowDeleteUser(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalDeleteUser
          data={modalData}
          onHide={handleCloseDelete}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showEmail}
        onHide={() => setShowEmail(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalSendMessage
          data={modalData}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3"
                onClick={() => resetTable()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default IntegralsUser;
