import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ModalDetailPkwt from "../modal/ModalDetailPkwt";
import moment from "moment";
import { getContract } from "../../../data-api/pkwt";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";

import ReactDatePicker, { registerLocale } from "react-datepicker";

import enGb from "date-fns/locale/en-GB";
registerLocale("en-GB", enGb);

function TablePKWTT(props) {
  const [pkwtt, setPkwtT] = useState([]);
  const [isLoading, setIsLoading] = useState(props.isLoading);

  const accessMenu = props.accessMenu;
  useEffect(() => {
    if (props.data.length === 0) {
      fetchDataPkwtT();
    } else {
      setPkwtT(props.data);
      setIsLoading(false);
    }
  }, []);

  const [dataModal, setDataModal] = useState("");

  const [modalFilter, setModalFilter] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [filterStartDateTemp, setFilterStartDateTemp] = useState("");
  const [filterEndDateTemp, setFilterEndDateTemp] = useState("");

  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDetail(false);
  };
  function handleShowDetail(nik, name) {
    setDataModal({ nik: nik, name: name });
    setShowDetail(true);
  }

  const columnsPkwtT = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status SC",
        accessor: "status_sc",
      },
      {
        Header: "Status Integrals",
        accessor: "status_integrals",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        // Cell: (props) => FormatDate(props.value),
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
      {
        Header: "Pkwt Type",
        accessor: "type",
        id: "type",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  // add custom new cell for button detail & renewal
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <Button
            className="btn btn-danger"
            onClick={() => handleShowDetail(row.values.nik, row.values.name)}
          >
            <FontAwesomeIcon
              icon={faFolderOpen}
              style={{ color: "#fff", float: "right" }}
            />
          </Button>
        ),
      },
    ]);
  };

  const data = pkwtt.length === 0 ? EmptyData : pkwtt;

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => delete v.id);
    const columnsExcel = columnsPkwtT.map((item) => item.Header);
    excelData.forEach((item) => {
      item.join_date = moment(item.join_date).format("DD-MMM-YYYY");
    });
    const dataSet = [];
    const dataHeader = [];
    //append header table
    // const columnsExcel = Object.keys(excelData[0]);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excelData.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: `Internal Contract PKWTT`,
    });
  }

  function filterPkwtT(event) {
    event.preventDefault();
    if (filterStartDateTemp === "" || filterEndDateTemp === "") {
      props.error(false, "Start Date and End Date must filled");
    } else {
      setFilterStartDate(filterStartDateTemp);
      setFilterEndDate(filterEndDateTemp);
      fetchDataPkwtT(
        moment(filterStartDateTemp).format("YYYY-MM-DD"),
        moment(filterEndDateTemp).format("YYYY-MM-DD")
      );
      setModalFilter(false);
    }
  }

  function fetchDataPkwtT(startDate, endDate) {
    setIsLoading(true);
    getContract(4, startDate, endDate).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data === null ? setPkwtT([]) : setPkwtT(res.data);
      } else {
        setPkwtT([]);
        props.error(false, res.meta.message);
      }
      setIsLoading(false);
    });
  }

  function removeFilter(type) {
    fetchDataPkwtT();
    setFilterStartDate("");
    setFilterStartDateTemp("");
    setFilterEndDate("");
    setFilterEndDateTemp("");
  }

  function handleFilter() {
    setModalFilter(true);
  }

  return (
    <div className="tabs-content">
      {filterStartDate !== "" && filterEndDate !== "" ? (
        <div className="d-flex flex-row bd-highlight mb-3">
          {filterStartDate !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {moment(filterStartDate).format("DD MMM YYYY")} -{" "}
              {moment(filterEndDate).format("DD MMM YYYY")}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter()}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
      <ReactTableCustomHooks
        columns={columnsPkwtT}
        data={data}
        handleDownloadExcel={handleDownloadExcel}
        handleFilter={handleFilter}
        isLoading={isLoading}
        tableHooks={tableHooks}
        hiddenColumns={["type"]}
        paging={[10, 20, 50, 100]}
      />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        size="sm"
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter Join Date</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form onSubmit={(e) => filterPkwtT(e)}>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semibold">Start Date</Form.Label>
                <div>
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={filterStartDateTemp ? filterStartDateTemp : ""}
                    onChange={(value) => {
                      setFilterStartDateTemp(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semibold">End Date</Form.Label>
                <div>
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={filterEndDateTemp ? filterEndDateTemp : ""}
                    onChange={(value) => {
                      setFilterEndDateTemp(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                  />
                </div>
              </Form.Group>
              <div className="d-grid gap-2">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow">
                  FILTER
                </button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showDetail}
        onHide={handleCloseDetail}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalDetailPkwt
          data={dataModal}
          alert={props.error}
          accessMenu={accessMenu}
        />
      </Modal>
    </div>
  );
}
export default TablePKWTT;
