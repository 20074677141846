import { Form, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { postSuperadminSendEmail } from "../../../../data-api/master-user";

function ModalSendMessage(props) {
  const emailBody = `Hi Mr./Ms ${props.data.original.name}, <br><br>
    This is an auto generated email. <br><br>
    Your login information as below : <br>
    <ul><li>Link : https://integrals.idstar.co.id/ </li>
    <li>User email : ${props.data.original.email} </li>
    </ul> <br>
    Please login to Integrals and press Sign In With Google button.<br>
    After redirecting to Google Form, select the user email according to the user email registered above.<br><br>
    <p align='center'>
    <img src='https://integrals-public.s3.ap-southeast-1.amazonaws.com/images/integrals_google_login.png' width='500px'/></p><br>
    If you got any problem, you can click the 'Contact Admin' as well.<br>
    Fill up the form and click Submit<br>
    <p align='center'>
    <img src='https://integrals-public.s3.ap-southeast-1.amazonaws.com/images/Capture+Login+Issue.png' width='350px'/></p><br>
    
    Thank you.
     <br/><br/>
    
    Regards, <br/><br/>
    
    Integrals Superadmin Team
    `;

  function sendEmail(event) {
    event.preventDefault();
    event.stopPropagation();

    const json = JSON.stringify({
      user_email: props.data.original.email,
      message: emailBody,
    });

    postSuperadminSendEmail(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        props.handleSuccessModal(false, result.meta.message);
      } else {
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.handleSuccessModal(true, message.message);
      }
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={sendEmail}>
          <div className="container">
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Email</Form.Label>
              <Form.Control
                className="form-control-sm"
                style={{ width: "90%" }}
                aria-describedby="basic-addon1"
                defaultValue={props.data.original.email}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Subject</Form.Label>
              <Form.Control
                className="form-control-sm"
                style={{ width: "90%" }}
                aria-describedby="basic-addon1"
                defaultValue={"Integrals - New Access User"}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2">Message</Form.Label>
              <Editor
                apiKey="rzu0yah0mou5ula2bois6fdgq2lxo1y70qzxk03vowfvgb2s"
                value={emailBody}
                style={{ width: "90%" }}
                init={{
                  width: 975,
                  height: 500,
                  menubar: false,
                }}
                disabled
              />
            </Form.Group>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="btn btn-danger col-2 mt-4 mb-4 btn-sm"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
}

export default ModalSendMessage;
