import {
  faCloudArrowDown,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import ReactTable from "../../../utils/ReactTable";
import { useRef } from "react";
import * as xlsx from "xlsx";
import { saveCostVariable } from "../../../data-api/cost-to-company";
import writeXlsxFile from "write-excel-file";

function ModalImportVar(props) {
  const [importData, setImportData] = useState([]);

  const [showUpload, setShowUpload] = useState(false);
  const handleShowUpload = () => setShowUpload(true);
  const handleCloseUpload = () => setShowUpload(false);
  const onFileChange = (files) => {};
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile.type === "text/csv") {
      const updatedList = [newFile];
      setFileList(updatedList);
      onFileChange(updatedList);

      if (e.target.files) {
        const reader = new FileReader();
        var jsonParse;
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "csv" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_csv(worksheet, { header: 1 });
          jsonParse = JSON.parse(convertToJson(json));
          if (
            jsonParse[0].hasOwnProperty("name") &&
            jsonParse[0].hasOwnProperty("value")
          ) {
            setImportData(jsonParse);
          } else {
            showAlertErrorUploadCSV(
              false,
              "Please upload right CSV format file"
            );
          }
          //   Object.keys(jsonParse).forEach((key) => {
          //     jsonParse[key].nik = jsonParse[key].nik.padStart(7, "0");
          //   });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } else {
      showAlertErrorUploadCSV(false, "Please upload CSV format");
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  const [showAlertUploadCSV, setShowAlertUploadCSV] = useState(false);
  const [AlertMessageUploadCSV, setAlertMessageUploadCSV] = useState("");

  function showAlertErrorUploadCSV(success, message) {
    setShowAlertUploadCSV(true);
    setAlertMessageUploadCSV(message);
  }

  function convertToJson(csv) {
    csv = csv.replaceAll(";", ",");
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        if (currentline[j].includes("/")) {
          var date = currentline[j].split("/");
          obj[headers[j]] = `20${date[2]}-${date[1].padStart(
            2,
            "0"
          )}-${date[0].padStart(2, "0")}`;
        } else {
          obj[headers[j]] = currentline[j];
        }
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
    setImportData([]);
  };

  function importCostVar() {
    if (data.length === 0) {
      showAlertError(false, "Import data cant be empty");
    } else {
      data.map((item) => (item.value = parseFloat(item.value)));
      const json = JSON.stringify(data);
      saveCostVariable(json).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        if (result.meta.message === "success") {
          props.handleSuccessModal(message);
        } else {
          props.handleSuccessModal(message);
        }
      });
    }
  }

  const columnsImport = useMemo(
    () => [
      {
        Header: "Variable Name",
        accessor: "name", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Value",
        accessor: "value",
      },
    ],
    []
  );

  const data = importData.length === 0 ? [] : importData;
  function handleDownloadExcel() {
    const dataSet = [];
    const dataHeader = [];
    columnsImport.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: typeof item === "string" ? String : Number,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Cost_variable_Import",
    });
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Import Cost Variable</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        <div className="container d-flex flex-row-reverse bd-highlight mt-3 mb-4">
          <button
            className="btn btn-danger ms-3"
            style={{ width: "170px" }}
            onClick={handleShowUpload}
          >
            <FontAwesomeIcon
              icon={faFileImport}
              style={{ marginRight: "10px" }}
            />
            Upload Files
          </button>
          <a
            className="btn btn-danger ms-3"
            style={{ width: "170px" }}
            href="https://integrals-public.s3.ap-southeast-1.amazonaws.com/ctc_example/cost_variable_example.csv"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ color: "#fff", marginRight: "10px" }}
            />
            Format File
          </a>
        </div>

        <ReactTable
          columns={columnsImport}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          hiddenColumns={[]}
        />

        <div className="container d-flex flex-row-reverse bd-highlight mt-3 mb-4">
          <button className="btn btn-danger ms-3 col-3" onClick={importCostVar}>
            Import Variable to DB
          </button>
        </div>
      </Modal.Body>

      <Modal show={showUpload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlertUploadCSV ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessageUploadCSV}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlertUploadCSV(false)}
              ></button>
            </div>
          ) : null}
          <div className="mb-4">
            <div className="custom-file-upload btn btn-outline-danger">
              <label>Browse</label>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
            <button
              className="btn btn-danger float-end col-4 mb-4"
              onClick={handleCloseUpload}
            >
              Submit
            </button>
          </div>
          {fileList.length === 0 ? (
            <div
              ref={wrapperRef}
              className="drop-file-input mt-4"
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div className="drop-file-input__label">
                <img alt="" />
                <p>Drag & Drop your files here</p>
              </div>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
          ) : null}
          {fileList.length > 0 ? (
            <div className="drop-file-preview">
              {fileList.map((item, index) => (
                <div key={index} className="drop-file-preview__item">
                  <img alt="" />
                  <div className="drop-file-preview__item__info">
                    <p>{item.name}</p>
                    <p>{item.size}B</p>
                  </div>
                  <span
                    className="drop-file-preview__item__del"
                    onClick={() => fileRemove(item)}
                  >
                    x
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ModalImportVar;
