import {
  faCircleCheck,
  faCircleXmark,
  faFolderOpen,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import TLStructureDetail from "../modal/TLStructureDetail";
import TLStructureEdit from "../modal/TLStructureEdit";
import TLStructureFilter from "../modal/TLStructureFilter";
import { getTLStructure } from "../../../data-api/tl-structure";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";

function TabTLStructure(props) {
  const accessMenu = props.accessMenu;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [filterNik, setFilterNik] = useState("");
  const [showDetail, setShowDetail] = useState(false);

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  function handleShowDetail(nik) {
    setFilterNik(nik);
    setShowDetail(true);
  }
  function handleCloseDetail() {
    setShowDetail(false);
  }

  const [showEdit, setShowEdit] = useState(false);
  function handleShowEdit(nik, name, group) {
    setFilterNik({
      nik: nik,
      name: name,
      group: group,
    });
    setShowEdit(true);
  }

  const [showFilter, setShowFilter] = useState(false);
  const [filterPSManager, setFilterPSManager] = useState("");
  const [filterTeamLeader, setFilterTeamLeader] = useState("");

  function filterData(psManager, teamLeader) {
    setFilterPSManager(psManager);
    setFilterTeamLeader(teamLeader);
    if (psManager !== undefined) {
      if (teamLeader !== undefined) {
        fetchData(psManager.label, teamLeader.value);
      } else {
        fetchData(psManager.label, "");
      }
    } else {
      fetchData(psManager.label, "");
    }
    setShowFilter(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData(psManager, tlStructure) {
    setIsLoading(true);
    let params = {
      ps_manager: psManager,
      t_leader: tlStructure,
    };
    getTLStructure(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "PS Manager",
        accessor: "ps_manager",
      },
      {
        Header: "Team Leader",
        accessor: "team_leader",
      },
      {
        Header: "Captain",
        accessor: "captain",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Group",
        accessor: "group",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowDetail(row.values.nik)}
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "edit",
        Header: "Set Group",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEdit(
                  row.values.nik,
                  row.values.name,
                  row.values.group
                )
              }
              disabled={!accessMenu.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  function handleDownloadExcel() {
    const columnsExcel = [
      "NIK",
      "Name",
      "Status",
      "PS Manager",
      "Team Leader",
      "Captain",
      "Position",
      "Grade",
      "Group",
    ];
    const excelData = data;
    excelData.forEach((v) => {
      delete v.Function;
      delete v.function;
    });
    const dataSet = [];
    const dataHeader = [];

    //append header table
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "TL Structure",
    });
  }

  function removeFilter(type) {
    if (type === "ps_manager") {
      if (filterTeamLeader === "") {
        fetchData("", filterTeamLeader);
      }
      setFilterPSManager("");
    } else if (type === "team_leader") {
      fetchData(filterPSManager.label, "");
      setFilterTeamLeader("");
    }
  }

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowDetail(false);
    setShowEdit(false);
    setShowFilter(false);
    setShowSuccess(true);
  }

  function handleFilter() {
    setShowFilter(true);
  }

  return (
    <>
      <div className="tabs-content">
        {/* show state filter */}
        <div className="d-flex flex-row bd-highlight mb-3">
          {filterPSManager !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              PS Manager : {filterPSManager.label}{" "}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("ps_manager")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterTeamLeader !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Team Leader : {filterTeamLeader.label}{" "}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("team_leader")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>
        <ReactTableCustomHooks
          columns={columns}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={handleFilter}
          tableHooks={tableHooks}
          hiddenColumns={[]}
          isLoading={isLoading}
          paging={[10, 20, 50, 100]}
        />
      </div>

      {/* modal detail */}
      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        dialogClassName="modal-profile"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <TLStructureDetail
          data={filterNik}
          closeDetail={handleCloseDetail}
          handleSuccessModal={handleSuccessModal}
          accessMenu={accessMenu}
        />
      </Modal>

      {/* modal edit */}
      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <TLStructureEdit
          data={filterNik}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      {/* modal filter */}
      <Modal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <TLStructureFilter
          filter={filterData}
          filterPSManager={filterPSManager}
          filterTeamLeader={filterTeamLeader}
        />
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3"
                onClick={() => {
                  filterData(filterPSManager, filterTeamLeader);
                  setShowSuccess(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TabTLStructure;
