import { Button, Modal, Tab } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { Tabs, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRef } from "react";
import "../Employee.css";
import ModalMessage from "../../template/ModalMessage";
import {
  deleteEmployeePhoto,
  getEmployeeProfile,
  updateEmployeePhoto,
} from "../../../data-api/employee";
import { useOutletContext } from "react-router-dom";
import LoadingEffect from "../../template/loadingEffectTable";

function ModalProfile(props) {
  const outletContext = useOutletContext();
  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Employee"
  ).sub_menu;
  const employeePhotoPermission = access.find(
    (data) => data.sub_menu === "Employee Photo"
  );

  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  //delete modal
  // const [show, setShow] = useState(false);

  //Modal handler Delete photo
  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);

  //upload modal
  const [showUpload, setShowUpload] = useState(false);

  const handleCloseUpload = () => setShowUpload(false);

  const handleShowUpload = () => {
    setShowUpload(true);
  };

  // Untuk menyimpan message alert jika ada kesalahan
  // const [Msg, setMsg] = useState("");

  const [check, setCheck] = useState(false);

  const [showAlertSignature, setShowAlertSignature] = useState(false);
  const [AlertMessageSignature, setAlertMessageSignature] = useState("");
  const [successUpload, setSuccessUpload] = useState(false);

  const onFileChange = (files) => {
    // console.log(files);
  };
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  // pop up modal success upload / gagal
  const [modalMessage, setModalMessage] = useState([]);

  // const [file, setFile] = useState(null);
  const onFileDrop = (e) => {
    setShowAlertSignature(false);
    const newFile = e.target.files[0];
    if (
      newFile.type !== "image/png" &&
      newFile.type !== "image/jpg" &&
      newFile.type !== "image/jpeg"
    ) {
      const updatedList = [...fileList];
      // updatedList.splice(fileList.indexOf(file), 1);
      setFileList(updatedList);
      onFileChange(updatedList);

      setAlertMessageSignature("Invalid format file upload");
      setSuccessUpload(false);
      setShowAlertSignature(true);
      // alert("Invalid format file upload");
    } else if (newFile.size > 1000000) {
      const updatedList = [...fileList];
      // updatedList.splice(fileList.indexOf(file), 1);
      setFileList(updatedList);
      onFileChange(updatedList);

      setAlertMessageSignature("Invalid file size upload");
      setSuccessUpload(false);
      setShowAlertSignature(true);
    } else {
      const updatedList = [newFile];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  function SubmitPhoto() {
    const formData = new FormData();
    formData.append("file", fileList[0]);
    updateEmployeePhoto(props.data, formData).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message2 = {
        code: result.meta.code,
        message: result.meta.message,
      };
      if (message2.code === 200) {
        handleCloseUpload();
        // open modal template
        setModalMessage(message2);
        setCheck(true);
      }
    });
  }

  function deletePhoto() {
    deleteEmployeePhoto(props.data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message2 = {
        code: result.meta.code,
        message: result.meta.message,
      };
      if (message2.code === 200) {
        handleCloseDelete();
        // open modal template
        setModalMessage(message2);
        setCheck(true);
      }
    });
  }

  useEffect(() => {
    getEmployeeProfile(props.data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setProfile({})
        : setProfile(result.data);
      setIsLoading(false);
    });
  }, []);

  const Refresh = () => {
    setShowUpload(false);
  };
  if (isLoading)
    return (
      <>
        <LoadingEffect />
      </>
    );

  return (
    <>
      <div className="tab-emp custom-text-14">
        <div className="container content-left">
          <Tabs defaultActiveKey="personal" id="uncontrolled-tab-example">
            <Tab eventKey="personal" title="Personal Detail">
              <div className="container container-width ">
                <table className="table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td className="td-1">Name</td>
                      <td className="td-2">
                        {profile.employee_name ? profile.employee_name : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>NIK</td>
                      <td>{profile.nik ? profile.nik : ""}</td>
                    </tr>
                    <tr>
                      <td>Birth Place / Date</td>
                      <td>
                        {profile.personal_information.birth_place !== undefined
                          ? profile.personal_information.birth_place
                          : ""}{" "}
                        /{" "}
                        {profile.personal_information.birth_date
                          ? profile.personal_information.birth_date
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Age</td>
                      <td>{profile.personal_information.age}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>
                        {profile.personal_information.phone_number}{" "}
                        <a
                          href={profile.personal_information.whatsapp_url}
                          target="_blank"
                          className="custom-text-14"
                          rel="noreferrer"
                        >
                          Whatsapp
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Last Education</td>
                      <td>
                        {profile.personal_information.educational_background}
                      </td>
                    </tr>
                    <tr>
                      <td>Major</td>
                      <td>{profile.personal_information.educational_major}</td>
                    </tr>
                    <tr>
                      <td>Degree</td>
                      <td>{profile.personal_information.grade_level}</td>
                    </tr>
                    <tr>
                      <td>Religion</td>
                      <td>{profile.personal_information.religion}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{profile.personal_information.gender}</td>
                    </tr>
                    <tr>
                      <td>Marital Status</td>
                      <td>{profile.personal_information.marital_status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="job" title="Job Detail">
              <div className="container">
                <table className="table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td>Company</td>
                      <td>{profile.job_information.company_name}</td>
                    </tr>
                    <tr>
                      <td>Talent Review</td>
                      <td>{profile.job_information.talent_review}</td>
                    </tr>
                    <tr>
                      <td>Office Email</td>
                      <td>{profile.job_information.email_office}</td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <td>{profile.job_information.department_name}</td>
                    </tr>
                    <tr>
                      <td>Function</td>
                      <td>{profile.job_information.function_name}</td>
                    </tr>
                    <tr>
                      <td>Grade</td>
                      <td>{profile.job_information.grade}</td>
                    </tr>
                    <tr>
                      <td>Direct Report</td>
                      <td>{profile.job_information.direct_report}</td>
                    </tr>
                    <tr>
                      <td>Resign Date</td>
                      <td>{profile.job_information.resign_date}</td>
                    </tr>
                    <tr>
                      <td>Leave Reason</td>
                      <td>{profile.job_information.leave_reason}</td>
                    </tr>
                    <tr>
                      <td>Work Duration</td>
                      <td>{profile.job_information.length_of_work}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="container content-right">
          {profile.personal_information.photo_url === "" ? (
            <Image
              style={{ width: "10rem", height: "10rem" }}
              roundedCircle
              src={`https://integrals.s3.ap-southeast-1.amazonaws.com/images/nophoto.png?${Date.now()}`}
            />
          ) : (
            <Image
              style={{ width: "10rem", height: "10rem" }}
              roundedCircle
              src={`${profile.personal_information.photo_url}?${Date.now()}`}
            />
          )}

          <button
            onClick={handleShowUpload}
            className="btn btn-outline-danger mt-3"
            disabled={!employeePhotoPermission.is_update}
          >
            Change Profile
          </button>
          {profile.personal_information.photo_url === "" ||
          !employeePhotoPermission.is_delete ? (
            <button
              className="btn btn-danger mt-2"
              style={{ width: "8rem" }}
              disabled
            >
              Delete
            </button>
          ) : (
            <button
              className="btn btn-danger mt-2"
              style={{ width: "8rem" }}
              onClick={handleShowDelete}
            >
              Delete
            </button>
          )}
        </div>
      </div>

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Delete Current Photo {profile.employee_name} ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary col-3" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="danger col-3" onClick={deletePhoto}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {check === false ? (
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showAlertSignature ? (
              <div
                className={
                  successUpload
                    ? "alert alert-success alert-dismissible fade show"
                    : "alert alert-danger alert-dismissible fade show"
                }
                role="alert"
              >
                <strong>{AlertMessageSignature}</strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setShowAlertSignature(false)}
                ></button>
              </div>
            ) : null}
            <div className="pb-1">
              <div className="custom-file-upload btn btn-outline-danger">
                <label>Browse</label>
                <input type="file" value="" onChange={onFileDrop} />
              </div>
              <div>
                <button
                  className="btn btn-danger float-end col-4 mb-4"
                  onClick={SubmitPhoto}
                >
                  Submit
                </button>
              </div>
            </div>

            <div className="mt-5 text-danger">
              <div>*File must be less than 1MB</div>
              <div>*Allowed file types: PNG, JPG, JPEG</div>
            </div>

            {fileList.length === 0 ? (
              <div
                ref={wrapperRef}
                className="drop-file-input mt-3"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              >
                <div className="drop-file-input__label">
                  <img alt="" />
                  <p>Drag & Drop your files here</p>
                </div>
                <input type="file" value="" onChange={onFileDrop} />
              </div>
            ) : null}
            {fileList.length > 0 ? (
              <div className="drop-file-preview">
                {fileList.map((item, index) => (
                  <div key={index} className="drop-file-preview__item">
                    <img alt="" />
                    <div className="drop-file-preview__item__info">
                      <p>{item.name}</p>
                      <p>{item.size}B</p>
                    </div>
                    <span
                      className="drop-file-preview__item__del"
                      onClick={() => fileRemove(item)}
                    >
                      x
                    </span>
                  </div>
                ))}
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
      ) : (
        <ModalMessage data={modalMessage} click={Refresh} />
      )}
    </>
  );
}

export default ModalProfile;
