import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getListStatusEmployee,
  updateEmployeeStatus,
} from "../../../data-api/employee";
import { useState } from "react";
import Select from "react-select";
import LoadingEffectForm from "../../template/loadingEffectForm";

function ModalEditStatus(props) {
  const [isLoading, setIsLoading] = useState(true);
  const employeeStatus = props.nik.employee_status.split(" - ");
  const [isConfirm, setIsConfirm] = useState(false);
  const [statusIndex, setStatusIndex] = useState("");

  const [listStatus, setListStatus] = useState([]);
  useEffect(() => {
    getListStatusEmployee().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListStatus([]);
      } else {
        if (employeeStatus[0] === "Active") {
          setListStatus(result.data.active);
          setStatusIndex(
            result.data.active.find(
              (item) =>
                item.label === props.nik.employee_status.split("Active - ")[1]
            )
          );
        } else if (employeeStatus[0] === "Non-Active") {
          setListStatus(result.data.resign);
          setStatusIndex(
            result.data.resign.find(
              (item) =>
                item.label ===
                props.nik.employee_status.split("Non-Active - ")[1]
            )
          );
        } else {
          setListStatus([]);
          setStatusIndex("");
        }
      }
      setIsLoading(false);
    });
  }, []);

  function formEditStatus(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setIsConfirm(true);
      } else {
        const json = JSON.stringify({
          status_id: statusIndex.value,
        });

        updateEmployeeStatus(props.nik.nik, json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            props.handleSuccessModal(result.meta);
          } else {
            props.handleSuccessModal(result.meta);
          }
          setIsConfirm(false);
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  return (
    <>
      <div className="container custom-text-14">
        <Modal.Header closeButton>
          <span className="custom-text-20 fw-semibold">
            Edit Employee Status
          </span>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <>
              <LoadingEffectForm row={3} />
            </>
          ) : (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">NIK</Form.Label>
                <Form.Label className="w-75">{props.nik.nik}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Name</Form.Label>
                <Form.Label className="w-75">
                  {props.nik.employee_name}
                </Form.Label>
              </Form.Group>
              <Form onSubmit={(e) => formEditStatus(e)}>
                {!isConfirm ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Status</Form.Label>
                      <Select
                        className="w-75 fw-normal custom-text-14"
                        options={listStatus.map((data) => {
                          return { value: data.value, label: data.label };
                        })}
                        defaultValue={{
                          label:
                            statusIndex !== ""
                              ? statusIndex.label
                              : employeeStatus[0] === "Active"
                              ? props.nik.employee_status.split("Active - ")[1]
                              : props.nik.employee_status.split(
                                  "Non-Active - "
                                )[1],
                        }}
                        onChange={(e) => setStatusIndex(e)}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minHeight: 35,
                          }),
                          menu: (base) => ({ ...base, zIndex: 9999 }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            fontSize: "14px",
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                      ></Select>
                    </Form.Group>
                    <div className="text-end">
                      <button
                        className="btn btn-danger mt-3 col-4 btn-sm"
                        type="submit"
                        name="submit"
                        value={"Submit"}
                      >
                        Confirm
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="w-25">Status</Form.Label>
                      <Form.Label className="w-75">
                        {statusIndex.label}
                      </Form.Label>
                    </Form.Group>
                    <div className="text-end">
                      <button
                        className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                        // onClick={(event) => {
                        //   setIsConfirm(false);
                        //   event.preventDefault();
                        // }}
                        type="submit"
                        name="cancel"
                        value={"Cancel"}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-danger mt-3 col-4 btn-sm"
                        type="submit"
                        name="submit"
                        value={"Submit"}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalEditStatus;
