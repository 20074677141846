import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Employee.css";
import { Button, Modal, Form } from "react-bootstrap";
import {
  faUser,
  faFolderOpen,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalProfile from "../modal/ModalProfile";
import ModalContract from "../modal/ModalContract";
import ModalExportDetail from "../modal/ModalExportDetail";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { getEmployees, getEmployeesFilter } from "../../../data-api/employee";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import { useOutletContext } from "react-router-dom";
import ModalEditStatus from "../modal/ModalEditStatus";
import ModalMessage from "../../template/ModalMessage";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import Select from "react-select";
import { ExcelDownload } from "../../../utils/ExcelDownload";
import DynamicTableModal from "../../../utils/DynamicTableModal";
import { STORAGE_KEY_EMPLOYEE } from "../../../utils/util";
registerLocale("en-US", enUs);

function TableEmployee(props) {
  const accessMenu = props.accessMenu;

  const outletContext = useOutletContext();
  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Employee"
  ).sub_menu;

  const employeePermission = access.find(
    (data) => data.sub_menu === "Employee"
  );
  const employeeExportPermission = access.find(
    (data) => data.sub_menu === "Employee Export"
  );
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    setResetColumn(columnsEmployee);
    loadDataFromStorage();
    setIsLoading(true);
    //fetch drop down list for employee filter
    getEmployeesFilter().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setFilterData([])
        : setFilterData(result.data);
    });

    let params = {
      status: status.value,
    };

    getEmployees(params).then((result) => {
      if (result.data === null || result.data === undefined) {
        setEmployee([]);
      } else {
        setEmployee(result.data);
      }
      setIsLoading(false);
    });
  }, []);

  //initiate var show modal Profile & Contract
  const [showProfileContract, setShowProfileContract] = useState(false);
  const handleCloseProfileContract = () => {
    setShowProfileContract(false);
  };
  function handleShowProfileContract(title, nik) {
    setShowProfileContract(true);
    setNik(nik);
    setModaltitle(title);
  }

  const [showEditStatus, setShowEditStatus] = useState(false);
  function handleShowEditStatus(data) {
    setShowEditStatus(true);
    setNik(data);
  }

  //initiate var show modal export detail
  const [showExport, setShowExport] = useState(false);

  //initiate store nik + title for modal Profile & Contract
  const [nik, setNik] = useState("");
  const [modaltitle, setModaltitle] = useState("");

  const [modalFilter, setModalFilter] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // initiate store filter
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");
  const [status, setStatus] = useState({ value: "1", label: "Active" });
  const [position, setPosition] = useState("");
  const [placement, setPlacement] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customFilter, setCustomFilter] = useState("");

  // initiate store filter temporary
  const [companyTemp, setCompanyTemp] = useState("");
  const [departmentTemp, setDepartmentTemp] = useState("");
  const [statusTemp, setStatusTemp] = useState("");
  const [positionTemp, setPositionTemp] = useState("");
  const [placementTemp, setPlacementTemp] = useState("");
  const [startDateTemp, setStartDateTemp] = useState("");
  const [endDateTemp, setEndDateTemp] = useState("");
  const [customFilterTemp, setCustomFilterTemp] = useState("");

  const [showFilterColumn, setshowFilterColumn] = useState(false);

  //func onclick button filter
  const formFilterHandler = () => {
    if (customFilterTemp === "") {
      setCompany(companyTemp);
      setDepartment(departmentTemp);
      setStatus(statusTemp);
      setPosition(positionTemp);
      setPlacement(placementTemp);
      if (startDateTemp !== "") {
        setStartDate(moment(startDateTemp).format("yyyy-MM-DD"));
      } else {
        setStartDate("");
      }
      if (endDateTemp !== "") {
        setEndDate(moment(endDateTemp).format("yyyy-MM-DD"));
      } else {
        setEndDate("");
      }
    } else {
      setCustomFilter(customFilterTemp);
      setCompany("");
      setDepartment("");
      setStatus("");
      setPosition("");
      setPlacement("");
      setStartDate("");
      setEndDate("");
    }
    fetchFilterEmployee(
      companyTemp.value,
      departmentTemp.value,
      statusTemp.value,
      positionTemp.value,
      placementTemp.value,
      startDateTemp === "" ? "" : moment(startDateTemp).format("yyyy-MM-DD"),
      endDateTemp === "" ? "" : moment(endDateTemp).format("yyyy-MM-DD"),
      customFilterTemp
    );
    setModalFilter(false);
  };

  //func fetch filter employee
  function fetchFilterEmployee(
    company,
    department,
    status,
    position,
    placement,
    startDate,
    endDate,
    customFilter
  ) {
    company = company !== "" ? company : "";
    department = department !== "" ? department : "";
    status = status !== "" ? status : "";
    position = position !== "" ? position : "";
    placement = placement !== "" ? placement : "";

    setIsLoading(true);
    let params = {
      company: company,
      department: department,
      status: status,
      position: position,
      placement: placement,
      start_date:
        startDate !== "" ? moment(startDate).format("yyyy-MM-DD") : "",
      end_date: endDate !== "" ? moment(endDate).format("yyyy-MM-DD") : "",
      f: customFilter,
    };

    getEmployees(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setEmployee([]);
      } else {
        setEmployee(result.data);
      }
      setIsLoading(false);
    });
  }

  // add custom new cell for button profile & contract
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "profile",
        Header: "Profile",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowProfileContract("Profile", row.values.nik)
              }
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "contract",
        Header: "Contract",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowProfileContract("Contract", row.values.nik)
              }
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "edit_status",
        Header: "Edit Status",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowEditStatus(row.values)}
              disabled={!employeePermission.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  //initiate column name for employee table
  const [resetColumn, setResetColumn] = useState([]);
  const [columnsEmployee, setColumnsEmployee] = useState([
    {
      Header: "NIK",
      accessor: "nik", // accessor is the "key" in the data for react-table
      Cell: ({ row }) => (
        <span className="nowrap text-center">{row.values.nik}</span>
      ),
      active: true,
    },
    {
      Header: "Name",
      accessor: "employee_name",
      active: true,
    },
    {
      Header: "Work Status",
      accessor: "work_status",
      active: true,
    },
    {
      Header: "Employee Status",
      accessor: "employee_status",
      active: true,
    },
    {
      Header: "Join Date",
      accessor: "join_date",
      Cell: (props) => (
        <span className="nowrap">
          {moment(props.value).format("DD-MMM-YYYY")}
        </span>
      ),
      active: true,
    },
    {
      Header: "Position",
      accessor: "position",
      active: true,
    },
    {
      Header: "Department",
      accessor: "department_name",
      active: true,
    },
    {
      Header: "Grade",
      accessor: "grade",
      active: true,
    },
    {
      Header: "Placement",
      accessor: "placement",
      active: true,
    },
  ]);

  const EmptyData = useMemo(() => [], []);
  const data = employee === null ? EmptyData : employee;

  // func export to excel xls
  function handleDownloadExcel() {
    ExcelDownload(columnsEmployee, [], data, ["id", "no"], "Employee", [
      "join_date",
    ]);
  }

  //func onClick button filter h-60 & non-ps
  function customFilterClick(type) {
    if (type === "non-ps") {
      if (customFilterTemp === "" || customFilterTemp === "h-60") {
        setCustomFilterTemp("non-ps");
      } else {
        setCustomFilterTemp("");
        setCustomFilter("");
      }
    } else if (type === "h-60") {
      if (customFilterTemp === "" || customFilterTemp === "non-ps") {
        setCustomFilterTemp("h-60");
      } else {
        setCustomFilterTemp("");
        setCustomFilter("");
      }
    }
  }

  //func remove filter on click
  function removeFilter(type) {
    if (type === "company") {
      fetchFilterEmployee(
        "",
        department.value,
        status.value,
        position.value,
        placement.value,
        startDate,
        endDate,
        customFilter
      );
      setCompany("");
      setCompanyTemp("");
    } else if (type === "department") {
      fetchFilterEmployee(
        company.value,
        "",
        status.value,
        position.value,
        placement.value,
        startDate,
        endDate,
        customFilter
      );
      setDepartment("");
      setDepartmentTemp("");
    } else if (type === "status") {
      fetchFilterEmployee(
        company.value,
        department.value,
        "",
        position.value,
        placement.value,
        startDate,
        endDate,
        customFilter
      );
      setStatus("");
      setStatusTemp("");
    } else if (type === "position") {
      fetchFilterEmployee(
        company.value,
        department.value,
        status.value,
        "",
        placement.value,
        startDate,
        endDate,
        customFilter
      );
      setPosition("");
      setPositionTemp("");
    } else if (type === "placement") {
      fetchFilterEmployee(
        company.value,
        department.value,
        status.value,
        position.value,
        "",
        startDate,
        endDate,
        customFilter
      );
      setPlacement("");
      setPlacementTemp("");
    } else if (type === "startdate") {
      fetchFilterEmployee(
        company.value,
        department.value,
        status.value,
        position.value,
        placement.value,
        "",
        endDate,
        customFilter
      );
      setStartDate("");
      setStartDateTemp("");
    } else if (type === "enddate") {
      fetchFilterEmployee(
        company.value,
        department.value,
        status.value,
        position.value,
        placement.value,
        startDate,
        "",
        customFilter
      );
      setEndDate("");
      setEndDateTemp("");
    } else if (type === "custom-filter") {
      fetchFilterEmployee(
        company.value,
        department.value,
        status.value,
        position.value,
        placement.value,
        startDate,
        endDate,
        ""
      );
      setCustomFilter("");
      setCustomFilterTemp("");
      setCompanyTemp("");
      setDepartmentTemp("");
      setStatusTemp("");
      setPositionTemp("");
      setPlacementTemp("");
      setStartDateTemp("");
      setEndDateTemp("");
    }
  }

  function handleFilter() {
    setModalFilter(true);
  }

  function handleSetting() {
    setshowFilterColumn(true);
  }

  function handleSuccessModal(message) {
    setShowEditStatus(false);
    setShowModalMessage(true);
    setModalMessage(message);
  }

  function refreshTable() {
    setShowModalMessage(false);
    getEmployees().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setEmployee([]);
      } else {
        setEmployee(result.data);
      }
    });
  }

  function loadDataFromStorage() {
    const serializedData = localStorage.getItem(STORAGE_KEY_EMPLOYEE);
    if (serializedData !== null) {
      let data = JSON.parse(serializedData);
      setColumnsEmployee(data);
    }
  }

  return (
    <>
      <div className="tabs-content">
        {/* show state filter */}
        <div className="d-flex flex-row bd-highlight mb-3 mt-4">
          {startDate !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Start : {startDate}{" "}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("startdate")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {endDate !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              End : {endDate}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("enddate")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {company !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Company : {company.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("company")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {department !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {department.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("department")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {status !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Status : {status.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("status")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {position !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {position.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("position")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {placement !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {placement.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("placement")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {customFilter !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {customFilter === "non-ps" ? "Vendor" : customFilter}{" "}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("custom-filter")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>

        <div className="row custom-text-14 w-100 ms-1 mb-3">
          <div className="col-4"></div>
          <div className="col-8 d-flex justify-content-end">
            <button
              className="btn btn-danger btn-sm ms-3 col-3 custom-text-14"
              onClick={() => setShowExport(true)}
              // style={{ width: "15.5%" }}
              disabled={!employeeExportPermission.is_read}
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ color: "#fff", marginRight: "10px" }}
              />
              View Details
            </button>
          </div>
        </div>

        <ReactTableCustomHooks
          columns={columnsEmployee}
          data={employee}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={handleFilter}
          tableHooks={tableHooks}
          hiddenColumns={
            columnsEmployee.filter((item) => item.active === false) === null
              ? []
              : columnsEmployee
                  .filter((item) => item.active === false)
                  .map((item) => item.accessor)
          }
          paging={[10, 20, 50, 100]}
          isLoading={isLoading}
          handleSetting={handleSetting}
        />
      </div>

      {/* Show modal Profle / Contract */}
      <Modal
        show={showProfileContract}
        onHide={handleCloseProfileContract}
        dialogClassName="modal-profile"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modaltitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modaltitle === "Profile" ? (
            <ModalProfile data={nik} accessMenu={accessMenu} />
          ) : (
            <ModalContract data={nik} />
          )}
        </Modal.Body>
      </Modal>

      {/* show modal filter */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            {isLoading ? (
              <>
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-semibold">Join Date</Form.Label>
                  <div className="d-flex align-items-start">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-50 form-control"
                      todayButton="Today"
                      dateFormat="dd/MM/yyyy"
                      selected={startDateTemp ? startDateTemp : ""}
                      onChange={(e) => setStartDateTemp(e)}
                      placeholderText="DD/MM/YYYY"
                      onInvalid={(e) => false}
                      onInput={(e) => e.target.setCustomValidity("")}
                      // required={endPeriode === "" ? false : true}
                      disabled={customFilterTemp !== "" ? true : false}
                      isClearable
                    />
                    <p className="fs-4 ms-2 me-2"> - </p>
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-50 form-control"
                      todayButton="Today"
                      dateFormat="dd/MM/yyyy"
                      selected={endDateTemp ? endDateTemp : ""}
                      onChange={(e) => setEndDateTemp(e)}
                      placeholderText="DD/MM/YYYY"
                      onInvalid={(e) => false}
                      onInput={(e) => e.target.setCustomValidity("")}
                      // required={endPeriode === "" ? false : true}
                      disabled={customFilterTemp !== "" ? true : false}
                      isClearable
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold">
                  <Form.Label>Company</Form.Label>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={filterData.company.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={companyTemp}
                    placeholder={"-- Select Company --"}
                    defaultValue={{
                      label: company.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setCompanyTemp(e);
                      } else {
                        setCompanyTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold">
                  <Form.Label>Department</Form.Label>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={filterData.department.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={departmentTemp}
                    placeholder={"-- Select Department --"}
                    defaultValue={{
                      label: department.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setDepartmentTemp(e);
                      } else {
                        setDepartmentTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold">
                  <Form.Label>Status</Form.Label>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={filterData.status.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={statusTemp}
                    placeholder={"-- Select Status --"}
                    defaultValue={{
                      label: status.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setStatusTemp(e);
                      } else {
                        setStatusTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold">
                  <Form.Label>Position</Form.Label>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={filterData.position.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={positionTemp}
                    placeholder={"-- Select Position --"}
                    defaultValue={{
                      label: position.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setPositionTemp(e);
                      } else {
                        setPositionTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold">
                  <Form.Label>Placement</Form.Label>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={filterData.placement.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={placementTemp}
                    placeholder={"-- Select Placement --"}
                    defaultValue={{
                      label: placement.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setPlacementTemp(e);
                      } else {
                        setPlacementTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <div className="d-flex justify-content-start mb-4">
                  {/* <button onClick={() => fetchFilterEmployeeButton("non-ps")}  className="btn btn-light col-4 fw-semibold shadow " type="button">Non-PS</button> */}
                  <button
                    onClick={() => customFilterClick("non-ps")}
                    className={`btn btn-light col-4 fw-semibold shadow  ${
                      customFilterTemp === "non-ps" ? "btn-custom-filter" : ""
                    }`}
                    type="button"
                  >
                    Vendor
                  </button>
                  <button
                    onClick={() => customFilterClick("h-60")}
                    className={`btn btn-light col-4 fw-semibold shadow ms-4  ${
                      customFilterTemp === "h-60" ? "btn-custom-filter" : ""
                    }`}
                    type="button"
                  >
                    H-60
                  </button>
                </div>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow"
                    onClick={formFilterHandler}
                  >
                    FILTER
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showEditStatus}
        onHide={() => setShowEditStatus(false)}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalEditStatus nik={nik} handleSuccessModal={handleSuccessModal} />
      </Modal>

      {/* Modal Export */}
      <Modal
        show={showExport}
        onHide={() => setShowExport(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalExportDetail />
      </Modal>

      {showModalMessage === true ? (
        <ModalMessage data={modalMessage} click={refreshTable} />
      ) : null}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="sm"
        show={showFilterColumn}
        onHide={() => setshowFilterColumn(false)}
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Setting Table</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            <DynamicTableModal
              columnsHeader={columnsEmployee}
              setColumnHeader={setColumnsEmployee}
              setCloseModal={setshowFilterColumn}
              resetColumn={resetColumn}
              storageKey={STORAGE_KEY_EMPLOYEE}
            />
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default TableEmployee;
