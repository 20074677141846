import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./idle.css";
import { getIdleFilter, getIdleWaiting } from "../../data-api/idle";
import ReactTable from "../../utils/ReactTable";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import { Form, Modal } from "react-bootstrap";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import Select from "react-select";
import ModalShowPDF from "../../utils/ModalShowPDF";
registerLocale("en-US", enUs);

function TableIdleWaiting(props) {
  const [filterClient, setFilterClient] = useState("");
  const [filterClientTemp, setFilterClientTemp] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterStartDateTemp, setFilterStartDateTemp] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterEndDateTemp, setFilterEndDateTemp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  useEffect(() => {
    setIsLoading(true);
    getIdleFilter().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setClients([])
        : setClients(result.data.filter_client);
    });

    fetchIdleWaiting();
  }, []);

  function fetchIdleWaiting(filterClient, filterStartDate, filterEndDate) {
    setIsLoading(true);
    getIdleWaiting({
      client: filterClient,
      startDate: filterStartDate,
      endDate: filterEndDate,
    }).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setIdle([])
        : setIdle(result.data);
      setIsLoading(false);
    });
  }

  function handleFilter() {
    setModalFilter(true);
  }

  const [clients, setClients] = useState([]);
  const [idle, setIdle] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);

  function filterIdle(event) {
    event.preventDefault();
    setFilterStartDate(
      filterStartDateTemp === "" || filterStartDateTemp === null
        ? ""
        : moment(filterStartDateTemp).format("yyyy-MM-DD")
    );
    setFilterEndDate(
      filterEndDateTemp === "" || filterEndDateTemp === null
        ? ""
        : moment(filterEndDateTemp).format("yyyy-MM-DD")
    );
    setFilterClient(
      filterClientTemp === "" || filterClientTemp === null
        ? ""
        : filterClientTemp
    );
    fetchIdleWaiting(
      filterClientTemp === "" ? "" : filterClientTemp.label,
      filterStartDateTemp === "" || filterStartDateTemp === null
        ? ""
        : moment(filterStartDateTemp).format("yyyy-MM-DD"),
      filterEndDateTemp === "" || filterEndDateTemp === null
        ? ""
        : moment(filterEndDateTemp).format("yyyy-MM-DD")
    );
    setModalFilter(false);
  }

  function removeFilter(type) {
    if (type === "client") {
      fetchIdleWaiting("", filterStartDate, filterEndDate);
      setFilterClient("");
      setFilterClientTemp("");
    } else if (type === "start_date") {
      fetchIdleWaiting(filterClient.label, "", filterEndDate);
      setFilterStartDate("");
      setFilterStartDateTemp("");
    } else if (type === "end_date") {
      fetchIdleWaiting(filterClient.label, filterStartDate, "");
      setFilterEndDate("");
      setFilterEndDateTemp("");
    }
  }

  //initiate column name for employee table
  const columnsIdle = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Role",
        accessor: "position",
      },
      {
        Header: "Client",
        accessor: "client",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) =>
          props.value === "" ? "" : moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) =>
          props.value === "" ? "" : moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "PDF",
        accessor: "upload_url",
        Cell: (props) => {
          return props.value !== "" ? (
            <button
              style={{
                padding: "0",
                border: "none",
                background: "none",
                textDecoration: "underline",
                color: "#0d6efd",
              }}
              // href={props.value}
              // href={`${props.value}?${Date.now()}`}
              href={`#`}
              // target="_blank"
              // rel="noreferrer"
              onClick={() => handleShowPDF(`${props.value}?${Date.now()}`)}
            >
              {props.value === "" ? "" : "View PDF"}
            </button>
          ) : (
            <p>-</p>
          );
        },
      },
    ],
    []
  );

  //initiate empty employee
  const EmptyData = useMemo(() => [], []);
  const data = idle === null ? EmptyData : idle;
  // func export to excel xls
  function handleDownloadExcel() {
    const columnsExcel = columnsIdle.map((item) => item.Header);
    data.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
    });
    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Idle Waiting Approval",
    });
  }

  return (
    <>
      {/* <div className="table-content-function-idle"> */}
      <div className="tabs-content">
        {/* show state filter */}
        <div className="ms-3 d-flex flex-row bd-highlight mb-3 mt-2">
          {filterClient !== "" && filterClient !== null ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Client: {filterClient.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("client")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterStartDate !== "" && filterStartDate !== null ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Start Date: {filterStartDate}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("start_date")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterEndDate !== "" && filterEndDate !== null ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              End Date: {filterEndDate}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("end_date")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>
        <ReactTable
          columns={columnsIdle}
          data={idle}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={handleFilter}
          isLoading={isLoading}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>Filter</Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form onSubmit={(e) => filterIdle(e)}>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semibold">Start Date</Form.Label>
                <div className="w-100">
                  <ReactDatePicker
                    locale={enUs}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={filterStartDateTemp}
                    onChange={(e) => setFilterStartDateTemp(e)}
                    placeholderText="DD/MM/YYYY"
                    isClearable
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semibold">End Date</Form.Label>
                <div className="w-100 ">
                  <ReactDatePicker
                    locale={enUs}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={filterEndDateTemp}
                    onChange={(e) => setFilterEndDateTemp(e)}
                    placeholderText="DD/MM/YYYY"
                    isClearable
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold ">
                <p>Select Clients </p>
                <Select
                  className="w-100 fw-normal custom-text-14"
                  options={clients.map((data) => {
                    return { value: data.value, label: data.label };
                  })}
                  isClearable
                  value={filterClientTemp}
                  placeholder={"-- Select Clients --"}
                  defaultValue={{
                    label: filterClient.label,
                  }}
                  onChange={(e) => {
                    if (e !== null) {
                      setFilterClientTemp(e);
                    } else {
                      setFilterClientTemp("");
                    }
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                ></Select>
              </Form.Group>
              <div className="d-grid gap-2">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow">
                  FILTER
                </button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default TableIdleWaiting;
