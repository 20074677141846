import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { updateCaptainGroup } from "../../../data-api/tl-structure";
import { ReactSelect } from "../../../utils/ReactSelect";
function TLStructureEdit(props) {
  const [group, setGroup] = useState(
    props.data.group === "" || props.data.group === undefined
      ? ""
      : { label: props.data.group, value: props.data.group }
  );
  const [isFetch, setIsFetch] = useState(false);

  function editGroup(event) {
    setIsFetch(true);
    event.preventDefault();
    if (group !== "") {
      const json = JSON.stringify({
        group: group.value.toString(),
      });

      updateCaptainGroup(props.data.nik, json).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        if (result.meta.status === "success") {
          props.handleSuccessModal(true, message.message);
        } else {
          props.handleSuccessModal(false, message.message);
        }
        setIsFetch(false);
      });
    } else {
    }
    setIsFetch(false);
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Update Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form.Label className="fw-semibold">
          {props.data.nik} - {props.data.name}
        </Form.Label>
        <Form onSubmit={(e) => editGroup(e)}>
          <Form.Group className="mb-3 fw-semibold">
            <Form.Label>GROUP</Form.Label>
            <ReactSelect
              value={group}
              setValue={setGroup}
              data={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
                {
                  value: "4",
                  label: "4",
                },
                {
                  value: "5",
                  label: "5",
                },
              ]}
              isLoading={false}
              placeholder={"-- Select Group --"}
              validationMessage={"Please Select Group"}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <button
              className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow btn-sm"
              disabled={isFetch}
            >
              {isFetch ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
}

export default TLStructureEdit;
