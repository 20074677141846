import { faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";

function DashboardChart({ chart, width = "100%", height = "250px" }) {
  return (
    <>
      {Object.keys(chart).length !== 0 ? (
        <div className="card h-100">
          <div
            className="card-body d-flex flex-column"
            style={{ padding: "0" }}
          >
            <h5 className="custom-text-18 text-start pt-3 px-4">
              {chart.options.chart.id}
            </h5>
            <hr />
            {chart.zoomOnClick !== undefined && chart.zoomOnClick !== null ? (
              <div className="text-end">
                <button
                  onClick={chart.zoomOnClick}
                  title="Zoom Chart"
                  style={{
                    padding: "0",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassPlus}
                    style={{ color: "gray" }}
                  />
                </button>
              </div>
            ) : null}
            <div className="flex-grow-1 row">
              <div className="my-auto">
                <Chart
                  options={chart.options}
                  series={chart.series}
                  type={chart.chart_type}
                  width={width}
                  height={height}
                />
              </div>
            </div>
            {chart.subTotal !== undefined && chart.subTotal !== null ? (
              <>
                <div className="d-flex justify-content-end">
                  <div
                    className="me-3 mb-2"
                    onClick={chart.subTotalOnClick}
                    style={{ cursor: "pointer" }}
                  >
                    Subtotal : {chart.subTotal}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DashboardChart;
