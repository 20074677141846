import {
  faCircleCheck,
  faCircleXmark,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import AddCaptain from "../modal/AddCaptain";
import { getCaptain } from "../../../data-api/tl-structure";
import ReactTable from "../../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";

function TabCaptain(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [showAddCaptain, setShowAddCaptain] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);

    fetchCaptain();
    setShowAddCaptain(false);
  }

  useEffect(() => {
    fetchCaptain();
  }, []);

  function fetchCaptain() {
    getCaptain().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Month Period",
        accessor: "month_periode",
      },
    ],
    []
  );

  function handleDownloadExcel() {
    const columnsExcel = columns.map((item) => item.Header);

    const dataSet = [];
    const dataHeader = [];

    //append header table
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "TL Structure Captain",
    });
  }

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowAddCaptain(false);
    setShowSuccess(true);
  }

  return (
    <>
      <div className="tabs-content">
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}

        <div className="row custom-text-14 w-100 ms-1 mb-3">
          <div className="col-4"></div>
          <div className="col-8 d-flex justify-content-end">
            <button
              className="btn btn-danger btn-sm ms-3 col-3 custom-text-14"
              onClick={() => setShowAddCaptain(true)}
              disabled={!props.accessMenu.is_create}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginRight: "10px" }}
                className="fa-cloud-arrow-down"
              />
              Add
            </button>
          </div>
        </div>
        <ReactTable
          columns={columns}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          isLoading={isLoading}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>

      {/* modal Add */}
      <Modal
        show={showAddCaptain}
        onHide={() => setShowAddCaptain(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <AddCaptain
          setAlert={showAlertError}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3"
                onClick={() => {
                  fetchCaptain();
                  setShowSuccess(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TabCaptain;
