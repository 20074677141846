import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getGenerateNIK,
  getListDropdownTalentP,
  postTalentPartnership,
} from "../../../data-api/talent-partnership";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDropdownPSManager,
  getDropdownTeamLeader,
} from "../../../data-api/tl-structure";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { FormatDate } from "../../template/format";
import { InvalidInput, ReactSelect } from "../../../utils/ReactSelect";
import Select from "react-select";
import LoadingEffectForm from "../../template/loadingEffectForm";
registerLocale("en-GB", enGb);

function ModalAddTalentP(props) {
  const [positions, setPositions] = useState([]);
  const [relations, setRelations] = useState([]);
  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  useEffect(() => {
    getListDropdownTalentP().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        setPositions(res.data.positions);
        setRelations(res.data.relations);
        setClients(res.data.placements);
        setStatuses(res.data.statuses);
      } else {
        showAlertError(false, res.meta.message);
      }
      setIsLoading(false);
    });
    getGenerateNIK().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        setNik(res.data);
      } else {
        showAlertError(false, res.meta.message);
      }
      setIsLoading(false);
    });
    getDropdownPSManager().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListPSManager([]);
      } else {
        setListPSManager(result.data);
      }
      setIsLoading(false);
    });
  }, []);

  function fetchTeamLeader(psManager) {
    let params = {
      ps_manager: psManager,
    };
    getDropdownTeamLeader(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListTeamLeader([]);
      } else {
        setListTeamLeader(result.data);
      }
      setIsLoading(false);
    });
  }

  const [listPSManager, setListPSManager] = useState([]);
  const [listTeamLeader, setListTeamLeader] = useState([]);

  const [psManager, setPsManager] = useState("");
  const [teamLeader, setTeamLeader] = useState("");

  const [nik, setNik] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [grade, setGrade] = useState("");
  const [source, setSource] = useState("");
  const [relation, setRelation] = useState("");
  const [client, setClient] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [addConfirm, setAddConfirm] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const handleAddTalentP = (e) => {
    e.preventDefault();
    if (!addConfirm) {
      setNik(e.target.nik.value);
      setName(e.target.name.value);
      setEmail(e.target.email.value);
      setGrade(e.target.grade.value);
      setSource(e.target.source.value);
      setAddConfirm(true);
    } else {
      setIsFetch(true);

      const data = {
        nik: nik,
        employee_name: name,
        email: email,
        position: position.value,
        grade: grade,
        source_talent: source,
        relation: parseInt(relation.value),
        client_id: parseInt(client.value),
        status: status.value,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        leader_nik: teamLeader.value,
      };

      postTalentPartnership(data)
        .then((res) => {
          if (res.meta.code === 401) {
            window.location.reload();
            return;
          }
          const message = {
            code: res.meta.code,
            message: res.meta.message,
          };
          props.close();
          props.message(message);
          setIsFetch(false);
        })
        .catch((error) => {
          setIsFetch(false);
        });
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffectForm row={13} />
        </Modal.Body>
      </>
    );

  return (
    <>
      {showAlert ? (
        <div
          className={
            AlertSuccess
              ? "alert alert-success alert-dismissible fade show"
              : "alert alert-danger alert-dismissible fade show"
          }
          role="alert"
        >
          <strong>{AlertMessage}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title>
          {!addConfirm ? "Add Vendor Employee" : "Confirm Add Vendor Employee"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {!addConfirm ? (
            // Modal Add Talent
            <Form
              onSubmit={(e) => handleAddTalentP(e)}
              className="custom-text-14"
            >
              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">NIK</Form.Label>
                <Form.Control
                  id="nik"
                  name="nik"
                  className="w-75 form-control-sm"
                  type="text"
                  defaultValue={nik}
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Name</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  className="w-75 form-control-sm"
                  type="text"
                  defaultValue={name}
                  pattern="[a-zA-Z' ']+"
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "Please fill out Name using letter"
                    );
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Email</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  className="w-75 form-control-sm"
                  type="email"
                  defaultValue={email}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "Please fill out Email with email format"
                    );
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Grade</Form.Label>
                <Form.Control
                  id="grade"
                  name="grade"
                  className="w-75 form-control-sm"
                  type="text"
                  pattern="\d*"
                  minLength={"1"}
                  maxLength={"1"}
                  onInvalid={(e) => {
                    if (isNaN(e.target.value)) {
                      e.target.setCustomValidity("Please input just number");
                    } else {
                      e.target.setCustomValidity("Please fill out Grade");
                    }
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  defaultValue={grade}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Source</Form.Label>
                <Form.Control
                  id="source"
                  name="source"
                  className="w-75 form-control-sm"
                  type="text"
                  defaultValue={source}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please fill out Source")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Position</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={position}
                    setValue={setPosition}
                    data={positions.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Position --"}
                    validationMessage={"Please Select Position"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Relation</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={relation}
                    setValue={setRelation}
                    data={relations.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Relation --"}
                    validationMessage={"Please Select Relation"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Client</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={client}
                    setValue={setClient}
                    data={clients.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Client --"}
                    validationMessage={"Please Select Client"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Status</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={status}
                    setValue={setStatus}
                    data={statuses.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Status --"}
                    validationMessage={"Please Select Status"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Start Date</Form.Label>
                <div className="w-75">
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate ? startDate : ""}
                    onChange={(value) => {
                      setStartDate(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Start Date")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">End Date</Form.Label>
                <div className="w-75">
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate ? endDate : ""}
                    onChange={(value) => {
                      setEndDate(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out End Date")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">PS Manager</Form.Label>
                <div className="w-75">
                  <Select
                    className="w-100 fw-normal custom-text-14 z-3"
                    options={listPSManager.map((data) => ({
                      label: data.name,
                      value: data.nik,
                    }))}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    isClearable
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={"-- Select PS Manager --"}
                    defaultValue={psManager}
                    onChange={(e) => {
                      setTeamLeader("");
                      if (e !== null) {
                        setPsManager(e);
                        fetchTeamLeader(e.value);
                      } else {
                        setTeamLeader("");
                        setListTeamLeader([]);
                      }
                    }}
                    ariaLiveMessages={"Please Select PS Manager"}
                    // components={{
                    //   Input: invalidInput,
                    // }}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">
                  Team Leader
                </Form.Label>
                <div className="w-75">
                  <Select
                    className="w-100 fw-normal custom-text-14 z-3"
                    options={
                      !isLoading &&
                      listTeamLeader.map((data) => ({
                        label: data.name,
                        value: data.nik,
                      }))
                    }
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    isClearable
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={"-- Select Team Leader --"}
                    value={teamLeader}
                    defaultValue={teamLeader}
                    onChange={(e) => {
                      if (e !== null) {
                        setTeamLeader(e);
                      } else {
                        setTeamLeader("");
                      }
                    }}
                    ariaLiveMessages={"Please Select Team Leader"}
                    // components={{
                    //   Input: invalidInput,
                    // }}
                  ></Select>
                </div>
              </Form.Group>
              <div className="d-flex flex-row-reverse bd-highlight">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm">
                  Confirm
                </button>
              </div>
            </Form>
          ) : (
            // Modal Confirm
            <>
              <div className="container custom-text-14">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">NIK</Form.Label>
                  <Form.Label className="mt-2">{nik}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Name</Form.Label>
                  <Form.Label className="mt-2">{name}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Email</Form.Label>
                  <Form.Label className="mt-2">{email}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Grade</Form.Label>
                  <Form.Label className="mt-2">{grade}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Source</Form.Label>
                  <Form.Label className="mt-2">{source}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Position</Form.Label>
                  <Form.Label className="mt-2">{position.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Relation</Form.Label>
                  <Form.Label className="mt-2">{relation.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Client</Form.Label>
                  <Form.Label className="mt-2">{client.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Status</Form.Label>
                  <Form.Label className="mt-2">{status.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(startDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(endDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">PS Manager</Form.Label>
                  <Form.Label className="mt-2"> {psManager.label} </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Team Leader</Form.Label>
                  <Form.Label className="mt-2"> {teamLeader.label} </Form.Label>
                </Form.Group>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={handleAddTalentP}
                    disabled={isFetch}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={() => setAddConfirm(false)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalAddTalentP;
