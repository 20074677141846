import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "../components/header/Header";
import SideNavBar from "../components/sidebar/Sidebar";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import PageDualLogin from "./PageDualLogin";
import PageNetworkError from "./PageNetworkError";

function PrivateRoute() {
  const cookies = new Cookies();
  const location = useLocation();
  const navigate = useNavigate();

  const [isExpanded, setExpendState] = useState(false);
  const [title, setTitle] = useState("");
  const [menu, setMenu] = useState([]);
  const [profile, setProfile] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [networkError, setErrorNerwork] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let auth = cookies.get("session") !== undefined;
  if (!auth) {
    setTimeout(function () {
      cookies.remove("session");
      navigate("/login");
    }, 3000);
  }

  useEffect(() => {
    if (auth) {
      fetchUserDetail();
    } else {
      setIsLoading(false);
    }
    // });
  }, []);

  function fetchUserDetail() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${cookies.get("session")}`,
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then((res) => {
        if (res.data.meta.code === 200) {
          setMenu(res.data.data.user_menu);
          setProfile(res.data.data.profile);
          // let pathCurrent = location.pathname.split("/");
          // let menuAccess = res.data.data.user_menu.find(
          //   (data) => data.path === "/" + pathCurrent[1]
          // );
          // if (menuAccess === undefined && menuAccess !== "profile") {
          //   navigate("/undefined");
          // }

          if (location.pathname !== "/profile") {
            let pathCurrent = location.pathname.split("/");
            let menuAccess = res.data.data.user_menu.find(
              (data) => data.menu_path === "/" + pathCurrent[1]
            );
            if (
              menuAccess === undefined ||
              !menuAccess.menu_active ||
              !menuAccess.access
            ) {
              navigate("/undefined");
            }
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        navigate("/");
        setMenu([]);
        // setIsLoading(false);
        if (error.code === "ERR_NETWORK") {
          setErrorNerwork(true);
        } else {
          if (
            error.response.data.meta.message !== undefined ||
            error.response.data.meta.message !== null
          ) {
            setErrorMessage(error.response.data.meta.message);
            setErrorCode(error.response.data.meta.code);
          } else {
            setErrorMessage(error.response.statusText);
            setErrorCode(error.response.status);
          }
          setTimeout(function () {
            cookies.remove("session");
            window.location.reload();
          }, 3000);
          // cookies.remove("session")
        }
        setIsLoading(false);
      });
  }

  function openBar(event) {
    // if (isExpanded) {
    //   document.getElementsByClassName("custom-container")[0].style.maxWidth =
    //     "1100px";
    // } else {
    //   document.getElementsByClassName("custom-container")[0].style.width =
    //     "100%";
    // }
    event.preventDefault();
    setExpendState(!isExpanded);
  }

  if (isLoading)
    return (
      <>
        <div id="wrap">
          <div id="body-main">
            <div id="main-content">
              <div id="main">
                <div className="m-auto align-middle mt-5 justify-content-center">
                  <>
                    {/* <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> */}
                  </>
                  {/* ) : (
                    <>
                      <PageDualLogin code={errorCode} message={errorMessage} />
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  return auth ? (
    <div id="wrap">
      <div id="body-main">
        {networkError ? (
          <>
            <div id="main-content">
              <div id="main">
                <div className="m-auto align-middle mt-5 justify-content-center">
                  <PageNetworkError />
                </div>
              </div>
            </div>
          </>
        ) : errorMessage !== "" ? (
          <>
            <div id="main-content">
              <div id="main">
                <div className="m-auto align-middle mt-5 justify-content-center">
                  <PageDualLogin code={errorCode} message={errorMessage} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div id="sidebar">
              <SideNavBar
                isExpanded={isExpanded}
                setExpendState={openBar}
                setTitle={setTitle}
                menu={menu}
              />
            </div>
            <div id="main-content">
              <div id="header">
                <Header
                  isExpanded={isExpanded}
                  setExpendState={openBar}
                  title={title}
                  profile={profile}
                  menu={menu}
                />
              </div>

              <div id="main" className="p-5 custom-text-14">
                <Outlet
                  context={{
                    profile: profile,
                    resetSidebar: fetchUserDetail,
                    accessMenu: menu,
                    sidebarExpand: isExpanded,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div id="wrap">
      <div id="body-main">
        <div id="main-content">
          <div id="main">
            <div className="m-auto align-middle mt-5 justify-content-center">
              <PageDualLogin
                code={"401"}
                message={`Unauthorized: your session has expired`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PrivateRoute;
