import {
  faAnglesLeft,
  faAnglesRight,
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
  faPlusCircle,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import "../Superadmin.css";
import { Image, Modal } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import {
  getApprovalPersonnel,
  updateApprovalPersonnelCompany,
  updateApprovalPersonnelStatus,
} from "../../../data-api/master-user";
import moment from "moment";
import ModalAddApproval from "./modal/ModalAddApproval";
import ModalDeleteApproval from "./modal/ModalDeleteApproval";

function ApprovalUser() {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Master User"
  ).sub_menu;
  const accessMenu = access.find((data) => data.sub_menu === "Approval User");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [showAddAccess, setShowAddAccess] = useState(false);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [showEditCompany, setShowEditCompany] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  function handleCloseShowDelete() {
    setShowDelete(false);
  }
  const [modalData, setModalData] = useState({});
  const [editStatusData, setEditStatusData] = useState({});

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    fetchSuperadminUser();
  }, []);

  function fetchSuperadminUser() {
    setIsLoading(true);
    getApprovalPersonnel().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  function resetTable() {
    fetchSuperadminUser();
    setShowAddAccess(false);
    setShowEditStatus(false);
    setShowSuccess(false);
  }

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowAddAccess(false);
    setShowSuccess(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "avatar_url",
        Cell: (props) => {
          return (
            <div>
              <Image
                style={{ width: "3rem" }}
                roundedCircle
                src={props.value}
                alt="profile"
              />
            </div>
          );
        },
      },
      {
        Header: "NIK",
        accessor: "nik",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Name",
        accessor: "employee_name",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-email">{props.value}</span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          <select
            className={
              row.original.status === "Active"
                ? "form-select form-select-sm text-success fw-bold"
                : "form-select form-select-sm text-danger fw-bold"
            }
            onChange={() => {
              handleShowEditStatus(row);
              if (row.original.status === "Active") {
                row.original.status = "InActive";
              } else {
                row.original.status = "Active";
              }
            }}
            disabled={!accessMenu.is_update}
          >
            <option
              className="text-success"
              value="Active"
              selected={row.original.status === "Active"}
            >
              Active
            </option>
            <option
              className="text-danger"
              value="InActive"
              selected={row.original.status === "InActive"}
            >
              InActive
            </option>
          </select>
        ),
      },
      {
        Header: "Company",
        accessor: "company",
        Cell: ({ row }) => (
          <select
            className={"form-select form-select-sm fw-bold text-success"}
            onChange={(e) => {
              row.original.company_code = e.target.value;
              handleShowEditCompany(row);
            }}
            disabled={!accessMenu.is_update}
          >
            <option
              className="text-success"
              value="ICT"
              selected={row.original.company_code === "ICT"}
            >
              IDStar
            </option>
            <option
              className="text-success"
              value="DSI"
              selected={row.original.company_code === "DSI"}
            >
              Drife
            </option>
          </select>
        ),
      },
    ],
    []
  );

  // add custom new cell for button detail & renewal
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "delete",
        Header: "",
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="btn btn-sm"
              onClick={() => {
                setModalData(row);
                setShowDelete(true);
              }}
              disabled={!accessMenu.is_delete}
            >
              <FontAwesomeIcon
                size="xl"
                icon={faTrashCan}
                style={{ color: "#dc3545", float: "right" }}
              />
            </button>
          </div>
        ),
      },
    ]);
  };

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination,
    tableHooks
  );

  function handleShowEditStatus(data) {
    setEditStatusData(data);
    setShowEditStatus(true);
  }

  function handleShowEditCompany(data) {
    setEditStatusData(data);
    setShowEditCompany(true);
  }

  function editStatus(event) {
    const json = JSON.stringify({
      status: editStatusData.original.status,
    });

    updateApprovalPersonnelStatus(editStatusData.original.id, json).then(
      (result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          handlePost(false, result.meta.message);
        } else {
          const message = {
            code: result.meta.code,
            message: result.meta.message,
          };
          handlePost(true, message.message);
        }
      }
    );
  }

  function editCompany(event) {
    const json = JSON.stringify({
      company: editStatusData.original.company_code,
    });

    updateApprovalPersonnelCompany(editStatusData.original.id, json).then(
      (result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          handlePost(false, result.meta.message);
        } else {
          const message = {
            code: result.meta.code,
            message: result.meta.message,
          };
          handlePost(true, message.message);
        }
      }
    );
  }

  function handlePost(status, message) {
    setPostSuccessMessage(message);
    setIsPostSuccess(status);
    setShowEditStatus(false);
    setShowDelete(false);
    setShowAddAccess(false);
    setShowEditCompany(false);
    setShowSuccess(true);
  }

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else if (searchVal.toLowerCase() === "active") {
      setGlobalFilter("true");
    } else if (
      searchVal.toLowerCase() === "in-active" ||
      searchVal.toLowerCase() === "inactive"
    ) {
      setGlobalFilter("false");
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="w-100 tabs-content rounded">
        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttablecustom"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  if (e.target.value === "") {
                    searchItem();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchVal(e.target.value);
                    searchItem();
                  }
                }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>
            <button
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={() => setShowAddAccess(true)}
              disabled={!accessMenu.is_create}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginRight: "10px" }}
              />
              Add Access
            </button>
          </div>
        </div>

        <div className="overflow-x-scroll">
          <table className="table-custom-superadmin">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                  {headerGroup.headers.map((column, key) => (
                    <th
                      {...column.getHeaderProps(column)}
                      scope="col"
                      key={key}
                      className="text-start text-danger"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <tr>
                  <td colSpan={headers.length}>No data available in table</td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className="nowrap text-start">
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={key}>
                      {row.cells.map((cell, key) => {
                        return (
                          <td {...cell.getCellProps()} key={key}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>
          {""}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div
          className="d-flex justify-content-end mb-2 me-2"
          style={{ color: "#525555" }}
        >
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        show={showAddAccess}
        onHide={() => setShowAddAccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalAddApproval
          closeModal={() => setShowAddAccess(false)}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showEditStatus}
        onHide={() => setShowEditStatus(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-center">
            <img src="/icons/question.png" alt="" style={{ width: "100px" }} />
            <h5>Are you sure?</h5>
            <p>
              Do you really want to change status to{" "}
              <b>
                {Object.keys(editStatusData).length === 0
                  ? ""
                  : editStatusData.original.status === "Active"
                  ? "Active"
                  : "InActive"}
              </b>
              ?
            </p>
            <div className="text-center">
              <button
                className="btn btn-secondary mt-3 col-4 btn-sm"
                onClick={() => {
                  if (editStatusData.original.status === "Active") {
                    data[editStatusData.index].status = "InActive";
                  } else {
                    data[editStatusData.index].status = "Active";
                  }
                  setShowEditStatus(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mt-3 col-4 ms-4 btn-sm"
                onClick={(e) => editStatus(e)}
              >
                Change
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditCompany}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-center">
            <img src="/icons/question.png" alt="" style={{ width: "100px" }} />
            <h5>Are you sure?</h5>
            <p>
              Do you really want to change company to{" "}
              <b>
                {Object.keys(editStatusData).length === 0
                  ? ""
                  : editStatusData.original.company_code === "ICT"
                  ? "IDStar"
                  : "Drife"}
              </b>
              ?
            </p>
            <div className="text-center">
              <button
                className="btn btn-secondary mt-3 col-4 btn-sm"
                onClick={() => {
                  if (editStatusData.original.company_code === "ICT") {
                    data[editStatusData.index].company_code = "DSI";
                  } else {
                    data[editStatusData.index].company_code = "ICT";
                  }
                  setShowEditCompany(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mt-3 col-4 ms-4 btn-sm"
                onClick={(e) => editCompany(e)}
              >
                Change
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3 btn-sm"
                onClick={() => resetTable()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalDeleteApproval
          data={modalData}
          onHide={handleCloseShowDelete}
          handlePost={handlePost}
        />
      </Modal>
    </>
  );
}

export default ApprovalUser;
