import { Form, Modal } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { putTalentReview } from "../../../data-api/talent-review";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { FormatDate } from "../../template/format";
import { InvalidInput } from "../../../utils/ReactSelect";
import Select from "react-select";
registerLocale("en-GB", enGb);

function ModalEditTalent(props) {
  const [trDate, setTRDate] = useState("");
  const [status, setStatus] = useState("");

  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  const [isFetch, setIsFetch] = useState(false);

  const [editConfirm, setEditConfirm] = useState(false);

  const handleUpdateTalent = (event) => {
    event.preventDefault();
    if (!editConfirm) {
      setEditConfirm(true);
    } else {
      setIsFetch(true);
      EditProjectTeam();
      setIsFetch(false);
    }
  };

  const EditProjectTeam = () => {
    const data = {
      nik: props.data.nik,
      tr_date: moment(trDate).format("YYYY-MM-DD"),
      status: status.label,
      modified_by: "",
    };

    putTalentReview(data).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message = {
        code: res.meta.code,
        message: res.meta.message,
      };
      props.message(message);
      props.close();
      setIsFetch(false);
    });
  };

  const ModalEdit = () => {
    return (
      <Form onSubmit={handleUpdateTalent} className="custom-text-14">
        <Form.Group className="mb-3 fw-semibold justify-content-between">
          <Form.Label className="mt-2">Employee</Form.Label>
          <Form.Control
            className="form-control-sm"
            type="text"
            value={props.data.nik + " - " + props.data.nama}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3 fw-semibold justify-content-between">
          <Form.Label className="mt-2">Status</Form.Label>
          <div className="w-100">
            <Select
              className="w-100 fw-normal custom-text-14"
              options={[
                {
                  label: "Done",
                  value: "1",
                },
              ]}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 35,
                  minHeight: 35,
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: "14px",
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              isClearable
              placeholder={"-- Select Status --"}
              defaultValue={status}
              onChange={(e) => setStatus(e)}
              required
              ariaLiveMessages={"Please Select Status"}
              components={{
                Input: invalidInput,
              }}
            ></Select>
          </div>
        </Form.Group>
        <Form.Group className="mb-3 justify-content-between">
          <Form.Label className="mt-2 fw-semibold">TR Date</Form.Label>
          <div>
            <ReactDatePicker
              locale={enGb}
              className="w-100 form-control"
              wrapperClassName="w-100 form-control"
              todayButton="Today"
              dateFormat="dd/MM/yyyy"
              selected={trDate ? trDate : ""}
              onChange={(value) => {
                setTRDate(value);
              }}
              isClearable
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              placeholderText="DD/MM/YYYY"
              onInvalid={(e) =>
                e.target.setCustomValidity("Please fill out TR Date")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </div>
        </Form.Group>
        <div className="d-flex flex-row-reverse bd-highlight">
          <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm">
            Confirm
          </button>
        </div>
      </Form>
    );
  };

  const ModalConfirm = () => {
    return (
      <>
        <div className="container custom-text-14">
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Employee</Form.Label>
            <Form.Label className="mt-2">
              {props.data.nik + " - " + props.data.nama}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Status</Form.Label>
            <Form.Label className="mt-2">{status.label}</Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">TR Date</Form.Label>
            <Form.Label className="mt-2">{FormatDate(trDate)}</Form.Label>
          </Form.Group>
          <div className="d-flex flex-row-reverse bd-highlight">
            <button
              className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              onClick={handleUpdateTalent}
              disabled={isFetch}
            >
              {isFetch ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              onClick={() => setEditConfirm(false)}
            >
              Back
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {!editConfirm ? "Edit Talent Review" : "Confirm Edit Talent Review"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {!editConfirm ? <ModalEdit /> : <ModalConfirm />}
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalEditTalent;
