import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getIdle = async ({ days = "", client = "" }) => {
  const requestParam = {
    filter_days: days,
    client: client,
  };
  const url = `${process.env.REACT_APP_API_URL}/idle`;
  return axios
    .get(url, {
      params: requestParam,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getIdleWaiting = async ({
  client = "",
  startDate = "",
  endDate = "",
}) => {
  const requestParam = {
    client: client,
    start_date: startDate,
    end_date: endDate,
  };
  const url = `${process.env.REACT_APP_API_URL}/idle/waiting`;
  return axios
    .get(url, {
      params: requestParam,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getIdleFilter = async () => {
  const url = `${process.env.REACT_APP_API_URL}/idle/filter`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getIdleOthers = async ({ client = "", status = "" }) => {
  const requestParam = {
    client: client,
    status_id: status,
  };
  const url = `${process.env.REACT_APP_API_URL}/idle/others`;
  return axios
    .get(url, {
      params: requestParam,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
