import {
  faDownload,
  faFileImport,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import TableImport from "../table/TableImport";
import {
  getAddSpkData,
  postImportCreateSpk,
  postImportUpdateSpk,
} from "../../../data-api/resource-team";
import { useOutletContext } from "react-router-dom";
import ReactTable from "../../../utils/ReactTable";
import moment from "moment/moment";
import writeXlsxFile from "write-excel-file";
import { ReactSelect } from "../../../utils/ReactSelect";
import LoadingEffectTable from "../../template/loadingEffectTable";

function ModalImportSpk({ setImportSuccess }) {
  const [importData, setImportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownApproveBy, setDropdownApproveBy] = useState({});
  const [approveBy, setApproveBy] = useState("");
  const [importDataResponse, setImportDataResponse] = useState({});
  const [successImport, setSuccessImport] = useState({});
  const [importAction, setImportAction] = useState("");
  const outletContext = useOutletContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };
  const handleShowConfirm = (action) => {
    if (importData.length === 0) {
      showAlertError(false, "Data cant be empty");
    } else {
      setImportAction(action);
      setShowConfirm(true);
      setImportDataResponse({
        created_by: outletContext.profile.nik,
        approve_by: approveBy.value,
        data: importData,
      });
    }
  };

  //upload modal
  const [showUpload, setShowUpload] = useState(false);

  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => setShowUpload(true);

  const onFileChange = (files) => {
    // console.log(files);
  };

  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile.type === "text/csv") {
      const updatedList = [newFile];
      setFileList(updatedList);
      onFileChange(updatedList);

      if (e.target.files) {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);

        reader.onload = (e) => {
          var jsonData = [];
          var headers = [];
          var rows = e.target.result.split("\r\n");
          for (var i = 0; i < rows.length - 1; i++) {
            var cells = rows[i].split(",");
            var rowData = {};
            for (var j = 0; j < cells.length; j++) {
              if (i === 0) {
                var headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                var key = headers[j];
                if (j === 0) {
                  cells[j] = cells[j].padStart(7, "0");
                }
                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i !== 0) {
              jsonData.push(rowData);
            }
          }
          jsonData.forEach((item) => {
            item.start_date = moment(item.start_date, "DD/MM/YYYY").isValid()
              ? moment(item.start_date, "DD/MM/YYYY").format("YYYY-MM-DD")
              : item.start_date;
            item.end_date = moment(item.end_date, "DD/MM/YYYY").isValid()
              ? moment(item.end_date, "DD/MM/YYYY").format("YYYY-MM-DD")
              : item.end_date;
          });
          setImportData(jsonData);
        };
      }
    } else {
      showAlertErrorUploadCSV(false, "Please upload CSV format");
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  const [showAlertUploadCSV, setShowAlertUploadCSV] = useState(false);
  const [AlertMessageUploadCSV, setAlertMessageUploadCSV] = useState("");

  function showAlertErrorUploadCSV(success, message) {
    setShowAlertUploadCSV(true);
    setAlertMessageUploadCSV(message);
  }

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
    setImportData([]);
  };

  function importCreateSpk() {
    setShowAlert(false);
    setIsFetch(true);
    if (importAction === "create") {
      postImportCreateSpk(importDataResponse).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          showAlertError(false, result.meta.message);
          handleCloseConfirm();
        } else {
          if (result.data.success_import === null) {
            showAlertError(false, "All Import (Create) Failed");
          } else {
            showAlertError(true, "Success Import (Create) SPK");
          }
          setSuccessImport(result);
          setShowConfirm(false);
        }
        setIsLoading(false);
        setIsFetch(false);
      });
    } else if (importAction === "update") {
      postImportUpdateSpk(importDataResponse).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          showAlertError(false, result.meta.message);
          handleCloseConfirm();
        } else {
          if (result.data.success_import === null) {
            showAlertError(false, "All Import (Update) Failed");
          } else {
            showAlertError(true, "Success Import (Update) SPK");
          }
          setSuccessImport(result);
          setShowConfirm(false);
        }
        setIsLoading(false);
        setIsFetch(false);
      });
    }
    setImportSuccess(true);
  }

  useEffect(() => {
    getAddSpkData().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
      } else {
        setDropdownApproveBy(result.data);
        setIsLoading(false);
        setShowConfirm(false);
      }
      setIsLoading(false);
    });
  }, []);

  const columnsImport = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Start_Date",
        accessor: "start_date",
      },
      {
        Header: "End_Date",
        accessor: "end_date",
      },
      {
        Header: "Client_Name",
        accessor: "client_name",
      },
      {
        Header: "Project_Name",
        accessor: "project_name",
      },
      {
        Header: "Spk_Type",
        accessor: "spk_type",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  const data = importData.length === 0 ? EmptyData : importData;
  function handleDownloadExcel() {
    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = columnsImport.map((item) => item.Header);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Import SPK",
    });
  }

  function importSpk(e) {
    e.preventDefault();
    if (e.nativeEvent.submitter.name === "create") {
      handleShowConfirm("create");
    } else if (e.nativeEvent.submitter.name === "update") {
      handleShowConfirm("update");
    }
  }

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <LoadingEffectTable />
          </div>
        </Modal.Body>
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Import SPK</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        {Object.keys(successImport).length === 0 ? (
          <>
            <div className="container d-flex flex-row-reverse bd-highlight mt-3 mb-4">
              <button
                className="btn btn-danger btn-sm ms-3 col-2 fa"
                onClick={handleShowUpload}
              >
                <FontAwesomeIcon
                  icon={faFileImport}
                  style={{ marginRight: "10px" }}
                />
                Upload Files
              </button>
              <a
                className="btn btn-danger btn-sm ms-3 col-2 fa"
                href="https://integrals-public.s3.ap-southeast-1.amazonaws.com/import_csv_example/import_spk_example.csv"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginRight: "10px" }}
                />
                CSV Example
              </a>
            </div>

            <ReactTable
              columns={columnsImport}
              data={data}
              handleDownloadExcel={handleDownloadExcel}
              handleFilter={null}
              hiddenColumns={[
                "spk_id",
                "employee_name",
                "employee_email",
                "client_id",
                "position",
                "email_office",
              ]}
            />

            <form onSubmit={(e) => importSpk(e)}>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between w-50">
                <Form.Label className="mt-2 custom-text-14">
                  Approve By
                </Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={approveBy}
                    setValue={setApproveBy}
                    data={dropdownApproveBy.approve_by}
                    isLoading={isLoading}
                    placeholder={"-- Select Approve By --"}
                    validationMessage={"Please Select Approve By"}
                  />
                </div>
              </Form.Group>

              <div className="d-flex flex-row-reverse bd-highlight mt-3">
                <button
                  className="btn btn-danger ms-3 col-2 fa btn-sm"
                  name="create"
                  value="create"
                  id="create"
                >
                  Create / Renewal SPK
                </button>
                <button
                  className="btn btn-danger ms-3 col-2 fa btn-sm"
                  // onClick={() => handleShowConfirm("update")}
                  name="update"
                  value="update"
                  id="update"
                >
                  Update SPK
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            <TableImport data={successImport} />
          </>
        )}
      </Modal.Body>

      <Modal show={showUpload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlertUploadCSV ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessageUploadCSV}</strong>
              <button
                type="button"
                className="btn-close btn-sm"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlertUploadCSV(false)}
              ></button>
            </div>
          ) : null}
          <div className="mb-4">
            <div className="custom-file-upload btn btn-outline-danger">
              <label>Browse</label>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
            <button
              className="btn btn-danger btn-sm float-end col-4 mb-4"
              onClick={handleCloseUpload}
            >
              Submit
            </button>
          </div>
          {fileList.length === 0 ? (
            <div
              ref={wrapperRef}
              className="drop-file-input mt-4"
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div className="drop-file-input__label">
                <img alt="" />
                <p>Drag & Drop your files here</p>
              </div>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
          ) : null}
          {fileList.length > 0 ? (
            <div className="drop-file-preview">
              {fileList.map((item, index) => (
                <div key={index} className="drop-file-preview__item">
                  <img alt="" />
                  <div className="drop-file-preview__item__info">
                    <p>{item.name}</p>
                    <p>{item.size}B</p>
                  </div>
                  <span
                    className="drop-file-preview__item__del"
                    onClick={() => fileRemove(item)}
                  >
                    x
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirm}
        onHide={handleCloseConfirm}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to import there records?.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-sm"
            onClick={handleCloseConfirm}
          >
            Close
          </Button>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={importCreateSpk}
            disabled={isFetch}
          >
            {" "}
            {isFetch ? (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            ) : (
              "Import SPK"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalImportSpk;
