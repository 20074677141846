import { useEffect, useState } from "react";
import TableTalentReview from "./table/TableTalentReview";
import TableContractReview from "./table/TableContractReview";
import { Tab, Tabs } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

function TalentReview() {
  const contractReview = [];

  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Talent Review"
  ).sub_menu;
  const contractReviewAccess = access.find(
    (data) => data.sub_menu === "Contract Review"
  );
  const talentReviewAccess = access.find(
    (data) => data.sub_menu === "Talent Review"
  );

  useEffect(() => {}, []);

  const [tabKey, setTabKey] = useState("1");

  function handleSelect(key) {
    setTabKey(key);
  }

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  return (
    <div className="w-100">
      {showAlert ? (
        <div
          className={
            AlertSuccess
              ? "alert alert-success alert-dismissible fade show"
              : "alert alert-danger alert-dismissible fade show"
          }
          role="alert"
        >
          <strong>{AlertMessage}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : null}
      <Tabs
        defaultActiveKey={tabKey}
        id="uncontrolled-tab-example"
        onSelect={(e) => handleSelect(e)}
      >
        {talentReviewAccess.is_read ? (
          <Tab eventKey={1} title="Talent Review">
            <TableTalentReview
              tabKey={tabKey}
              error={showAlertError}
              accessMenu={talentReviewAccess}
            />
          </Tab>
        ) : null}

        {contractReviewAccess.is_read ? (
          <Tab eventKey={2} title="Contract Review">
            <TableContractReview
              tabKey={tabKey}
              error={showAlertError}
              accessMenu={contractReview}
            />
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
}

export default TalentReview;
