import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faFilter,
  faGear,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
  // faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

function ReactTableCustomHooks(props) {
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    document.getElementById("search-reacttablecustom").reset();
  }, [props.data]);

  const {
    rows,
    headers,
    // getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // selectedFlatRows,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      //  globalFilter
    },
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        pageIndex: 0,
        pageSize:
          props.pageSize === undefined || props.pageSize === null
            ? 10
            : props.pageSize,
        hiddenColumns: props.hiddenColumns,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    props.tableHooks
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      // setSearchVal(moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD"));
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
      // } else if (moment(searchVal, "MMM", true).format("MM") !== "Invalid date") {
      //   setGlobalFilter(moment(searchVal, "MMM", true).format("MM"));
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="row custom-text-14 w-100 ms-1">
        <div className="col-4 text-start text-danger fw-semibold d-flex">
          <div className={"mt-1"}>{rows.length} records</div>
          {props.paging !== undefined && props.paging !== null ? (
            <>
              <div className="ms-4">
                <Form.Select
                  className="form-select-sm"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {props.paging.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </>
          ) : null}
          {props.handleSetting !== undefined && props.handleSetting !== null ? (
            <>
              <button
                className="btn btn-outline-danger btn-sm ms-3 fa nowrap text-center"
                onClick={() => props.handleSetting(true)}
              >
                <FontAwesomeIcon
                  icon={faGear}
                  style={{ marginRight: "10px" }}
                  className="fa-cloud-arrow-down m-auto"
                />
              </button>
            </>
          ) : null}
        </div>
        <div className="col-8 bd-highlight d-flex justify-content-end">
          <form
            id="search-reacttablecustom"
            className="input-group w-50 flex border border-danger rounded"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search ..."
              value={searchVal}
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  // setGlobalFilter(e.target.value);
                  setSearchVal(e.target.value);
                  searchItem();
                }
              }}
              onChange={(e) => setSearchVal(e.target.value)}
              // style={{ width: "50px" }}
            />
            {searchVal !== "" ? (
              <button
                className="btn btn-outline-light text-danger btn-sm"
                onClick={() => {
                  setSearchVal("");
                  setGlobalFilter("");
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ color: "#848484" }}
                  size="sm"
                />
              </button>
            ) : null}
            <button
              className="btn btn-danger btn-sm"
              type="button"
              id="button-addon2"
              onClick={() =>
                // setGlobalFilter(searchVal)
                searchItem()
              }
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </form>

          {props.handleFilter !== null ? (
            <>
              <button
                className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
                onClick={() => props.handleFilter(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  style={{ marginRight: "10px" }}
                  className="fa-cloud-arrow-down"
                />
                Filter
              </button>
            </>
          ) : null}

          <button
            className="btn btn-outline-danger btn-sm ms-3 col-3 nowrap"
            onClick={props.handleDownloadExcel}
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ marginRight: "10px" }}
              className="fa-cloud-arrow-down"
            />
            Download
          </button>
        </div>
      </div>
      <div className="overflow-x-scroll px-3 mt-3">
        <table className="table table-sm custom-table align-middle">
          <thead className="text-danger align-middle nowrap custom-text-14">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    key={index}
                    className="text-danger"
                  >
                    {column.render("Header")}
                    <span className="ms-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {props.isLoading !== undefined &&
          props.isLoading !== null &&
          props.isLoading ? (
            <>
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
                className="custom-text-14"
              >
                <td colSpan={headers.length}>
                  <div className="w-100">
                    <div className="shine-photo shine"></div>
                  </div>
                </td>
              </tbody>
            </>
          ) : rows.length === 0 ? (
            <tbody style={{ backgroundColor: "#ececec", textAlign: "center" }}>
              <td colSpan={headers.length}>No data available in table</td>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()} className="custom-text-14">
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="text-start"
                          style={{
                            maxWidth: cell.column.maxWidth,
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination d-flex justify-content-center">
        <button
          className="ms-1 me-2 btn btn-danger btn-sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            className="mt-1 me-1"
            icon={faAnglesLeft}
            style={{ color: "#fff" }}
          />
          First
        </button>{" "}
        <button
          className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"< Prev"}
        </button>{" "}
        {pageIndex > 4 ? (
          <span className="align-text-bottom bg-danger me-4">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        {pageOptions
          .slice(
            pageIndex < 5
              ? 0
              : pageIndex >= pageCount - 4
              ? pageCount - 9
              : pageIndex - 4,
            pageIndex < 5
              ? 9
              : pageIndex >= pageCount - 4
              ? pageCount
              : pageIndex + 5
          )
          .map((index, i) => {
            if (index === pageIndex) {
              return (
                <button
                  className="ms-1 btn btn-danger btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            } else {
              return (
                <button
                  className="ms-1  btn btn-outline-danger btn-border btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage && index === pageCount}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            }
          })}
        {pageIndex + 1 < pageOptions.length - 3 ? (
          <span className="align-text-bottom bg-danger ms-3 me-3">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        <button
          className="ms-3 btn btn-outline-danger btn-border btn-sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {"Next >"}
        </button>{" "}
        <button
          className="ms-2 me-1 btn btn-danger btn-sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
          <FontAwesomeIcon
            className="mt-1 ms-1"
            icon={faAnglesRight}
            style={{ color: "#fff", float: "right" }}
          />
        </button>{" "}
      </div>
      <div className="d-flex justify-content-end">
        Page{" "}
        <strong className="ms-1">
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </div>
    </>
  );
}

export default ReactTableCustomHooks;
