function PageDualLogin(props) {
  return (
    <section className="page_404 rounded">
      <div className="container ">
        <div className="row ">
          <div style={{ width: "800px" }}>
            <div className=" text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">{props.code}</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>
                <p>{props.message}</p>
                {/* <a
                  href="/"
                  className="link_404"
                  onClick={() => cookies.remove("session")}
                > */}
                <div className="spinner-border my-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div className="h4"> Redirect to Login Page</div>

                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageDualLogin;
