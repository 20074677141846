import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { getTalentReview } from "../../../data-api/talent-review";
import ModalMessage from "../../template/ModalMessage";
import { Modal } from "react-bootstrap";
import ModalEditTalent from "../modal/ModalEditTalent";
import { FormatDate } from "../../template/format";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";

const columns = [
  // {
  //   Header: "No",
  //   accessor: "no",
  // },
  {
    Header: "NIK",
    accessor: "nik",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "employee_status",
  },
  {
    Header: "Work Status",
    accessor: "work_status",
  },
  {
    Header: "Join Date",
    accessor: "join_date",
    Cell: (props) => FormatDate(props.value),
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Grade",
    accessor: "grade",
  },
  {
    Header: "Client",
    accessor: "client",
  },
  {
    Header: "Talent Review",
    accessor: "talent_review",
  },
  {
    Header: "Review Date",
    accessor: "review_date",
    Cell: (props) => (props.values !== "" ? FormatDate(props.value) : "-"),
  },
];

function TableTalentReview(props) {
  useEffect(() => {
    refreshDataTalent();
  }, []);

  const refreshDataTalent = async () => {
    setIsLoading(true);
    getTalentReview().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data === null ? setTalentReview([]) : setTalentReview(res.data);
      } else {
        setTalentReview([]);
      }
      setIsLoading(false);
    });
  };

  function handleDownloadExcel() {
    data.forEach((item) => {
      item.join_date = FormatDate(item.join_date);
      item.review_date = FormatDate(item.review_date);
    });

    const dataSet = [];
    const dataHeader = [];

    columns.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Talent_Review",
    });
  }

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const [modalData, setModalData] = useState("");
  const [showModalMessage, setShowModalMessage] = useState(false);

  const handleShowModalMessage = (modalData) => {
    setModalData(modalData);
    setShowModalMessage(true);
  };

  const showUpdateTalent = (nik, name) => {
    const data = {
      nik: nik,
      nama: name,
    };

    setModalData(data);
    setShowEdit(true);
  };

  const [talentReview, setTalentReview] = useState([]);

  const EmptyData = useMemo(() => [], []);

  // for handle list columns table
  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: "Update",
        Header: "Update",
        Cell: ({ row }) =>
          row.values.review_date !== "" || !props.accessMenu.is_update ? (
            <div className="text-center">
              <button className="btn btn-danger btn-sm" disabled>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </div>
          ) : (
            <div className="text-center">
              <button
                className="btn btn-danger btn-sm"
                onClick={() =>
                  showUpdateTalent(row.values.nik, row.values.name)
                }
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </div>
          ),
      },
    ]);
  };

  const data = talentReview.length === 0 ? EmptyData : talentReview;

  const closeSuccess = () => {
    refreshDataTalent();
    setShowModalMessage(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="tabs-content">
      <ReactTableCustomHooks
        columns={columns}
        data={data}
        handleDownloadExcel={handleDownloadExcel}
        handleFilter={null}
        tableHooks={tableHooks}
        hiddenColumns={[]}
        isLoading={isLoading}
        paging={[10, 20, 50, 100]}
      />
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditTalent
          data={modalData}
          refreshTable={props.refreshTable}
          close={handleCloseEdit}
          message={handleShowModalMessage}
        />
      </Modal>

      {showModalMessage ? (
        <ModalMessage data={modalData} click={closeSuccess} />
      ) : null}
    </div>
  );
}

export default TableTalentReview;
