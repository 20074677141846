import { Modal, Tab, Tabs } from "react-bootstrap";
import TablePKWT from "./table/TablePKWT";
import TablePKWTT from "./table/TablePKWTT";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ModalAddPkwt from "./modal/ModalAddPkwt";
import ModalImportPkwt from "./modal/ModalImportPkwt";
import { getContract } from "../../data-api/pkwt";
import { useOutletContext } from "react-router-dom";
import LoadingEffect from "../template/loadingEffectTable";

function Pkwt() {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Internal Contract"
  ).sub_menu;
  const pkwtPermission = access.find((data) => data.sub_menu === "PKWT");
  const pkwttPermission = access.find((data) => data.sub_menu === "PKWTT");
  const [isLoading, setIsLoading] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  const [pkwt, setPkwt] = useState([]);
  const [pkwtt, setPkwtT] = useState([]);

  useEffect(() => {
    refreshData();
  }, []);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
    refreshData();
  };

  const [tabKey, setTabKey] = useState("1");

  function handleSelect(key) {
    setTabKey(key);
  }

  const [showImport, setShowImport] = useState(false);
  const handleCloseImport = () => {
    setShowImport(false);
  };

  const refreshData = async (key) => {
    setIsLoading(true);
    if (key !== "") {
      setTabKey(key);
    }
    await getContract(1).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data === null ? setPkwt([]) : setPkwt(res.data);
      } else {
        setPkwt([]);
        showAlertError(false, res.meta.message);
      }
    });
    await getContract(4).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data === null ? setPkwtT([]) : setPkwtT(res.data);
      } else {
        setPkwtT([]);
        showAlertError(false, res.meta.message);
      }
      setIsLoading(false);
    });
  };

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  if (isLoading) {
    return (
      <div className="w-100 tabs-content rounded">
        <LoadingEffect />
      </div>
    );
  }

  return (
    <>
      <div
        // className="mx-auto bg-transparent rounded p-4 mt-4 fs-24"
        // style={{ width: "100%" }}
        // className={
        //   outletContext.sidebarExpand
        //     ? "bg-transparent custom-container rounded p-4 mt-4 fs-24 expand-custom-container custom-text-14"
        //     : "bg-transparent custom-container rounded p-4 mt-4 fs-24 custom-text-14"
        // }
        className="w-100"
      >
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        <div className="d-flex flex-row-reverse bd-highlight mt-3">
          <button
            className="btn btn-danger ms-3 col-2 fa btn-sm"
            onClick={() => setShowAdd(true)}
            disabled={!pkwtPermission.is_create}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ marginRight: "10px" }}
            />
            Add PKWT
          </button>
          <button
            className="btn btn-danger ms-3 col-2 fa btn-sm"
            onClick={() => setShowImport(true)}
            disabled={!pkwtPermission.is_create}
          >
            <FontAwesomeIcon
              icon={faFileImport}
              style={{ marginRight: "10px" }}
            />
            Import PKWT
          </button>
        </div>

        <Tabs
          defaultActiveKey={tabKey}
          id="uncontrolled-tab-example"
          onSelect={(e) => handleSelect(e)}
        >
          <Tab eventKey={1} title="PKWT">
            <TablePKWT
              tabKey={tabKey}
              data={pkwt}
              isLoading={isLoading}
              error={showAlertError}
              accessMenu={pkwtPermission}
            />
          </Tab>
          <Tab eventKey={2} title="PKWTT">
            <TablePKWTT
              tabKey={tabKey}
              data={pkwtt}
              isLoading={isLoading}
              error={showAlertError}
              accessMenu={pkwttPermission}
            />
          </Tab>
        </Tabs>
      </div>

      <Modal
        show={showAdd}
        onHide={handleCloseAdd}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalAddPkwt close={handleCloseAdd} />
      </Modal>

      <Modal
        show={showImport}
        onHide={handleCloseImport}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalImportPkwt data={""} />
      </Modal>
    </>
  );
}
export default Pkwt;
