import { useEffect, useState } from "react";
import History from "./History";

function Helpdesk() {
  useEffect(() => {}, []);
  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  return (
    <div className="w-100 tabs-content rounded">
      <div className="">
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        <History error={showAlertError} />
        {/* <TableHistory data={pageData} click={refreshDataHistory}/> */}
      </div>
    </div>
  );
}

export default Helpdesk;
