import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Collapsible from "./Collapse";
import {
  getSuperadminRoleDetail,
  updateSuperadminRolePermission,
} from "../../../data-api/master-user";
import { useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function ModalEditRole(props) {
  const outletContext = useOutletContext();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRoleDetail();
  }, []);

  function fetchRoleDetail() {
    setIsLoading(true);

    getSuperadminRoleDetail(props.data.id).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  function checkAllSubMenu(event, menu_index, sub_menu_index) {
    const updatedList = { ...data };
    if (event.target.checked) {
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_create = true;
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_read = true;
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_update = true;
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_delete = true;
    } else {
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_create = false;
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_read = false;
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_update = false;
      updatedList.menu[menu_index].sub_menu[sub_menu_index].is_delete = false;
    }
    setData(updatedList);
  }

  function checkAllMenu(event, menu_index) {
    const updatedList = { ...data };
    if (event.target.checked) {
      updatedList.menu[menu_index].sub_menu.forEach((data) => {
        if (
          data.sub_menu === "Superadmin User" ||
          data.sub_menu === "Integrals Menu"
        ) {
          data.is_create =
            outletContext.profile.role_id === "1" ? true : data.is_create;
          data.is_read = true;
          data.is_update =
            outletContext.profile.role_id === "1" ? true : data.is_update;
          data.is_delete =
            outletContext.profile.role_id === "1" ? true : data.is_delete;
        } else {
          data.is_create = true;
          data.is_read = true;
          data.is_update = true;
          data.is_delete = true;
        }
      });
    } else {
      updatedList.menu[menu_index].sub_menu.forEach((data) => {
        if (
          data.sub_menu === "Superadmin User" ||
          data.sub_menu === "Integrals Menu"
        ) {
          data.is_create =
            outletContext.profile.role_id === "1" ? false : data.is_create;
          data.is_read = false;
          data.is_update =
            outletContext.profile.role_id === "1" ? false : data.is_update;
          data.is_delete =
            outletContext.profile.role_id === "1" ? false : data.is_delete;
        } else {
          data.is_create = false;
          data.is_read = false;
          data.is_update = false;
          data.is_delete = false;
        }
      });
    }
    setData(updatedList);
  }

  function checkMenuIsChecked(menu_index) {
    let checked = true;
    data.menu[menu_index].sub_menu.forEach((data) => {
      if (
        data.is_create !== true ||
        data.is_read !== true ||
        data.is_update !== true ||
        data.is_delete !== true
      ) {
        checked = false;
      }
    });

    return checked;
  }

  function editRolePermission(event) {
    setIsLoading(true);
    event.preventDefault();

    let json = [];

    data.menu.forEach((parent) => {
      parent.sub_menu.forEach((child) => {
        json.push({
          permission_id: child.permission_id,
          is_create: child.is_create,
          is_read: child.is_read,
          is_update: child.is_update,
          is_delete: child.is_delete,
        });
      });
    });

    updateSuperadminRolePermission(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        props.handleSuccessModal(false, result.meta.message);
      } else {
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.handleSuccessModal(true, message.message);
      }
      setIsLoading(false);
    });
  }

  function checkRadioChart(menuIndex, subMenuIndex) {
    const updatedList = { ...data };
    updatedList.menu[menuIndex].sub_menu.forEach((subMenu) => {
      subMenu.is_create = false;
      subMenu.is_read = false;
      subMenu.is_update = false;
      subMenu.is_delete = false;
    });
    updatedList.menu[menuIndex].sub_menu[subMenuIndex].is_read = true;
    setData(updatedList);
  }

  return (
    <>
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Edit Access Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflox-x">
          <form onSubmit={editRolePermission}>
            {data.menu !== undefined && data.menu.length > 0
              ? data.menu.map((parent, i) => (
                  <>
                    <div>
                      {parent.menu === "Dashboard" ? (
                        <>
                          <Collapsible title={parent.menu}>
                            <span
                              className="form-check-label fw-semibold"
                              for="flexCheckDefault"
                            >
                              {parent.menu}
                            </span>

                            <div className="ms-4">
                              {parent.sub_menu.map((child, j) => (
                                <>
                                  <div className="d-flex flex-row mb-2">
                                    <div className="col-4">
                                      <input
                                        className="form-check-input form-select-sm ms-1"
                                        style={{ transform: "scale(1.3)" }}
                                        type="radio"
                                        name="chartradio"
                                        id="flexCheckDefault"
                                        defaultChecked={
                                          child.is_read ? true : false
                                        }
                                        onChange={() => {
                                          checkRadioChart(i, j);
                                        }}
                                      />
                                      <label
                                        className="form-check-label ms-2"
                                        for="flexCheckDefault"
                                      >
                                        {child.sub_menu}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </Collapsible>
                        </>
                      ) : (
                        <>
                          <Collapsible title={parent.menu}>
                            <div className="d-flex flex-row mb-2 text-center fw-semibold">
                              <div className="col-4"></div>
                              <span className="col-1">Create</span>
                              <span className="col-1">Read</span>
                              <span className="col-1">Update</span>
                              <span className="col-1">Delete</span>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input form-select-sm"
                                style={{ transform: "scale(1.3)" }}
                                type="checkbox"
                                id="flexCheckDefault"
                                onChange={(e) => checkAllMenu(e, i)}
                                defaultChecked={checkMenuIsChecked(i)}
                              />
                              <span
                                className="fw-semibold form-check-label"
                                for="flexCheckDefault"
                              >
                                {parent.menu}
                              </span>
                            </div>
                            <div className="ms-4">
                              {parent.sub_menu.map((child, j) => (
                                <>
                                  <div className="d-flex flex-row mb-2">
                                    <div className="col-4">
                                      <input
                                        className={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? "form-check-input form-select-sm ms-1 bg-secondary"
                                            : "form-check-input form-select-sm ms-1"
                                        }
                                        style={{
                                          transform: "scale(1.3)",
                                        }}
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        checked={
                                          child.is_read === true &&
                                          child.is_create === true &&
                                          child.is_update === true &&
                                          child.is_delete === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          checkAllSubMenu(e, i, j)
                                        }
                                        disabled={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className=" ms-2"
                                        for="flexCheckDefault"
                                      >
                                        {child.sub_menu}
                                      </label>
                                    </div>
                                    <div className="col-1">
                                      <input
                                        className={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? "form-check-input ms-1 bg-secondary"
                                            : "form-check-input ms-1"
                                        }
                                        style={{ transform: "scale(1.3)" }}
                                        type="checkbox"
                                        id={child.permission_id}
                                        key={child.permission_id}
                                        checked={child.is_create}
                                        onChange={() => {
                                          const updatedList = { ...data };
                                          updatedList.menu[i].sub_menu[
                                            j
                                          ].is_create = !child.is_create;
                                          setData(updatedList);
                                        }}
                                        disabled={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                    <div className="col-1">
                                      <input
                                        className="form-check-input ms-1"
                                        style={{ transform: "scale(1.3)" }}
                                        type="checkbox"
                                        id={child.permission_id}
                                        key={child.permission_id}
                                        checked={child.is_read}
                                        onChange={() => {
                                          const updatedList = { ...data };
                                          updatedList.menu[i].sub_menu[
                                            j
                                          ].is_read = !child.is_read;
                                          setData(updatedList);
                                        }}
                                      />
                                    </div>
                                    <div className="col-1">
                                      <input
                                        className={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? "form-check-input ms-1 bg-secondary"
                                            : "form-check-input ms-1"
                                        }
                                        style={{ transform: "scale(1.3)" }}
                                        type="checkbox"
                                        id={child.permission_id}
                                        key={child.permission_id}
                                        checked={child.is_update}
                                        onChange={() => {
                                          const updatedList = { ...data };
                                          updatedList.menu[i].sub_menu[
                                            j
                                          ].is_update = !child.is_update;
                                          setData(updatedList);
                                        }}
                                        disabled={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                    <div className="col-1">
                                      <input
                                        className={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? "form-check-input ms-1 bg-secondary"
                                            : "form-check-input ms-1"
                                        }
                                        style={{ transform: "scale(1.3)" }}
                                        type="checkbox"
                                        id={child.permission_id}
                                        key={child.permission_id}
                                        //   value={parent.value}
                                        checked={child.is_delete}
                                        onChange={() => {
                                          const updatedList = { ...data };
                                          updatedList.menu[i].sub_menu[
                                            j
                                          ].is_delete = !child.is_delete;
                                          setData(updatedList);
                                        }}
                                        disabled={
                                          (child.sub_menu ===
                                            "Superadmin User" ||
                                            child.sub_menu ===
                                              "Integrals Menu") &&
                                          outletContext.profile.role_id !== "1"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </Collapsible>
                        </>
                      )}
                    </div>
                  </>
                ))
              : null}
            <div className="text-center">
              <button
                className="btn btn-danger mt-4 w-75"
                type="submit"
                name="submit"
                value={"Submit"}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  </>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalEditRole;
