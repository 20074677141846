import { useEffect } from "react";
import { useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { getHistoryProjectTeam } from "../../../data-api/project-team";
import TableProjectHistory from "../table/TableProjectHistory";
import TableResourceHistory from "../table/TableResourceHistory";
import LoadingEffect from "../../template/loadingEffectTable";

function ModalDetailProject(props) {
  const [isLoading, setIsLoading] = useState(true);

  const [tabKey, setTabKey] = useState("1");

  const handleSelect = (key) => {
    setTabKey(key);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const [resource, setResource] = useState([]);
  const [project, setProject] = useState([]);

  const refreshData = async () => {
    setIsLoading(true);
    getHistoryProjectTeam(props.data.nik).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        if (res.data.project_history === null) {
          setProject([]);
        } else {
          setProject(res.data.project_history);
        }
        if (res.data.resource_history === null) {
          setResource([]);
        } else {
          setResource(res.data.resource_history);
        }
      } else {
        showAlertError(false, res.meta.message);
        setResource([]);
        setProject([]);
      }
      setIsLoading(false);
    });
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  const showAlertError = (success, message) => {
    setShowAlert(true);
    setAlertSuccess(success);
    setAlertMessage(message);
  };

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffect />
        </Modal.Body>
      </>
    );

  return (
    <>
      {showAlert ? (
        <div
          className={
            AlertSuccess
              ? "alert alert-success alert-dismissible fade show"
              : "alert alert-danger alert-dismissible fade show"
          }
          role="alert"
        >
          <strong>{AlertMessage}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title>History Project - {props.data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Tabs
          defaultActiveKey={tabKey}
          id="uncontrolled-tab-example"
          onSelect={(e) => handleSelect(e)}
        >
          <Tab eventKey={1} title="Project">
            <TableProjectHistory
              header={{ nik: props.data.nik, name: props.data.name }}
              data={project}
              tabKey={tabKey}
              error={showAlertError}
              refreshData={refreshData}
              access={props.access}
            />
          </Tab>
          <Tab eventKey={2} title="Resource">
            <TableResourceHistory
              header={{ nik: props.data.nik, name: props.data.name }}
              data={resource}
              tabKey={tabKey}
              error={showAlertError}
              refreshData={refreshData}
              access={props.access}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </>
  );
}

export default ModalDetailProject;
