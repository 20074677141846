import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "./Clients.css";
import { postClient } from "../../data-api/master-data-clients";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGb from "date-fns/locale/en-GB"; // the locale you want
import Select from "react-select";
registerLocale("en-GB", enGb);

function ModalAddClient(props) {
  const [isFetch, setIsFetch] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);

  const [client, setClient] = useState("");
  const [officialName, setOfficialName] = useState("");
  const [Address, setAddress] = useState("");
  const [relation, setRelation] = useState("");
  const [procType, setProcType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [startPeriode, setStartPeriode] = useState("");
  const [endPeriode, setEndPeriode] = useState("");

  function formAddClient(event) {
    event.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      fetchAddClient();
    }
  }

  //send json to backend
  function fetchAddClient() {
    const json = JSON.stringify({
      client_name: client,
      official_name: officialName,
      address: Address,
      relation: relation.label,
      proc_type: procType.label,
      project_name: projectName,
      start_periode: moment(startPeriode).format("YYYY-MM-DD"),
      end_periode: moment(endPeriode).format("YYYY-MM-DD"),
    });

    postClient(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.handleClose(message);
      } else {
        setRelation("-1");
        setStartPeriode("");
        setEndPeriode("");
        setIsFetch(false);
        const message2 = {
          code: result.meta.code,
          message: result.meta.message,
        };
        if (message2.code === 200) {
          props.handleClose(message2);
        }
      }
    });
  }

  return (
    <>
      <div>
        <Modal.Header closeButton>
          <Modal.Title>Add New Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => formAddClient(e)}>
            {
              // form input
              !addConfirm ? (
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Client</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setClient(e.target.value)}
                      defaultValue={client}
                      required
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please fill out this field."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Official Name</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setOfficialName(e.target.value)}
                      defaultValue={officialName}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Address</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setAddress(e.target.value)}
                      defaultValue={Address}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Relation to</Form.Label>
                    <Select
                      className="w-75 fw-normal custom-text-14"
                      options={[
                        { label: "IDStar", value: 1 },
                        { label: "Drife", value: 2 },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable={true}
                      placeholder={"-- Select Relation --"}
                      defaultValue={relation}
                      onChange={(e) => setRelation(e)}
                      required
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Procurement Type</Form.Label>
                    <Select
                      className="w-75 fw-normal custom-text-14"
                      options={[
                        { label: "Project", value: 1 },
                        { label: "Resource", value: 2 },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable={true}
                      placeholder={"-- Select Procurement --"}
                      defaultValue={procType}
                      onChange={(e) => setProcType(e)}
                      required
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Project Name</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setProjectName(e.target.value)}
                      defaultValue={projectName}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Start Periode
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={startPeriode ? startPeriode : ""}
                        onChange={(value) => setStartPeriode(value)}
                        isClearable
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) => false}
                        onInput={(e) => e.target.setCustomValidity("")}
                        required={endPeriode === "" ? false : true}
                        // maxDate={new Date(endPeriode)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      End Periode
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={endPeriode ? endPeriode : ""}
                        onChange={(value) => setEndPeriode(value)}
                        isClearable
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) => false}
                        onInput={(e) => e.target.setCustomValidity("")}
                        required={startPeriode === "" ? false : true}
                        // minDate={new Date(startPeriode)}
                      />
                    </div>
                  </Form.Group>
                </div>
              ) : (
                // add spk confirmation
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Client</Form.Label>
                    <Form.Label className="mt-2">{client}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Official Name</Form.Label>
                    <Form.Label className="mt-2">{officialName}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Address</Form.Label>
                    <Form.Label className="mt-2">{Address}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Relation to</Form.Label>
                    <Form.Label className="mt-2">{relation.label}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Procurement Type</Form.Label>
                    <Form.Label className="mt-2">{procType.label}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Project Name</Form.Label>
                    <Form.Label className="mt-2">{projectName}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Start Date</Form.Label>
                    <Form.Label className="mt-2">
                      {startPeriode !== ""
                        ? moment(startPeriode).format("DD-MMM-YYYY")
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">End Date</Form.Label>
                    <Form.Label className="mt-2">
                      {endPeriode !== ""
                        ? moment(endPeriode).format("DD-MMM-YYYY")
                        : "-"}
                    </Form.Label>
                  </Form.Group>
                </div>
              )
            }
            <div className="d-grid">
              {addConfirm ? (
                <button
                  className="btn btn-danger mb-4 ms-1 me-1 fw-bold w-50 btn-sm"
                  onClick={(e) => setAddConfirm(false)}
                >
                  Back{" "}
                </button>
              ) : null}

              <button className="btn btn-danger mb-4 ms-1 me-1 fw-bold shadow addConfirm btn-sm">
                {isFetch ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : addConfirm ? (
                  "Submit"
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalAddClient;
