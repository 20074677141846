import { useMemo, useState } from "react";
import { getListCost, getSearchCost } from "../../../data-api/cost-to-company";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import writeXlsxFile from "write-excel-file";

function TabSeachCTC(props) {
  const [data, setData] = useState([]);
  var moneyFormatter = new Intl.NumberFormat();
  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.nik}</span>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Grade",
        accessor: "grade",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.grade}</span>
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: "gender",
        // Cell: ({ row }) => (
        //   <div className="text-center">
        //     <span>
        //       {row.values.gender === "M"
        //         ? "Male"
        //         : row.values.gender === "F"
        //         ? "Female"
        //         : ""}
        //     </span>
        //   </div>
        // ),
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Result",
        accessor: "result",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.result)}</span>
          </div>
        ),
      },
    ],
    []
  );

  function fetchCostVar() {
    setShowAlert(false);
    if (searchVal === undefined || searchVal.length < 4) {
      setData([]);
      showAlertError(
        false,
        "Search value length must be greater than or equal to 4"
      );
    } else {
      setIsLoading(true);
      if (searchVal === "") {
        setData([]);
        setIsLoading(false);
      } else {
        let param = {
          search: searchVal,
        };
        getSearchCost(param).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          result.data === null || result.data === undefined
            ? setData([])
            : setData(result.data);
          setIsLoading(false);
        });
      }
    }
  }

  function handleDownloadExcel() {
    const dataSet = [];
    const dataHeader = [];
    columns.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    var customData = [];
    data.forEach((value) => {
      customData.push({
        nik: value.nik,
        name: value.name,
        grade: value.grade,
        gender: value.gender,
        position: value.position,
        result: value.result,
      });
    });

    // FILTERED ROWS
    customData.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: typeof item === "string" ? String : Number,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Cost_Search_CTC",
    });
  }
  const [searchVal, setSearchVal] = useState("");

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  return (
    <>
      <div className="tabs-content">
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttable"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    // setGlobalFilter(e.target.value);
                    setSearchVal(e.target.value);
                    fetchCostVar();
                  }
                }}
                onChange={(e) => setSearchVal(e.target.value)}
                // style={{ width: "50px" }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  // type="button"
                  // id="button-addon2"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() =>
                  // setGlobalFilter(searchVal)
                  fetchCostVar()
                }
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>

            <button
              className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={handleDownloadExcel}
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ marginRight: "10px" }}
                className="fa-cloud-arrow-down"
              />
              Download
            </button>
          </div>
        </div>
        <div className="overflow-x-scroll px-3 mt-3">
          <table className="table table table-sm custom-table align-middle">
            <thead className="text-danger align-middle custom-text-14">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      key={index}
                      className="text-danger"
                    >
                      {column.render("Header")}
                      <span className="ms-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
                className="custom-text-14"
              >
                <td colSpan={headers.length}>No data available in table</td>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className="custom-text-14">
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="text-start"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>{" "}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div className="d-flex justify-content-end">
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>
    </>
  );
}

export default TabSeachCTC;
