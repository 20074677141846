import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import ModalEmailSpk from "./ModalEmailSpk";
import ModalEditSpk from "./ModalEditSpk";
import { getSpkDetail } from "../../../data-api/resource-team";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import ModalShowPDF from "../../../utils/ModalShowPDF";

function ModalDetailSpk(props) {
  const accessMenu = props.accessMenu;
  const nik = props.data;
  const [detailSpk, setDetailSpk] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emailData, setEmailData] = useState({});
  const [showEmail, setShowEmail] = useState(false);

  const handleShowEmail = (
    employee_email,
    nik,
    employee_name,
    position,
    placement,
    spk_type,
    start_date,
    end_date,
    pdf
  ) => {
    setEmailData({
      email: employee_email,
      nik: nik,
      employee_name: employee_name,
      position: position,
      placement: placement,
      spk_type: spk_type,
      start_date: start_date,
      end_date: end_date,
      pdf: pdf,
    });
    setShowEmail(true);
  };
  const handleCloseEmail = () => {
    setShowEmail(false);
  };

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  const [editStatus, setEditStatus] = useState(false);
  const [formData, setFormData] = useState({});
  const [showEditSpk, setShowEditSpk] = useState(false);

  const handleCloseEditSpk = () => {
    if (editStatus) {
      fetchDetailSpk();
    }
    setShowEditSpk(false);
  };

  const columnsSpk = useMemo(
    () => [
      {
        Header: "SPK ID",
        accessor: "spk_id",
      },
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Email",
        accessor: "email_office",
      },
      {
        Header: "Client ID",
        accessor: "client_id",
      },
      {
        Header: "Client",
        accessor: "client_name",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => (
          <>{`${moment(props.value).format("DD-MMM-YYYY")} ${
            props.cell.row.original.return_date === ""
              ? ""
              : `(spk return at ${moment(
                  props.cell.row.original.return_date
                ).format("DD-MMM-YYYY")})`
          }`}</>
        ),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Contract Title",
        accessor: "contract_title",
      },
      {
        Header: "Contract No",
        accessor: "contract_no",
      },
      {
        Header: "PDF",
        accessor: "pdf",
        Cell: (props) => (
          <button
            style={{
              padding: "0",
              border: "none",
              background: "none",
              textDecoration: "underline",
              color: "#0d6efd",
            }}
            href={`#`}
            onClick={() => handleShowPDF(`${props.value}?${Date.now()}`)}
          >
            {props.value === "" ? "" : "View PDF"}
          </button>
        ),
      },
      {
        Header: "Last SPK",
        accessor: "last_spk",
      },
      {
        Header: "Return SPK Date",
        accessor: "return_date",
      },
      {
        Header: "Return Reason",
        accessor: "return_reason",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);
  useEffect(() => {
    fetchDetailSpk();
  }, []);

  function fetchDetailSpk() {
    setIsLoading(true);
    getSpkDetail(nik).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setDetailSpk([]);
      } else {
        setDetailSpk(result.data);
      }
      setIsLoading(false);
    });
  }

  function editSpk(
    spk_id,
    spk_type,
    nik,
    employee_name,
    start_date,
    end_date,
    client_id,
    contract_title,
    contract_no,
    last_spk,
    return_date,
    return_reason
  ) {
    setFormData({
      spk_id: spk_id,
      spk_type: spk_type,
      nik: nik,
      employee_name: employee_name,
      start_date: start_date,
      end_date: end_date,
      client_id: client_id,
      contract_title: contract_title,
      contract_no: contract_no,
      last_spk: last_spk,
      return_date: return_date,
      return_reason: return_reason,
    });
    setShowEditSpk(true);
  }

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => {
                editSpk(
                  row.values.spk_id,
                  row.values.spk_type,
                  row.values.nik,
                  row.values.employee_name,
                  row.values.start_date,
                  row.values.end_date,
                  row.values.client_id,
                  row.values.contract_title,
                  row.values.contract_no,
                  row.values.last_spk,
                  row.values.return_date,
                  row.values.return_reason
                );
              }}
              disabled={!accessMenu.is_update && !accessMenu.is_delete}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "send_email",
        Header: "Send Email",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => {
                handleShowEmail(
                  row.values.email_office,
                  row.values.nik,
                  row.values.employee_name,
                  row.values.position,
                  row.values.client_name,
                  row.values.spk_type,
                  row.values.start_date,
                  row.values.end_date,
                  row.values.pdf
                );
              }}
              disabled={!accessMenu.is_update && !accessMenu.is_delete}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  const data = detailSpk.length === 0 ? EmptyData : detailSpk;
  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => {
      delete v.spk_id;
      delete v.client_id;
      delete v.spk_no;
      delete v.contract_title;
      delete v.contract_no;
      delete v.email_office;
      delete v.position;
    });
    let excel = [];
    excelData.forEach((v) => {
      excel.push({
        nik: v.nik,
        employee_name: v.employee_name,
        start_date: v.start_date,
        end_date: v.end_date,
        client_name: v.client_name,
        spk_type: v.spk_type,
        // contract_title: v.contract_title,
        // contract_no: v.contract_no,
        pdf: v.pdf,
      });
    });

    excel.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = [
      "NIK",
      "Name",
      "Start Date",
      "End Date",
      "Client",
      "SPK Type",
      // "Contract Title",
      // "Contract No",
      "PDF",
    ];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excel.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: `Detail SPK ${detailSpk[0].nik} - ${detailSpk[0].employee_name}`,
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {detailSpk[0]?.nik} - {detailSpk[0]?.employee_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ReactTableCustomHooks
            columns={columnsSpk}
            data={data}
            handleDownloadExcel={handleDownloadExcel}
            handleFilter={null}
            tableHooks={props.title !== "waiting" ? tableHooks : ""}
            isLoading={isLoading}
            hiddenColumns={[
              "spk_id",
              "nik",
              "employee_name",
              "employee_email",
              "client_id",
              "position",
              "email_office",
              "contract_title",
              "contract_no",
              "last_spk",
              "return_date",
              "return_reason",
            ]}
          />
        </div>
      </Modal.Body>

      <Modal
        show={showEmail}
        onHide={handleCloseEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEmailSpk
          title={"talent"}
          data={emailData}
          alert={props.alert}
          closeModal={handleCloseEmail}
        />
      </Modal>

      <Modal
        show={showEditSpk}
        onHide={handleCloseEditSpk}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditSpk
          data={formData}
          resetTable={fetchDetailSpk}
          editStatus={setEditStatus}
          accessMenu={accessMenu}
          setActionEdit={props.setActionEdit}
          menu="detail"
        />
      </Modal>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}
export default ModalDetailSpk;
