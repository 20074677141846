import {
  faCloudArrowDown,
  faFolderOpen,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FilterResign from "./FilterResign";
import Editres from "./Editresign";
import Detail from "./Modalresign";
import moment from "moment";
import ModalMessage from "./ModalMessage";
import Addresign from "./addresign";
import { useOutletContext } from "react-router-dom";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import { getResign } from "../../../data-api/resign";
import writeXlsxFile from "write-excel-file";

function TableResign() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterNik, setFilterNik] = useState("");
  const [filteryear, setFilteryear] = useState("");
  const [filtercompany, setFiltercompany] = useState("");
  const [filtermonth, setFiltermonth] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [showAddresign, setShowAddresign] = useState(false);
  const [showmodalmessageres, setshowmodalmessageres] = useState(false);
  const [modaldataresign, setmodaldataresign] = useState([]);

  //get context for access menu
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Resign"
  ).sub_menu;
  const resignAccess = access.find((data) => (data.sub_menu = "Resign"));

  const [showmodalmessage, setshowmodalmessage] = useState(false);
  const [modaldata, setmodaldata] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  function handleShowEdit(data) {
    setFilterNik(data);
    setShowEdit(true);
  }

  const [showFilter, setShowFilter] = useState(false);

  function handlemodalMres(resign) {
    setmodaldataresign(resign);
    setshowmodalmessageres(true);
  }
  function handleCloseResign() {
    setShowAddresign(false);
  }
  function handleShowDetail(nik) {
    setFilterNik(nik);
    setShowDetail(true);
  }
  function handleCloseDetail() {
    setShowDetail(false);
  }
  function Handlemodalmessage(modaldata) {
    setshowmodalmessage(false);
    setmodaldata(modaldata);
    setshowmodalmessage(true);
  }

  function HandlecloseEdit() {
    setShowEdit(false);
  }

  function closemodaledit1() {
    setShowAddresign(false);
    setshowmodalmessage(false);
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setIsLoading(true);
    getResign().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Position",
        accessor: "position_name",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Last Day",
        accessor: "last_day",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "Reason",
        accessor: "reason",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger bnt-sm"
              onClick={() => handleShowDetail(row)}
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger bnt-sm"
              onClick={() =>
                handleShowEdit({
                  nik: row.values.nik,
                  name: row.values.employee_name,
                  reason: row.values.reason,
                  client: row.values.placement,
                  position: row.values.position_name,
                  department: row.values.department,
                })
              }
              disabled={!resignAccess.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  function handleDownloadExcel() {
    const columnsExcel = columns.map((item) => item.Header);
    data.forEach((item) => {
      item.last_day =
        item.last_day === "" ? "" : moment(item.last_day).format("DD-MMM-YYYY");
    });
    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    var newData = [];
    data.forEach((item) => {
      newData.push({
        nik: item.nik,
        employee_name: item.employee_name,
        position_name: item.position_name,
        placement: item.placement,
        company: item.company,
        last_day: item.last_day,
        reason: item.reason,
      });
    });
    newData.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Resign",
    });
  }

  function removeFilter(type) {
    if (type === "month") {
      fetchresign("", filteryear.value, filtercompany.value);
      setFiltermonth("");
    } else if (type === "year") {
      fetchresign(filtermonth.value, "", filtercompany.value);
      setFilteryear("");
    } else if (type === "company") {
      fetchresign(filtermonth.value, filteryear.value, "");
      setFiltercompany("");
    }
  }
  function filter(month, year, company) {
    setFiltermonth(month);
    setFilteryear(year);
    setFiltercompany(company);
    fetchresign(
      month === "" ? "" : month.value,
      year === "" ? "" : year.value,
      company === "" ? "" : company.value
    );
  }
  function fetchresign(month, year, company) {
    setIsLoading(true);
    let params = {
      month: month,
      year: year,
      company: company,
    };
    getResign(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      // setDataisLoaded(false);
      setIsLoading(false);
    });
  }

  function handleClosefilter() {
    setShowFilter(false);
  }
  function handleFilter() {
    setShowFilter(true);
  }
  return (
    <>
      {/* show state filter */}
      <div className="d-flex flex-row bd-highlight mb-3 mt-3">
        {filtermonth !== "" ? (
          <div className="p-2 bd-highlight card me-2 d-flex flex-row">
            Month : {filtermonth.label}{" "}
            <button
              type="button"
              className="btn btn-danger col-2 ms-2"
              style={{ width: "25px", height: "25px", borderRadius: "100px" }}
              onClick={(e) => removeFilter("month")}
            >
              <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
            </button>
          </div>
        ) : null}
        {filteryear !== "" ? (
          <div className="p-2 bd-highlight card me-2 d-flex flex-row">
            Year : {filteryear.label}
            <button
              type="button"
              className="btn btn-danger col-2 ms-2"
              style={{ width: "25px", height: "25px", borderRadius: "100px" }}
              onClick={(e) => removeFilter("year")}
            >
              <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
            </button>
          </div>
        ) : null}
        {filtercompany !== "" ? (
          <div className="p-2 bd-highlight card me-2 d-flex flex-row">
            company : {filtercompany.label}
            <button
              type="button"
              className="btn btn-danger col-2 ms-2"
              style={{ width: "25px", height: "25px", borderRadius: "100px" }}
              onClick={(e) => removeFilter("company")}
            >
              <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
            </button>
          </div>
        ) : null}
      </div>

      <div className="row custom-text-14 w-100 ms-1 mb-3">
        <div className="col-4"></div>
        <div className="col-8 d-flex justify-content-end">
          <button
            className="btn btn-outline-danger btn-sm ms-3 col-3 custom-text-14"
            onClick={() => {
              setShowAddresign(true);
            }}
            disabled={!resignAccess.is_create}
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ color: "red", marginRight: "10px" }}
            />
            Add
          </button>
        </div>
      </div>

      <ReactTableCustomHooks
        columns={columns}
        data={data}
        handleDownloadExcel={handleDownloadExcel}
        handleFilter={handleFilter}
        tableHooks={tableHooks}
        hiddenColumns={[]}
        paging={[10, 20, 50, 100]}
        isLoading={isLoading}
      />

      {/* modal detail */}
      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Detail data={filterNik} onHide={handleCloseDetail} />
      </Modal>

      {/* modal edit */}
      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Editres
          data={filterNik}
          message={Handlemodalmessage}
          close={HandlecloseEdit}
        />
      </Modal>

      {showmodalmessage ? (
        <ModalMessage data={modaldata} click={closemodaledit1} />
      ) : null}

      {/* modal filter */}
      <Modal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <FilterResign
          filter={filter}
          onHide={handleClosefilter}
          filterData={{
            month: filtermonth,
            year: filteryear,
            company: filtercompany,
          }}
        />
      </Modal>
      {/* modal Add */}
      <Modal
        show={showAddresign}
        onHide={() => setShowAddresign(false)}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Addresign message={handlemodalMres} onHide={handleCloseResign} />
      </Modal>

      {showmodalmessageres ? (
        <ModalMessage data={modaldataresign} click={closemodaledit1} />
      ) : null}
    </>
  );
}

export default TableResign;
