import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import "./Login.css";
import { Editor } from "@tinymce/tinymce-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { axiosError } from "../../utils/util";

function Login() {
	const [params] = useSearchParams()

  useEffect(() => {
    fetchUserDetail();
		if (params.get("error") != null && params.get("code") != null) {
			setShowModalGoogleIssue(true);
			setErrorMessage(params.get("error"))
			setCode(params.get("code"))
		}
  }, []);

  function fetchUserDetail() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${cookies.get("session")}`,
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res);
          navigate("/");
        }
      })
      .catch((error) => {
        // let errorResponse = axiosError(error);
        // setErrorMessage(errorResponse.meta.message);
      });
  }
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [otpAttemp, setOTPAttemp] = useState("");

  const [showModalOtp, setShowModalOtp] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

	const [showModalGoogleIssue, setShowModalGoogleIssue] = useState(false);
	const [code, setCode] = useState(200);

  const [modalSuccess, setModalSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [showContactAdmin, setShowContactAdmin] = useState(false);
  const [showSuccessContactAdmin, setShowSuccessContactAdmin] = useState(false);
  const [emailMessage, setEmailMessage] =
    useState(`Dear Team Integrals, <br><br>
  [your issue here ... ex: OTP not received, OTP tidak sesuai]<br/><br/>
  Please help,<br/>
  Thank you.
   <br/>`);

  const navigate = useNavigate();
  const cookies = new Cookies();

  function setToken(token, expires) {
    cookies.set("session", token, {
      expires: new Date(expires),
    });
  }

  function requestOTP(event) {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/request-otp`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
          },
        }
      )
      .then((res) => {
        if (res.data.meta.status === "success") {
          setOTPAttemp(res.data.data.attemps);
          setModalSuccess(true);
          setShowModalOtp(true);
        } else {
          setModalSuccess(false);
          setShowModalOtp(true);
        }
      })
      .catch((error) => {
        let errorResponse = axiosError(error);
        setErrorMessage(errorResponse.meta.message);
        // if (error.code === "ERR_NETWORK") {
        //   setErrorMessage(error.message);
        // } else {
        //   setErrorMessage(error.response.data.meta.message);
        // }

        setModalSuccess(false);
        setShowModalOtp(true);
      });
  }

  function login(event) {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          email: email,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
          },
        }
      )
      .then((res) => {
        if (res.data.meta.status === "success") {
          setShowModalSuccess(true);
          setModalSuccess(true);
          setToken(res.data.data.access_token, res.data.data.expires_in);
          setTimeout(function () {
            // navigate("/");
            window.location.reload();
          }, 2000);
        } else {
          setShowModalSuccess(true);
          setModalSuccess(false);
        }
      })
      .catch((error) => {
        let errorResponse = axiosError(error);
        setErrorMessage(errorResponse.meta.message);
        // setErrorMessage(error.response.data.meta.message);
        setShowModalSuccess(true);
        setModalSuccess(false);
      });
  }

	const handleLoginViaGoogle = () => {
		const rootUrl = `https://accounts.google.com/o/oauth2/auth`;

		const options = {
			redirect_uri: `${String(process.env.REACT_APP_GOOGLE_REDIRECT_URL)}`,
			client_id: `${String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}`,
			access_type: "offline",
			response_type: "code",
			prompt: "consent",
			scope: [
				"https://www.googleapis.com/auth/userinfo.email",
			].join(" "),
		};

		const qs = new URLSearchParams(options);

		window.location.replace(`${rootUrl}?${qs.toString()}`);
	}

  function sendEmail(event) {
    event.preventDefault();
    var bodyFormData = new FormData();

    let newMessage = emailMessage;
    let tempMessage = emailMessage.trim();

    if (
      tempMessage === `<p><br></p>` ||
      tempMessage === `<p><br data-mce-bogus="1"></p>` ||
      tempMessage === `<p></p>`
    ) {
      newMessage = "";
    }

    bodyFormData.append("user_request", email);
    bodyFormData.append("type", "2");
    bodyFormData.append("section", "2");
    bodyFormData.append("message", newMessage);

    axios
      .post(`${process.env.REACT_APP_API_URL}/helpdesk/ticket`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then(function (response) {
        setShowContactAdmin(false);
        setShowSuccessContactAdmin(true);
      })
      .catch(function (error) {
        // setErrorMessage(error.response.data.meta.message);
        let errorResponse = axiosError(error);
        setErrorMessage(errorResponse.meta.message);
        setShowModalSuccess(true);
        setModalSuccess(false);
      });
  }

  return (
    <>
      <div className="login-page">
        <div className="container card container-login">
          <div className="login-left-container">
            <div className="img-login-container">
              <img
                className="img-login-logo"
                src="/icons/idstar-logo.png"
                alt="idstar logo"
              />
            </div>
            <div className="login-content-container h-50 mt-5 pt-3">
            <img src="/integrals.png" alt="logo" style={{
              width:"100px"
            }} />
              <p
                className="fw-bold"
                style={{ fontSize: "36px", color: "#525555" }}
              >
                Welcome Back
              </p>
              <p
                className="fw-medium"
                style={{
                  fontSize: "16px",
                  color: "#525555",
                  marginTop: "-25px",
                }}
              >
                {/* Please enter your details */}
                Please sign in with your google office account
              </p>
              <div className="form-login">
                {/* <Form className="" onSubmit={(e) => login(e)}>
                  <Form.Group className="fw-semibold d-flex flex-column">
                    <Form.Label className="mt-2 text-start">Email</Form.Label>
                    <div className="d-flex flex-row">
                      <Form.Control
                        style={{ width: "100%" }}
                        aria-describedby="basic-addon1"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            requestOTP(e);
                          }
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="fw-semibold d-flex flex-column mt-2">
                    <Form.Label className="mt-2 text-start">OTP</Form.Label>
                    <div className="d-flex flex-row">
                      <Form.Control
                        style={{ width: "60%" }}
                        aria-describedby="basic-addon1"
                        onChange={(e) => setOTP(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            login(e);
                          }
                        }}
                      />
                      <div className="text-end flex-fill">
                        <button
                          className="btn btn-outline-danger px-3"
                          onClick={(e) => requestOTP(e)}
                        >
                          Request
                        </button>
                      </div>
                    </div>
                  </Form.Group>
                  <button type="submit" className="btn btn-danger mt-4 w-100">
                    Login
                  </button>
                </Form> */}
                <button 
									className="btn btn-light mt-5 w-100 d-flex flex-row p-0 rounded-3" 
									onClick={handleLoginViaGoogle}
								>
                  <div className="px-2 text-center align-middle justify-center">
                    <img
                      src={
                        "https://integrals-public.s3.ap-southeast-1.amazonaws.com/image/icons/Google.png"
                      }
                      width={"18px"}
                      alt={""}
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                  <div
                    className="flex-fill bg-primary text-white rounded-end"
                    style={{ padding: "5px" }}
                  >
                    Sign In with Google
                  </div>
                </button>
                <div className="p-2">
                  have a login problem ?{" "}
                  <span
                    className="fw-bold text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowContactAdmin(true)}
                  >
                    Contact Admin
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 login-right-container">
            <img
              className="login-right-logo"
              src="/icons/logo-login.png"
              alt="integrals logo"
            />
          </div>
        </div>
      </div>

      <Modal
        show={showModalOtp}
        onHide={() => setShowModalOtp(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className={modalSuccess ? "bg-success" : "bg-danger"}
        >
          <Modal.Title>
            {modalSuccess ? "Send Email Success" : "Send Email Failed"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalSuccess ? (
            <div className="text-center">
              <p>We have sent an OTP to Your email.</p>
              <p>OTP has been sent {otpAttemp} time(s) of max 3 attempts.</p>
              <p>Please check Your email.</p>
            </div>
          ) : (
            <div className="text-center">
              <p>{errorMessage}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalSuccess}
        onHide={() => setShowModalSuccess(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className={modalSuccess ? "bg-success" : "bg-danger"}
        >
          <Modal.Title>
            {modalSuccess ? "Login Success" : "Login Failed"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalSuccess ? (
            <div className="text-center">
              <p> Please wait to redirect</p>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <p>{errorMessage}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showContactAdmin}
        onHide={() => setShowContactAdmin(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Login Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={sendEmail}>
            <div className="container">
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2 w-25">Email</Form.Label>
                <Form.Control
                  className="w-100 "
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Subject</Form.Label>
                <Form.Control
                  style={{ width: "90%" }}
                  aria-describedby="basic-addon1"
                  defaultValue={"Integrals - New Access User"}
                  disabled
                />
              </Form.Group> */}
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2 w-25">Issue</Form.Label>

                <Editor
                  className="w-75"
                  apiKey="rzu0yah0mou5ula2bois6fdgq2lxo1y70qzxk03vowfvgb2s"
                  initialValue={emailMessage}
                  // style={{ width: "50%" }}
                  init={{
                    width: 1200,
                    height: 500,
                    menubar: false,
                  }}
                  onChange={(e) => setEmailMessage(e.level.content)}
                />
              </Form.Group>
              <div className="d-flex justify-content-end mt-5">
                <button
                  className="btn btn-danger col-3 mt-4 mb-4"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccessContactAdmin}
        onHide={() => setShowSuccessContactAdmin(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="p-5">
            <div
              className="bg-danger rounded-circle col-6 m-auto"
              style={{ width: "200px", height: "200px" }}
            >
              <FontAwesomeIcon
                style={{ marginTop: "35px", marginLeft: "35px" }}
                icon={faEnvelope}
                size="8x"
                color="white"
              />
            </div>
            <h2 className="text-center text-danger fw-bold mt-4">Success</h2>
            <div className="text-center fw-semibold">
              Your issue has been sent
            </div>
            <div className="text-center mt-5">
              <button
                className="btn btn-danger px-5"
                onClick={() => setShowSuccessContactAdmin(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

			<Modal
        show={showModalGoogleIssue}
        onHide={() => setShowModalGoogleIssue(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className={"bg-danger"}
        >
          <Modal.Title>
            {code[0] === "4" ? "Login Issue" : 
							code === "500" ? "Server Error": ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
					<div className="text-center">
						<p>{errorMessage}</p>
					</div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
