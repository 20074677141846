import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getWaitingSpkHistory } from "../../../data-api/resource-team";
import ReactTable from "../../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";

function ModalRejectHistory(props) {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getWaitingSpkHistory(props.data.spk_id).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setHistory([]);
      } else {
        setHistory(result.data);
      }
      setIsLoading(false);
    });
  }, []);

  const columnsHistory = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Reject Reason",
        accessor: "reject_reason",
      },
      {
        Header: "Reject Time",
        accessor: "reject_time",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);
  const data = history.length === 0 ? EmptyData : history;
  function handleDownloadExcel() {
    const columnsExcel = columnsHistory.map((item) => item.Header);
    const dataSet = [];
    const dataHeader = [];

    //append header table
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: `Reject SPK History ${props.data.nik} - ${props.data.employee_name}`,
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>History Reject</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactTable
          columns={columnsHistory}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          isLoading={isLoading}
          hiddenColumns={[]}
        />
      </Modal.Body>
    </>
  );
}

export default ModalRejectHistory;
