import { useEffect, useState } from "react";
import { Image, Modal, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Modal.css";

function ModalMessage(props) {
  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleShow();
  }, []);

  const CloseClick = () => {
    props.click();
    handleClose();
  };

  function Message() {
    if (props.data.code === 201 || props.data.code === 200) {
      return (
        <>
          <Row>
            <div style={{ textAlign: "center" }}>
              <Image
                style={{ width: "10rem", height: "10rem" }}
                roundedCircle
                src={
                  "https://integrals.s3.ap-southeast-1.amazonaws.com/images/success.png"
                }
              />
            </div>
          </Row>
          <Row>
            <h4 style={{ textAlign: "center" }} className="mt-4">
              Success
            </h4>
          </Row>

          <Row>
            <h5 style={{ textAlign: "center" }}>{props.data.message}</h5>
          </Row>

          <Row>
            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-success mt-5"
                onClick={() => CloseClick()}
              >
                Close
              </button>
            </div>
          </Row>
        </>
      );
    } else if (props.data.code === 404) {
      return (
        <>
          <Row>
            <div style={{ textAlign: "center" }}>
              <Image
                style={{ width: "11rem", height: "10rem" }}
                src={
                  "https://integrals.s3.ap-southeast-1.amazonaws.com/images/warning.png"
                }
              />
            </div>
          </Row>
          <Row>
            <h4 style={{ textAlign: "center" }} className="mt-4">
              Warning
            </h4>
          </Row>

          <Row>
            <h5 style={{ textAlign: "center" }}>{props.data.message}</h5>
          </Row>

          <Row>
            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-warning mt-5"
                onClick={() => CloseClick()}
              >
                Close
              </button>
            </div>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <div style={{ textAlign: "center" }} className="p3">
              <Image
                style={{ width: "10rem", height: "10rem" }}
                roundedCircle
                src={
                  "https://integrals.s3.ap-southeast-1.amazonaws.com/images/failed-icon-7.png"
                }
              />
            </div>
          </Row>
          <Row>
            <h4 style={{ textAlign: "center" }} className="mt-4">
              Error
            </h4>
          </Row>

          <Row>
            <h5 style={{ textAlign: "center" }}>
              {props.data.meta === undefined
                ? props.data.message
                : props.data.meta.message}
            </h5>
          </Row>

          <Row>
            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-danger mt-5"
                onClick={() => CloseClick()}
              >
                Close
              </button>
            </div>
          </Row>
        </>
      );
    }
  }

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-3">
        <Message />
      </Modal.Body>
    </Modal>
  );
}

export default ModalMessage;
