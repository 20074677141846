import { faFolderOpen, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalDetailProject from "../modal/ModalDetailProject";
import ModalEditProject from "../modal/ModalEditProject";
import ModalMessage from "../../template/ModalMessage";
import { useOutletContext } from "react-router-dom";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";
import { getProjectTeam } from "../../../data-api/project-team";

const columns = [
  {
    Header: "NIK",
    accessor: "nik",
  },
  {
    Header: "Name",
    accessor: "employee_name",
  },
  {
    Header: "Position",
    accessor: "position_name",
  },
  {
    Header: "Grade",
    accessor: "grade",
  },
  {
    Header: "Placement",
    accessor: "placement",
  },
  {
    Header: "Project Type",
    accessor: "project_type",
  },
];

function TableProject(props) {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Project Team"
  ).sub_menu;
  const ProjectTeamAccess = access.find(
    (data) => data.sub_menu === "Project Team"
  );

  const [project, setProject] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setIsLoading(true);
    getProjectTeam().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data !== null ? setProject(res.data) : setProject([]);
      } else {
        props.error(false, res.meta.message);
        setProject([]);
      }
      setIsLoading(false);
    });
  }

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDetail(false);
  };

  const [modalData, setModalData] = useState([]);

  const handleShowDetail = (nik, name) => {
    const data = {
      nik: nik,
      name: name,
    };
    setModalData(data);
    setShowDetail(true);
  };

  const handleShowEdit = (nik, name, type) => {
    let type_id = "";
    if (type === "Resource") {
      type_id = "2";
    } else {
      type_id = "1";
    }

    const data = {
      nik: nik,
      name: name,
      type: type_id,
    };
    setModalData(data);
    setShowEdit(true);
  };

  // for handle list columns table
  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: "detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowDetail(row.values.nik, row.values.employee_name)
              }
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        Header: "Add",
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEdit(
                  row.values.nik,
                  row.values.employee_name,
                  row.values.project_type
                )
              }
              disabled={!ProjectTeamAccess.is_create}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </div>
        ),
      },
    ]);
  };

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((item) => {
      delete item.project_start;
      delete item.project_end;
    });

    const dataSet = [];
    const dataHeader = [];
    //append header table
    const columnsExcel = columns.map((item) => item.Header);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excelData.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Project Team",
    });
  }

  const [showModalMessage, setShowModalMessage] = useState(false);

  const handleShowModalMessage = (modalData) => {
    setModalData(modalData);
    setShowModalMessage(true);
  };

  const handleCloseMessage = () => {
    setShowModalMessage(false);
    refreshData();
  };

  const EmptyData = useMemo(() => [], []);

  const data = project.length === 0 ? EmptyData : project;

  return (
    <>
      <div className="">
        <ReactTableCustomHooks
          columns={columns}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          tableHooks={tableHooks}
          isLoading={isLoading}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>

      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditProject
          data={modalData}
          close={handleCloseEdit}
          message={handleShowModalMessage}
        />
      </Modal>

      <Modal
        show={showDetail}
        onHide={handleCloseDetail}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalDetailProject data={modalData} access={ProjectTeamAccess} />
      </Modal>

      {showModalMessage ? (
        <ModalMessage data={modalData} click={handleCloseMessage} />
      ) : null}
    </>
  );
}

export default TableProject;
