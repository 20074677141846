import { Alert, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  getExportFilter,
  getTimesheetExport,
} from "../../../data-api/timesheet";
import Select from "react-select";
import { FormatDate } from "../../template/format";
import "../Timesheet.css";
import moment from "moment";
import writeXlsxFile from "write-excel-file";

const columns = [
  {
    Header: "NIK",
    accessor: "nik", // accessor is the "key" in the data
  },
  {
    Header: "Name",
    accessor: "name", // accessor is the "key" in the data
  },
  {
    Header: "Phone Number",
    accessor: "phone_number", // accessor is the "key" in the data
  },
  {
    Header: "Email Office",
    accessor: "email_office", // accessor is the "key" in the data
  },
  {
    Header: "Placement",
    accessor: "placement", // accessor is the "key" in the data
  },
  {
    Header: "Work Days",
    accessor: "work_days", // accessor is the "key" in the data
  },
  {
    Header: "Sick Leave",
    accessor: "sick_leave", // accessor is the "key" in the data
  },
  {
    Header: "Normal Leave",
    accessor: "normal_leave", // accessor is the "key" in the data
  },
  {
    Header: "Start Date",
    accessor: "start_date", // accessor is the "key" in the data
    Cell: (props) => FormatDate(props.value),
  },
  {
    Header: "End Date",
    accessor: "end_date", // accessor is the "key" in the data
    Cell: (props) => FormatDate(props.value),
  },
  {
    Header: "Periode",
    accessor: "periode", // accessor is the "key" in the data
  },
  {
    Header: "Timesheet",
    accessor: "timesheet_file", // accessor is the "key" in the data
    Cell: ({ row }) => (
      <div style={{ "text-align": "center" }}>
        <input
          type="checkbox"
          defaultChecked={row.values.timesheet_file === "Y" ? true : false}
          disabled
        />
      </div>
    ),
  },
  {
    Header: "BAST",
    accessor: "bast_file", // accessor is the "key" in the data
    Cell: ({ row }) => (
      <div style={{ "text-align": "center" }}>
        <input
          type="checkbox"
          defaultChecked={row.values.bast_file === "Y" ? true : false}
          disabled
        />
      </div>
    ),
  },
  {
    Header: "Overtime",
    accessor: "overtime_file", // accessor is the "key" in the data
    Cell: ({ row }) => (
      <div style={{ "text-align": "center" }}>
        <input
          type="checkbox"
          defaultChecked={row.values.overtime_file === "Y" ? true : false}
          disabled
        />
      </div>
    ),
  },
  {
    Header: "Leave",
    accessor: "leave_file", // accessor is the "key" in the data
    Cell: ({ row }) => (
      <div style={{ "text-align": "center" }}>
        <input
          type="checkbox"
          defaultChecked={row.values.leave_file === "Y" ? true : false}
          disabled
        />
      </div>
    ),
  },
];

function ModalExport(props) {
  const [modalTitle, setModalTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [searchVal, setSearchVal] = useState("");

  const [placements, setPlacements] = useState([]);
  const [periodies, setPeriodeis] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Msg, setMsg] = useState("");

  useEffect(() => {
    getExportFilter().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code !== 200) {
        setMsg(result.meta.message);
        return;
      }

      setPlacements(result.data.placements);
      setPeriodeis(result.data.months);
    });
    DataExport();
    setModalTitle(props.data.title);
    handleShow();
  }, []);

  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [...columns]);
  };

  const [dataTable, setDataTable] = useState([]);
  const [valuePlacement, setValuePlacement] = useState([]);
  const [valuePeriode, setValuePeriode] = useState([]);

  const DataExport = (state, type) => {
    setIsLoading(true);
    let placement = valuePlacement.value;
    let periode = valuePeriode.value;

    if (type === 1) {
      if (state == null) {
        placement = undefined;
        setValuePlacement([]);
      } else {
        placement = state.value;
        setValuePlacement({ value: state.value, label: state.label });
      }
    } else if (type === 2) {
      if (state == null) {
        periode = undefined;
        setValuePeriode([]);
      } else {
        periode = state.value;
        setValuePeriode({ value: state.value, label: state.label });
      }
    }

    getTimesheetExport(placement, periode).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code !== 200) {
        setMsg(result.meta.message);
        setIsLoading(false);
        return;
      }
      setDataTable(result.data);
      setIsLoading(false);
    });
  };

  // func export to excel xls
  function handleDownloadExcel() {
    data.forEach((item) => {
      item.start_date = FormatDate(item.start_date);
      item.end_date = FormatDate(item.end_date);
    });

    const dataSet = [];
    const dataHeader = [];
    columns.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Export_Timesheet",
    });
  }

  const EmptyData = useMemo(() => [], []);

  //catching the data. Purpose is to only have 1 instance.
  const data =
    dataTable === null || dataTable === undefined ? EmptyData : dataTable;
  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      {Msg === "" ? null : (
        <Alert variant="danger" onClose={() => setMsg("")} dismissible>
          <strong>{Msg}</strong>
        </Alert>
      )}
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ms-3 me-3 custom-text-14">
          <div className="d-flex justify-content-between mt-1 mb-3">
            <div className="container text-start text-danger fw-semibold">
              {rows.length} records
            </div>
            <div className="col-8 bd-highlight d-flex justify-content-end">
              <form
                id="search-reacttablecustom"
                className="input-group w-50 flex border border-danger rounded"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search ..."
                  value={searchVal}
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  onChange={(e) => {
                    setSearchVal(e.target.value);
                    if (e.target.value === "") {
                      setGlobalFilter(e.target.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchVal(e.target.value);
                      searchItem();
                    }
                  }}
                />
                {searchVal !== "" ? (
                  <button
                    className="btn btn-outline-light text-danger btn-sm"
                    onClick={() => {
                      setSearchVal("");
                      setGlobalFilter("");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ color: "#848484" }}
                      size="sm"
                    />
                  </button>
                ) : null}
                <button
                  className="btn btn-danger btn-sm"
                  type="button"
                  id="button-addon2"
                  onClick={() => searchItem()}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    style={{ color: "#fff", float: "right" }}
                  />
                </button>
              </form>
              <button
                className="btn btn-outline-danger ms-3 col-3 fa btn-sm"
                onClick={handleDownloadExcel}
              >
                <FontAwesomeIcon
                  icon={faCloudArrowDown}
                  style={{ marginRight: "10px" }}
                  className="fa-cloud-arrow-down"
                />
                Download
              </button>
            </div>
          </div>
          <Row className="mb-3">
            <Col>
              <Select
                className="basic-single form-select-sm"
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 35,
                    minHeight: 35,
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: "14px",
                  }),
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                options={placements}
                isClearable={true}
                placeholder={"-- Select Placement --"}
                defaultValue={valuePlacement}
                onChange={(e) => DataExport(e, 1)}
              ></Select>
            </Col>
            <Col>
              <Select
                className="basic-single form-select-sm"
                classNamePrefix="select"
                options={periodies}
                isClearable={true}
                placeholder={"-- Select Periode --"}
                defaultValue={valuePeriode}
                onChange={(e) => DataExport(e, 2)}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 35,
                    minHeight: 35,
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: "14px",
                  }),
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              ></Select>
            </Col>
          </Row>

          <div className="overflow-x-scroll custom-table custom-text-14">
            <table className="table table table-sm align-middle">
              <thead
                className="text-danger align-middle nowrap"
                style={{ textAlign: "center" }}
              >
                {headerGroups.map((headerGroup, key) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                    {headerGroup.headers.map((column, key) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        key={key}
                        className="text-danger"
                      >
                        {column.render("Header")}
                        <span className="ms-2">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {isLoading ? (
                <>
                  <tbody
                    style={{ backgroundColor: "#ececec", textAlign: "center" }}
                    className="custom-text-14"
                  >
                    <td colSpan={headers.length}>
                      <div className="w-100">
                        <div className="shine-photo shine"></div>
                      </div>
                    </td>
                  </tbody>
                </>
              ) : rows.length === 0 ? (
                <tbody
                  style={{
                    backgroundColor: "#ececec",
                    textAlign: "center",
                  }}
                >
                  <td colSpan={headers.length}>No data available in table</td>
                </tbody>
              ) : (
                <tbody
                  {...getTableBodyProps()}
                  className="nowrap"
                  style={{ textAlign: "center" }}
                >
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={key}>
                        {row.cells.map((cell, key) => {
                          return (
                            <td {...cell.getCellProps()} key={key}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          <div className="pagination d-flex justify-content-center">
            <button
              className="ms-1 me-2 btn btn-danger btn-sm"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon
                className="mt-1 me-1"
                icon={faAnglesLeft}
                style={{ color: "#fff" }}
              />
              First
            </button>{" "}
            <button
              className="ms-1 me-3 btn btn-outline-danger  btn-border btn-sm"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"< Prev"}
            </button>{" "}
            {pageIndex > 4 ? (
              <span className="align-text-bottom bg-danger me-4">
                <p className="mt-3" style={{ position: "absolute" }}>
                  ...
                </p>
              </span>
            ) : null}
            {pageOptions
              .slice(
                pageIndex < 5
                  ? 0
                  : pageIndex >= pageCount - 4
                  ? pageCount - 9
                  : pageIndex - 4,
                pageIndex < 5
                  ? 9
                  : pageIndex >= pageCount - 4
                  ? pageCount
                  : pageIndex + 5
              )
              .map((index, i) => {
                if (index === pageIndex) {
                  return (
                    <button
                      className="ms-1  btn btn-danger btn-sm"
                      onClick={() => gotoPage(index)}
                      disabled={!canNextPage}
                      key={i}
                    >
                      {index + 1}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className="ms-1  btn btn-outline-danger  btn-border btn-sm"
                      onClick={() => gotoPage(index)}
                      disabled={!canNextPage && index === pageCount}
                      key={i}
                    >
                      {index + 1}
                    </button>
                  );
                }
              })}
            {pageIndex + 1 < pageOptions.length - 3 ? (
              <span className="align-text-bottom bg-danger ms-3 me-3">
                <p className="mt-3" style={{ position: "absolute" }}>
                  ...
                </p>
              </span>
            ) : null}
            <button
              className="ms-3  btn btn-outline-danger  btn-border btn-sm"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {"Next >"}
            </button>{" "}
            <button
              className="ms-2 me-1 btn btn-danger fa btn-sm"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Last
              <FontAwesomeIcon
                className="mt-1 ms-1"
                icon={faAnglesRight}
                style={{ color: "#fff", float: "right" }}
              />
            </button>{" "}
          </div>
          <div className="d-flex justify-content-end">
            Page{" "}
            <strong className="ms-1">
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalExport;
