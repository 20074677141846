import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getListDropdownTalentP,
  getTalentPartnershipById,
  putTalentPartnership,
} from "../../../data-api/talent-partnership";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDropdownPSManager,
  getDropdownTeamLeader,
} from "../../../data-api/tl-structure";

import ReactDatePicker from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { FormatDate } from "../../template/format";
import { InvalidInput, ReactSelect } from "../../../utils/ReactSelect";
import Select from "react-select";
import LoadingEffectForm from "../../template/loadingEffectForm";

function ModalEditTalentP(props) {
  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  const [positions, setPositions] = useState([]);
  const [relations, setRelations] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [listPSManager, setListPSManager] = useState([]);
  const [listTeamLeader, setListTeamLeader] = useState([]);

  const [psManager, setPsManager] = useState("");
  const [teamLeader, setTeamLeader] = useState("");

  const [nik, setNik] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [grade, setGrade] = useState("");
  const [source, setSource] = useState("");
  const [relation, setRelation] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [editConfirm, setEditConfirm] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getListDropdownTalentP().then((resultData) => {
      if (resultData.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (resultData.meta.code === 200) {
        setPositions(resultData.data.positions);
        setRelations(resultData.data.relations);
        setStatuses(resultData.data.statuses);

        getTalentPartnershipById(props.data.id).then((res) => {
          if (res.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (res.meta.code === 200) {
            setNik(res.data.nik);
            setName(res.data.employee_name);
            setEmail(res.data.email);
            setGrade(res.data.grade);
            setSource(res.data.source_talent);

            let getPosition = resultData.data.positions.find(
              (item) => item.value === res.data.position.toString()
            );
            setPosition(getPosition !== undefined ? getPosition : "");

            let getRelation = resultData.data.relations.find(
              (item) => item.value === res.data.relation.toString()
            );
            setRelation(getRelation !== undefined ? getRelation : "");

            let getStatus = resultData.data.statuses.find(
              (item) => item.value === res.data.status.toString()
            );
            setStatus(getStatus !== undefined ? getStatus : "");

            if (res.data.start_date !== "") {
              setStartDate(new Date(res.data.start_date));
            }
            if (res.data.end_date !== "") {
              setEndDate(new Date(res.data.end_date));
            }

            getDropdownPSManager().then((result) => {
              if (result.meta.code === 401) {
                window.location.reload();
                return;
              }
              if (result.data === null || result.data === undefined) {
                setListPSManager([]);
              } else {
                setListPSManager(result.data);

                let getPS = result.data.find(
                  (item) => item.nik === res.data.ps_manager
                );
                setPsManager(
                  getPS !== undefined
                    ? { value: getPS.nik, label: getPS.name }
                    : ""
                );
                if (getPS !== undefined) {
                  getDropdownTeamLeader({
                    ps_manager: res.data.ps_manager,
                  }).then((resultTL) => {
                    if (resultTL.meta.code === 401) {
                      window.location.reload();
                      return;
                    }
                    if (resultTL.data === null || resultTL.data === undefined) {
                      setListTeamLeader([]);
                    } else {
                      setListTeamLeader(resultTL.data);
                      let getTL = resultTL.data.find(
                        (item) => item.nik === res.data.leader_nik
                      );
                      setTeamLeader(
                        getTL !== undefined
                          ? { value: getTL.nik, label: getTL.name }
                          : ""
                      );
                    }
                    setIsLoading(false);
                  });
                } else {
                  setIsLoading(false);
                }
              }
            });
          } else {
            props.error(false, res.meta.message);
            props.close();
            setIsLoading(false);
          }
        });
      } else if (resultData.meta.code === 401) {
        window.location.reload();
      } else {
        showAlertError(false, resultData.meta.message);
        setIsLoading(false);
      }
    });
  }

  function fetchTeamLeader(psManager) {
    let params = {
      ps_manager: psManager,
    };
    getDropdownTeamLeader(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListTeamLeader([]);
      } else {
        setListTeamLeader(result.data);
      }
      setIsLoading(false);
    });
  }

  const handleEditTalentP = (e) => {
    e.preventDefault();
    if (!editConfirm) {
      setNik(e.target.nik.value);
      setName(e.target.name.value);
      setEmail(e.target.email.value);
      setGrade(e.target.grade.value);
      setSource(e.target.source.value);

      setEditConfirm(true);
    } else {
      setIsFetch(true);

      const data = {
        nik: nik,
        employee_name: name,
        email: email,
        position: position.value,
        grade: grade,
        source_talent: source,
        relation: parseInt(relation.value),
        status: status.value,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        leader_nik: teamLeader.value,
      };

      putTalentPartnership(props.data.id, data)
        .then((res) => {
          if (res.meta.code === 401) {
            window.location.reload();
            return;
          }
          const message = {
            code: res.meta.code,
            message: res.meta.message,
          };
          props.close();
          props.message(message);
          setIsFetch(false);
        })
        .catch((error) => {
          setIsFetch(false);
        });
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffectForm row={12} />
        </Modal.Body>
      </>
    );

  return (
    <>
      {showAlert ? (
        <div
          className={
            AlertSuccess
              ? "alert alert-success alert-dismissible fade show"
              : "alert alert-danger alert-dismissible fade show"
          }
          role="alert"
        >
          <strong>{AlertMessage}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title>
          {!editConfirm
            ? "Edit Vendor Employee"
            : "Confirm Edit Vendor Employee"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {!editConfirm ? (
            // Modal Edit Talent P
            <Form onSubmit={handleEditTalentP} className="custom-text-14">
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Nik</Form.Label>
                <Form.Control
                  id="nik"
                  name="nik"
                  className="w-75 form-control-sm"
                  type="text"
                  defaultValue={nik}
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Name</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  className="w-75 form-control-sm"
                  type="text"
                  defaultValue={name}
                  pattern="[a-zA-Z' ']+"
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "Please fill out Name using letter"
                    );
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Email</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  className="w-75 form-control-sm"
                  type="email"
                  defaultValue={email}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "Please fill out Email with email format"
                    );
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Grade</Form.Label>
                <Form.Control
                  id="grade"
                  name="grade"
                  className="w-75 form-control-sm"
                  type="text"
                  pattern="\d*"
                  minLength={"1"}
                  maxLength={"1"}
                  defaultValue={grade}
                  onInvalid={(e) => {
                    if (isNaN(e.target.value)) {
                      e.target.setCustomValidity("Please input just number");
                    } else {
                      e.target.setCustomValidity("Please fill out Grade");
                    }
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Source</Form.Label>
                <Form.Control
                  id="source"
                  name="source"
                  className="w-75 form-control-sm"
                  type="text"
                  defaultValue={source}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please fill out Source")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Position</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={position}
                    setValue={setPosition}
                    data={positions.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Position --"}
                    validationMessage={"Please Select Position"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Relation</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={relation}
                    setValue={setRelation}
                    data={relations.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Relation --"}
                    validationMessage={"Please Select Relation"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Status</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={status}
                    setValue={setStatus}
                    data={statuses.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Status --"}
                    validationMessage={"Please Select Status"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Start Date</Form.Label>
                <div className="w-75">
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate ? startDate : ""}
                    onChange={(value) => {
                      setStartDate(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Start Date")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">End Date</Form.Label>
                <div className="w-75">
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate ? endDate : ""}
                    onChange={(value) => {
                      setEndDate(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out End Date")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">PS Manager</Form.Label>
                <div className="w-75">
                  <Select
                    className="w-100 fw-normal custom-text-14 z-3"
                    options={listPSManager.map((data) => ({
                      label: data.name,
                      value: data.nik,
                    }))}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    isClearable
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={"-- Select PS Manager --"}
                    defaultValue={psManager}
                    onChange={(e) => {
                      setTeamLeader("");
                      if (e !== null) {
                        setPsManager(e);
                        fetchTeamLeader(e.value);
                      } else {
                        setTeamLeader("");
                        setListTeamLeader([]);
                      }
                    }}
                    ariaLiveMessages={"Please Select PS Manager"}
                    // components={{
                    //   Input: invalidInput,
                    // }}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">
                  Team Leader
                </Form.Label>
                <div className="w-75">
                  <Select
                    className="w-100 fw-normal custom-text-14 z-3"
                    options={
                      !isLoading &&
                      listTeamLeader.map((data) => ({
                        label: data.name,
                        value: data.nik,
                      }))
                    }
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={"-- Select Team Leader --"}
                    value={teamLeader}
                    defaultValue={teamLeader}
                    isClearable
                    onChange={(e) => {
                      if (e !== null) {
                        setTeamLeader(e);
                      } else {
                        setTeamLeader("");
                      }
                    }}
                    ariaLiveMessages={"Please Select Team Leader"}
                    // components={{
                    //   Input: invalidInput,
                    // }}
                  ></Select>
                </div>
              </Form.Group>

              <div className="d-flex flex-row-reverse bd-highlight">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm">
                  Confirm
                </button>
              </div>
            </Form>
          ) : (
            // Modal Confirm
            <>
              <div className="container custom-text-14">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Nik</Form.Label>
                  <Form.Label className="mt-2">{nik}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Name</Form.Label>
                  <Form.Label className="mt-2">{name}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Email</Form.Label>
                  <Form.Label className="mt-2">{email}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Grade</Form.Label>
                  <Form.Label className="mt-2">{grade}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Source</Form.Label>
                  <Form.Label className="mt-2">{source}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Position</Form.Label>
                  <Form.Label className="mt-2">{position.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Relation</Form.Label>
                  <Form.Label className="mt-2">{relation.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Status</Form.Label>
                  <Form.Label className="mt-2">{status.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(startDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(endDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">PS Manager</Form.Label>
                  <Form.Label className="mt-2"> {psManager.label} </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Team Leader</Form.Label>
                  <Form.Label className="mt-2"> {teamLeader.label} </Form.Label>
                </Form.Group>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={handleEditTalentP}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={() => setEditConfirm(false)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalEditTalentP;
