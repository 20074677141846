import { Form, Modal } from "react-bootstrap";
import "./Clients.css";
import { deleteClient } from "../../data-api/master-data-clients";

function ModalDeleteClient(props) {
  function formDeleteClient(event) {
    event.preventDefault();
    fetchDelClient();
  }

  //send json to backend
  function fetchDelClient() {
    deleteClient(props.data.id).then((result) => {
      if (result.data === null || result.data === undefined) {
        if (result.response.data.meta.code === 401) {
          window.location.reload();
          return;
        }

        let message = [];
        if (result.response.data.meta.code === 401) {
          window.location.reload();
          return;
        }

        if (
          result.code !== "ERR_BAD_REQUEST" ||
          result.response.data.meta.code === 500
        ) {
          message = {
            code: result.response.data.meta.code,
            message: result.response.data.meta.message,
          };
        } else {
          message = {
            code: result.response.request.status,
            message: "404 - Not Found",
          };
        }
        props.handleClose(message);
      } else {
        const message2 = {
          code: result.data.meta.code,
          message: result.data.meta.message,
        };
        if (message2.code === 200) {
          props.handleClose(message2);
        }
      }
    });
  }

  return (
    <>
      <div className="custom-text-14">
        <Modal.Header>
          <Modal.Title className="justify-content-center">
            <div className="h3-title">Are you Sure ?</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => formDeleteClient(e)}>
            <div className="container">
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-center">
                <Form.Label className="mt-2">
                  Do you really want delete this client?
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-center">
                <Form.Label className="mt-2 modal-body-font">
                  {props.data.client_name} - {props.data.project_name}
                </Form.Label>
              </Form.Group>
            </div>

            <div className="d-flex  ">
              <button
                className="btn btn-danger mb-4 ms-4 me-1 fw-bold shadow w-50 btn-sm"
                onClick={() => props.handleClose("")}
              >
                NO
              </button>
              <button className="btn btn-danger mb-4 ms-4 me-1 fw-bold shadow w-50 btn-sm">
                YES
              </button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalDeleteClient;
