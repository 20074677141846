import {
  faAnglesLeft,
  faAnglesRight,
  faCircleCheck,
  faCircleXmark,
  faFilter,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import DetailTicket from "../modal/DetailTicket";
import UpdateTicket from "../modal/UpdateTicket";
import FilterTicket from "../modal/FilterTicket";
import moment from "moment";
import SendEmail from "../modal/SendEmail";
import { getSuperadminHelpdeskLog } from "../../../../data-api/master-user";

function Ticket(props) {
  const accessMenu = props.accessMenu;
  const [isLoading, setIsLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [data, setData] = useState([]);

  const [showDetail, setShowDetail] = useState(false);
  function handleShowDetail(data) {
    setModalData(data);
    setShowDetail(true);
  }

  const [showUpdate, setShowUpdate] = useState(false);
  function handleShowUpdate(data) {
    setModalData(data);
    setShowUpdate(true);
  }
  const [showFilter, setShowFilter] = useState(false);

  const [showSendEmail, setShowSendEmail] = useState(false);
  function handleShowSendEmail(data) {
    setModalData(data);
    setShowSendEmail(true);
  }
  function handleCloseSendEmail() {
    setShowSendEmail(false);
  }
  const [modalData, setModalData] = useState("");

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  const [filterType, setFilterType] = useState("");
  const [filterSection, setFilterSection] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData(type, section, status) {
    setIsLoading(true);
    let params = {
      type: type,
      section: section,
      status: status,
    };
    getSuperadminHelpdeskLog(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Request Time",
        accessor: "request_time",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Section",
        accessor: "section",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Email",
        accessor: "email_office",
      },
      {
        Header: "Solved By",
        accessor: "solved_by",
      },
      {
        Header: "Solve Method",
        accessor: "solve_method",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "item",
        Header: "Item",
        Cell: ({ row }) => (
          <div className="card px-3 py-2">
            <div className="d-flex flex-row-reverse bd-highlight mb-4">
              <p className="text-secondary">
                {" "}
                {moment(row.original.request_time).format("YYYY-MM-DD hh:mm")}
              </p>
            </div>
            <div className="text-start" style={{ marginTop: "-50px" }}>
              <span className="fw-bold custom-text-18">
                {row.original.type} - {row.original.section}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-start col-8">
                <p className="fs-14">
                  {row.original.message !== "" ? (
                    row.original.message.replaceAll(/<\/?[^>]+(>|$)/gi, "")
                  ) : (
                    <>&#8203;</>
                  )}
                </p>
                <p className="fw-bold" style={{ marginTop: "-10px" }}>
                  From {row.original.name}
                </p>
                <div
                  className="d-flex justify-content-between fw-bold"
                  style={{ marginTop: "-10px" }}
                >
                  <p
                    className={
                      row.original.status === "New Received"
                        ? "text-primary"
                        : row.original.status === "In Progress"
                        ? "text-warning"
                        : row.original.status === "Done"
                        ? "text-success"
                        : row.original.status === "Cancelled"
                        ? "text-danger"
                        : ""
                    }
                  >
                    {row.original.status}
                  </p>
                </div>
              </div>
              <div className="col-4 text-end align-self-end">
                <button
                  className="btn btn-outline-danger fw-semibold mb-3 btn-sm"
                  onClick={() => {
                    handleShowDetail(row);
                  }}
                  style={{ width: "120px", height: "35px", padding: "0" }}
                >
                  <span>Detail</span>
                </button>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-danger ms-4 fw-semibold btn-sm"
                    onClick={() => {
                      handleShowSendEmail(row);
                    }}
                    style={{ width: "120px", height: "35px", padding: "0" }}
                    disabled={!accessMenu.is_update}
                  >
                    <span style={{ marginTop: "-20px" }}>Send Email</span>
                  </button>
                  <button
                    className="btn btn-danger ms-4 fw-semibold btn-sm"
                    onClick={() => {
                      handleShowUpdate(row);
                    }}
                    style={{ width: "120px", height: "35px", padding: "0" }}
                    disabled={!accessMenu.is_update}
                  >
                    <span style={{ marginTop: "-20px" }}>Update</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ]);
  };

  const {
    rows,
    headers,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        hiddenColumns: [
          "id",
          "name",
          "request_time",
          "type",
          "section",
          "status",
          "message",
          "email_office",
          "solved_by",
          "solve_method",
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowDetail(false);
    setShowUpdate(false);
    setShowSendEmail(false);
    setShowSuccess(true);
  }

  function filterTicket(type, section, status) {
    setFilterType(type);
    setFilterSection(section);
    setFilterStatus(status);
    fetchData(type.value, section.value, status.value);
    setShowFilter(false);
    document.getElementById("search-reacttable-ticket").reset();
  }

  function removeFilter(type) {
    if (type === "type") {
      fetchData("", filterSection.value, filterStatus.value);
      setFilterType("");
    } else if (type === "section") {
      fetchData(filterType.value, "", filterStatus.value);
      setFilterSection("");
    } else if (type === "status") {
      fetchData(filterType.value, filterSection.value, "");
      setFilterStatus("");
    }
  }

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="tabs-content">
        {/* show state filter */}

        <div className="row custom-text-14 w-100 ms-1 mb-2">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttable-ticket"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  if (e.target.value === "") {
                    setGlobalFilter(e.target.value);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchVal(e.target.value);
                    searchItem();
                  }
                }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>
            <button
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={() => setShowFilter(true)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                style={{ marginRight: "10px" }}
                className="fa-cloud-arrow-down"
              />
              Filter
            </button>
          </div>
        </div>

        <div className="d-flex flex-row bd-highlight mb-3 mt-2">
          {filterType !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Type : {filterType.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("type")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterSection !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Section : {filterSection.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("section")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterStatus !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Status : {filterStatus.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("status")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>

        <div className="overflow-x-scroll">
          <table className="table table-borderless table-sm align-middle">
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <tr>
                  <td colSpan={headers.length}>No data available in table</td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={key}>
                      {row.cells.map((cell, key) => {
                        return (
                          <td {...cell.getCellProps()} key={key}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>

        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>
          {""}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div className="d-flex justify-content-end">
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <DetailTicket data={modalData} />
      </Modal>

      <Modal
        show={showUpdate}
        onHide={() => setShowUpdate(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <UpdateTicket
          data={modalData}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <FilterTicket
          submitFilter={filterTicket}
          data={{
            type: filterType,
            section: filterSection,
            status: filterStatus,
          }}
        />
      </Modal>

      <Modal
        show={showSendEmail}
        onHide={() => setShowSendEmail(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <SendEmail
          data={modalData}
          handleSuccessModal={handleSuccessModal}
          onHide={handleCloseSendEmail}
        />
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3 btn-sm"
                onClick={() => {
                  fetchData();
                  setShowSuccess(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Ticket;
