import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faFilter,
  faFolderOpen,
  faMagnifyingGlass,
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ModalMessage from "../../template/ModalMessage";
import ModalEditTalentP from "../modal/ModalEditTalentP";
import {
  deleteTalentPartnership,
  getListDropdownTalentP,
  getTalentPartnership,
} from "../../../data-api/talent-partnership";
import ModalDetailTalentP from "../modal/ModalDetaiTalentP";
import { FormatDate } from "../../template/format";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import { ReactSelect } from "../../../utils/ReactSelect";

const columns = [
  {
    id: "id",
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "NIK",
    accessor: "nik",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "employee_status",
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Grade",
    accessor: "grade",
  },
  {
    Header: "Source Talent",
    accessor: "source_talent",
  },
  {
    Header: "Company",
    accessor: "nickname",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    Cell: (props) => {
      if (props.value !== "") {
        return FormatDate(props.value);
      } else {
        return null;
      }
    },
  },
  {
    Header: "End Date",
    accessor: "end_date",
    Cell: (props) => {
      if (props.value !== "") {
        return FormatDate(props.value);
      } else {
        return null;
      }
    },
  },
  {
    Header: "Client",
    accessor: "client_name",
  },
  {
    Header: "Renewal Days",
    accessor: "renewal_days",
  },
];

function TableTalentP(props) {
  const outletContext = useOutletContext();

  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Vendor Employee"
  ).sub_menu;
  const talentPAccess = access.find((data) => data.sub_menu === "Vendor");

  const [talentP, setTalentP] = useState([]);

  useEffect(() => {
    getListDropdownTalentP().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        setPositions(res.data.positions);
        setRelations(res.data.relations);
        setClients(res.data.placements);
        setStatuses(res.data.statuses);
      } else {
        props.error(true, res.meta.message);
      }
    });

    if (props.data.length === 0) {
      fetchFilterTalent();
    } else {
      setTalentP(props.data);
      setIsLoading(false);
    }
  }, []);

  const [searchVal, setSearchVal] = useState("");

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDetail(false);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => {
    setDeleteConfirm(false);
    setShowDelete(false);
  };

  const [modalData, setModalData] = useState([]);

  const [modalFilter, setModalFilter] = useState(false);

  const [positions, setPositions] = useState([]);
  const [relations, setRelations] = useState([]);
  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [positionTemp, setPositionTemp] = useState("");
  const [relationTemp, setRelationTemp] = useState("");
  const [clientTemp, setClientTemp] = useState("");
  const [statusTemp, setStatusTemp] = useState("");

  const [position, setPosition] = useState("");
  const [relation, setRelation] = useState("");
  const [client, setClient] = useState("");
  const [status, setStatus] = useState("");

  function filterTalent(event) {
    event.preventDefault();

    const data = {
      position: positionTemp.value,
      relation: relationTemp.value,
      client: clientTemp.value,
      status: statusTemp.value,
    };

    setPosition(positionTemp);
    setRelation(relationTemp);
    setClient(clientTemp);
    setStatus(statusTemp);

    fetchFilterTalent(data);
    setModalFilter(false);
    document.getElementById("search-reacttable-talent").reset();
  }

  function fetchFilterTalent(params) {
    setIsLoading(true);
    getTalentPartnership(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code === 200) {
        result.data !== null ? setTalentP(result.data) : setTalentP([]);
      } else {
        props.error(false, result.meta.message);
        setTalentP([]);
      }
      setIsLoading(false);
    });
  }

  function removeFilter(type) {
    let data = {};
    if (type === "position") {
      data = {
        position: "",
        relation: relationTemp.value,
        client: clientTemp.value,
        status: statusTemp.value,
      };
      setPosition("");
      setPositionTemp("");
    } else if (type === "relation") {
      data = {
        position: positionTemp.value,
        relation: "",
        client: clientTemp.value,
        status: statusTemp.value,
      };
      setRelation("");
      setRelationTemp("");
    } else if (type === "client") {
      data = {
        position: positionTemp.value,
        relation: relationTemp.value,
        client: "",
        status: statusTemp.value,
      };
      setClient("");
      setClientTemp("");
    } else if (type === "status") {
      data = {
        position: positionTemp.value,
        relation: relationTemp.value,
        client: clientTemp.value,
        status: "",
      };
      setStatus("");
      setStatusTemp("");
    }
    fetchFilterTalent(data);
  }

  function handleFilter() {
    setModalFilter(true);
  }

  const handleShowDetail = (nik, name) => {
    const data = {
      nik: nik,
      name: name,
    };
    setModalData(data);
    setShowDetail(true);
  };

  const handleShowEdit = (id) => {
    const data = {
      id: id,
    };
    setModalData(data);
    setShowEdit(true);
  };

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleShowDelete = (nik, name) => {
    if (!deleteConfirm) {
      const data = {
        nik: nik,
        name: name,
      };
      setModalData(data);
      setShowDelete(true);
      setDeleteConfirm(true);
    } else {
      deleteTalentPartnership(modalData.nik).then((res) => {
        if (res.meta.code === 401) {
          window.location.reload();
          return;
        }
        const message = {
          code: res.meta.code,
          message: res.meta.message,
        };
        handleShowModalMessage(message);
        setDeleteConfirm(false);
        handleCloseDelete();
      });
    }
  };

  const [showModalMessage, setShowModalMessage] = useState(false);

  const handleShowModalMessage = (modalData) => {
    setModalData(modalData);
    setShowModalMessage(true);
  };

  // for handle list columns table
  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowEdit(row.values.id)}
              disabled={!talentPAccess.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </div>
        ),
      },
      {
        id: "detail",
        Header: "History",
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowDetail(row.values.nik, row.values.name)}
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </div>
        ),
      },
      {
        id: "delete",
        Header: "Delete",
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowDelete(row.values.nik, row.values.name)}
              disabled={!talentPAccess.is_delete}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </div>
        ),
      },
    ]);
  };

  function handleDownloadExcel() {
    const excelData = data;

    const columnsExcel = [
      "NIK",
      "Name",
      "Email",
      "Status",
      "Position",
      "Grade",
      "Source Talent",
      "Company",
      "Client",
      "Start Date",
      "End Date",
    ];
    excelData.forEach((v) => delete v.id);
    excelData.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excelData.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Talent_Partnership",
    });
  }

  const EmptyData = useMemo(() => [], []);

  const data = talentP.length === 0 ? EmptyData : talentP;
  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0, hiddenColumns: "id" },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  const [isLoading, setIsLoading] = useState(true);

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="tabs-content">
        <div className="d-flex flex-row bd-highlight mb-3">
          {position !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Position : {position.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => {
                  removeFilter("position");
                }}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {relation !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Relation : {relation.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => {
                  removeFilter("relation");
                }}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {client !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Client : {client.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => {
                  removeFilter("client");
                }}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {status !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Status : {status.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => {
                  removeFilter("status");
                }}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>

        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
            <div className="ms-4">
              <Form.Select
                className="form-select-sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttable-talent"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  if (e.target.value === "") {
                    searchItem();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    searchItem();
                  }
                }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>
            <button
              className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={() => handleFilter(true)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                style={{ marginRight: "10px" }}
                className="fa-cloud-arrow-down"
              />
              Filter
            </button>
            <button
              className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={handleDownloadExcel}
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ marginRight: "10px" }}
              />
              Download
            </button>
          </div>
        </div>
        <div className="overflow-x-scroll px-3 mt-3">
          <table className="table table table-sm custom-table align-middle">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                  {headerGroup.headers.map((column, key) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      key={key}
                      className="text-danger"
                    >
                      {column.render("Header")}
                      <span className="ms-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <td colSpan={headers.length}>No data available in table</td>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className="text-start">
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={key}>
                      {row.cells.map((cell, key) => {
                        return (
                          <td {...cell.getCellProps()} key={key}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>
          {""}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div
          className="d-flex justify-content-end mb-2 me-2"
          style={{ color: "#525555" }}
        >
          Page{"."}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        size="l"
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form onSubmit={(e) => filterTalent(e)}>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Position</Form.Label>
                <div className="w-100">
                  <ReactSelect
                    value={positionTemp}
                    setValue={setPositionTemp}
                    data={positions.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Position --"}
                    required={false}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Relation</Form.Label>
                <div className="w-100">
                  <ReactSelect
                    value={relationTemp}
                    setValue={setRelationTemp}
                    data={relations.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Relation --"}
                    required={false}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Client</Form.Label>
                <div className="w-100">
                  <ReactSelect
                    value={clientTemp}
                    setValue={setClientTemp}
                    data={clients.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Client --"}
                    required={false}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Status</Form.Label>
                <div className="w-100">
                  <ReactSelect
                    value={statusTemp}
                    setValue={setStatusTemp}
                    data={statuses.map((data) => ({
                      label: data.label,
                      value: data.value,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Status --"}
                    required={false}
                  />
                </div>
              </Form.Group>
              <div className="d-grid gap-2">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow">
                  FILTER
                </button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showEdit}
        onHide={() => handleCloseEdit(modalData.id)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditTalentP
          data={modalData}
          refreshTable={props.refreshTable}
          error={props.error}
          close={handleCloseEdit}
          message={handleShowModalMessage}
          accessMenu={talentPAccess}
        />
      </Modal>

      <Modal
        show={showDetail}
        onHide={handleCloseDetail}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalDetailTalentP
          data={modalData}
          close={handleCloseDetail}
          show={handleShowDetail}
          refreshTable={props.refreshTable}
          accessMenu={talentPAccess}
        />
      </Modal>

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Body>
          <div className="container" style={{ textAlign: "center" }}>
            <h3>Are you sure?</h3>
            <p style={{ fontSize: 18 }}>
              Do you really want to delete{" "}
              {modalData.nik + " - " + modalData.name}
            </p>
            <div className="d-flex justify-content-center bd-highlight">
              <button
                className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-3 fw-bold shadow px-5 btn-sm"
                onClick={() => handleCloseDelete()}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mb-4 ms-1 me-1 mt-3 fw-bold shadow px-5 btn-sm"
                onClick={() => handleShowDelete()}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showModalMessage ? (
        <ModalMessage data={modalData} click={props.refreshTable} />
      ) : null}
    </>
  );
}

export default TableTalentP;
