import { Form, Modal } from "react-bootstrap";

function DetailTicket(props) {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Detail Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <div className="p-3">
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Date</Form.Label>
            <Form.Label className="w-75 text-start">
              {props.data.original.request_time}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">From</Form.Label>
            <Form.Label className="w-75 text-start">
              {props.data.original.name}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Status</Form.Label>
            <Form.Label className="w-75 text-start">
              <span
                className={
                  props.data.original.status === "New Received"
                    ? "text-primary"
                    : props.data.original.status === "In Progress"
                    ? "text-warning"
                    : props.data.original.status === "Done"
                    ? "text-success"
                    : ""
                }
              >
                {props.data.original.status}
              </span>
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Message</Form.Label>
            <Form.Label className="w-75 text-start">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.original.message,
                }}
              ></div>
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Solve Method</Form.Label>
            <Form.Label className="w-75 text-start">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.original.solve_method,
                }}
              ></div>
            </Form.Label>
          </Form.Group>
        </div>
      </Modal.Body>
    </>
  );
}

export default DetailTicket;
