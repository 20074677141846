import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getListDropdown = async () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/timesheetbast/list-dropdown`, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTimesheet = async () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/timesheetbast/uploads`, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTimesheetById = async (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/timesheetbast/${id}`, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTimesheetUploadById = async (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/timesheetbast/upload/${id}`, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postTimesheetBast = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/timesheetbast`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putTimesheetBast = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/timesheetbast/${id}`;
  return axios
    .put(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postUploadFile = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/timesheetbast/upload`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putUploadFile = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/timesheetbast/upload/${id}`;
  return axios
    .put(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getExportFilter = async () => {
  const url = `${process.env.REACT_APP_API_URL}/timesheetbast/upload/export/filter`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTimesheetHistory = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/timesheetbast/upload/history/${nik}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTimesheetExport = async (placement, periode) => {
  var param = "";
  if (placement !== undefined && periode !== undefined) {
    param = `?client=${placement}&month=${periode}`;
  } else if (placement !== undefined) {
    param = `?client=${placement}`;
  } else if (periode !== undefined) {
    param = `?month=${periode}`;
  }

  const url = `${process.env.REACT_APP_API_URL}/timesheetbast/upload/export${param}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
