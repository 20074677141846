import {
  faAnglesLeft,
  faAnglesRight,
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
  faPlusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import "./IntegralsMenu.css";
import { useOutletContext } from "react-router-dom";
import Collapsible from "./Collapse";
import {
  addSuperadminMenu,
  addSuperadminSubmenu,
  getSuperadminMenu,
  updateSuperadminMenuStatus,
} from "../../../data-api/master-user";
import moment from "moment";
import { ReactSelect } from "../../../utils/ReactSelect";

function IntegralsMenu() {
  const outletContext = useOutletContext();
  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Master User"
  ).sub_menu;
  const accessMenu = access.find((data) => data.sub_menu === "Integrals Menu");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [showAddAccess, setShowAddAccess] = useState(false);
  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");
  const [postStatus, setPostStatus] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [addMenu, setAddMenu] = useState("");

  const [showEditStatus, setShowEditStatus] = useState(false);
  const [editStatusData, setEditStatusData] = useState({});

  const [showAddSubmenu, setShowAddSubmenu] = useState(false);
  const [menuDetail, setMenuDetail] = useState("");

  useEffect(() => {
    fetchUserMenu();
  }, []);

  function fetchUserMenu() {
    setIsLoading(true);
    getSuperadminMenu().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  function resetTable() {
    fetchUserMenu();
    setShowAddAccess(false);
    setShowSuccess(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "menu_id",
        width: 10,
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Menu",
        accessor: "menu",
        Cell: (props) => {
          return (
            <div>
              <Collapsible title={props.value}>
                <div>
                  <ul>
                    {props.row.original.sub_menu !== null
                      ? props.row.original.sub_menu.map((data) => (
                          <>
                            <li>{data.sub_menu}</li>
                          </>
                        ))
                      : null}
                  </ul>
                </div>
              </Collapsible>
            </div>
          );
        },
      },
    ],
    []
  );

  function editStatus(event) {
    const json = JSON.stringify({
      is_active: editStatusData.original.is_active,
    });
    updateSuperadminMenuStatus(editStatusData.original.menu_id, json).then(
      (result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          handlePost(false, result.meta.message);
        } else {
          const message = {
            code: result.meta.code,
            message: result.meta.message,
          };
          handlePost(true, message.message);
        }
      }
    );
  }

  function handlePost(status, message) {
    setPostSuccessMessage(message);
    setIsPostSuccess(status);
    setShowEditStatus(false);
    setShowAddAccess(false);
    setShowSuccess(true);
  }

  function handleShowEditStatus(data) {
    setEditStatusData(data);
    setShowEditStatus(true);
  }

  // add custom new cell for button detail & renewal
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "active",
        Header: "",
        Cell: ({ row }) => (
          <div className="text-end d-flex flex-row-reverse">
            <div>
              <button
                className="btn btn-sm"
                onClick={() => {
                  setMenuDetail(row.original);
                  setShowAddSubmenu(true);
                }}
                disabled={!accessMenu.is_create}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="2xl"
                  className="text-danger"
                />
              </button>
            </div>
            <div style={{ marginTop: "10px" }} className="me-3">
              <label className="switch">
                <input
                  disabled={!accessMenu.is_update}
                  onChange={(e) => {
                    handleShowEditStatus(row);
                    if (row.original.is_active === true) {
                      row.original.is_active = false;
                    } else {
                      row.original.is_active = true;
                    }
                  }}
                  type="checkbox"
                  checked={row.original.is_active === true ? true : false}
                />
                <span
                  className={
                    !accessMenu.is_update
                      ? "slider round bg-secondary"
                      : "slider round"
                  }
                ></span>
              </label>
            </div>
          </div>
        ),
      },
    ]);
  };

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination,
    tableHooks
  );

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowAddAccess(false);
    setShowAddSubmenu(false);
    setShowSuccess(true);
  }

  function AddNewMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setAddMenu({
          menu: event.target[0].value,
          status: postStatus.value,
          created_by: outletContext.profile.nik,
        });
        setIsConfirm(true);
      } else {
        const json = JSON.stringify(addMenu);

        addSuperadminMenu(json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            handleSuccessModal(false, result.meta.message);
          } else {
            const message = {
              code: result.meta.code,
              message: result.meta.message,
            };
            handleSuccessModal(true, message.message);
          }
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  function addNewSubmenu(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setAddMenu({
          parent_menu_id: menuDetail.menu_id,
          sub_menu: event.target[1].value,
        });
        setIsConfirm(true);
      } else {
        const json = JSON.stringify(addMenu);

        addSuperadminSubmenu(json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            handleSuccessModal(false, result.meta.message);
          } else {
            const message = {
              code: result.meta.code,
              message: result.meta.message,
            };
            handleSuccessModal(true, message.message);
          }
          setIsConfirm(false);
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="w-100 tabs-content rounded">
        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttablecustom"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  if (e.target.value === "") {
                    searchItem();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchVal(e.target.value);
                    searchItem();
                  }
                }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>
            <button
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={() => setShowAddAccess(true)}
              disabled={!accessMenu.is_create}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginRight: "10px" }}
              />
              Add Menu
            </button>
          </div>
        </div>

        <div className="overflow-x-scroll">
          <table className="table-custom-superadmin">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                  {headerGroup.headers.map((column, key) => (
                    <th
                      {...column.getHeaderProps(column)}
                      scope="col"
                      key={key}
                      className="text-start text-danger"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <tr>
                  <td colSpan={headers.length}>No data available in table</td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className="nowrap text-start">
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={key}>
                      {row.cells.map((cell, key) => {
                        return (
                          <td {...cell.getCellProps()} key={key}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>
          {""}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div
          className="d-flex justify-content-end mb-2 me-2"
          style={{ color: "#525555" }}
        >
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        show={showAddAccess}
        onHide={() => {
          setShowAddAccess(false);
          setIsConfirm(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={AddNewMenu} className="mt-3">
            {!isConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">Menu</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    defaultValue={addMenu?.menu}
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Menu")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">
                    Status
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={postStatus}
                      setValue={setPostStatus}
                      data={[
                        { value: "1", label: "Active" },
                        { value: "2", label: "In-Active" },
                      ]}
                      isLoading={isLoading}
                      placeholder={"-- Select Status --"}
                      validationMessage={"Please Select Status"}
                    />
                  </div>
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Menu</Form.Label>
                  <Form.Label className="w-75">{addMenu.menu}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Status</Form.Label>
                  <Form.Label className="w-75">
                    {addMenu.status === 1 ? "Active" : "In-Active"}
                  </Form.Label>
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                    type="submit"
                    name="cancel"
                    value={"Cancel"}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
            ;
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddSubmenu}
        onHide={() => {
          setShowAddSubmenu(false);
          setIsConfirm(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Submenu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-3" onSubmit={addNewSubmenu}>
            {!isConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">Menu</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    required
                    defaultValue={menuDetail.menu}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">
                    Submenu
                  </Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Submenu")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Menu</Form.Label>
                  <Form.Label className="w-75">{menuDetail.menu}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Sub Menu</Form.Label>
                  <Form.Label className="w-75">{addMenu.sub_menu}</Form.Label>
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                    type="submit"
                    name="cancel"
                    value={"Cancel"}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
            ;
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditStatus}
        onHide={() => setShowEditStatus(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-center">
            <img src="/icons/question.png" alt="" style={{ width: "100px" }} />
            <h5>Are you sure?</h5>
            <p>
              Do you really want to change menu{" "}
              <strong>
                {Object.keys(editStatusData).length !== 0
                  ? editStatusData.original.menu
                  : ""}
              </strong>{" "}
              status to{" "}
              {Object.keys(editStatusData).length === 0 ? (
                ""
              ) : editStatusData.original.status === "1" ? (
                <strong>Active</strong>
              ) : (
                <strong>In-Active</strong>
              )}
              ?
            </p>
            <div className="text-center">
              <button
                className="btn btn-secondary mt-3 col-4 btn-sm"
                onClick={() => {
                  if (editStatusData.original.is_active === true) {
                    data[editStatusData.index].is_active = false;
                  } else {
                    data[editStatusData.index].is_active = true;
                  }
                  setShowEditStatus(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mt-3 col-4 ms-4 btn-sm"
                onClick={(e) => editStatus(e)}
              >
                Change
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3 btn-sm"
                onClick={() => resetTable()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default IntegralsMenu;
