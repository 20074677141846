import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getSuperadminRoleDetail } from "../../../data-api/master-user";
import { useOutletContext } from "react-router-dom";
import Collapsible from "./Collapse";

const ModalDetailRole = ({ detailData }) => {
  const outletContext = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    fetchRoleDetail();
  }, []);

  function fetchRoleDetail() {
    setIsLoading(true);

    getSuperadminRoleDetail(detailData?.id).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Detail Access Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflox-x pb-3">
          {data?.menu.length > 0 &&
            data.menu.map((parent, i) => (
              <>
                <div>
                  {parent.menu === "Dashboard" ? (
                    <>
                      <Collapsible title={parent.menu}>
                        <div className="ms-4">
                          {parent.sub_menu.map((child, j) => (
                            <>
                              <div className="d-flex flex-row mb-2">
                                <div className="col-4">
                                  <input
                                    className="form-check-input form-select-sm ms-1"
                                    style={{ transform: "scale(1.3)" }}
                                    type="radio"
                                    name="chartradio"
                                    defaultChecked={
                                      child.is_read ? true : false
                                    }
                                    disabled
                                  />
                                  <label className="ms-2 text-dark">
                                    {child.sub_menu}
                                  </label>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </Collapsible>
                    </>
                  ) : (
                    <>
                      <Collapsible title={parent.menu}>
                        <div className="d-flex flex-row mb-2 text-center fw-semibold">
                          <div className="col-4"></div>
                          <span className="col-1">Create</span>
                          <span className="col-1">Read</span>
                          <span className="col-1">Update</span>
                          <span className="col-1">Delete</span>
                        </div>

                        <div className="ms-4">
                          {parent.sub_menu.map((child, j) => (
                            <>
                              <div className="d-flex flex-row mb-2">
                                <div className="col-4">
                                  <label className=" ms-2 fw-semibold">
                                    {child.sub_menu}
                                  </label>
                                </div>
                                <div className="col-1">
                                  <input
                                    className={
                                      (child.sub_menu === "Superadmin User" ||
                                        child.sub_menu === "Integrals Menu") &&
                                      outletContext.profile.role_id !== "1"
                                        ? "form-check-input ms-1 bg-secondary"
                                        : "form-check-input ms-1"
                                    }
                                    style={{ transform: "scale(1.3)" }}
                                    type="checkbox"
                                    id={child.permission_id}
                                    key={child.permission_id}
                                    checked={child.is_create}
                                    onChange={() => {
                                      const updatedList = { ...data };
                                      updatedList.menu[i].sub_menu[
                                        j
                                      ].is_create = !child.is_create;
                                      setData(updatedList);
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-1">
                                  <input
                                    className="form-check-input ms-1"
                                    style={{ transform: "scale(1.3)" }}
                                    type="checkbox"
                                    id={child.permission_id}
                                    key={child.permission_id}
                                    checked={child.is_read}
                                    onChange={() => {
                                      const updatedList = { ...data };
                                      updatedList.menu[i].sub_menu[j].is_read =
                                        !child.is_read;
                                      setData(updatedList);
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-1">
                                  <input
                                    className={
                                      (child.sub_menu === "Superadmin User" ||
                                        child.sub_menu === "Integrals Menu") &&
                                      outletContext.profile.role_id !== "1"
                                        ? "form-check-input ms-1 bg-secondary"
                                        : "form-check-input ms-1"
                                    }
                                    style={{ transform: "scale(1.3)" }}
                                    type="checkbox"
                                    id={child.permission_id}
                                    key={child.permission_id}
                                    checked={child.is_update}
                                    onChange={() => {
                                      const updatedList = { ...data };
                                      updatedList.menu[i].sub_menu[
                                        j
                                      ].is_update = !child.is_update;
                                      setData(updatedList);
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-1">
                                  <input
                                    className={
                                      (child.sub_menu === "Superadmin User" ||
                                        child.sub_menu === "Integrals Menu") &&
                                      outletContext.profile.role_id !== "1"
                                        ? "form-check-input ms-1 bg-secondary"
                                        : "form-check-input ms-1"
                                    }
                                    style={{ transform: "scale(1.3)" }}
                                    type="checkbox"
                                    id={child.permission_id}
                                    key={child.permission_id}
                                    //   value={parent.value}
                                    checked={child.is_delete}
                                    onChange={() => {
                                      const updatedList = { ...data };
                                      updatedList.menu[i].sub_menu[
                                        j
                                      ].is_delete = !child.is_delete;
                                      setData(updatedList);
                                    }}
                                    disabled
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </Collapsible>
                    </>
                  )}
                </div>
              </>
            ))}
        </div>
      </Modal.Body>
    </>
  );
};

export default ModalDetailRole;
