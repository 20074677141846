import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Addresign.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import { getResignModal, postResign } from "../../../data-api/resign";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import { ReactSelect } from "../../../utils/ReactSelect";
import LoadingEffectForm from "../../template/loadingEffectForm";
registerLocale("en-US", enUs);

function Addresign(props) {
  const [items, setitems] = useState([]);
  const [DataisLoaded, setDataisLoaded] = useState(true);
  const [options, setoptions] = useState("");
  const [placementoptions, setplacementoptions] = useState("");
  const [positionoptions, setpositionoptions] = useState("");
  const [departmentoptions, setdepartmentoptions] = useState("");
  const [NewDate, setNewDate] = useState("");
  const [employee, setemployee] = useState("");
  const [addConfirm, setAddConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getResignModal().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setitems([])
        : setitems(result.data);
      setDataisLoaded(false);
      setIsLoading(false);
    });
  }, []);
  const Addsubmit = async (data) => {
    const json = JSON.stringify({
      nik: employee.value,
      name: employee.label,
      reason: options.value,
      client: placementoptions.value,
      position: positionoptions.value,
      department: departmentoptions.value,
      last_day: moment(NewDate).format("yyyy-MM-DD"),
    });

    postResign(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        const message2 = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.message(message2);
      } else {
        const message2 = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.message(message2);
      }
    });
  };
  function formAddResign(event) {
    event.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      Addsubmit();
    }
  }

  if (isLoading)
    return (
      <>
        <LoadingEffectForm row={6} />
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <div className="fw-semibold custom-text-20">
          {!addConfirm
            ? "Add Resign"
            : addConfirm
            ? "Add Resign Confirmation"
            : null}
        </div>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={(e) => formAddResign(e)}>
          {!addConfirm ? (
            <>
              <div className="">
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Employee
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={employee}
                      setValue={setemployee}
                      data={items.employees?.map((item) => ({
                        value: item.nik,
                        label: item.employee_name,
                      }))}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Employee --"}
                      validationMessage={"Please Select Employee"}
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Placement
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={placementoptions}
                      setValue={setplacementoptions}
                      data={items.placement}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Placement --"}
                      validationMessage={"Please Select Placement"}
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Position
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={positionoptions}
                      setValue={setpositionoptions}
                      data={items.position}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Position --"}
                      validationMessage={"Please Select Position"}
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Department
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={departmentoptions}
                      setValue={setdepartmentoptions}
                      data={items.department}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Department --"}
                      validationMessage={"Please Select Department"}
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Last Day
                  </Form.Label>
                  <div className="w-75">
                    <Form.Group className="d-flex justify-content-between">
                      <div className="w-100">
                        <ReactDatePicker
                          locale={enUs}
                          showMonthDropdown
                          showYearDropdown
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          className="w-100 h-25 form-control"
                          wrapperClassName="w-100 form-control z-3"
                          todayButton="Today"
                          dateFormat="dd/MM/yyyy"
                          onChange={(e) => setNewDate(e)}
                          placeholderText="DD/MM/YYYY"
                          onInput={(e) => e.target.setCustomValidity("")}
                          selected={NewDate ? NewDate : ""}
                          required
                          isClearable
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Reason
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={options}
                      setValue={setoptions}
                      data={items.resign_reason}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Reason --"}
                      validationMessage={"Please Select Reason"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow btn-sm">
                  ADD
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    NIK
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {employee.value}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Name
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {employee.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Placement
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {placementoptions.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Position
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {positionoptions.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Department
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {departmentoptions.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Last Day
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {moment(NewDate).format("DD-MM-YYYY")}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Reason
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {options.label}
                  </Form.Label>
                </div>

                <div className="d-flex justify-content-between mb-3 mt-3">
                  <button
                    className="col-5 me-2 btn btn-danger btn-sm"
                    style={{ width: "100px" }}
                    onClick={(e) => setAddConfirm(false)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-danger col-2  btn btn-danger btn-sm"
                    style={{ width: "100px" }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}
export default Addresign;
