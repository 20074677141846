import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

function ModalMessage(props) {
  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleShow();
  }, []);

  function Message() {
    if (props.data.code === 201 || props.data.code === 200) {
      return (
        <>
          <div className="container d-flex flex-column py-2">
            <FontAwesomeIcon
              className="mb-4"
              icon={faCircleCheck}
              size="10x"
              color="green"
            />
            <h4 style={{ textAlign: "center" }}>{props.data.message}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3"
                onClick={() => CloseClick()}
              >
                Close
              </button>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="container d-flex flex-column text-center">
            <FontAwesomeIcon
              className="mb-4"
              icon={faCircleXmark}
              size="10x"
              color="red"
            />
            <h4 style={{ textAlign: "center" }}>{props.data.message}</h4>
            {/* <span>Please try again later</span>
						<span>If still error please create ticket in helpdesk page</span> */}
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3"
                onClick={() => CloseClick()}
              >
                Close
              </button>
            </div>
          </div>
        </>
      );
    }
  }

  const CloseClick = () => {
    handleClose();
    props.click();
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Message />
      </Modal.Body>
    </Modal>
  );
}

export default ModalMessage;
