import React from "react";
import "./Clients.css";
import TableClients from "./TableClients";

function Clients() {
  return (
    <div className="w-100 tabs-content rounded">
      <div>
        <div>
          <TableClients />
        </div>
      </div>
    </div>
  );
}

export default Clients;
