import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getListCost = async (param) => {
  const url = `${process.env.REACT_APP_API_URL}/cost`;
  return axios
    .get(url, {
      params: param,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSearchCost = async (param) => {
  const url = `${process.env.REACT_APP_API_URL}/cost/ctc`;
  return axios
    .get(url, {
      params: param,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListCostVariable = async () => {
  const url = `${process.env.REACT_APP_API_URL}/cost/var`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const importCostCalculate = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/cost/calculate/import`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const saveCostCalculate = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/cost/calculate`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const saveCostVariable = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/cost/var`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTotalVar = async () => {
  const url = `${process.env.REACT_APP_API_URL}/cost/total-var`;
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getCostInsurance = async () => {
  const url = `${process.env.REACT_APP_API_URL}/cost/insurance`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const saveCostInsurance = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/cost/insurance`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
