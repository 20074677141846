import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DashboardItem({ title, onClick, data, className }) {
  return (
    <>
      <div
        className={`mx-auto ${className}`}
        style={{ width: "50%", cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="pt-3 h-50 text-center w-100">
          <div className="d-flex justify-content-center">
            <FontAwesomeIcon
              icon={faUser}
              color="#e2585c"
              className="rounded-circle bg-body-secondary"
              style={{
                padding: "5px",
                backgroundColor: "f5d3d5",
              }}
            />
            <span className="custom-text-16 text-start ms-2 fw-semibold">
              {title}
            </span>
          </div>
        </div>
        <div className={`text-start h-50 pb-3 text-center`}>
          <span className="fw-bold custom-text-22">{data}</span>
        </div>
      </div>
    </>
  );
}

export default DashboardItem;
