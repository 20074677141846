import {
  faDownload,
  faFileImport,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import TableImport from "../table/TableImport";
import { postContractImport, putContractimport } from "../../../data-api/pkwt";
import { FormatDate } from "../../template/format";
import moment from "moment";

function ModalImportPkwt() {
  const [importData, setImportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [importDataResponse, setImportDataResponse] = useState({});
  const [successImport, setSuccessImport] = useState({});
  const [importAction, setImportAction] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);

  const [isFetch, setIsFetch] = useState(false);

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };
  const handleShowConfirm = (action) => {
    if (importData.length === 0) {
      showAlertError(false, "Data cant be empty");
    } else {
      setImportAction(action);
      setShowConfirm(true);
      setImportDataResponse({
        created_by: "Import From React",
        data: importData,
      });
    }
  };

  //upload modal
  const [showUpload, setShowUpload] = useState(false);

  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => setShowUpload(true);

  const onFileChange = (files) => {
    // console.log(files);
  };

  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    setIsLoading(true);
    const newFile = e.target.files[0];
    if (newFile.type === "text/csv") {
      const updatedList = [newFile];
      setFileList(updatedList);
      onFileChange(updatedList);

      if (e.target.files) {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
          var jsonData = [];
          var headers = [];
          var rows = e.target.result.split("\r\n");
          for (var i = 0; i < rows.length - 1; i++) {
            var cells = rows[i].split(",");
            var rowData = {};
            for (var j = 0; j < cells.length; j++) {
              if (i === 0) {
                var headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                var key = headers[j];
                if (j === 0) {
                  cells[j] = cells[j].padStart(7, "0");
                } else if (j === 1 || j === 2) {
                  cells[j] = moment(cells[j]).format("YYYY-MM-DD");
                }

                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i !== 0) {
              jsonData.push(rowData);
            }
          }
          // const data = e.target.result;
          // const workbook = xlsx.read(data, { type: "csv" });
          // const sheetName = workbook.SheetNames[0];
          // const worksheet = workbook.Sheets[sheetName];
          // const json = xlsx.utils.sheet_to_csv(worksheet, { header: 1 });
          // var jsonParse = JSON.parse(convertToJson(json))
          // Object.keys(jsonParse).forEach((key) => {
          //   jsonParse[key].nik = jsonParse[key].nik.padStart(7, "0");
          // });
          // setImportData(jsonParse);
          setImportData(jsonData);
        };
        // reader.readAsArrayBuffer(e.target.files[0]);
      }
    } else if (newFile.size / 1048576 > 5) {
      showAlertErrorUploadCSV(false, "Please upload with size under 5 MB");
    } else {
      showAlertErrorUploadCSV(false, "Please upload CSV format");
    }
    setIsLoading(false);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  const [showAlertUploadCSV, setShowAlertUploadCSV] = useState(false);
  const [AlertMessageUploadCSV, setAlertMessageUploadCSV] = useState("");

  function showAlertErrorUploadCSV(success, message) {
    setShowAlertUploadCSV(true);
    setAlertMessageUploadCSV(message);
  }

  const fileRemove = (file) => {
    setIsLoading(true);
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
    setImportData([]);
    setIsLoading(false);
  };

  function importCreatePkwt() {
    setShowAlert(false);
    setIsLoading(true);
    setIsFetch(true);
    if (importAction === "create") {
      postContractImport(importDataResponse).then((res) => {
        if (res.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (res.meta !== undefined) {
          showAlertError(true, "Success import (Create) Internal Contract");
          setSuccessImport(res);
          setIsLoading(false);
          setIsFetch(false);
          setShowConfirm(false);
        } else {
          showAlertError(false, res.message);
          handleCloseConfirm();
          setIsFetch(false);
          setIsLoading(false);
        }
      });
    } else if (importAction === "update") {
      // setIsLoading(true);
      putContractimport(importDataResponse).then((res) => {
        if (res.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (res.meta.code === 200) {
          showAlertError(true, "Success import (Update) Internal Contract");
          setSuccessImport(res);
          setIsLoading(false);
          setShowConfirm(false);
          setIsFetch(false);
        } else {
          setIsFetch(false);
          setIsLoading(false);
          showAlertError(false, res.meta.message);
          handleCloseConfirm();
        }
      });
    }
  }

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const columnsImport = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Start_Date",
        accessor: "start_date",
        Cell: (props) => FormatDate(props.value),
      },
      {
        Header: "End_Date",
        accessor: "end_date",
        Cell: (props) => (props.values !== "" ? FormatDate(props.value) : "-"),
      },
      {
        Header: "No_PKWT",
        accessor: "no_pkwt",
      },
      {
        Header: "Type",
        accessor: "type",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  const data = importData.length === 0 ? EmptyData : importData;
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: columnsImport,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  if (isLoading)
    return (
      <div className="w-100">
        <div className="shine-photo shine"></div>
      </div>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Import Internal Contract</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        {Object.keys(successImport).length === 0 ? (
          <>
            <div className="d-flex flex-row-reverse bd-highlight mt-3 mb-4">
              <button
                className="btn btn-danger btn-sm ms-3 col-2 fa"
                onClick={handleShowUpload}
              >
                <FontAwesomeIcon
                  icon={faFileImport}
                  style={{ marginRight: "10px" }}
                />
                Upload Files
              </button>
              <a
                className="btn btn-danger btn-sm ms-3 col-2 fa"
                href="https://integrals-public.s3.ap-southeast-1.amazonaws.com/import_csv_example/import_pkwt_example.csv"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginRight: "10px" }}
                />
                CSV Example
              </a>
            </div>
            {isLoading ? (
              <>
                <div className="w-100">
                  <div className="shine-photo shine"></div>
                </div>
              </>
            ) : (
              <>
                <div className="overflow-x-scroll">
                  <table className="table table table-sm custom-table align-middle">
                    <thead className="text-danger align-middle nowrap">
                      {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                          {headerGroup.headers.map((column, index) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              scope="col"
                              key={index}
                              className="text-danger"
                            >
                              {column.render("Header")}
                              <span className="ms-2">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FontAwesomeIcon icon={faSortDown} />
                                  ) : (
                                    <FontAwesomeIcon icon={faSortUp} />
                                  )
                                ) : (
                                  <FontAwesomeIcon icon={faSort} />
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {importData.length === 0 ? (
                      <tbody
                        style={{
                          backgroundColor: "#ececec",
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <td colSpan={columnsImport.length}>
                            No data available in table
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={index}>
                              {row.cells.map((cell, index) => {
                                return (
                                  <td {...cell.getCellProps()} key={index}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="pagination d-flex justify-content-center">
                  <button
                    className="ms-1 me-3 btn btn-outline-danger  btn-border"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {"< Prev"}
                  </button>{" "}
                  {pageOptions
                    .slice(
                      pageIndex < 3
                        ? 0
                        : pageIndex >= pageCount - 3
                        ? pageCount - 5
                        : pageIndex - 2,
                      pageIndex < 3
                        ? 5
                        : pageIndex >= pageCount - 3
                        ? pageCount
                        : pageIndex + 3
                    )
                    .map((index) => {
                      if (index === pageIndex) {
                        return (
                          <button
                            className="ms-1  btn btn-danger"
                            onClick={() => gotoPage(index)}
                            disabled={!canNextPage}
                            key={index}
                          >
                            {index + 1}
                          </button>
                        );
                      } else {
                        return (
                          <button
                            className="ms-1  btn btn-outline-danger  btn-border"
                            onClick={() => gotoPage(index)}
                            disabled={!canNextPage && index === pageCount}
                            key={index}
                          >
                            {index + 1}
                          </button>
                        );
                      }
                    })}
                  <button
                    className="ms-3  btn btn-outline-danger  btn-border"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {"Next >"}
                  </button>{" "}
                </div>
                <div className="d-flex justify-content-end">
                  Page{" "}
                  <strong className="ms-1">
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </div>
              </>
            )}
            <div className="d-flex flex-row-reverse bd-highlight mt-3">
              <button
                className="btn btn-danger btn-sm ms-3 col-2 fa"
                onClick={() => handleShowConfirm("create")}
              >
                Add
              </button>
              <button
                className="btn btn-danger btn-sm ms-3 col-2 fa"
                onClick={() => handleShowConfirm("update")}
              >
                Update
              </button>
            </div>
          </>
        ) : (
          <>
            <TableImport data={successImport} />
          </>
        )}
      </Modal.Body>

      <Modal show={showUpload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlertUploadCSV ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessageUploadCSV}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlertUploadCSV(false)}
              ></button>
            </div>
          ) : null}
          <div className="mb-4">
            <div className="custom-file-upload btn btn-outline-danger">
              <label>Browse</label>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
            <button
              className="btn btn-danger float-end col-4 mb-4"
              onClick={handleCloseUpload}
            >
              Submit
            </button>
          </div>
          {fileList.length === 0 ? (
            <div
              ref={wrapperRef}
              className="drop-file-input mt-4"
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div className="drop-file-input__label">
                <img alt="" />
                <p>Drag & Drop your files here</p>
              </div>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
          ) : null}
          {fileList.length > 0 ? (
            <div className="drop-file-preview">
              {fileList.map((item, index) => (
                <div key={index} className="drop-file-preview__item">
                  <div className="drop-file-preview__item__info">
                    <p>{item.name}</p>
                    <p>{item.size}B</p>
                  </div>
                  <span
                    className="drop-file-preview__item__del"
                    onClick={() => fileRemove(item)}
                  >
                    x
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirm}
        onHide={handleCloseConfirm}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to import there records?.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={importCreatePkwt}
            disabled={isFetch}
          >
            {" "}
            {isFetch ? (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            ) : (
              "Import PKWT"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalImportPkwt;
