import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  usePagination,
  useTable,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faCloudArrowDown,
  faUpload,
  faTimeline,
  faSortDown,
  faSortUp,
  faSort,
  faAnglesLeft,
  faAnglesRight,
  faXmark,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import ModalNew from "./modal/ModalNew";
import ModalEdit from "./modal/ModalEdit";
import ModalExport from "./modal/ModalExport";
import ModalHistory from "./modal/ModalHistory";
import ModalNewUpload from "./modal/ModalNewUpload";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import { FormatDate } from "../template/format";
import { getTimesheet } from "../../data-api/timesheet";
import DynamicTableModal from "../../utils/DynamicTableModal";
import { STORAGE_KEY_TIMESHEET } from "../../utils/util";
import { ExcelDownload } from "../../utils/ExcelDownload";

function TableTimesheet(props) {
  // get context for access permission
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Timesheet"
  ).sub_menu;
  const accessMenu = access.find((data) => data.sub_menu === "Timesheet");

  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [showFilterColumn, setshowFilterColumn] = useState(false);
  const [resetColumn, setResetColumn] = useState([]);

  const [columns, setcolumns] = useState([
    {
      Header: "Id",
      accessor: "timesheet_id", // accessor is the "key" in the data
      id: "timesheet_id",
      active: true,
    },
    {
      Header: "NIK",
      accessor: "nik", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Name",
      accessor: "name", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Placement",
      accessor: "placement", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Cut Off",
      accessor: "cut_off", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Category",
      accessor: "category", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Periode",
      accessor: "periode", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Submitted Date",
      accessor: "submitted_date", // accessor is the "key" in the data
      active: true,
      Cell: (props) => FormatDate(props.value),
    },
    {
      Header: "Work Days",
      accessor: "work_days", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Sick Leave",
      accessor: "sick_leave", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Normal Leave",
      accessor: "normal_leave", // accessor is the "key" in the data
      active: true,
    },
    {
      Header: "Timesheet",
      accessor: "timesheet_file", // accessor is the "key" in the data
      active: true,
      Cell: ({ row }) => (
        <div style={{ "text-align": "center" }}>
          <input
            type="checkbox"
            defaultChecked={row.values.timesheet_file === "Y" ? true : false}
            disabled
          />
        </div>
      ),
    },
    {
      Header: "BAST",
      accessor: "bast_file", // accessor is the "key" in the data
      active: true,
      Cell: ({ row }) => (
        <div style={{ "text-align": "center" }}>
          <input
            type="checkbox"
            defaultChecked={row.values.bast_file === "Y" ? true : false}
            disabled
          />
        </div>
      ),
    },
    {
      Header: "Overtime",
      accessor: "overtime_file", // accessor is the "key" in the data
      active: true,
      Cell: ({ row }) => (
        <div style={{ "text-align": "center" }}>
          <input
            type="checkbox"
            defaultChecked={row.values.overtime_file === "Y" ? true : false}
            disabled
          />
        </div>
      ),
    },
    {
      Header: "Leave",
      accessor: "leave_file", // accessor is the "key" in the data
      active: true,
      Cell: ({ row }) => (
        <div style={{ "text-align": "center" }}>
          <input
            type="checkbox"
            defaultChecked={row.values.leave_file === "Y" ? true : false}
            disabled
          />
        </div>
      ),
    },
  ]);

  const handleShowNew = async (title) => {
    await setModalTitle("");
    const data = {
      title: title,
    };
    setModalData(data);
    setModalTitle(title);
  };

  const handleShowEdit = async (title, id) => {
    await setModalTitle("");
    const data = {
      title: title,
      id: id,
    };
    setModalData(data);
    setModalTitle(title);
  };

  const handleShowUpload = async (title, nik, name) => {
    await setModalTitle("");
    const data = {
      title: title,
      nik: nik,
      name: name,
    };
    setModalData(data);
    setModalTitle(title);
  };

  const handleShowHistory = async (title, nik, name) => {
    await setModalTitle("");
    const data = {
      title: title,
      nik: nik,
      name: name,
    };
    setModalData(data);
    setModalTitle(title);
  };

  const handleShowExport = async (title) => {
    await setModalTitle("");
    const data = {
      title: title,
    };
    setModalData(data);
    setModalTitle(title);
  };

  const loadDataFromStorage = () => {
    const serializedData = localStorage.getItem(STORAGE_KEY_TIMESHEET);
    if (serializedData !== null) {
      let data = JSON.parse(serializedData);
      setcolumns(data);
    }
  };

  // func export to excel xls
  function handleDownloadExcel() {
    // const columns = [
    //   "NIK",
    //   "Name",
    //   "Placement",
    //   "Cut Off",
    //   "Category",
    //   "periode",
    //   "Work Days",
    //   "Sick Leave",
    //   "Normal Leave",
    //   "Timesheet",
    //   "BAST",
    //   "Overtime",
    //   "Leave",
    // ];
    // const excelData = data;
    // excelData.forEach((v) => {
    //   delete v.timesheet_id;
    // });
    // const dataSet = [];
    // const dataHeader = [];
    // columns.forEach((item) => {
    //   dataHeader.push({
    //     value: item,
    //     type: String,
    //     fontWeight: "bold",
    //   });
    // });
    // dataSet.push(dataHeader);

    // // FILTERED ROWS
    // excelData.forEach((value) => {
    //   const dataRow = [];

    //   Object.values(value).forEach((item) => {
    //     dataRow.push({
    //       value: item,
    //       type: String,
    //     });
    //   });
    //   dataSet.push(dataRow);
    // });

    // writeXlsxFile(dataSet, {
    //   fileName: "Timesheet",
    // });

    ExcelDownload(
      columns,
      ["timesheet_id"],
      data,
      ["timesheet_id"],
      "Timesheet",
      []
    );
  }
  const [isLoading, setIsLoading] = useState(true);
  const [timesheet, setTimesheet] = useState([]);

  useEffect(() => {
    setResetColumn(columns);
    loadDataFromStorage();
    refreshDataTimesheet();
  }, []);

  const refreshDataTimesheet = async () => {
    setIsLoading(true);
    getTimesheet().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code !== 200) {
        props.setMsg(result.meta.message);
        setIsLoading(false);
        return;
      }
      setTimesheet(result);
      setIsLoading(false);
    });
  };

  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: "edit-timesheet",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEdit(
                  "Edit Timesheet/BAST",
                  row.values.timesheet_id,
                  row.values.name
                )
              }
              disabled={!accessMenu.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "upload-file",
        Header: "Upload",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowUpload("Upload File", row.values.nik, row.values.name)
              }
              disabled={!accessMenu.is_update}
            >
              <FontAwesomeIcon
                icon={faUpload}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "history",
        Header: "History",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowHistory("History", row.values.nik, row.values.name)
              }
            >
              <FontAwesomeIcon
                icon={faTimeline}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  //initiate empty employee
  const EmptyData = useMemo(() => [], []);

  //catching the data. Purpose is to only have 1 instance.
  const data =
    timesheet.data === null || timesheet.data === undefined
      ? EmptyData
      : timesheet.data;
  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["timesheet_id"].concat(
          columns.filter((item) => item.active === false) === null
            ? []
            : columns
                .filter((item) => item.active === false)
                .map((item) => item.accessor)
        ),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="row custom-text-14 w-100 ms-1 mb-3">
        <div className="col-4"></div>
        <div className="col-8 d-flex justify-content-end">
          <button
            className="btn btn-danger btn-sm ms-3 col-3 custom-text-14"
            onClick={() => handleShowExport("View Timesheet")}
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ marginRight: "10px" }}
            />
            Export
          </button>
        </div>
      </div>
      <div className="row custom-text-14 w-100 ms-1">
        <div className="col-4 text-start text-danger fw-semibold d-flex">
          <div className={"mt-1"}>{rows.length} records</div>
          <div className="ms-4">
            <Form.Select
              className="form-select-sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </div>
          <button
            className="btn btn-outline-danger btn-sm ms-3 fa nowrap text-center"
            onClick={() => setshowFilterColumn(true)}
          >
            <FontAwesomeIcon
              icon={faGear}
              style={{ marginRight: "10px" }}
              className="fa-cloud-arrow-down m-auto"
            />
          </button>
        </div>
        <div className="col-8 bd-highlight d-flex justify-content-end">
          <form
            id="search-reacttablecustom"
            className="input-group w-50 flex border border-danger rounded"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search ..."
              value={searchVal}
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              onChange={(e) => {
                setSearchVal(e.target.value);
                if (e.target.value === "") {
                  searchItem();
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setSearchVal(e.target.value);
                  searchItem();
                }
              }}
            />
            {searchVal !== "" ? (
              <button
                className="btn btn-outline-light text-danger btn-sm"
                onClick={() => {
                  setSearchVal("");
                  setGlobalFilter("");
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ color: "#848484" }}
                  size="sm"
                />
              </button>
            ) : null}
            <button
              className="btn btn-danger btn-sm"
              type="button"
              id="button-addon2"
              onClick={() => searchItem()}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </form>
          <button
            className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
            onClick={handleDownloadExcel}
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ marginRight: "10px" }}
              className="fa-cloud-arrow-down"
            />
            Download
          </button>
          <button
            className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
            onClick={() => handleShowNew("Add New Timesheet/BAST")}
            disabled={!accessMenu.is_create}
          >
            <FontAwesomeIcon
              icon={faSquarePlus}
              style={{ marginRight: "10px" }}
            />
            New
          </button>
        </div>
      </div>
      <div className="overflow-x-scroll mt-3">
        <table className="table table-sm custom-table align-middle">
          <thead className="text-danger align-middle nowrap">
            {headerGroups.map((headerGroup, key) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    key={key}
                    className="text-danger"
                  >
                    {column.render("Header")}
                    <span className="ms-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isLoading ? (
            <>
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
                className="custom-text-14"
              >
                <td colSpan={headers.length}>
                  <div className="w-100">
                    <div className="shine-photo shine"></div>
                  </div>
                </td>
              </tbody>
            </>
          ) : rows.length === 0 ? (
            <tbody style={{ backgroundColor: "#ececec", textAlign: "center" }}>
              <td colSpan={headers.length}>No data available in table</td>
            </tbody>
          ) : (
            <tbody
              {...getTableBodyProps()}
              className="custom-text-14 text-start nowrap"
            >
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={key}>
                    {row.cells.map((cell, key) => {
                      return (
                        <td {...cell.getCellProps()} key={key}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination d-flex justify-content-center">
        <button
          className="ms-1 me-2 btn btn-danger"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            className="mt-1 me-1"
            icon={faAnglesLeft}
            style={{ color: "#fff" }}
          />
          First
        </button>{" "}
        <button
          className="ms-1 me-3 btn btn-outline-danger  btn-border"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"< Prev"}
        </button>{" "}
        {pageIndex > 4 ? (
          <span className="align-text-bottom bg-danger me-4">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        {pageOptions
          .slice(
            pageIndex < 5
              ? 0
              : pageIndex >= pageCount - 4
              ? pageCount - 9
              : pageIndex - 4,
            pageIndex < 5
              ? 9
              : pageIndex >= pageCount - 4
              ? pageCount
              : pageIndex + 5
          )
          .map((index, i) => {
            if (index === pageIndex) {
              return (
                <button
                  className="ms-1  btn btn-danger"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            } else {
              return (
                <button
                  className="ms-1  btn btn-outline-danger  btn-border"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage && index === pageCount}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            }
          })}
        {pageIndex + 1 < pageOptions.length - 3 ? (
          <span className="align-text-bottom bg-danger ms-3 me-3">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        <button
          className="ms-3  btn btn-outline-danger  btn-border"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {"Next >"}
        </button>{" "}
        <button
          className="ms-2 me-1 btn btn-danger fa"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
          <FontAwesomeIcon
            className="mt-1 ms-1"
            icon={faAnglesRight}
            style={{ color: "#fff", float: "right" }}
          />
        </button>{" "}
      </div>
      <div className="d-flex justify-content-end">
        Page{" "}
        <strong className="ms-1">
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </div>

      {modalTitle === "Add New Timesheet/BAST" ? (
        <ModalNew data={modalData} click={refreshDataTimesheet} />
      ) : modalTitle === "Edit Timesheet/BAST" ? (
        <ModalEdit data={modalData} click={refreshDataTimesheet} />
      ) : modalTitle === "View Timesheet" ? (
        <ModalExport data={modalData} />
      ) : modalTitle === "Upload File" ? (
        <ModalNewUpload data={modalData} click={refreshDataTimesheet} />
      ) : modalTitle === "History" ? (
        <ModalHistory data={modalData} accessMenu={accessMenu} />
      ) : null}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="sm"
        show={showFilterColumn}
        onHide={() => setshowFilterColumn(false)}
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Setting Table</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            <DynamicTableModal
              columnsHeader={columns}
              setColumnHeader={setcolumns}
              setCloseModal={setshowFilterColumn}
              resetColumn={resetColumn}
              storageKey={STORAGE_KEY_TIMESHEET}
              hideColumn={["timesheet_id"]}
            />
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default TableTimesheet;
