import TableImportFailed from "./TableImportFailed";
import TableImportSuccess from "./TableImportSuccess";

function TableImport(props) {
  return (
    <>
      <h3>Success Import SPK</h3>
      <TableImportSuccess data={props.data.data.success_import} />
      <h3>Failed Import SPK</h3>
      <TableImportFailed data={props.data.data.failed_import} />
    </>
  );
}

export default TableImport;
