import { useState } from "react";
import TableTimesheet from "./TableTimesheet";
import { Alert } from "react-bootstrap";

function Timesheet() {
  const [Msg, setMsg] = useState("");

  return (
    <div className="w-100 tabs-content rounded">
      <div className="">
        {Msg === "" ? null : (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <strong>{Msg}</strong>
          </Alert>
        )}
        <TableTimesheet setMsg={setMsg} />
      </div>
    </div>
  );
}

export default Timesheet;
