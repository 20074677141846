import "../dashboard/dashboard.css";

const LoadingEffectForm = (props) => {
  const row = props?.row === undefined ? 3 : props?.row;
  const rows = [];
  for (let i = 0; i < row; i++) {
    rows.push(
      <>
        <div className="d-flex" style={{ height: "50px" }}>
          <div className="shine-div shine w-25 me-5 rounded"></div>
          <div className="shine-div shine w-75 rounded"></div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="w-100">
        <div className="row">
          {rows}
          <div className="d-flex flex-row-reverse" style={{ height: "50px" }}>
            <div className="shine-div shine w-25 rounded"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingEffectForm;
