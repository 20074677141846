import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Clients.css";
import { Button, Modal, Form } from "react-bootstrap";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAddClient from "./ModalAddClient";
import ModalEditClient from "./ModalEditClient";
import ModalDeleteClient from "./ModalDeleteClient";
import ModalMessage from "../template/ModalMessage";
import { getClient } from "../../data-api/master-data-clients";
import { useOutletContext } from "react-router-dom";
import ReactTableCustomHooks from "../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";

function TableClients() {
  useEffect(() => {
    // setEmployee(employeeData);
    //fetch all employee data
    getClient().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setClients([])
        : setClients(result.data);
      setIsLoading(false);
    });
  }, []);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAddClient = (data) => {
    setShowAdd(false);
    setModalMessage(data);
    setShowModalMessage(true);
  };

  const [modalMessage, setModalMessage] = useState({});

  const [showModalMessage, setShowModalMessage] = useState(false);
  function handleShowAddClient() {
    setShowAdd(true);
  }

  const [check, setCheck] = useState(false);

  const [dataEdit, setDataEdit] = useState({});

  //initiate store nik + title for modal Profile & Contract
  const [modalFilter, setModalFilter] = useState(false);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // initiate store filter
  const [relation, setRelation] = useState("");
  const [type, setType] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [clientFilter2, setClientFilter2] = useState("");

  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Master Data Client"
  ).sub_menu;

  const mstClientAccess = access.find(
    (data) => data.sub_menu === "Master Data Client"
  );

  // handling modal Edit
  const [showEditClient, setShowEditClient] = useState(false);
  const handleCloseEditClient = (data) => {
    setShowEditClient(false);
    setModalMessage(data);
    setShowModalMessage(true);
  };

  function handleShowEditClient(data) {
    setShowEditClient(true);
    setDataEdit(data);
  }

  //handling modal delete
  const [showDeleteClient, setShowDeleteClient] = useState(false);
  const handleCloseDeleteClient = (data) => {
    if (data === "") {
      setShowDeleteClient(false);
    } else {
      setShowDeleteClient(false);
      setModalMessage(data);
      setShowModalMessage(true);
    }
  };

  function handleShowDeleteClient(data) {
    setShowDeleteClient(true);
    setDataEdit(data);
  }

  const Refresh = () => {
    // refresh page..
    window.location.reload();
  };

  //func onclick button filter
  const formFilterHandler = (event) => {
    event.preventDefault();
    setClientFilter(relation !== "" ? relation.label : "");
    setClientFilter2(type !== "" ? type.label : "");
    fetchFilterClients(
      relation !== "" ? relation.value.toString() : "",
      type !== "" ? type.value.toString() : ""
    );

    setCheck(false);
    setModalFilter(false);
  };

  function removeFilter(filter) {
    if (filter === "relation") {
      fetchFilterClients("", type === "" ? "" : type.value.toString());
      setRelation("");
      setClientFilter("");
    } else if (filter === "type") {
      fetchFilterClients(relation === "" ? "" : relation.value.toString(), "");
      setType("");
      setClientFilter2("");
    }
  }

  //func fetch filter employee
  function fetchFilterClients(relation, type) {
    setIsLoading(true);
    let params = {
      relation: relation,
      type: type,
    };
    getClient(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setClients([])
        : setClients(result.data);
      setIsLoading(false);
    });
    // }
  }

  // add custom new cell for button profile & contract
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowEditClient(row.original)}
              disabled={!mstClientAccess.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "Delete",
        Header: "Delete",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowDeleteClient(row.original)}
              disabled={!mstClientAccess.is_delete}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  //initiate column name for employee table
  const columnsIdle = useMemo(
    () => [
      {
        Header: "No",
        accessor: "id", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Client",
        accessor: "client_name",
      },
      {
        Header: "Official name",
        accessor: "official_name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Relation",
        accessor: "relation",
      },
      {
        Header: "Type",
        accessor: "proc_type",
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      // {
      //   Header: "Start Periode",
      //   accessor: "start_periode",
      //   Cell: (props) =>
      //     props.value === "" ? "" : Moment(props.value).format("DD-MMM-YYYY"),
      // },
      // {
      //   Header: "End Periode",
      //   accessor: "end_periode",
      //   Cell: (props) =>
      //     props.value === "" ? "" : Moment(props.value).format("DD-MMM-YYYY"),
      // },
    ],
    []
  );

  //initiate empty employee
  const EmptyData = useMemo(() => [], []);

  //intiate data to table
  const data = clients === null ? EmptyData : clients;
  function handleDownloadExcel() {
    const columnsExcel = [
      "No",
      "Client",
      "Official Name",
      "Address",
      "Relation",
      // "Start Periode",
      // "End Periode",
      "Type",
      "Project Name",
    ]; //columnsIdle.map( item => item.Header)
    data.forEach((item) => {
      delete item.start_periode;
      delete item.end_periode;
    });

    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });
    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Data_Clients",
    });
  }

  function handleFilter() {
    setModalFilter(true);
  }

  return (
    <>
      {/* show state filter */}
      {clientFilter !== "" || clientFilter2 !== "" ? (
        <>
          <div className="mx-3 d-flex flex-row bd-highlight">
            {clientFilter !== "" ? (
              <div className="p-2 bd-highlight card me-2 d-flex flex-row">
                Relation : {clientFilter}
                <button
                  type="button"
                  className="btn btn-danger col-2 ms-2"
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "100px",
                  }}
                  onClick={(e) => removeFilter("relation")}
                >
                  <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
                </button>
              </div>
            ) : null}
            {clientFilter2 !== "" ? (
              <div className="p-2 bd-highlight card me-2 d-flex flex-row">
                Type : {clientFilter2}
                <button
                  type="button"
                  className="btn btn-danger col-2 ms-2"
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "100px",
                  }}
                  onClick={(e) => removeFilter("type")}
                >
                  <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : null}

      <div className="row custom-text-14 w-100 ms-1 mb-3">
        <div className="col-4"></div>
        <div className="col-8 d-flex justify-content-end">
          <button
            className="btn btn-danger btn-sm ms-3 col-3 custom-text-14"
            onClick={() => handleShowAddClient()}
            disabled={!mstClientAccess.is_create}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ marginRight: "10px" }}
            />
            Add
          </button>
        </div>
      </div>

      <ReactTableCustomHooks
        columns={columnsIdle}
        data={clients}
        handleDownloadExcel={handleDownloadExcel}
        handleFilter={handleFilter}
        tableHooks={tableHooks}
        hiddenColumns={["id"]}
        paging={[10, 20, 50, 100]}
        isLoading={isLoading}
      />

      {check === false ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          show={modalFilter}
          size="sm"
          onHide={() => setModalFilter(false)}
          className="bg-yellow"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="container">
            <Modal.Body>
              <form onSubmit={formFilterHandler}>
                <div className="mb-4 row-filter">
                  <Form.Group className="mb-3 fw-semibold col-5 ">
                    <Form.Label>Relation :</Form.Label>
                    <Select
                      className="w-100 fw-normal custom-text-14"
                      options={[
                        { label: "IDStar", value: 1 },
                        { label: "Drife", value: 2 },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable={true}
                      placeholder={"-- Select Relation --"}
                      defaultValue={{
                        label: clientFilter,
                      }}
                      onChange={(e) =>
                        e === null ? setRelation("") : setRelation(e)
                      }
                    ></Select>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-start mb-4 row-filter">
                  <Form.Group className="mb-3 fw-semibold col-5 ">
                    <Form.Label>Type :</Form.Label>
                    <Select
                      className="w-100 fw-normal custom-text-14"
                      options={[
                        { label: "Project", value: 1 },
                        { label: "Resource", value: 2 },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable={true}
                      placeholder={"-- Select Procurement --"}
                      defaultValue={{ label: clientFilter2 }}
                      onChange={(e) => (e === null ? setType("") : setType(e))}
                    ></Select>
                  </Form.Group>
                </div>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow"
                    // onClick={formFilterHandler}
                  >
                    FILTER
                  </button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      ) : null}

      {showAdd === true ? (
        <Modal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          dialogClassName="modalAdd"
          contentClassName="modal-height"
        >
          <ModalAddClient
            handleClose={handleCloseAddClient}
            showAdd={showAdd}
          />
        </Modal>
      ) : null}

      {showEditClient === true ? (
        <Modal
          show={showEditClient}
          onHide={() => setShowEditClient(false)}
          dialogClassName="modalAdd"
          contentClassName="modal-height"
        >
          <ModalEditClient
            data={dataEdit}
            handleClose={handleCloseEditClient}
          />
        </Modal>
      ) : null}

      {showDeleteClient === true ? (
        <Modal
          show={showDeleteClient}
          onHide={() => setShowDeleteClient(false)}
          dialogClassName="modalDelete"
          contentClassName="modal-height-del"
        >
          <ModalDeleteClient
            data={dataEdit}
            handleClose={handleCloseDeleteClient}
          />
        </Modal>
      ) : null}

      {showModalMessage === true ? (
        <ModalMessage data={modalMessage} click={Refresh} />
      ) : null}
    </>
  );
}

export default TableClients;
