import { useState } from "react";
import { Datasets } from "../Datasets";
import Chart from "react-apexcharts";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookies from "universal-cookie";
import DashboardItem from "./components/DashboardItem";
import DashboardItemCarousel from "./components/DashboardItemCarousel";
import DashboardChart from "./components/DashboardCharts";

function DashboardHC(props) {
  const cookies = new Cookies();

  const filterResign = [
    { key: "1", value: "Resign by Month 2021 - IDStar" },
    { key: "2", value: "Resign by Month 2022 - IDStar" },
    { key: "3", value: "Resign by Month 2023 - IDStar" },
    { key: "4", value: "Resign by Month 2021 - MTK" },
    { key: "5", value: "Resign by Month 2022 - MTK" },
    { key: "6", value: "Resign by Month 2023 - MTK" },
    { key: "7", value: "Resign by Month 2021 - Drife" },
    { key: "8", value: "Resign by Month 2022 - Drife" },
    { key: "9", value: "Resign by Month 2023 - Drife" },
  ];

  const turnOverRate = props.charts.find(
    (item) => item.options.chart.id === "Turn Over Rate"
  );

  const [resignByMonth, setResignByMonth] = useState(
    props.charts.find((item) =>
      item.options.chart.id.match(/Resign by Month.*/)
    )
  );
  const talentMovement = props.charts.find(
    (item) => item.options.chart.id === "Talent Movement"
  );

  const lengthOfWork = props.charts.find(
    (item) => item.options.chart.id === "Length Of Work"
  );

  const employeeStatus = props.charts.find(
    (item) => item.options.chart.id === "Employee by Status"
  );

  const employeeDepartment = props.charts.find(
    (item) => item.options.chart.id === "Employee by Department"
  );

  function fetchChartResign(value) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/charts/resign?filter=${value + 1}`,
        {
          headers: {
            Authorization: `Bearer ${cookies.get("session")}`,
            XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
          },
        }
      )
      .then((res) => {
        var item = res.data.data.charts[0];

        var state = {
          size: 50,
          chart_detail: item.chart_detail,
          chart_type: "line",
          options: {
            chart: {
              id: item.chart_name,
              type: item.chart_type.type,
              stacked: item.chart_type.is_stacked,
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  props.detailChart(item.chart_name, item.chart_detail, config);
                },
              },
            },
            plotOptions: {
              bar: {
                horizontal: item.chart_type.is_horizontal,
                borderRadius: 7,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
              },
            },
            xaxis: {
              categories: Object.keys(item.labels).map(
                (key) => item.labels[key].label
              ),
            },
            colors: ["#d93333", "#f9c332", "#aaaaaa"],
          },
          series: Object.keys(item.datasets).map(
            (key) =>
              new Datasets(
                item.datasets[key].series_name,
                Object.keys(item.datasets[key].data).map(
                  (data) => item.datasets[key].data[data].value
                )
              )
          ),
        };
        setResignByMonth(state);
        // let newArr = [...charts]; // copying the old datas array
        // let index = newArr.map(
        //   (item) => item.options.chart.id.match(/Resign by Month.*/) !== null
        // );
        // newArr[index.indexOf(true)] = state; // replace e.target.value with whatever you want to change it to
        // setCharts(newArr);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  return (
    <>
      <div className="row w-100 my-3">
        <div className="flex col-sm-4">
          {Object.keys(talentMovement).length !== 0 ? (
            <>
              <div className="align-items-start d-flex flex-row h-50">
                <DashboardItem
                  className={"me-3 card h-75"}
                  title={talentMovement.options.xaxis.categories[0]}
                  onClick={() =>
                    props.detailChart(
                      talentMovement.options.chart.id,
                      talentMovement.chart_detail,
                      { dataPointIndex: 0, seriesIndex: 0 }
                    )
                  }
                  data={talentMovement.series[0].data[0]}
                />
                <DashboardItemCarousel
                  id={"Resign"}
                  data={[
                    {
                      title: talentMovement.options.xaxis.categories[2],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 2, seriesIndex: 2 }
                        ),
                      data: talentMovement.series[0].data[2],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[4],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 4, seriesIndex: 4 }
                        ),
                      data: talentMovement.series[0].data[4],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[5],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 5, seriesIndex: 5 }
                        ),
                      data: talentMovement.series[0].data[5],
                    },
                  ]}
                />
              </div>
              <div className="align-items-end d-flex flex-row h-50">
                <DashboardItem
                  className={"me-3 card h-75"}
                  title={talentMovement.options.xaxis.categories[1]}
                  onClick={() =>
                    props.detailChart(
                      talentMovement.options.chart.id,
                      talentMovement.chart_detail,
                      { dataPointIndex: 1, seriesIndex: 1 }
                    )
                  }
                  data={talentMovement.series[0].data[1]}
                />
                <DashboardItemCarousel
                  id={"TotalPS"}
                  data={[
                    {
                      title: talentMovement.options.xaxis.categories[3],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 3, seriesIndex: 3 }
                        ),
                      data: talentMovement.series[0].data[3],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[6],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 6, seriesIndex: 6 }
                        ),
                      data: talentMovement.series[0].data[6],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[7],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 7, seriesIndex: 7 }
                        ),
                      data: talentMovement.series[0].data[7],
                    },
                  ]}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex col-sm-8">
          {Object.keys(resignByMonth).length !== 0 ? (
            <>
              <div className="card h-100">
                <div className="card-body" style={{ padding: "0" }}>
                  <h5 className="custom-text-18 text-start pt-3 px-4">
                    {resignByMonth.options.chart.id}
                  </h5>
                  <hr />
                  {resignByMonth.options.chart.id.match(/Resign by Month.*/) ? (
                    <>
                      <Form.Group className="fw-semibold ms-4 col-4">
                        <Form.Select
                          className=""
                          onChange={(e) =>
                            fetchChartResign(e.target.selectedIndex)
                          }
                          required
                        >
                          {filterResign.map((resignByMonth) => (
                            <option key={resignByMonth.key}>
                              {resignByMonth.value}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </>
                  ) : null}
                  <Chart
                    options={resignByMonth.options}
                    series={resignByMonth.series}
                    type={resignByMonth.chart_type}
                    width="100%"
                    height="200px"
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="row w-100 my-3">
        <div className="flex col-sm-8">
          <DashboardChart chart={employeeStatus} />
        </div>
        <div className="flex col-sm-4">
          <DashboardChart chart={turnOverRate} />
        </div>
      </div>

      <div className="row w-100 my-3">
        <div className="flex col-sm-8">
          <DashboardChart chart={employeeDepartment} />
        </div>
        <div className="flex col-sm-4">
          <DashboardChart chart={lengthOfWork} />
        </div>
      </div>
    </>
  );
}

export default DashboardHC;
