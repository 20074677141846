import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { getEmailPO, setEmailPO } from "../../../data-api/talent-partnership";

function ModalSetEmailPO(props) {
  const [emailTo, setEmailTo] = useState([]);
  const [emailCc, setEmailCc] = useState([]);

  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    getEmailPO().then((result) => {
      if (result.meta.code === 200) {
        setEmailTo(result.data.to);
        setEmailCc(result.data.cc);
      } else {
        showAlertError(true, result.meta.message);
      }
    });
  }, []);

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");
  function showAlertError(success, message) {
    setShowAlert(true);
    setAlertSuccess(success);
    setAlertMessage(message);
  }

  function saveEmail(event) {
    if (emailTo.length === 0) {
      showAlertError(false, "Form To cant be empty");
      return;
    }

    event.preventDefault();
    const json = [...emailTo, ...emailCc];

    setIsFetch(true);
    setEmailPO(json).then((result) => {
      setIsFetch(false);
      if (result.meta.code === 200) {
        props.alert(true, result.meta.message);
        props.close();
      } else {
        props.alert(false, result.meta.message);
        props.close();
      }
    });
  }

  function addEmailTo(e) {
    if (
      e.key === " " ||
      e.code === "Space" ||
      e.keyCode === 32 ||
      e.code === "Enter"
    ) {
      var email = e.target.value;
      var validRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.toLowerCase().match(validRegex)) {
        setEmailTo((emailTo) => [
          ...emailTo,
          {
            email: email,
            type: "to",
          },
        ]);
        e.target.value = "";
      } else {
        showAlertError(false, email + " invalid email address");
      }
    }
  }

  function removeEmailTo(email) {
    setEmailTo(emailTo.filter((item) => item.email !== email));
  }

  function addEmailCc(e) {
    if (
      e.key === " " ||
      e.code === "Space" ||
      e.keyCode === 32 ||
      e.code === "Enter"
    ) {
      var email = e.target.value;
      var validRegex =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (email.match(validRegex)) {
        setEmailCc((emailCc) => [
          ...emailCc,
          {
            email: email,
            type: "cc",
          },
        ]);
        e.target.value = "";
      } else {
        showAlertError(false, email + " invalid email address");
      }
    }
  }

  function removeEmailCc(email) {
    setEmailCc(emailCc.filter((item) => item.email !== email));
  }

  return (
    <>
      <div className="container custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>Set Notification Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlert ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessage}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
          ) : null}
          <div className="container">
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2 col-1">To</Form.Label>
              <div
                className="d-flex flex-wrap col-11"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "7px",
                }}
              >
                {emailTo.length !== 0
                  ? emailTo.map((row, key) => (
                      <>
                        <div
                          className="card d-flex flex-row p-1 m-1 custom-text-14"
                          key={key}
                        >
                          <div className="me-2 fw-normal">{row.email}</div>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rounded-circle my-auto"
                            style={{
                              padding: "0",
                              margin: "0",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={(e) => removeEmailTo(row.email)}
                          >
                            <p style={{ marginTop: "-3px" }}>x</p>
                          </button>
                        </div>
                      </>
                    ))
                  : null}
                <div className="bd-highlight m-2 flex-fill">
                  <Form.Control
                    style={{ border: "0" }}
                    className="form-control-sm"
                    onKeyPress={(e) => {
                      addEmailTo(e);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2 col-1">CC</Form.Label>
              <div
                className="d-flex flex-wrap col-11"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "7px",
                }}
              >
                {emailCc.length !== 0
                  ? emailCc.map((row, key) => (
                      <>
                        <div
                          className="card d-flex flex-row p-1 m-1 custom-text-14"
                          key={key}
                        >
                          <div className="me-2 fw-normal">{row.email}</div>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rounded-circle my-auto"
                            style={{
                              padding: "0",
                              margin: "0",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={(e) => removeEmailCc(row.email)}
                          >
                            <p style={{ marginTop: "-2px" }}>x</p>
                          </button>
                        </div>
                      </>
                    ))
                  : null}
                <div className="bd-highlight m-2 flex-fill">
                  <Form.Control
                    style={{ border: "0" }}
                    className="form-control-sm"
                    onKeyPress={(e) => {
                      addEmailCc(e);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <div className="d-flex justify-content-end mt-1">
              <button
                className="btn btn-danger btn-sm col-2 mt-1 mb-1"
                onClick={saveEmail}
                disabled={isFetch}
              >
                {isFetch ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalSetEmailPO;
