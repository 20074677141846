import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardItem from "./DashboardItem";

function DashboardItemCarousel({ id, data = [] }) {
  return (
    <>
      <div
        id={`carousel${id}`}
        className="carousel slide card h-75"
        style={{ width: "50%", cursor: "pointer" }}
      >
        <div className="carousel-indicators absolute">
          {data.map((item, i) => (
            <button
              key={i}
              type="button"
              data-bs-target={`#carousel${id}`}
              className={`bg-danger rounded-circle ${i === 0 ? "active" : ""}`}
              aria-current={i === 0 ? "true" : ""}
              data-bs-slide-to={`${i}`}
              aria-label={`Slide ${i + 1}`}
              style={{ width: "7px", height: "7px" }}
            ></button>
          ))}
        </div>
        <div className="carousel-inner w-100 h-100">
          {data.map((item, i) => (
            <div
              key={i}
              className={`carousel-item h-100 ${i === 0 ? "active" : ""} `}
            >
              <DashboardItem
                className={"h-100 w-100"}
                title={item?.title}
                onClick={item?.onClick}
                data={item?.data}
              />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={`#carousel${id}`}
          data-bs-slide="prev"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            color="#e2585c"
            size="xl"
            style={{
              padding: "3px",
              // backgroundColor: "f5d3d5",
            }}
          />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={`#carousel${id}`}
          data-bs-slide="next"
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            color="#e2585c"
            size="xl"
            style={{
              padding: "3px",
              // backgroundColor: "f5d3d5",
            }}
          />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
export default DashboardItemCarousel;
