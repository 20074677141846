import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useMemo } from "react";
import { getEmployeesUnsyncrone } from "../../../data-api/employee";
import moment from "moment";
import ReactTable from "../../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";

function TableUnsyncrone() {
  const [unsyncroneFilter, setUnsyncroneFilter] = useState({
    value: 0,
    label: "Unsyncrone Position",
  });
  const [unsyncrone, setUnsyncrone] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEmployeesUnsyncrone().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setUnsyncrone([])
        : setUnsyncrone(result.data);
      setIsLoading(false);
    });
  }, []);

  const columnsClient = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "PKWT",
        accessor: "pkwt",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Department",
        accessor: "department_name",
      },
      {
        Header: "Position",
        accessor: "position_name",
      },
      {
        Header: "Client",
        accessor: "client",
      },
    ],
    []
  );

  const columnsPosition = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "PKWT",
        accessor: "pkwt",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Position",
        accessor: "position_name",
      },
    ],
    []
  );

  const columnsDepartment = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "PKWT",
        accessor: "pkwt",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Department",
        accessor: "department_name",
      },
    ],
    []
  );

  const columnsNotFound = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Employee Status ID",
        accessor: "employee_status_id",
      },
      {
        Header: "Employment Status ID",
        accessor: "employment_status_id",
      },
      {
        Header: "Department ID",
        accessor: "department_id",
      },
      {
        Header: "Position ID",
        accessor: "position_id",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Client ID",
        accessor: "client_id",
      },
    ],
    []
  );

  const columnsPkwt = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Integrals PKWT",
        accessor: "integrals_pkwt_status",
      },
      {
        Header: "Starconnect PKWT",
        accessor: "starconnect_pkwt_status",
      },
      {
        Header: "Rank",
        accessor: "rank",
      },
    ],
    []
  );

  const columnsRole = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "PKWT",
        accessor: "pkwt",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Department",
        accessor: "department_name",
      },
      {
        Header: "Position",
        accessor: "position_name",
      },
      {
        Header: "Client",
        accessor: "client",
      },
    ],
    []
  );

  function handleDownloadExcel(header, data, downloadName) {
    // console.log(header, data, downloadName);
    // const columnsExcel = [
    //   // "No",
    //   "NIK",
    //   "Name",
    //   "Status",
    //   "Employeee Status",
    //   "Join Date",
    //   "Position",
    //   "Departement",
    //   "Grade",
    //   "Placement",
    // ];
    const excelData = data;
    // excelData.forEach((v) => {
    //   delete v.id;
    //   delete v.no;
    // });
    if (downloadName !== "Employee Unsyncrone PKWT") {
      excelData.forEach((item) => {
        item.join_date = moment(item.join_date).format("DD-MMM-YYYY");
      });
    }

    const dataSet = [];
    const dataHeader = [];
    header.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: downloadName,
    });
  }

  if (isLoading) return <span>Loading...</span>;

  return (
    <>
      <div className="tabs-content">
        <div className="px-4">
          <div className="mt-4">
            <Form.Group className="mb-3 fw-semibold col-4 mx-3 custom-text-14">
              {/* <Form.Label>Department</Form.Label> */}
              <Select
                className="w-50 fw-normal custom-text-14 text-start"
                options={[
                  {
                    value: 0,
                    label: "Unsyncrone Position",
                  },
                  {
                    value: 1,
                    label: "Unsyncrone Client",
                  },
                  {
                    value: 2,
                    label: "Unsyncrone Department",
                  },
                  {
                    value: 3,
                    label: "Unsyncrone Not Found",
                  },
                  {
                    value: 4,
                    label: "Unsyncrone PKWT",
                  },
                  {
                    value: 5,
                    label: "Unsyncrone Role",
                  },
                ]}
                value={unsyncroneFilter}
                defaultValue={unsyncroneFilter}
                onChange={(e) => {
                  setUnsyncroneFilter(e);
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 35,
                    minHeight: 35,
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: "14px",
                  }),
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              ></Select>
            </Form.Group>
          </div>
          {unsyncroneFilter.value === 0 ? (
            <ReactTable
              columns={columnsPosition}
              data={
                unsyncrone?.unsyncrone_position === null
                  ? []
                  : unsyncrone?.unsyncrone_position
              }
              handleDownloadExcel={() =>
                handleDownloadExcel(
                  columnsPosition,
                  unsyncrone?.unsyncrone_position === null
                    ? []
                    : unsyncrone?.unsyncrone_position,
                  "Employee Unsyncrone Position"
                )
              }
              handleFilter={null}
              hiddenColumns={[]}
              paging={[10, 20, 50, 100]}
            />
          ) : unsyncroneFilter.value === 1 ? (
            <ReactTable
              columns={columnsClient}
              data={
                unsyncrone?.unsyncrone_client === null
                  ? []
                  : unsyncrone?.unsyncrone_client
              }
              handleDownloadExcel={() =>
                handleDownloadExcel(
                  columnsClient,
                  unsyncrone?.unsyncrone_client === null
                    ? []
                    : unsyncrone?.unsyncrone_client,
                  "Employee Unsyncrone Client"
                )
              }
              handleFilter={null}
              hiddenColumns={[]}
              paging={[10, 20, 50, 100]}
            />
          ) : unsyncroneFilter.value === 2 ? (
            <ReactTable
              columns={columnsDepartment}
              data={
                unsyncrone?.unsyncrone_department === null
                  ? []
                  : unsyncrone?.unsyncrone_department
              }
              handleDownloadExcel={() =>
                handleDownloadExcel(
                  columnsDepartment,
                  unsyncrone?.unsyncrone_department === null
                    ? []
                    : unsyncrone?.unsyncrone_department,
                  "Employee Unsyncrone Department"
                )
              }
              handleFilter={null}
              hiddenColumns={[]}
              paging={[10, 20, 50, 100]}
            />
          ) : unsyncroneFilter.value === 3 ? (
            <ReactTable
              columns={columnsNotFound}
              data={
                unsyncrone.unsyncrone_not_found === null
                  ? []
                  : unsyncrone?.unsyncrone_not_found
              }
              handleDownloadExcel={() =>
                handleDownloadExcel(
                  columnsNotFound,
                  unsyncrone.unsyncrone_not_found === null
                    ? []
                    : unsyncrone?.unsyncrone_not_found,
                  "Employee Unsyncrone Not Found"
                )
              }
              handleFilter={null}
              hiddenColumns={[]}
              paging={[10, 20, 50, 100]}
            />
          ) : unsyncroneFilter.value === 4 ? (
            <ReactTable
              columns={columnsPkwt}
              data={
                unsyncrone.unsyncrone_pkwt === null
                  ? []
                  : unsyncrone?.unsyncrone_pkwt
              }
              handleDownloadExcel={() =>
                handleDownloadExcel(
                  columnsPkwt,
                  unsyncrone.unsyncrone_pkwt === null
                    ? []
                    : unsyncrone?.unsyncrone_pkwt,
                  "Employee Unsyncrone PKWT"
                )
              }
              handleFilter={null}
              hiddenColumns={[]}
              paging={[10, 20, 50, 100]}
            />
          ) : unsyncroneFilter.value === 5 ? (
            <ReactTable
              columns={columnsRole}
              data={
                unsyncrone.unsyncrone_role === null
                  ? []
                  : unsyncrone?.unsyncrone_role
              }
              handleDownloadExcel={() =>
                handleDownloadExcel(
                  columnsRole,
                  unsyncrone.unsyncrone_role === null
                    ? []
                    : unsyncrone?.unsyncrone_role,
                  "Employee Unsyncrone Role"
                )
              }
              handleFilter={null}
              hiddenColumns={[]}
              paging={[10, 20, 50, 100]}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default TableUnsyncrone;
