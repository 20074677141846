import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function ModalShowPDF(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleShow();
  }, []);

  const CloseClick = () => {
    handleClose();
    props.click(false);
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Body>
        <div>
          <iframe
            title="View PDF"
            className="w-100 rounded"
            style={{ zoom: "1" }}
            src={props.data}
            height="600"
            width="500"
            // title="Iframe Example"
          ></iframe>
          <div style={{ textAlign: "center" }}>
            <button
              className="btn btn-danger mt-2"
              onClick={() => CloseClick()}
            >
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalShowPDF;
