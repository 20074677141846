import { useEffect, useMemo, useState } from "react";
import { getUnsyncroneTL } from "../../../data-api/tl-structure";
import ReactTable from "../../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";

function TabUnsyncrone() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getUnsyncroneTL().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Company",
        accessor: "nickname",
      },
      {
        Header: "Official Name",
        accessor: "official_name",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Direct Report",
        accessor: "direct_report",
      },
    ],
    []
  );

  function handleDownloadExcel() {
    const columnsExcel = columns.map((item) => item.Header);

    const dataSet = [];
    const dataHeader = [];

    //append header table
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "TL Structure Unsyncrone",
    });
  }

  return (
    <div className="tabs-content">
      <ReactTable
        columns={columns}
        data={data}
        handleDownloadExcel={handleDownloadExcel}
        handleFilter={null}
        isLoading={isLoading}
        hiddenColumns={[]}
        paging={[10, 20, 50, 100]}
      />
    </div>
  );
}
export default TabUnsyncrone;
