export const axiosError = (error) => {
  var meta = {
    message:
      error.response === undefined
        ? error.message
        : error.response.data.meta.message,
    code: 500,
    status: "error",
  };
  var data = {
    meta: meta,
    data: null,
  };
  return data;
};

export const SAVED_EVENT = "INTEGRALS";
export const STORAGE_KEY_EMPLOYEE = "EMPLOYEE";
export const STORAGE_KEY_TIMESHEET = "TIMESHEET";
