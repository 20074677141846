import { Tab } from "bootstrap";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react-table";
import { getEmployeeContract } from "../../../data-api/employee";
import moment from "moment";
import ReactTable from "../../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";
import LoadingEffect from "../../template/loadingEffectTable";

function ModalContract(props) {
  const [contract, setContract] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEmployeeContract(props.data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setContract({});
      } else {
        var pkwtInformation = [];
        if (result.data.pkwt_information !== null) {
          result.data.pkwt_information.forEach((element) => {
            pkwtInformation.push({
              start_date: element.start_date,
              end_date: element.end_date,
              no_pkwt: element.no_pkwt,
              type: element.type,
            });
          });
        }

        var contractInformation = [];
        if (result.data.contract_information !== null) {
          result.data.contract_information.forEach((element) => {
            contractInformation.push({
              client_name: element.client_name,
              start_date: element.start_date,
              end_date: element.end_date,
              spk_type: element.spk_type,
            });
          });
        }

        result.data.pkwt_information = pkwtInformation;
        result.data.contract_information = contractInformation;

        setContract(result.data);
      }
      setIsLoading(false);
    });
  }, []);

  const columnsInternal = useMemo(
    () => [
      {
        Header: "Start Date",
        accessor: "start_date", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <div>
            {row.values.start_date !== ""
              ? moment(row.values.start_date).format("DD-MMM-YYYY")
              : "~"}
          </div>
        ),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ row }) => (
          <div>
            {row.values.end_date !== ""
              ? moment(row.values.end_date).format("DD-MMM-YYYY")
              : "~"}
          </div>
        ),
      },
      {
        Header: "No. PKWT",
        accessor: "no_pkwt",
      },
      {
        Header: "Type",
        accessor: "type",
      },
    ],
    []
  );

  const columnsExternal = useMemo(
    () => [
      {
        Header: "Placement",
        accessor: "client_name",
      },
      {
        Header: "Start Date",
        accessor: "start_date", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <div>
            {row.values.start_date !== ""
              ? moment(row.values.start_date).format("DD-MMM-YYYY")
              : "~"}
          </div>
        ),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ row }) => (
          <div>
            {" "}
            {row.values.end_date !== ""
              ? moment(row.values.end_date).format("DD-MMM-YYYY")
              : "~"}
          </div>
        ),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
    ],
    []
  );

  function handleDownloadExcel(header, data, downloadName) {
    const columnsExcel = header.map((item) => item.Header);
    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);
    data.forEach((item) => {
      // delete item.no;
      item.start_date = moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date = moment(item.end_date).format("DD-MMM-YYYY");
    });
    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: downloadName,
    });
  }

  if (isLoading)
    return (
      <>
        <LoadingEffect />
      </>
    );

  return (
    <>
      <div>
        <table className="table table-borderless table-sm custom-text-14">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{contract.employee_name}</td>
            </tr>
            <tr>
              <td>NIK</td>
              <td>{contract.nik} </td>
            </tr>
          </tbody>
        </table>
        <Tabs
          defaultActiveKey="internal"
          id="uncontrolled-tab-example"
          className="custom-text-14"
        >
          <Tab eventKey="internal" title="Internal">
            <div className="mt-3">
              <ReactTable
                columns={columnsInternal}
                data={contract.pkwt_information}
                handleDownloadExcel={() =>
                  handleDownloadExcel(
                    columnsInternal,
                    contract.pkwt_information,
                    `Employee Contract Internal ${contract.nik} - ${contract.employee_name}`
                  )
                }
                handleFilter={null}
                hiddenColumns={[]}
              />
            </div>
          </Tab>
          <Tab eventKey="external" title="External">
            <div className="mt-3">
              <ReactTable
                columns={columnsExternal}
                data={contract.contract_information}
                handleDownloadExcel={() =>
                  handleDownloadExcel(
                    columnsExternal,
                    contract.contract_information,
                    `Employee Contract External ${contract.nik} - ${contract.employee_name}`
                  )
                }
                handleFilter={null}
                hiddenColumns={[]}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ModalContract;
