import moment from "moment";
import writeXlsxFile from "write-excel-file";

export const ExcelDownload = (
  columnsHeader = [],
  deleteHeader = [],
  columnsData = [],
  deleteData = [],
  excelName = "",
  dateColumn = []
) => {
  const columnsExcel = columnsHeader.filter(
    (item) => item.active === true && !deleteHeader.includes(item.accessor)
  );
  const excelData = columnsData;
  excelData.forEach((v) => {
    if (deleteData.length > 0) {
      deleteData.forEach((item) => {
        delete v[item];
      });
    }
  });
  excelData.forEach((item) => {
    if (dateColumn.length > 0) {
      dateColumn.forEach((value) => {
        item[value] = moment(item[value]).format("DD-MMM-YYYY");
      });
    }
  });
  const dataSet = [];
  const dataHeader = [];
  columnsExcel.forEach((item) => {
    dataHeader.push({
      value: item.Header,
      key: item.accessor,
      type: String,
      fontWeight: "bold",
    });
  });
  dataSet.push(dataHeader);

  var arr = [];

  columnsData.forEach((item) => {
    let obj = {};
    dataHeader.forEach((header) => {
      obj[header.key] = item[header.key];
    });
    arr.push(obj);
  });

  // FILTERED ROWS
  arr.forEach((value) => {
    const dataRow = [];

    Object.values(value).forEach((item) => {
      dataRow.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
      });
    });
    dataSet.push(dataRow);
  });

  writeXlsxFile(dataSet, {
    //columns, // (optional) column widths, etc.
    fileName: excelName,
  });
};
