import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import {
  getTimesheetUploadById,
  putUploadFile,
} from "../../../data-api/timesheet";
import ModalMessage from "../../template/ModalMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ReactSelect } from "../../../utils/ReactSelect";

function ModalEditUpload(props) {
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState([]);
  const [check, setCheck] = useState(false);
  const [detailTimesheet, setDetailTimesheet] = useState([]);

  const [isFetch, setIsFetch] = useState(false);

  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getTimesheetUploadById(props.data.id).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      setDetailTimesheet(result.data);
    });

    setModalTitle(props.data.title);
    handleShow();
  }, []);

  const [Msg, setMsg] = useState("");

  const [fileList, setFileList] = useState([]);

  const [workDays, setWorkDays] = useState("");
  const [sickLeave, setSickLeave] = useState("");
  const [normalLeave, setNormalLeave] = useState("");
  const [category, setCategory] = useState({ value: "1", label: "Timesheet" });

  const onFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile.type !== "application/pdf") {
      setMsg("Please upload with PDF format");
    } else if (newFile.size / 1048576 > 5) {
      setMsg("Please upload with size under 5 MB");
    } else {
      setFileList([e.target.files[0]]);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
  };

  const ConfirmUpload = async (e) => {
    setIsFetch(true);
    e.preventDefault();

    setWorkDays(e.target.work_days.value);
    setSickLeave(e.target.sick_leave.value);
    setNormalLeave(e.target.normal_leave.value);

    setMsg("");
    setModalTitle("Confirm Update Upload File Timesheet");
    setIsFetch(false);
  };

  const UpdateTimesheetUpload = async () => {
    setIsFetch(true);

    const formData = new FormData();

    if (category.value === "1") {
      formData.append("timesheet_url", fileList[0]);
    }
    if (category.value === "2") {
      formData.append("bast_url", fileList[0]);
    }
    if (category.value === "3") {
      formData.append("overtime_url", fileList[0]);
    }
    if (category.value === "4") {
      formData.append("leave_url", fileList[0]);
    }

    formData.append("nik", detailTimesheet.nik);
    formData.append("periode", detailTimesheet.periode);
    formData.append("work_days", workDays);
    formData.append("sick_leave", sickLeave);
    formData.append("normal_leave", normalLeave);

    let message = [];
    putUploadFile(props.data.id, formData).then((resp) => {
      if (resp.meta.code === 401) {
        window.location.reload();
        return;
      }
      message = {
        code: resp.meta.code,
        message: resp.meta.message,
      };
      setModalMessage(message);
      setCheck(true);
      setIsFetch(false);
      handleClose();
    });
  };

  const handleBacktoHistory = () => {
    handleClose();
    props.click();
  };

  if (check === false) {
    return (
      <Modal
        show={show}
        size="xl"
        onHide={handleBacktoHistory}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        {Msg === "" ? null : (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <strong>{Msg}</strong>
          </Alert>
        )}
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalTitle === props.data.title ? (
            // Modal Input Edit Upload Timesheet
            <>
              <div className="container custom-text-14">
                <h5>
                  Periode
                  {detailTimesheet.month === "1"
                    ? " January"
                    : detailTimesheet.month === "2"
                    ? " February"
                    : detailTimesheet.month === "3"
                    ? " March"
                    : detailTimesheet.month === "4"
                    ? " April"
                    : detailTimesheet.month === "5"
                    ? " May"
                    : detailTimesheet.month === "6"
                    ? " June"
                    : detailTimesheet.month === "7"
                    ? " July"
                    : detailTimesheet.month === "8"
                    ? " August"
                    : detailTimesheet.month === "9"
                    ? " September"
                    : detailTimesheet.month === "10"
                    ? " October"
                    : detailTimesheet.month === "11"
                    ? " November"
                    : detailTimesheet.month === "12"
                    ? " Desember"
                    : detailTimesheet.month}
                  {` ${detailTimesheet.year}`}
                </h5>
                <Form onSubmit={ConfirmUpload}>
                  <Form.Group className="mb-2 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Category</Form.Label>
                    <div className="w-75">
                      <ReactSelect
                        value={category}
                        setValue={setCategory}
                        data={[
                          {
                            value: "1",
                            label: "Timesheet",
                          },
                          {
                            value: "2",
                            label: "BAST",
                          },
                          {
                            value: "3",
                            label: "Overtime",
                          },
                          {
                            value: "4",
                            label: "Leave",
                          },
                        ]}
                        placeholder={"-- Select Category --"}
                        validationMessage={"Please Select Category"}
                      />
                    </div>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3 fw-semibold">
                        <Form.Label className="mt-2">Work Days</Form.Label>
                        <Form.Control
                          className="form-control-sm"
                          type="number"
                          id="work_days"
                          name="work_days"
                          defaultValue={detailTimesheet.work_days}
                          min={0}
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              "Please fill out Work Days"
                            )
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 fw-semibold">
                        <Form.Label className="mt-2">Sick Leave</Form.Label>
                        <Form.Control
                          className="form-control-sm"
                          type="number"
                          id="sick_leave"
                          name="sick_leave"
                          defaultValue={detailTimesheet.sick_leave}
                          min={0}
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              "Please fill out Sick Days"
                            )
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 fw-semibold">
                        <Form.Label className="mt-2">Normal Leave</Form.Label>
                        <Form.Control
                          className="form-control-sm"
                          type="number"
                          id="normal_leave"
                          name="normal_leave"
                          defaultValue={detailTimesheet.normal_leave}
                          min={0}
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              "Please fill out Normal Days"
                            )
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-1 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">File Upload</Form.Label>
                    <div className="d-flex justify-content-start w-75">
                      <label
                        for="file_upload"
                        className="btn btn-outline-danger w-25 btn-sm"
                      >
                        Browse
                      </label>
                      <input
                        type="file"
                        id="file_upload"
                        name="file_upload"
                        hidden
                        onChange={(e) => onFileChange(e)}
                        className="form-control "
                      />
                      {fileList.length > 0
                        ? fileList.map((item, index) => (
                            <>
                              <Form.Label className="mt-2 px-2">
                                {item.name} /{" "}
                                {Math.round((item.size / 1024) * 1000) / 1000}{" "}
                                KB
                              </Form.Label>
                              <span
                                className="btn btn-danger btn-circle btn-sm"
                                onClick={() => fileRemove(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faCircleXmark}
                                  size="2x"
                                />
                              </span>
                            </>
                          ))
                        : null}
                    </div>
                  </Form.Group>

                  <div className="d-flex flex-row-reverse bd-highlight">
                    <button
                      className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                      disabled={isFetch}
                    >
                      {isFetch ? (
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                      ) : (
                        "Confirm"
                      )}
                    </button>
                  </div>
                </Form>
              </div>
            </>
          ) : (
            // Modal Confirm
            <>
              <div className="container">
                <h4 style={{ textAlign: "center" }} className="mt-2">
                  Are you sure want to update this file?
                </h4>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    type="submit"
                    disabled={isFetch}
                    onClick={UpdateTimesheetUpload}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={() => setModalTitle(props.data.title)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  } else {
    return <ModalMessage data={modalMessage} click={props.click} />;
  }
}

export default ModalEditUpload;
