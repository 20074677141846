import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { postUploadFile } from "../../../data-api/timesheet";
import ModalMessage from "../../template/ModalMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { ReactSelect } from "../../../utils/ReactSelect";
registerLocale("en-GB", enGb);

function ModalNewUpload(props) {
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState([]);
  const [check, setCheck] = useState(false);

  const [isFetch, setIsFetch] = useState(false);

  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setModalTitle(props.data.title);
    handleShow();
  }, []);

  const [Msg, setMsg] = useState("");

  const onFileChange = (e, type) => {
    if (e.target.files[0].type !== "application/pdf") {
      setMsg("Please upload with PDF format");
    } else if (e.target.files[0].size / 1048576 > 5) {
      setMsg("Please upload with size under 5 MB");
    } else {
      if (type === "timesheet") {
        setTimesheet([e.target.files[0]]);
      } else if (type === "bast") {
        setBast([e.target.files[0]]);
      } else if (type === "overtime") {
        setOvertime([e.target.files[0]]);
      } else if (type === "leave") {
        setLeave([e.target.files[0]]);
      }
    }
  };

  const fileRemove = (file, type) => {
    if (type === "timesheet") {
      const updatedList = [...timesheet];
      updatedList.splice(timesheet.indexOf(file), 1);
      setTimesheet(updatedList);
    } else if (type === "bast") {
      const updatedList = [...bast];
      updatedList.splice(bast.indexOf(file), 1);
      setBast(updatedList);
    } else if (type === "overtime") {
      const updatedList = [...overtime];
      updatedList.splice(overtime.indexOf(file), 1);
      setOvertime(updatedList);
    } else if (type === "leave") {
      const updatedList = [...leave];
      updatedList.splice(leave.indexOf(file), 1);
      setLeave(updatedList);
    }
  };

  const [timesheet, setTimesheet] = useState([]);
  const [bast, setBast] = useState([]);
  const [overtime, setOvertime] = useState([]);
  const [leave, setLeave] = useState([]);

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [workDays, setWorkDays] = useState("");
  const [sickLeave, setSickLeave] = useState("");
  const [normalLeave, setNormalLeave] = useState("");

  const ConfirmUpload = async (e) => {
    setIsFetch(true);
    e.preventDefault();

    setWorkDays(e.target.work_days.value);
    setSickLeave(e.target.sick_leave.value);
    setNormalLeave(e.target.normal_leave.value);

    setMsg("");
    setModalTitle("Confirm Upload File");
    setIsFetch(false);
  };

  const CreateTimesheetUpload = async () => {
    setIsFetch(true);

    const formData = new FormData();

    formData.append("nik", props.data.nik);
    formData.append("month", month.value);
    formData.append("year", moment(year).format("YYYY"));
    formData.append("timesheet_url", timesheet[0]);
    formData.append("bast_url", bast[0]);
    formData.append("overtime_url", overtime[0]);
    formData.append("leave_url", leave[0]);
    formData.append("work_days", workDays);
    formData.append("sick_leave", sickLeave);
    formData.append("normal_leave", normalLeave);

    let message = [];
    postUploadFile(formData).then((resp) => {
      if (resp.meta.code === 401) {
        window.location.reload();
        return;
      }
      message = {
        code: resp.meta.code,
        message: resp.meta.message,
      };
      setModalMessage(message);
      setCheck(true);
      setIsFetch(false);
      handleClose();
    });
  };

  if (check === false) {
    return (
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        {Msg === "" ? null : (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <strong>{Msg}</strong>
          </Alert>
        )}
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalTitle === props.data.title ? (
            // Modal Input
            <Form onSubmit={(e) => ConfirmUpload(e)}>
              <div className="container custom-text-14">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Employee</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    id="employee"
                    name="employee"
                    aria-describedby="basic-addon1"
                    value={props.data.nik + " - " + props.data.name}
                    disabled
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3 fw-semibold">
                      <Form.Label className="mt-2">Month</Form.Label>
                      <div className="w-100">
                        <ReactSelect
                          value={month}
                          setValue={setMonth}
                          data={[
                            {
                              value: "1",
                              label: "January",
                            },
                            {
                              value: "2",
                              label: "February",
                            },
                            {
                              value: "3",
                              label: "March",
                            },
                            {
                              value: "4",
                              label: "April",
                            },
                            {
                              value: "5",
                              label: "May",
                            },
                            {
                              value: "6",
                              label: "June",
                            },
                            {
                              value: "7",
                              label: "July",
                            },
                            {
                              value: "8",
                              label: "August",
                            },
                            {
                              value: "9",
                              label: "September",
                            },
                            {
                              value: "10",
                              label: "October",
                            },
                            {
                              value: "11",
                              label: "November",
                            },
                            {
                              value: "12",
                              label: "Desember",
                            },
                          ]}
                          placeholder={"-- Select Month --"}
                          validationMessage={"Please Select Month"}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 ">
                      <Form.Label className="mt-2 fw-semibold">Year</Form.Label>
                      <div>
                        <ReactDatePicker
                          locale={enGb}
                          className="w-100 form-control"
                          wrapperClassName="w-100 form-control"
                          selected={year}
                          onChange={(date) => {
                            setYear(date);
                          }}
                          showYearPicker
                          isClearable
                          dateFormat="yyyy"
                          placeholderText="-- Select Year --"
                          onInvalid={(e) =>
                            e.target.setCustomValidity("Please fill out Year")
                          }
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3 fw-semibold">
                      <Form.Label>Work Days</Form.Label>
                      <Form.Control
                        type="number"
                        id="work_days"
                        name="work_days"
                        className="mt-2 form-control-sm"
                        aria-describedby="basic-addon1"
                        defaultValue={workDays ? workDays : 0}
                        min={0}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please fill out Work Days"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 fw-semibold">
                      <Form.Label>Sick Leave</Form.Label>
                      <Form.Control
                        type="number"
                        id="sick_leave"
                        name="sick_leave"
                        className="mt-2 form-control-sm"
                        min={0}
                        defaultValue={sickLeave ? sickLeave : 0}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please fill out Sick Leave"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 fw-semibold">
                      <Form.Label>Normal Leave</Form.Label>
                      <Form.Control
                        type="number"
                        id="normal_leave"
                        name="normal_leave"
                        className="mt-2 form-control-sm"
                        min={0}
                        defaultValue={normalLeave ? normalLeave : 0}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please fill out Normal Leave"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Timesheet</Form.Label>
                  <div className="d-flex justify-content-start w-75">
                    <label
                      for="timesheet"
                      className="btn btn-outline-danger w-25"
                    >
                      Browse
                    </label>
                    <input
                      type="file"
                      id="timesheet"
                      name="timesheet"
                      className="form-control  form-control-sm"
                      hidden
                      onChange={(e) => onFileChange(e, "timesheet")}
                    />
                    {timesheet.length > 0
                      ? timesheet.map((item, index) => (
                          <>
                            <Form.Label className="mt-2 px-2">
                              {item.name} /{" "}
                              {Math.round((item.size / 1024) * 1000) / 1000} KB
                            </Form.Label>
                            <span
                              className="btn btn-danger btn-circle btn-sm"
                              onClick={() => fileRemove(item, "timesheet")}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                            </span>
                          </>
                        ))
                      : null}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Bast</Form.Label>
                  <div className="d-flex justify-content-start w-75">
                    <label for="bast" className="btn btn-outline-danger w-25">
                      Browse
                    </label>
                    <input
                      type="file"
                      id="bast"
                      name="bast"
                      className="form-control form-control-sm"
                      hidden
                      onChange={(e) => onFileChange(e, "bast")}
                    />
                    {bast.length > 0
                      ? bast.map((item, index) => (
                          <>
                            <Form.Label className="mt-2 px-2">
                              {item.name} /{" "}
                              {Math.round((item.size / 1024) * 1000) / 1000} KB
                            </Form.Label>
                            <span
                              className="btn btn-danger btn-circle btn-sm"
                              onClick={() => fileRemove(item, "bast")}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                            </span>
                          </>
                        ))
                      : null}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Overtime</Form.Label>
                  <div className="d-flex justify-content-start w-75">
                    <label
                      for="overtime"
                      className="btn btn-outline-danger w-25"
                    >
                      Browse
                    </label>
                    <input
                      type="file"
                      id="overtime"
                      name="overtime"
                      className="form-control form-control-sm"
                      hidden
                      onChange={(e) => onFileChange(e, "overtime")}
                    />
                    {overtime.length > 0
                      ? overtime.map((item, index) => (
                          <>
                            <Form.Label className="mt-2 px-2">
                              {item.name} /{" "}
                              {Math.round((item.size / 1024) * 1000) / 1000} KB
                            </Form.Label>
                            <span
                              className="btn btn-danger btn-circle btn-sm"
                              onClick={() => fileRemove(item, "overtime")}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                            </span>
                          </>
                        ))
                      : null}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Leave</Form.Label>
                  <div className="d-flex justify-content-start w-75">
                    <label for="leave" className="btn btn-outline-danger w-25">
                      Browse
                    </label>
                    <input
                      type="file"
                      id="leave"
                      name="leave"
                      className="form-control form-control-sm"
                      hidden
                      onChange={(e) => onFileChange(e, "leave")}
                    />
                    {leave.length > 0
                      ? leave.map((item, index) => (
                          <>
                            <Form.Label className="mt-2 px-2">
                              {item.name} /{" "}
                              {Math.round((item.size / 1024) * 1000) / 1000} KB
                            </Form.Label>
                            <span
                              className="btn btn-danger btn-circle btn-sm"
                              onClick={() => fileRemove(item, "leave")}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                            </span>
                          </>
                        ))
                      : null}
                  </div>
                </Form.Group>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    disabled={isFetch}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          ) : (
            // Modal Confirm
            <div className="container">
              <h3 style={{ textAlign: "center" }}>
                Are you sure want to upload this file?
              </h3>
              <div className="d-flex flex-row-reverse bd-highlight">
                <Button
                  className="btn btn-danger btn-sm"
                  type="submit"
                  disabled={isFetch}
                  onClick={CreateTimesheetUpload}
                >
                  {isFetch ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <button
                  className="btn btn-outline-secondary me-2 btn-sm"
                  onClick={() => setModalTitle(props.data.title)}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  } else {
    return <ModalMessage data={modalMessage} click={props.click} />;
  }
}

export default ModalNewUpload;
