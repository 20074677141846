import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "./Clients.css";
import { updateClient } from "../../data-api/master-data-clients";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGb from "date-fns/locale/en-GB"; // the locale you want
import Select from "react-select";
registerLocale("en-GB", enGb);

function ModalEditClient(props) {
  const [isFetch, setIsFetch] = useState(false);
  const [client, setClient] = useState(props.data.client_name);
  const [officialName, setOfficialName] = useState(props.data.official_name);
  const [Address, setAddress] = useState(props.data.address);
  const [relation, setRelation] = useState({
    label: props.data.relation,
    value: props.data.relation,
  });
  const [procType, setProcType] = useState({
    label: props.data.proc_type,
    value: props.data.proc_type,
  });
  const [projectName, setProjectName] = useState(props.data.project_name);
  const [startPeriode, setStartPeriode] = useState(
    props.data.start_periode === "" ? "" : new Date(props.data.start_periode)
  );
  const [endPeriode, setEndPeriode] = useState(
    props.data.end_periode === "" ? "" : new Date(props.data.end_periode)
  );

  const [editConfirm, setEditConfirm] = useState(false);

  function formEditClient(event) {
    event.preventDefault();
    if (!editConfirm) {
      setEditConfirm(true);
    } else {
      fetchEditClient();
    }
  }

  //send json to backend
  function fetchEditClient() {
    const json = JSON.stringify({
      client_name: client,
      official_name: officialName,
      address: Address,
      relation: relation.label,
      proc_type: procType.label,
      project_name: projectName,
      start_periode: moment(startPeriode).format("YYYY-MM-DD"),
      end_periode: moment(endPeriode).format("YYYY-MM-DD"),
    });

    updateClient(props.data.id, json).then((result) => {
      if (result.data === null || result.data === undefined) {
        if (result.response.data.meta.code === 401) {
          window.location.reload();
          return;
        }
        const message = {
          code: result.response.data.meta.code,
          message: result.response.data.meta.message,
        };
        props.handleClose(message);
      } else {
        setRelation("-1");
        setStartPeriode("");
        setEndPeriode("");
        setIsFetch(false);
        const message2 = {
          code: result.data.meta.code,
          message: result.data.meta.message,
        };
        if (message2.code === 200) {
          props.handleClose(message2);
        }
      }
    });
  }

  return (
    <>
      <div className="custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>Edit Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => formEditClient(e)}>
            {
              // form input
              !editConfirm ? (
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Client</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setClient(e.target.value)}
                      defaultValue={props.data.client_name}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Please fill out Client")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Official Name</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setOfficialName(e.target.value)}
                      defaultValue={props.data.official_name}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please fill out Official Name"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Address</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setAddress(e.target.value)}
                      defaultValue={props.data.address}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Please fill out Address")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Relation to</Form.Label>
                    <Select
                      className="w-75 fw-normal custom-text-14"
                      options={[
                        { label: "IDStar", value: "IDStar" },
                        { label: "Drife", value: "Drife" },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable={true}
                      placeholder={"-- Select Relation --"}
                      defaultValue={relation}
                      onChange={(e) => setRelation(e)}
                      required
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Procurement Type</Form.Label>
                    <Select
                      className="w-75 fw-normal custom-text-14"
                      options={[
                        { label: "Project", value: "Project" },
                        { label: "Resource", value: "Resource" },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable={true}
                      placeholder={"-- Select Procurement --"}
                      defaultValue={procType}
                      onChange={(e) => setProcType(e)}
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Project Name</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setProjectName(e.target.value)}
                      defaultValue={props.data.project_name}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please fill out Project Name"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Start Periode
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        // selected={
                        //   startPeriode === "" ? "" : new Date(startPeriode)
                        // }
                        selected={startPeriode}
                        onChange={(value) => setStartPeriode(value)}
                        isClearable
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) => false}
                        onInput={(e) => e.target.setCustomValidity("")}
                        // required={endPeriode === "" ? false : true}
                        required
                        // maxDate={new Date(endPeriode)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      End Periode
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        // selected={
                        //   endPeriode === "" ? new Date() : new Date(endPeriode)
                        // }
                        selected={endPeriode}
                        onChange={(value) => setEndPeriode(value)}
                        isClearable
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) => false}
                        onInput={(e) => e.target.setCustomValidity("")}
                        // required={startPeriode === "" ? false : true}
                        required
                        // minDate={new Date(startPeriode)}
                      />
                    </div>
                  </Form.Group>
                </div>
              ) : (
                // add spk confirmation
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Client</Form.Label>
                    <Form.Label className="mt-2">{client}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Official Name</Form.Label>
                    <Form.Label className="mt-2">{officialName}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2 w-25">Address</Form.Label>
                    <Form.Label className="mt-2 w-75 text-end">
                      {Address}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Relation to</Form.Label>
                    <Form.Label className="mt-2">{relation.label}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Procurement Type</Form.Label>
                    <Form.Label className="mt-2">{procType.label}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Project Name</Form.Label>
                    <Form.Label className="mt-2">{projectName}</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Start Date</Form.Label>
                    <Form.Label className="mt-2">
                      {startPeriode === ""
                        ? ""
                        : moment(startPeriode).format("DD-MMM-YYYY")}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">End Date</Form.Label>
                    <Form.Label className="mt-2">
                      {endPeriode === ""
                        ? ""
                        : moment(endPeriode).format("DD-MMM-YYYY")}
                    </Form.Label>
                  </Form.Group>
                </div>
              )
            }

            <div className="d-grid">
              {editConfirm ? (
                <button
                  className="btn btn-danger mb-4 ms-1 me-1 fw-bold w-50 btn-sm"
                  onClick={(e) => setEditConfirm(false)}
                >
                  Back{" "}
                </button>
              ) : null}

              <button className="btn btn-danger mb-4 ms-1 me-1 fw-bold shadow addConfirm btn-sm">
                {isFetch ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : editConfirm ? (
                  "Submit"
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalEditClient;
