import { Form, Modal } from "react-bootstrap";
import { postSuperadminHelpdeskSendEmail } from "../../../../data-api/master-user";

function SendEmail(props) {
  function sendEmail() {
    postSuperadminHelpdeskSendEmail(props.data.original.id).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        props.handleSuccessModal(false, result.meta.message);
      } else {
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.handleSuccessModal(true, message.message);
      }
    });
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <div className="p-3">
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">From</Form.Label>
            <Form.Label className="w-75 text-start">
              {props.data.original.name}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Type</Form.Label>
            <Form.Label className="w-75 text-start">
              {props.data.original.type}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Section</Form.Label>
            <Form.Label className="w-75 text-start">
              {props.data.original.section}
            </Form.Label>
          </Form.Group>

          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Status</Form.Label>
            <Form.Label className="w-75 text-start">
              <span
                className={
                  props.data.original.status === "New Received"
                    ? "text-primary"
                    : props.data.original.status === "On Progress"
                    ? "text-warning"
                    : props.data.original.status === "Done"
                    ? "text-success"
                    : ""
                }
              >
                {props.data.original.status}
              </span>
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="w-25 text-start">Message</Form.Label>
            <Form.Label className="w-75 text-start  border rounded p-2">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.original.message,
                }}
              ></div>
            </Form.Label>
          </Form.Group>
          <div className="d-flex justify-content-between mb-3">
            <button
              className="btn btn-outline-danger col-5 me-4 btn-sm"
              onClick={props.onHide}
            >
              Back
            </button>
            <button className="btn btn-danger col-5 btn-sm" onClick={sendEmail}>
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default SendEmail;
