import React from "react";
import PropTypes from "prop-types";
import "./Collapse.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faSortDown } from "@fortawesome/free-solid-svg-icons";

const Collapsible = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const ref = React.useRef();

  const [height, setHeight] = React.useState();

  const handleToggle = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
    setHeight(ref.current.clientHeight);
  };

  const classes = `list-group-item ${isExpanded ? "is-expanded" : null}`;
  const currentHeight = isExpanded ? height : 0;
  return (
    <div className={classes}>
      <div className="card-title d-flex flex-row" onClick={handleToggle}>
        <span className="fw-semibold custom-text-18">{title}</span>
        {isExpanded ? (
          <>
            <FontAwesomeIcon icon={faSortDown} className="ms-3 pt-1" />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCaretLeft} className="ms-3 pt-2" />
          </>
        )}
      </div>
      <div
        className="card-collapse card"
        style={{ height: currentHeight + "px" }}
      >
        <div className="card-body" ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string,
};

export default Collapsible;
