import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { getChartDetail } from "../../data-api/dashboard";
import TableChartDetail from "../../utils/TableChartDetail";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";

function DetailChartMultiFilter(props) {
  const [filter1, setFilter1] = useState(
    props.listDropdown[0].data[props.data.dataPointIndex]
  );
  const [filter2, setFilter2] = useState(
    props.listDropdown[1].data[props.data.seriesIndex]
  );

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(filter1, filter2);
  }, []);

  function fetchData(filter1, filter2) {
    setIsLoading(true);
    let params = {};
    if (props.data.title === "Talent Movement In & Out (Q1 - Q2 - Q3 - Q4)") {
      params = {
        type: filter1.value,
        month: filter2.value,
      };
    } else if (props.data.title === "Talent on Client Map (TOP 7)") {
      params = {
        client: filter1.value,
        grade: filter2.value,
      };
    } else if (props.data.title === "Talent Role Map") {
      params = {
        position: filter1.value,
        grade: filter2.value,
      };
    } else if (props.data.title === "Employee by Status") {
      params = {
        status: filter2.value,
        company: filter1.value,
      };
    } else if (props.data.title === "Talent Position Map") {
      params = {
        position: filter1.value,
        grade: filter2.value,
      };
    } else if (props.data.title === "Vendor Employee Source Talent") {
      params = {
        filter: filter1.value,
        status: filter2.value,
      };
    }
    getChartDetail(params, props.data.detailUrl).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor:
          props.data.title === "Employee by Department"
            ? "employee_name"
            : "name",
      },
    ],
    []
  );

  function handleDownloadExcel() {
    const columnsExcel = columns.map((item) => item.Header);
    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: `Chart_${props.data.title}_${filter1.label}_${filter2.label}`,
    });
  }

  return (
    <>
      <div className="overflow-x-scroll">
        <div style={{ minWidth: "600px" }}>
          <div className="d-flex flex-row mt-4 mb-3">
            <div className="container text-start text-danger fw-semibold">
              <Form.Group className="fw-semibold d-flex ">
                <Select
                  className="w-25 fw-normal custom-text-14 text-black"
                  options={props.listDropdown[0].data.map((data) => {
                    return { value: data.value, label: data.label };
                  })}
                  defaultValue={{
                    label: props.listDropdown[0].data.find(
                      (x) => x.value === filter1.value
                    ).label,
                  }}
                  onChange={(e) => {
                    setFilter1(e);
                    fetchData(e, filter2);
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                ></Select>

                <Select
                  className="w-25 fw-normal custom-text-14 text-black ms-3"
                  options={props.listDropdown[1].data.map((data) => {
                    return { value: data.value, label: data.label };
                  })}
                  defaultValue={{
                    label: props.listDropdown[1].data.find(
                      (x) => x.value === filter2.value
                    ).label,
                  }}
                  onChange={(e) => {
                    setFilter2(e);
                    fetchData(filter1, e);
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                ></Select>
              </Form.Group>
            </div>
          </div>

          <TableChartDetail
            columns={columns}
            data={data}
            handleDownloadExcel={handleDownloadExcel}
            handleFilter={null}
            hiddenColumns={[]}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default DetailChartMultiFilter;
