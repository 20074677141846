import { useEffect, useMemo, useState } from "react";
import { getListCost } from "../../../data-api/cost-to-company";
import ReactTable from "../../../utils/ReactTable";
import { Form } from "react-bootstrap";
import writeXlsxFile from "write-excel-file";

function TabCTC(props) {
  const [data, setData] = useState([]);
  var moneyFormatter = new Intl.NumberFormat();

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.nik}</span>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Grade",
        accessor: "grade",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.grade}</span>
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: "gender",
        // Cell: ({ row }) => (
        //   <div className="text-center">
        //     <span>
        //       {row.values.gender === "M"
        //         ? "Male"
        //         : row.values.gender === "F"
        //         ? "Female"
        //         : ""}
        //     </span>
        //   </div>
        // ),
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Result",
        accessor: "result",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.result)}</span>
          </div>
        ),
      },
    ],
    []
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCostVar();
  }, []);

  function fetchCostVar(param) {
    setIsLoading(true);
    getListCost(param).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  function handleDownloadExcel() {
    const dataSet = [];
    const dataHeader = [];
    columns.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    var customData = [];
    data.forEach((value) => {
      customData.push({
        nik: value.nik,
        name: value.name,
        grade: value.grade,
        gender: value.gender,
        position: value.position,
        result: value.result,
      });
    });

    // FILTERED ROWS
    customData.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: typeof item === "string" ? String : Number,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Cost_CTC",
    });
  }
  function ctcFilter(filter) {
    if (filter === 0) {
      fetchCostVar();
    } else {
      fetchCostVar({
        status: filter.toString(),
      });
    }
  }
  return (
    <>
      <div className="tabs-content">
        <div className="mt-4">
          <Form.Group className="mb-3 fw-semibold col-2 mx-3 custom-text-14">
            {/* <Form.Label>Department</Form.Label> */}
            <Form.Select
              onChange={(e) => ctcFilter(e.target.selectedIndex)}
              className="custom-text-14 form-select-sm"
              style={{ fontSize: "14px" }}
            >
              <option>All</option>
              <option>Active</option>
              <option>Non-Active</option>
            </Form.Select>
          </Form.Group>
        </div>
        <ReactTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>
    </>
  );
}

export default TabCTC;
