import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { getChartDetail } from "../../data-api/dashboard";
import TableChartDetail from "../../utils/TableChartDetail";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";

function DetailChart(props) {
  const [filter, setFilter] = useState(
    props?.listDropdown[props?.data?.dataPointIndex] !== undefined
      ? props?.listDropdown[props?.data?.dataPointIndex]
      : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(filter);
  }, []);

  function fetchData(filter) {
    setIsLoading(true);
    let detailUrl = props?.data?.detailUrl;
    if (props?.data?.title === "Religion Chart") {
      detailUrl += `?religion=${filter?.value}`;
    } else if (props?.data?.title === "Employee by Department") {
      detailUrl += `?department=${filter?.value}`;
    } else {
      detailUrl += `?filter=${filter?.value}`;
    }
    let params = {};
    getChartDetail(params, detailUrl).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result?.data === null || result?.data === undefined
        ? setData([])
        : setData(result?.data);
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor:
          props.data.title === "Employee by Department"
            ? "employee_name"
            : "name",
      },
    ],
    []
  );

  const columnEmployeeByDepartment = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Department",
        accessor: "department_name",
      },
      {
        Header: "Position",
        accessor: "position_name",
      },
    ],
    []
  );

  const columnVendorEmployee = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Source",
        accessor: "source_talent",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Placement",
        accessor: "client_location",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
      },
      {
        Header: "End Date",
        accessor: "end_date",
      },
    ],
    []
  );

  function handleDownloadExcel() {
    // const columnsExcel = columns.map((item) => item.Header);
    const columnsExcel =
      props.data.title === "Employee by Department"
        ? columnEmployeeByDepartment.map((item) => item.Header)
        : props.data.title === "Vendor Employee"
        ? columnVendorEmployee.map((item) => item.Header)
        : columns.map((item) => item.Header);

    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: `Chart_${props.data.title}_${filter.label}`,
    });
  }

  // if (isLoading) return <span>Loading...</span>;

  return (
    <>
      <div className="overflow-x-scroll">
        <div style={{ minWidth: "600px" }}>
          <div className="d-flex flex-row mt-4">
            <div className="container text-start text-danger fw-semibold">
              <Form.Group className="mb-3 fw-semibold d-flex">
                <Select
                  className="w-25 fw-normal custom-text-14 text-black"
                  options={props.listDropdown.map((data) => {
                    return { value: data.value, label: data.label };
                  })}
                  defaultValue={{
                    label: filter.label,
                  }}
                  onChange={(e) => {
                    fetchData(e);
                    setFilter(e);
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                ></Select>
              </Form.Group>
            </div>
          </div>

          <TableChartDetail
            columns={
              props.data.title === "Employee by Department"
                ? columnEmployeeByDepartment
                : props.data.title === "Vendor Employee"
                ? columnVendorEmployee
                : columns
            }
            data={data}
            handleDownloadExcel={handleDownloadExcel}
            handleFilter={null}
            hiddenColumns={[]}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default DetailChart;
