import { useEffect, useMemo } from "react";
import { useState } from "react";
import { getListCostVariable } from "../../../data-api/cost-to-company";
import ReactTable from "../../../utils/ReactTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import ModalImportVar from "../modal/ModalImportVar";
import ModalMessage from "../../template/ModalMessage";
import writeXlsxFile from "write-excel-file";

function TabVariable(props) {
  const [data, setData] = useState([]);
  const [showImport, setShowImport] = useState(false);
  function handleCloseImport() {
    setShowImport(false);
  }

  const [modalSuccessData, setModalSuccessData] = useState({});
  const [modalSuccess, setModalSuccess] = useState(false);
  function handleCloseModalSuccess() {
    fetchCostVar();
    setModalSuccess(false);
  }
  function handleOpenModalSuccess(data) {
    fetchCostVar();
    setShowImport(false);
    setModalSuccessData(data);
    setModalSuccess(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Variable Name",
        accessor: "variable_name", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Value",
        accessor: "value",
      },
    ],
    []
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCostVar();
  }, []);

  function fetchCostVar() {
    setIsLoading(true);
    getListCostVariable().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  function handleDownloadExcel() {
    const dataSet = [];
    const dataHeader = [];
    columns.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: typeof item === "string" ? String : Number,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Cost_Variable",
    });
  }

  return (
    <>
      <div className="tabs-content">
        <div className="row custom-text-14 w-100 ms-1 mb-3">
          <div className="col-4"></div>
          <div className="col-8 d-flex justify-content-end">
            <button
              className="btn btn-danger btn-sm ms-3 col-3 custom-text-14"
              onClick={() => setShowImport(true)}
              disabled={
                !props.accessMenu.is_read || !props.accessMenu.is_update
              }
            >
              <FontAwesomeIcon
                icon={faFileImport}
                style={{ marginRight: "10px" }}
              />
              Import
            </button>
          </div>
        </div>

        <ReactTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>

      <Modal
        show={showImport}
        onHide={handleCloseImport}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalImportVar handleSuccessModal={handleOpenModalSuccess} />
      </Modal>

      {modalSuccess ? (
        <ModalMessage data={modalSuccessData} click={handleCloseModalSuccess} />
      ) : null}
    </>
  );
}

export default TabVariable;
