import { Form, Modal } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import {
  getEditDataProjectTeam,
  putProjectTeam,
} from "../../../data-api/project-team";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { InvalidInput } from "../../../utils/ReactSelect";
import LoadingEffectForm from "../../template/loadingEffectForm";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { FormatDate } from "../../template/format";
registerLocale("en-GB", enGb);

function ModalEditProject(props) {
  const index = "0";
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));

  const [project, setProject] = useState([]);
  const [resource, setResource] = useState([]);
  const [category, setCategory] = useState("");
  const [projectName, setProjectName] = useState("");

  const [isFetch, setIsFetch] = useState(false);

  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  useEffect(() => {
    getEditDataProjectTeam().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        if (res.data === null) {
          setProject([]);
          setResource([]);
        } else {
          setProject(res.data[0].data);
          setResource(res.data[1].data);
          if (props.data.type === "1") {
            setStartDate(
              new Date(
                res.data[0].data[index].project_start !== ""
                  ? res.data[0].data[index].project_start
                  : Date.now()
              )
            );
            setEndDate(
              new Date(
                res.data[0].data[index].project_end !== ""
                  ? res.data[0].data[index].project_end
                  : Date.now()
              )
            );
          }
        }
      } else {
        showAlertError(false, res.meta.message);
        setProject([]);
        setResource([]);
      }
      setIsLoading(false);
    });
  }, []);

  const [editConfirm, setEditConfirm] = useState(false);

  const handleEditingProject = () => {
    if (!editConfirm) {
      setEditConfirm(true);
    } else {
      setIsFetch(true);
      EditProjectTeam();
      setIsFetch(false);
    }
  };

  const EditProjectTeam = () => {
    const data = {
      nik: props.data.nik,
      mst_data_client_id: projectName.value,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      type: category.value,
      created_by: "From React",
    };

    putProjectTeam(props.data.nik, data).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message = {
        code: res.meta.code,
        message: res.meta.message,
      };
      props.message(message);
      props.close();
      setIsFetch(false);
    });
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(true);
  const [AlertMessage, setAlertMessage] = useState("");

  const showAlertError = (success, message) => {
    setShowAlert(true);
    setAlertSuccess(success);
    setAlertMessage(message);
  };

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffectForm row={5} />
        </Modal.Body>
      </>
    );

  return (
    <>
      {showAlert ? (
        <div
          className={
            AlertSuccess
              ? "alert alert-success alert-dismissible fade show"
              : "alert alert-danger alert-dismissible fade show"
          }
          role="alert"
        >
          <strong>{AlertMessage}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title>
          {!editConfirm ? "Add Project Team" : "Confirm Add Project Team"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {!editConfirm ? (
            // Form Add
            <Form onSubmit={handleEditingProject} className="custom-text-14">
              <Form.Group className="mb-3 fw-semibold justify-content-between">
                <Form.Label className="mt-2">Name</Form.Label>
                <Form.Control
                  className="form-control-sm"
                  type="text"
                  value={props.data.name}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold justify-content-between">
                <Form.Label className="mt-2">Category</Form.Label>
                <Select
                  className="w-100 fw-normal custom-text-14 z-3"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={[
                    {
                      value: "1",
                      label: "Project",
                    },
                    {
                      value: "2",
                      label: "Resource",
                    },
                  ]}
                  isClearable
                  placeholder={"-- Select Category --"}
                  defaultValue={category}
                  onChange={(e) => {
                    if (e !== null) {
                      setCategory(e);
                    }
                  }}
                  ariaLiveMessages="Please select Category"
                  components={{
                    Input: invalidInput,
                  }}
                ></Select>
              </Form.Group>
              {/* Project */}
              {category.value === "1" ? (
                <>
                  <Form.Group className="mb-3 fw-semibold justify-content-between">
                    <Form.Label className="mt-2">Project Name</Form.Label>
                    <Select
                      className="w-100 fw-normal custom-text-14 z-3"
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      options={project.map((data) => {
                        return {
                          value: data.id,
                          label: `${data.project_name} - ${data.client_name}`,
                          project_start: data.project_start,
                          project_end: data.project_end,
                        };
                      })}
                      isClearable
                      placeholder={"-- Select Project --"}
                      defaultValue={projectName}
                      onChange={(e) => {
                        if (e !== null) {
                          setProjectName(e);
                        }
                      }}
                      ariaLiveMessages="Please select Project"
                      components={{
                        Input: invalidInput,
                      }}
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold justify-content-between">
                    <Form.Label className="mt-2">Start Project</Form.Label>
                    <Form.Control
                      className="form-control-sm"
                      type="text"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold justify-content-between">
                    <Form.Label className="mt-2">End Project</Form.Label>
                    <Form.Control
                      className="form-control-sm"
                      type="text"
                      disabled
                    />
                  </Form.Group>
                </>
              ) : (
                // Resource
                <>
                  <Form.Group className="mb-3 fw-semibold justify-content-between">
                    <Form.Label className="mt-2">Project Name</Form.Label>
                    <Select
                      className="w-100 fw-normal custom-text-14 z-3"
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      options={resource.map((data) => {
                        return {
                          value: data.id,
                          label: `${data.project_name} - ${data.client_name}`,
                        };
                      })}
                      isClearable
                      placeholder={"-- Select Project --"}
                      defaultValue={projectName}
                      onChange={(e) => {
                        if (e !== null) {
                          setProjectName(e);
                        }
                      }}
                      ariaLiveMessages="Please select Project"
                      components={{
                        Input: invalidInput,
                      }}
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Start Project
                    </Form.Label>
                    <div className="w-100">
                      <ReactDatePicker
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(value) => {
                          setStartDate(value);
                        }}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please fill out Start Date"
                          )
                        }
                        maxDate={endDate}
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      End Project
                    </Form.Label>
                    <div className="w-100">
                      <ReactDatePicker
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(value) => {
                          setEndDate(value);
                        }}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Please fill out End Date")
                        }
                        minDate={startDate}
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                  </Form.Group>
                </>
              )}
              <div className="d-flex flex-row-reverse bd-highlight">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm">
                  Confirm
                </button>
              </div>
            </Form>
          ) : (
            // modal confirm
            <>
              <div className="container custom-text-14">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Name</Form.Label>
                  <Form.Label className="mt-2">{props.data.name}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Category</Form.Label>
                  <Form.Label className="mt-2">{category.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Project Name</Form.Label>
                  <Form.Label className="mt-2">
                    {projectName.label}
                    {/* {category.value === "1"
                ? project[index].project_name +
                  " - " +
                  project[index].client_name
                : resource[index].project_name +
                  " - " +
                  resource[index].client_name} */}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Project</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(startDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Project</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(endDate)}
                  </Form.Label>
                </Form.Group>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={handleEditingProject}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={() => setEditConfirm(false)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalEditProject;
