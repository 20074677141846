import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import {
  getListDropdownTicket,
  postTicketing,
} from "../../../data-api/helpdesk";
import ModalMessage from "../../template/ModalMessage";
import { faCircleXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOutletContext } from "react-router-dom";
import { ReactSelect } from "../../../utils/ReactSelect";

function ModalNewTicket(props) {
  const [types, setTypes] = useState([]);
  const [sections, setSections] = useState([]);

  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // untuk menyimpan value Title modal
  const [modalTitle, setModalTitle] = useState("");

  const [isFetch, setIsFetch] = useState(false);

  // untuk menyimpan value code dan message setelah create data baru
  const [modalMessage, setModalMessage] = useState([]);

  const [Msg, setMsg] = useState("");

  const outletContext = useOutletContext();

  useEffect(() => {
    refreshDataTicket();
  }, []);

  const refreshDataTicket = async () => {
    setModalTitle(props.data.title);
    getListDropdownTicket().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code !== 200) {
        setMsg(result.meta.message);
        return;
      }

      setTypes(result.data.types);
      setSections(result.data.sections);
      // setIsLoading(false)
    });
    handleShow();
    // setIsLoading(true)
  };

  const [type, setType] = useState("");
  const [section, setSection] = useState("");

  const sampleMarkup = `<p>Dear Team Helpdesk,<br/><br/><br/><br/>Regards,<br/>${outletContext.profile.employee_name}`;

  const blocksFromHTML = convertFromHTML(sampleMarkup);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const template = EditorState.createWithContent(state);

  const [editorState, setEditorState] = useState(() => template);

  const [fileList, setFileList] = useState([]);

  const onFileChange = (e) => {
    let cek = false;
    let data = [];
    for (let index = 0; index < e.target.files.length; index++) {
      if (
        e.target.files[index].type !== "application/pdf" &&
        e.target.files[index].type !== "image/jpeg" &&
        e.target.files[index].type !== "image/png"
      ) {
        setMsg("Please upload with PDF/JPG/JPEG/PNG format");
        cek = true;
        break;
      } else if (e.target.files[index].size / 1048576 > 5) {
        setMsg("Please upload with size under 5 MB");
        cek = true;
        break;
      } else {
        data = [...data.slice(0, index), e.target.files[index]];
      }
    }
    if (!cek) {
      setFileList(data);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    // const updatedList = [...fileList];
    // updatedList.splice(fileList.indexOf(file), 1);
    // setFileList(updatedList);
  };

  const handleSendTicketing = async (e) => {
    setIsFetch(true);
    e.preventDefault();
    await setModalMessage("");
    const formData = new FormData();

    formData.append("user_request", outletContext.profile.employee_email);
    formData.append("type", type.value);
    formData.append("section", section.value);

    var message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    var newmessage = message.trim();
    if (newmessage === `<p></p>`) {
      message = "";
    }

    formData.append("message", message);

    if (fileList.length > 0) {
      fileList.map((data, index) => {
        formData.append(`file`, data);
      });
    }

    const resp = await postTicketing(formData);
    if (resp.meta.code === 401) {
      window.location.reload();
      return;
    }
    const msg = {
      code: resp.meta.code,
      message: resp.meta.message,
    };
    handleClose();
    setModalMessage(msg);
    setIsFetch(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {Msg === "" ? null : (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <strong>{Msg}</strong>
          </Alert>
        )}
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-text-14">
          <Form onSubmit={handleSendTicketing}>
            <Row>
              <Col>
                <Form.Label className="fw-semibold">Type</Form.Label>
              </Col>
              <Col xs={10}>
                <ReactSelect
                  value={type}
                  setValue={setType}
                  data={types}
                  isLoading={false}
                  placeholder={"-- Select Type --"}
                  validationMessage={"Please Select Type"}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label className="fw-semibold">Section</Form.Label>
              </Col>
              <Col xs={10}>
                <ReactSelect
                  value={section}
                  setValue={setSection}
                  data={sections}
                  isLoading={false}
                  placeholder={"-- Select Section --"}
                  validationMessage={"Please Select Section"}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label className="fw-semibold">Message</Form.Label>
              </Col>
              <Col xs={10}>
                <div
                  style={{ textAlign: "left" }}
                  className="container card px-10 py-2 fs-24"
                >
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e) => setEditorState(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label className="fw-semibold">Attacment</Form.Label>
              </Col>
              <Col xs={8}>
                <div className="d-flex justify-content-start w-75">
                  <label
                    for="file"
                    className="btn btn-outline-danger w-25 btn-sm"
                  >
                    Browse
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    className="form-control form-control-sm"
                    hidden
                    onChange={(e) => onFileChange(e)}
                    multiple
                  />
                </div>
                {fileList.length > 0
                  ? fileList.map((item, index) => (
                      <>
                        <Form.Label className="mt-2 px-2">
                          {item.name} /{" "}
                          {Math.round((item.size / 1024) * 1000) / 1000} KB
                        </Form.Label>
                        <span
                          className="btn btn-danger btn-circle btn-sm"
                          onClick={() => fileRemove(item)}
                        >
                          <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                        </span>
                      </>
                    ))
                  : null}
              </Col>
              <Col></Col>
            </Row>
            <div className="d-flex flex-row-reverse bd-highlight">
              <button
                disabled={isFetch}
                className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              >
                {isFetch ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {modalMessage.message !== undefined ? (
        <ModalMessage
          data={modalMessage}
          // click={props.click}
          click={() => {
            setModalMessage("");
            window.location.reload();
          }}
        />
      ) : null}
    </>
  );
}

export default ModalNewTicket;
