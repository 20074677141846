import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getProjectTeam = async () => {
  const link = `${process.env.REACT_APP_API_URL}/project-team`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getHistoryProjectTeam = async (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/project-team/${nik}`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEditDataProjectTeam = async () => {
  const link = `${process.env.REACT_APP_API_URL}/project-team/edit-data`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putProjectTeam = async (nik, data) => {
  const link = `${process.env.REACT_APP_API_URL}/project-team/${nik}`;
  return axios
    .put(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putProjectTeamDetail = async (id, data) => {
  const link = `${process.env.REACT_APP_API_URL}/project-team/detail/${id}`;
  return axios
    .put(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
