import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getContract = async (type, start, end) => {
  const url = `${process.env.REACT_APP_API_URL}/contracts?type=${type}`;
  return axios
    .get(url, {
      params: {
        start: start,
        end: end,
      },
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getHistoryContractByNik = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/contract/history/${nik}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getContractById = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/contract/${id}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListDropdownPkwt = async () => {
  const url = `${process.env.REACT_APP_API_URL}/contract/list-dropdown`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postContract = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/contract`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putContract = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/contract/${id}`;
  return axios
    .put(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postContractImport = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/contract/import`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error;
    });
};

export const putContractimport = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/contract/import`;
  return axios
    .put(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
