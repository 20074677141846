import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TabPSManager from "./tabs/TabPSManager";
import TabTLStructure from "./tabs/TabTLStructure";
import TabTeamLeader from "./tabs/TabTeamLeader";
import TabCaptain from "./tabs/TabCaptain";
import TabUnsyncrone from "./tabs/TabUnsyncrone";
import { useOutletContext } from "react-router-dom";

function TlStructure() {
  const [tabKey, setTabKey] = useState(1);
  function handleSelect(key) {
    setTabKey(key);
  }

  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "TL Structure"
  ).sub_menu;

  const tlStructureAccess = access.find(
    (data) => data.sub_menu === "TL Structure"
  );

  const psManagerAccess = access.find((data) => data.sub_menu === "PS Manager");

  const tlAccess = access.find((data) => data.sub_menu === "Team Leader");

  const captainAccess = access.find((data) => data.sub_menu === "Captain");

  const unsynAccess = access.find((data) => data.sub_menu === "Unsyncrone");

  return (
    <>
      <div className="w-100" style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey={tabKey}
          id="uncontrolled-tab-example"
          onSelect={(e) => handleSelect(e)}
        >
          {tlStructureAccess.is_read ? (
            <Tab eventKey={1} title="TL Structure">
              <TabTLStructure tabKey={tabKey} accessMenu={tlStructureAccess} />
            </Tab>
          ) : null}

          {psManagerAccess.is_read ? (
            <Tab eventKey={2} title="PS Manager">
              <TabPSManager tabKey={tabKey} accessMenu={psManagerAccess} />
            </Tab>
          ) : null}

          {tlAccess.is_read ? (
            <Tab eventKey={3} title="Team Leader">
              <TabTeamLeader tabKey={tabKey} accessMenu={tlAccess} />
            </Tab>
          ) : null}

          {captainAccess.is_read ? (
            <Tab eventKey={4} title="Captain">
              <TabCaptain tabKey={tabKey} accessMenu={captainAccess} />
            </Tab>
          ) : null}

          {unsynAccess.is_read ? (
            <Tab eventKey={5} title="Unsynchronized Data">
              <TabUnsyncrone
                // resetTable={handleLoading}
                tabKey={tabKey}
                accessMenu={unsynAccess}
              />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    </>
  );
}

export default TlStructure;
