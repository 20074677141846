import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getTalentPartnership = (params) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnerships`;
  return axios
    .get(link, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListDropdownTalentP = () => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/list-dropdown`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getGenerateNIK = () => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/generate-nik`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postTalentPartnership = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership`;
  return axios
    .post(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTalentPartnershipById = (id) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/${id}`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putTalentPartnership = (id, data) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/${id}`;
  return axios
    .put(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteTalentPartnership = (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/${nik}`;
  return axios
    .delete(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEmailPO = () => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/email-notification`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const setEmailPO = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/email-notification`;
  return axios
    .post(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTalentPartnershipDetail = (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/detail/${nik}`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postTalentPartnershipDetail = (data) => {
  const link = `${process.env.REACT_APP_API_URL}/talent-partnership/detail`;
  return axios
    .post(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
