import { Image, Modal } from "react-bootstrap";
import "./UserProfile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  faCircleCheck,
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChangeAvatarModal from "./ChangeAvatarModal";
import ModalMessage from "../template/ModalMessage";
import { axiosError } from "../../utils/util";

function UserProfile() {
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const [showModalAvatar, setShowModalAvatar] = useState(false);

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const [modalSuccessData, setModalSuccessData] = useState({});
  const [modalSuccess, setModalSuccess] = useState(false);
  function handleCloseModalSuccess() {
    setModalSuccess(false);
  }

  useEffect(() => {
    fetchUserDetail();
  }, []);

  function fetchUserDetail() {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${cookies.get("session")}`,
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setData(res.data.data);
        } else {
          setData({});
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setData({});
        setIsLoading(false);
        let errorResponse = axiosError(error);
        setModalSuccessData(errorResponse.meta);
      });
  }

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowModalAvatar(false);
    setShowSuccess(true);
  }

  if (isLoading)
    return (
      <>
        <div className="w-100">
          <div className="shine-photo shine"></div>
        </div>
      </>
    );

  return (
    <>
      <div className="container bg-white rounded p-4 mt-5 text-start profile-detail-container">
        <div className="profile-detail-title">My Profile</div>
        <div className="card d-flex flex-row p-4">
          <div className="me-5">
            <Image
              style={{ width: "6rem", float: "left" }}
              roundedCircle
              src={data.avatar_url}
            />
            <div>
              <FontAwesomeIcon
                className="border rounded-circle border-danger p-1 bg-white"
                icon={faEdit}
                style={{
                  color: "red",
                  float: "right",
                  marginTop: "-20px",
                  cursor: "pointer",
                }}
                onClick={() => setShowModalAvatar(true)}
              />
            </div>
          </div>
          <div className="d-flex flex-column text-start mt-2">
            <div className="p-2 profile-detail-content-item-header">
              {data.name}
            </div>
            <div className="p-2 profile-detail-content-item-body">
              {data.position === "" ? <span>&nbsp;</span> : data.position}
            </div>
            <div className="p-2 profile-detail-content-item-body">
              {data.department}
            </div>
          </div>
        </div>

        <div className="card text-start mt-4 p-3">
          <div className="profile-detail-subtitle">Personal Information</div>
          <div className="d-flex flex-row text-start">
            <div className="">
              <div className="d-flex flex-column">
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    First Name
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.name.split(" ")[0]}
                  </div>
                </div>
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    Email Address
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.email}
                  </div>
                </div>
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    Position
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.position === "" ? <span>&nbsp;</span> : data.position}
                  </div>
                </div>
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    Status
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.employment_status}
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-5">
              <div className="d-flex flex-column">
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    Last Name
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.name.split(" ").splice(1, 100).join(" ")}
                  </div>
                </div>
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">Phone</div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.phone_number}
                  </div>
                </div>
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    Department
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.department}
                  </div>
                </div>
                <div className="p-2 profile-detail-content-container">
                  <div className="p-2 profile-detail-content-header">
                    Contract
                  </div>
                  <div
                    className="p-2 profile-detail-content-body"
                    style={{ marginTop: "-20px" }}
                  >
                    {data.contract}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModalAvatar}
        onHide={() => setShowModalAvatar(false)}
        centered
      >
        <ChangeAvatarModal data={data} handleSuccess={handleSuccessModal} />
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3"
                onClick={() => {
                  setShowSuccess(false);
                  window.location.reload();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {modalSuccess ? (
        <ModalMessage data={modalSuccessData} click={handleCloseModalSuccess} />
      ) : null}
    </>
  );
}

export default UserProfile;
