import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getSpkApproveReject,
  getWaitingSpk,
  postSendEmail,
} from "../../../data-api/resource-team";
import { useOutletContext } from "react-router-dom";

function ModalEmailSpk(props) {
  const outletContext = useOutletContext();
  const title =
    props.title === "ps"
      ? "Send Email SPK for Approval"
      : props.title === "hc"
      ? "Send Email Approve/Reject to HC"
      : props.title === "talent"
      ? `Send Email ${props.data.spk_type} ${props.data.employee_name}`
      : "";
  const [emailTo, setEmailTo] = useState(
    props.title === "ps"
      ? ["erwin.hardijanto@idstar.group", "indah.affandi@idstar.group"]
      : props.title === "hc"
      ? ["hrhelpdesk@idstar.group"]
      : props.title === "talent"
      ? props.data.email === ""
        ? []
        : [props.data.email]
      : []
  );
  const [emailCc, setEmailCc] = useState(
    props.title === "talent"
      ? [
          "integralsteam@idstar.co.id",
          "professional.services@idstar.co.id",
          " hrhelpdesk@idstar.group",
          outletContext.profile.employee_email,
        ]
      : ["integralsteam@idstar.co.id", outletContext.profile.employee_email]
  );
  const [emailSubject, setEmailSubject] = useState(
    props.title === "ps"
      ? "SPK Waiting Approval"
      : props.title === "hc"
      ? "Approved and Rejected SPK"
      : props.title === "talent"
      ? `${props.data.spk_type} ${props.data.employee_name}`
      : ""
  );

  const [emailBody, setEmailBody] = useState(`
    Dear Pak Erwin / Bu Anti,
    <br>
    Berikut data SPK yang menunggu untuk di approve :

    <table border=1 border-collapse="collapse">
    <tr>
        <td width="70px">NIK </td>
        <td width="250px">Name </td>
        <td width="250px">Position </td>
        <td>Client</td><td>Type</td>
        <td  width="70px">Status</td>
        <td  width="100px">Start Date</td>
        <td  width="100px">End Date</td>
        <td width="100px">Link PDF</td>
    </tr>
    </table>
    <br>

    Terimakasih.
    <br>
    Regards, <br>
    `);

  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    if (props.title === "ps") {
      getWaitingSpk().then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        setEmailBody(
          `
                    Dear Pak Erwin / Bu Anti,
                    <br>
                    Berikut data SPK yang menunggu untuk di approve :

                    <table border=1 border-collapse="collapse">
                    <tr>
                        <td width="70px">NIK </td>
                        <td width="250px">Name </td>
                        <td width="250px">Position </td>
                        <td>Client</td>
                        <td>Type</td>
                        <td  width="70px">Status</td>
                        <td  width="100px">Start Date</td>
                        <td  width="100px">End Date</td>
                        <td width="100px">Link PDF</td>
                    </tr>
                    ` +
            result.data
              .map((row) => {
                return `<tr><td>
                  ${row.nik}
                  </td><td>
                  ${row.employee_name}
                  </td><td>
                  ${row.position}
                  </td><td>
                  ${row.placement}
                  </td><td>
                  ${row.spk_type}
                  </td><td>Waiting Approval</td><td>
                  ${row.start_date}
                  </td><td>
                  ${row.end_date}
                  </td><td><a href='
                  ${row.pdf}?${Date.now()}'>
                  ${row.pdf === "" ? "" : "View PDF"}
                  </a></td></tr>`;
              })
              .join(" ") +
            `</table>
                    <br>

                    Terimakasih.
                    <br>
                    Regards, <br>
                    ${outletContext.profile.employee_name}
                    <br><br>
                    `
        );
      });
    } else if (props.title === "hc") {
      getSpkApproveReject().then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        setEmailBody(
          `
                    Dear Tim HC,
                    <br><br>
                    Berikut data SPK yang telah di Approve / Reject :
                    <br><br>
                    <table border=1 border-collapse="collapse">
                    <tr><td width="70px">NIK </td><td width="250px">Name </td><td>Client</td><td>Type</td><td width="100px">Start Date</td><td width="100px">End Date</td><td  width="70px">Status</td><td>Reject Reason</td><td>Approval/Reject Date</td><td width="100px">Link PDF</td></tr>
                    ` +
            result.data
              .map((row) => {
                return `<tr><td>
                  ${row.nik}
                  </td><td>
                  ${row.employee_name}
                  </td><td>
                  ${row.placement}
                  </td><td>
                  ${row.spk_type}
                  </td><td>
                  ${row.start_date}
                  </td><td>
                  ${row.end_date}
                  </td><td>
                  ${row.spk_status}
                  </td><td>
                  ${row.reject_reason}
                  </td><td>
                  ${row.modified_date}
                  </td>
                  <td><a href='
                  ${row.pdf_url}?${Date.now()}
                  '>
                  ${row.pdf_url === "" ? "" : "View PDF"}
                  </a></td>
                  </tr>`;
              })
              .join(" ") +
            `</table>
                    <br>

                    Terimakasih.
                    <br>
                    Regards, <br>
                    ${outletContext.profile.employee_name}
                    <br><br>
                    `
        );
        // }
      });
    } else if (props.title === "talent") {
      setEmailBody(
        `
                Dear ${props.data.employee_name},
                <br><br>
                Berikut data ${props.data.spk_type} yang telah disetujui :
                <br><br>
                <table border=1 border-collapse="collapse">
                <tr><td width="70px">NIK </td><td width="250px">Name </td><td width="250px">Position </td><td>Client</td><td>Type</td><td  width="100px">Start Date</td><td  width="100px">End Date</td><td  width="70px">Status</td><td width="100px">Link PDF</td></tr>
                <tr>
                <td>${props.data.nik}</td>
                <td>${props.data.employee_name}</td>
                <td>${props.data.position}</td>
                <td>${props.data.placement}</td>
                <td>${props.data.spk_type}</td>
                <td>${props.data.start_date}</td>
                <td>${props.data.end_date}</td>
                <td>Approved</td>
                <td><a href='${props.data.pdf}?${Date.now()}'> ${
          props.data.pdf === "" ? "" : "View PDF"
        }</a></td>
                </tr> 
                </table>
                <br>
                Silahkan dipergunakan sebagai syarat untuk bekerja di Client. <br><br>
                Terimakasih.<br>
                <br>
                Regards, <br>
                ${outletContext.profile.employee_name}
                <br><br>
                `
      );
    }
  }, []);

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");
  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  function sendEmail(event) {
    if (emailTo.length === 0) {
      showAlertError(false, "Form To cant be empty");
    }
    event.preventDefault();
    const json = JSON.stringify({
      to: emailTo,
      cc: emailCc,
      subject: emailSubject,
      message: emailBody,
    });

    setIsFetch(true);
    postSendEmail(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      setIsFetch(false);
      if (result.meta.code === 200) {
        props.alert(true, "The email has been sent successfully.");
        props.closeModal();
      } else {
        props.alert(false, result.meta.message);
        props.closeModal();
      }
    });
  }

  function addEmailTo(e) {
    if (
      e.key === " " ||
      e.code === "Space" ||
      e.keyCode === 32 ||
      e.code === "Enter"
    ) {
      var email = e.target.value;
      var validRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.toLowerCase().match(validRegex)) {
        setEmailTo((emailTo) => [...emailTo, email]);
        e.target.value = "";
      } else {
        showAlertError(false, email + " invalid email address");
      }
    }
  }

  function removeEmailTo(email) {
    setEmailTo(emailTo.filter((item) => item !== email));
  }

  function addEmailCc(e) {
    if (
      e.key === " " ||
      e.code === "Space" ||
      e.keyCode === 32 ||
      e.code === "Enter"
    ) {
      var email = e.target.value;
      var validRegex =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (email.match(validRegex)) {
        setEmailCc((emailCc) => [...emailCc, email]);
        e.target.value = "";
      } else {
        showAlertError(false, email + " invalid email address");
      }
    }
  }

  function removeEmailCc(email) {
    setEmailCc(emailCc.filter((item) => item !== email));
  }

  return (
    <>
      <div className="container custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlert ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessage}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
          ) : null}
          <div className="container">
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2 col-1">To</Form.Label>
              <div
                className="d-flex flex-wrap col-11"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "7px",
                }}
              >
                {emailTo.length !== 0
                  ? emailTo.map((email, key) => (
                      <>
                        <div
                          className="card d-flex flex-row p-1 m-1 custom-text-14"
                          key={key}
                        >
                          <div className="me-2 fw-normal">{email}</div>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rounded-circle my-auto"
                            style={{
                              padding: "0",
                              margin: "0",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={(e) => removeEmailTo(email)}
                          >
                            <p style={{ marginTop: "-3px" }}>x</p>
                          </button>
                        </div>
                      </>
                    ))
                  : null}
                <div className="bd-highlight m-2 flex-fill">
                  <Form.Control
                    style={{ border: "0" }}
                    className="form-control-sm"
                    onKeyPress={(e) => {
                      addEmailTo(e);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2 col-1">CC</Form.Label>
              <div
                className="d-flex flex-wrap col-11"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "7px",
                }}
              >
                {emailCc.length !== 0
                  ? emailCc.map((email, key) => (
                      <>
                        <div
                          className="card d-flex flex-row p-1 m-1 custom-text-14"
                          key={key}
                        >
                          <div className="me-2 fw-normal">{email}</div>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rounded-circle my-auto"
                            style={{
                              padding: "0",
                              margin: "0",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={(e) => removeEmailCc(email)}
                          >
                            <p style={{ marginTop: "-2px" }}>x</p>
                          </button>
                        </div>
                      </>
                    ))
                  : null}
                <div className="bd-highlight m-2 flex-fill">
                  <Form.Control
                    style={{ border: "0" }}
                    className="form-control-sm"
                    onKeyPress={(e) => {
                      addEmailCc(e);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2 col-1">Subject</Form.Label>
              <div className="col-11">
                <Form.Control
                  // style={{ width: "90%" }}
                  className="form-control-sm"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setEmailSubject(e.target.value)}
                  defaultValue={emailSubject}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="mt-2 col-1">Message</Form.Label>
              {/* <Form.Control
                as="textarea" rows={10}
                style={{width:"90%"}}
                aria-describedby="basic-addon1"
                onChange={(e) => setEmailMessageo(e.target.value)}
                /> */}
              <Editor
                className="form-control-sm w-100 custom-text-14"
                apiKey="rzu0yah0mou5ula2bois6fdgq2lxo1y70qzxk03vowfvgb2s"
                value={emailBody}
                style={{ width: "100%" }}
                init={{
                  width: 950,
                  height: 300,
                  menubar: false,
                }}
                onEditorChange={setEmailBody}
              />
            </Form.Group>
            <div className="d-flex justify-content-end mt-1">
              <button
                className="btn btn-danger btn-sm col-2 mt-1 mb-1"
                onClick={sendEmail}
                disabled={isFetch}
              >
                {isFetch ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalEmailSpk;
