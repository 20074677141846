import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { putApproveSpk } from "../../../data-api/resource-team";
import { ReactSelect } from "../../../utils/ReactSelect";
import ModalShowPDF from "../../../utils/ModalShowPDF";

function ModalApproveSpk(props) {
  const formData = props.data;
  const [approve, setApprove] = useState("");
  const [reason, setReason] = useState(" ");
  const [approveSuccess, setApproveSuccess] = useState({});
  const [approveConfirm, setApproveConfirm] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const outletContext = useOutletContext();

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  //submit button onClick event
  function formApproveSpk(event) {
    event.preventDefault();
    if (!approveConfirm) {
      setApproveConfirm(true);
    } else {
      setIsFetch(true);
      fetchApproveSpk();
    }
  }

  //send json to backend
  function fetchApproveSpk() {
    const json = JSON.stringify({
      spk_id: formData.id_spk,
      status: approve.value,
      reject_reason: reason === "" ? " " : reason,
      approve_by: outletContext.profile.nik,
    });
    putApproveSpk(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setApproveSuccess(result);
        props.approveStatus(false);
      } else {
        setApproveSuccess(result);
        props.approveStatus(true);
      }
      setIsFetch(false);
    });
  }

  return (
    <>
      <div className="custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>Edit SPK (Waiting Approval)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => formApproveSpk(e)}>
            {Object.keys(approveSuccess).length === 0 && !approveConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Name</Form.Label>
                  <Form.Label className="mt-2">{formData.name}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Client</Form.Label>
                  <Form.Label className="mt-2">{formData.client}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2">
                    {moment(formData.start_date).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2">
                    {moment(formData.end_date).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">SPK Type</Form.Label>
                  <Form.Label className="mt-2">{formData.spk_type}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Status</Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={approve}
                      setValue={setApprove}
                      data={[
                        { value: "true", label: "Approve" },
                        { value: "false", label: "Reject" },
                      ]}
                      isLoading={false}
                      placeholder={"-- Select Approve / Reject --"}
                      validationMessage={"Please Select Approve / Reject"}
                    />
                  </div>
                </Form.Group>
                {approve.value === "false" ? (
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Reason</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setReason(e.target.value)}
                      required
                    />
                  </Form.Group>
                ) : null}
              </>
            ) : Object.keys(approveSuccess).length === 0 && approveConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Name</Form.Label>
                  <Form.Label className="mt-2">{formData.name}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Client</Form.Label>
                  <Form.Label className="mt-2">{formData.client}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2">
                    {moment(formData.start_date).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2">
                    {moment(formData.end_date).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">SPK Type</Form.Label>
                  <Form.Label className="mt-2">{formData.spk_type}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Status</Form.Label>
                  <Form.Label className="mt-2">{approve.label}</Form.Label>
                </Form.Group>
                {approve.value === "false" ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Reason</Form.Label>
                      <Form.Label className="mt-2">{reason}</Form.Label>
                    </Form.Group>
                  </>
                ) : null}
              </>
            ) : Object.keys(approveSuccess).length !== 0 &&
              approveSuccess.meta.status === "success" ? (
              <div className="container d-flex flex-column py-4">
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
                <button
                  style={{
                    padding: "0",
                    border: "none",
                    background: "none",
                  }}
                  className="m-auto mt-4"
                  href={`#`}
                  onClick={() =>
                    handleShowPDF(`${approveSuccess.data.pdf}?${Date.now()}`)
                  }
                >
                  <input
                    className="btn btn-success"
                    type="button"
                    value="View PDF"
                  />
                </button>
              </div>
            ) : Object.keys(approveSuccess).length !== 0 &&
              approveSuccess.meta.status !== "success" ? (
              <div className="container d-flex flex-column">
                <FontAwesomeIcon icon={faCircleXmark} />
              </div>
            ) : null}

            {Object.keys(approveSuccess).length === 0 ? (
              <div className="d-flex flex-row-reverse bd-highlight">
                <button
                  className="btn btn-danger btn-sm mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5"
                  disabled={isFetch}
                >
                  {isFetch ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : approveConfirm ? (
                    approve.value === "false" ? (
                      "Reject"
                    ) : (
                      "Approve"
                    )
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : null}
          </Form>
        </Modal.Body>
      </div>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default ModalApproveSpk;
