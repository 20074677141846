import React from "react";
import TableEmployee from "./table/TableEmployee";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Tabs } from "react-bootstrap";
import TableUnsyncrone from "./table/TableUnsyncrone";
import { useOutletContext } from "react-router-dom";

function Employee() {
  const outletContext = useOutletContext();
  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Employee"
  ).sub_menu;
  const employeePermission = access.find(
    (data) => data.sub_menu === "Employee"
  );
  const employeeUnsyncronePermission = access.find(
    (data) => data.sub_menu === "Employee Unsyncrone"
  );

  return (
    <div className="w-100">
      <Tabs defaultActiveKey="employee-tab" id="uncontrolled-tab-example">
        {/* check permission employee */}
        {employeePermission.is_read ? (
          <Tab eventKey="employee-tab" title="Employee">
            <TableEmployee accessMenu={access.sub_menu} />
          </Tab>
        ) : null}
        {/* check permission employee unsyncrone */}
        {employeeUnsyncronePermission.is_read ? (
          <Tab eventKey="unsyncrone-tab" title="Unsynchronized Data">
            <TableUnsyncrone />
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
}
export default Employee;
