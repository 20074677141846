import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

function SideNavBar(props) {
  const [menu, setMenu] = useState([]);
  const [expandSubMenu, setExpandSubMenu] = useState(false);
  const [masterUserAccess, setMasterUserAccess] = useState();

  const location = useLocation();
  const locationPath = location.pathname.split("/");

  useEffect(() => {
    if (props.menu.length !== 0) {
      listMenu(props.menu);
      setMasterUserAccess(
        props.menu.find((item) => item.menu_path === "/master-user").sub_menu
      );
    }
  }, []);

  function listMenu(data) {
    const updatedMenu = [...menu];
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        let icon = "";

        if (data[i].menu === "Dashboard") {
          icon = "dashboard-logo.png";
        } else if (data[i].menu === "Employee") {
          icon = "employee-logo.png";
        } else if (data[i].menu === "Idle") {
          icon = "idle-logo.png";
        } else if (data[i].menu === "Resign") {
          icon = "resign-logo.png";
        } else if (data[i].menu === "Resource Team") {
          icon = "resource-team-logo.png";
        } else if (data[i].menu === "Project Team") {
          icon = "project-team-logo.png";
        } else if (data[i].menu === "Internal Contract") {
          icon = "internal-contract-logo.png";
        } else if (data[i].menu === "Vendor Employee") {
          icon = "talent-partnership-logo.png";
        } else if (data[i].menu === "TL Structure") {
          icon = "tl-structure-logo.png";
        } else if (data[i].menu === "Talent Review") {
          icon = "talent-review-logo.png";
        } else if (data[i].menu === "Timesheet") {
          icon = "timesheet-logo.png";
        } else if (data[i].menu === "Master Data Client") {
          icon = "master-clients-logo.png";
        } else if (data[i].menu === "Cost To Company") {
          icon = "ctc-icon.png";
        } else if (data[i].menu === "Helpdesk") {
          icon = "helpdesk-logo.png";
        } else if (data[i].menu === "Master User") {
          icon = "superadmin-logo.png";
        }

        if (data[i].menu_active && data[i].access) {
          let newMenu = {
            title: data[i].menu,
            menu_path: data[i].menu_path,
            icon: icon,
          };
          updatedMenu[i] = newMenu;
        }
      }
    }
    setMenu(updatedMenu);
  }

  return (
    <div
      className={
        props.isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
    >
      <div className="sidebar-content nav-upper">
        <div className={props.isExpanded ? "nav-logo" : "nav-logo nav-logo-NX"}>
          <img src="/integrals-logo.png" alt="logo" />
        </div>
        <div className={props.isExpanded ? "nav-menu" : "nav-menu nav-menu-NX"}>
          {menu.map((item, index) => {
            return (
              <>
                {item.menu_path !== "/master-user" ? (
                  <>
                    <NavLink
                      title={item.title}
                      key={index}
                      className={
                        props.isExpanded &&
                        locationPath[1] === item.menu_path.split("/")[1]
                          ? "menu-item menu-item-active"
                          : props.isExpanded &&
                            locationPath[1] !== item.menu_path.split("/")[1]
                          ? "menu-item"
                          : !props.isExpanded &&
                            locationPath[1] !== item.menu_path.split("/")[1]
                          ? "menu-item menu-item-NX"
                          : "menu-item menu-item-NX menu-item-NX-active"
                      }
                      to={item.menu_path}
                      state={{ title: item.title }}
                    >
                      <div style={{ width: "18px" }}>
                        <img
                          src={"/icons/" + item.icon}
                          width={"18px"}
                          alt={""}
                        />
                      </div>
                      {props.isExpanded && (
                        <span className="sidenav-item-text">{item.title}</span>
                      )}
                    </NavLink>
                  </>
                ) : (
                  <>
                    <span
                      title={item.title}
                      key={index}
                      className={
                        props.isExpanded &&
                        locationPath[1] === item.menu_path.split("/")[1]
                          ? "menu-item menu-item-active"
                          : props.isExpanded &&
                            locationPath[1] !== item.menu_path.split("/")[1]
                          ? "menu-item"
                          : !props.isExpanded &&
                            locationPath[1] !== item.menu_path.split("/")[1]
                          ? "menu-item menu-item-NX"
                          : "menu-item menu-item-NX menu-item-NX-active"
                      }
                      onClick={(e) => {
                        if (expandSubMenu) {
                          setExpandSubMenu(false);
                        } else {
                          setExpandSubMenu(true);
                        }
                        if (!props.isExpanded) {
                          props.setExpendState(e);
                        }
                      }}
                      aria-current="page"
                    >
                      <div style={{ width: "25px" }}>
                        <img
                          src={"/icons/" + item.icon}
                          width={"25px"}
                          alt=""
                        />
                      </div>

                      {props.isExpanded && (
                        <span className="sidenav-item-text">Master User</span>
                      )}
                      {props.isExpanded ? (
                        <>
                          <div className="item-menu-dropdown-btn container text-end">
                            {expandSubMenu ? (
                              <>
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className="me-3"
                                />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon={faCaretLeft}
                                  className="me-3 pt-2"
                                />
                              </>
                            )}
                          </div>
                        </>
                      ) : null}
                    </span>
                    {props.isExpanded && expandSubMenu ? (
                      <>
                        <div
                          className="nav-sub-menu d-flex flex-column text-start collapse mt-2 ms-4"
                          id="submenu-superadmin"
                          style={{ borderLeft: "1px solid white" }}
                        >
                          {masterUserAccess.find(
                            (menu) => menu.sub_menu === "Superadmin User"
                          )?.is_read && (
                            <NavLink
                              title="SuperAdmin User"
                              className={
                                locationPath[1] === "master-user" &&
                                locationPath[2] === undefined
                                  ? "sub-menu-item sub-menu-item-active"
                                  : "sub-menu-item"
                              }
                              to={item.menu_path}
                              state={{ title: "SuperAdmin User" }}
                              aria-current
                            >
                              <span className="sub-menu-item-text">
                                SuperAdmin User
                              </span>
                            </NavLink>
                          )}

                          {masterUserAccess.find(
                            (menu) => menu.sub_menu === "Integrals Menu"
                          )?.is_read && (
                            <NavLink
                              title="Integrals Menu"
                              className={
                                locationPath[1] === "master-user" &&
                                locationPath[2] === "integrals-menu"
                                  ? "sub-menu-item sub-menu-item-active"
                                  : "sub-menu-item"
                              }
                              to={item.menu_path + "/integrals-menu"}
                              state={{ title: "Integrals Menu" }}
                            >
                              <span className="sub-menu-item-text">
                                Integrals Menu
                              </span>
                            </NavLink>
                          )}

                          {masterUserAccess.find(
                            (menu) => menu.sub_menu === "Integrals Role"
                          )?.is_read && (
                            <NavLink
                              title="Integrals Role"
                              className={
                                locationPath[1] === "master-user" &&
                                locationPath[2] === "integrals-role"
                                  ? "sub-menu-item sub-menu-item-active"
                                  : "sub-menu-item"
                              }
                              to={item.menu_path + "/integrals-role"}
                              state={{ title: "Integrals Role" }}
                            >
                              <span className="sub-menu-item-text">
                                Integrals Role
                              </span>
                            </NavLink>
                          )}

                          {masterUserAccess.find(
                            (menu) => menu.sub_menu === "Integrals User"
                          )?.is_read && (
                            <NavLink
                              title="Integrals User"
                              className={
                                locationPath[1] === "master-user" &&
                                locationPath[2] === "integrals-user"
                                  ? "sub-menu-item sub-menu-item-active"
                                  : "sub-menu-item"
                              }
                              to={item.menu_path + "/integrals-user"}
                              state={{ title: "Integrals User" }}
                            >
                              <span className="sub-menu-item-text">
                                Integrals User
                              </span>
                            </NavLink>
                          )}

                          {masterUserAccess.find(
                            (menu) => menu.sub_menu === "Approval User"
                          )?.is_read && (
                            <NavLink
                              title="Approval User"
                              className={
                                locationPath[1] === "master-user" &&
                                locationPath[2] === "approval-user"
                                  ? "sub-menu-item sub-menu-item-active"
                                  : "sub-menu-item"
                              }
                              to={item.menu_path + "/approval-user"}
                              state={{ title: "Approval User" }}
                            >
                              <span className="sub-menu-item-text">
                                Approval User
                              </span>
                            </NavLink>
                          )}

                          {masterUserAccess.find(
                            (menu) => menu.sub_menu === "Tickets"
                          )?.is_read && (
                            <NavLink
                              title="Tickets"
                              className={
                                locationPath[1] === "master-user" &&
                                locationPath[2] === "tickets"
                                  ? "sub-menu-item sub-menu-item-active"
                                  : "sub-menu-item"
                              }
                              to={item.menu_path + "/tickets"}
                              state={{ title: "SuperAdmin Tickets" }}
                            >
                              <span className="sub-menu-item-text">
                                Tickets
                              </span>
                            </NavLink>
                          )}
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
