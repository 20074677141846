import { Tab, Tabs } from "react-bootstrap";
import TabVariable from "./tabs/TabVariable";
import TabCalculate from "./tabs/TabCalculate";
import TabCTC from "./tabs/TabCTC";
import { useOutletContext } from "react-router-dom";
import TabSeachCTC from "./tabs/TabSearchCTC";
import TabInsurance from "./tabs/TabInsurance";

function CostToCompany(props) {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Cost To Company"
  ).sub_menu;

  const CostToCompanyAccess = access.find(
    (data) => data.sub_menu === "Cost to Company"
  );
  const CostCalculateAccess = access.find(
    (data) => data.sub_menu === "Cost Calculate"
  );
  const CostVariableAccess = access.find(
    (data) => data.sub_menu === "Cost Variable"
  );
  const CTCAccess = access.find((data) => data.sub_menu === "CTC");
  const CostInsuranceAccess = access.find(
    (data) => data.sub_menu === "Cost Insurance"
  );
  return (
    <>
      <div className="w-100">
        <Tabs defaultActiveKey="ctc-tab" id="uncontrolled-tab-example">
          {CostVariableAccess.is_read ? (
            <Tab eventKey="variable-tab" title="Variable">
              <TabVariable accessMenu={CostVariableAccess} />
            </Tab>
          ) : null}
          {CostInsuranceAccess.is_read ? (
            <Tab eventKey="cost-insurance-tab" title="Insurance">
              <TabInsurance accessMenu={CostInsuranceAccess} />
            </Tab>
          ) : null}
          {CostCalculateAccess.is_read ? (
            <Tab eventKey="calculate-tab" title="Calculate">
              <TabCalculate accessMenu={CostCalculateAccess} />
            </Tab>
          ) : null}
          {CostToCompanyAccess.is_read ? (
            <Tab eventKey="cost-to-company-tab" title="Cost To Company">
              <TabCTC accessMenu={CostToCompanyAccess} />
            </Tab>
          ) : null}
          {CTCAccess.is_read ? (
            <Tab eventKey="ctc-tab" title="CTC">
              <TabSeachCTC accessMenu={CostToCompanyAccess} />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    </>
  );
}

export default CostToCompany;
