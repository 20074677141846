import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import { axiosError } from "../../utils/util";

function ChangeAvatarModal(props) {
  const outletContext = useOutletContext();
  const [profileData, setProfileData] = useState(props.data);
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  useEffect(() => {
    fetchListAvatar();
  }, []);

  function fetchListAvatar() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/avatar`, {
        headers: {
          Authorization: `Bearer ${cookies.get("session")}`,
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setData(res.data.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        setData([]);
        let errorResponse = axiosError(error);
        props.handleSuccess(true, errorResponse.meta.message);
      });
  }

  function chooseAvatar(avatar_url) {
    let tempData = { ...profileData };
    tempData.avatar_url = avatar_url;
    setProfileData(tempData);
  }

  function updateAvatar() {
    let avatar = data.find((item) => item.url === profileData.avatar_url);
    const json = JSON.stringify({
      avatar_id: parseInt(avatar.id),
    });

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user/${outletContext.profile.user_id}/avatar`,
        json,
        {
          headers: {
            Authorization: `Bearer ${cookies.get("session")}`,
            XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
          },
        }
      )
      .then((res) => {
        props.handleSuccess(true, res.data.meta.message);
      })
      .catch((error) => {
        let errorResponse = axiosError(error);
        props.handleSuccess(true, errorResponse.meta.message);
      });
  }

  return (
    <>
      <Modal.Body>
        <div className="change-avatar-modal">
          <div className="ms-3 mt-3">
            <h5 className="fw-bold mb-4">Choose avatar from list ?</h5>
          </div>
          <div>
            <div className="d-flex align-content-start flex-wrap avatar-container">
              {data.map((item) => (
                <>
                  <div className="p-3">
                    <Image
                      className={
                        profileData.avatar_url === item.url
                          ? "avatar-item-active"
                          : "avatar-item"
                      }
                      style={{ width: "5rem" }}
                      roundedCircle
                      src={item.url}
                      onClick={() => chooseAvatar(item.url)}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="text-center my-3">
            <button className="btn btn-danger col-5" onClick={updateAvatar}>
              Apply
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default ChangeAvatarModal;
