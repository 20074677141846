import { Tab } from "bootstrap";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Tabs, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Employee.css";
import { getEmployeesExport } from "../../../data-api/employee";
import moment from "moment";
import ReactTable from "../../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";
import LoadingEffect from "../../template/loadingEffectTable";

function ModalExportDetail() {
  const [exportDetail, setExportDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columnsPersonal = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Employee Name",
        accessor: "employee_name",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Employee Status",
        accessor: "employee_status",
      },
      {
        Header: "Work Status",
        accessor: "work_status",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Email Office",
        accessor: "email_office",
      },
      {
        Header: "Department Name",
        accessor: "department_name",
      },
      {
        Header: "Function Name",
        accessor: "function_name",
      },
      {
        Header: "Position Name",
        accessor: "position_name",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Direct Report",
        accessor: "direct_report",
      },
      {
        Header: "Length of Work",
        accessor: "length_of_work",
      },
      {
        Header: "Bank ACC Number",
        accessor: "bank_acc_number",
      },
      {
        Header: "Bank ACC Name",
        accessor: "bank_acc_name",
      },
      {
        Header: "No BPJS Kesehatan",
        accessor: "no_bpjs_kesehatan",
      },
      {
        Header: "No BPJS Ketenagakerjaan",
        accessor: "no_bpjs_ketenagakerjaan",
      },
      {
        Header: "Url Talent Data",
        accessor: "url_talent_data",
      },
      {
        Header: "Birth Place",
        accessor: "birth_place",
      },
      {
        Header: "Birth Date",
        accessor: "birth_date",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Blood Type",
        accessor: "blood_type",
      },
      {
        Header: "Marital Tax Status",
        accessor: "marital_tax_status",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "KTP",
        accessor: "ktp_no",
      },
      {
        Header: "NPWP",
        accessor: "npwp_no",
      },
      {
        Header: "KK",
        accessor: "kk_no",
      },
      {
        Header: "Birth Mother",
        accessor: "birth_mother",
      },
      {
        Header: "KTP Address",
        accessor: " ktp_address",
      },
      {
        Header: "Domicile Address",
        accessor: "domicile_address",
      },
      {
        Header: "Domicile",
        accessor: "domicile",
      },
      {
        Header: "Religion",
        accessor: "religion",
      },
      {
        Header: "THR",
        accessor: "thr",
      },
      {
        Header: "Emergency Pic",
        accessor: "emergency_pic",
      },
      {
        Header: "Emergency Relation",
        accessor: "emergency_relation",
      },
      {
        Header: "Emergency No",
        accessor: "emergency_no",
      },
      {
        Header: "Last Education",
        accessor: "last_education",
      },
      {
        Header: "Educational Background",
        accessor: "educational_background",
      },
      {
        Header: "Educational Major",
        accessor: "educational_major",
      },
    ],
    []
  );

  const columnsPKWT = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },

      {
        Header: "Employee Name",
        accessor: "employee_name",
      },
      {
        Header: "Work Status",
        accessor: "work_status",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "No PKWT-1",
        accessor: "no_pkwt1",
      },
      {
        Header: "Start PKWT-1",
        accessor: "start_pkwt1",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "End PKWT-1",
        accessor: "end_pkwt1",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "No PKWT-2",
        accessor: "no_pkwt2",
      },
      {
        Header: "Start PKWT-2",
        accessor: "start_pkwt2",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "End PKWT-2",
        accessor: "end_pkwt2",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "No PKWT-3",
        accessor: "no_pkwt3",
      },
      {
        Header: "Start PKWT-3",
        accessor: "start_pkwt3",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "End PKWT-3",
        accessor: "end_pkwt3",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "No PKWTT",
        accessor: "no_pkwtt",
      },
      {
        Header: "PKWTT Date",
        accessor: "pkwtt_date",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "" || props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getEmployeesExport().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setExportDetail([]);
      } else {
        setExportDetail(result.data);
      }
      setIsLoading(false);
    });
  }, []);

  function handleDownloadExcel(header, data, downloadName) {
    const excelData = data;

    if (downloadName === "Employee Export PKWT") {
      excelData?.forEach((item) => {
        delete item.no;
        item.start_pkwt1 =
          item.start_pkwt1 === "" || item.start_pkwt1 === "0000-00-00"
            ? ""
            : moment(item.start_pkwt1).format("DD-MMM-YYYY");
        item.end_pkwt1 =
          item.end_pkwt1 === "" || item.end_pkwt1 === "0000-00-00"
            ? ""
            : moment(item.end_pkwt1).format("DD-MMM-YYYY");
        item.start_pkwt2 =
          item.start_pkwt2 === "" || item.start_pkwt2 === "0000-00-00"
            ? ""
            : moment(item.start_pkwt2).format("DD-MMM-YYYY");
        item.end_pkwt2 =
          item.end_pkwt2 === "" || item.end_pkwt2 === "0000-00-00"
            ? ""
            : moment(item.end_pkwt2).format("DD-MMM-YYYY");
        item.start_pkwt3 =
          item.start_pkwt3 === "" || item.start_pkwt3 === "0000-00-00"
            ? ""
            : moment(item.start_pkwt3).format("DD-MMM-YYYY");
        item.end_pkwt3 =
          item.end_pkwt3 === "" || item.end_pkwt3 === "0000-00-00"
            ? ""
            : moment(item.end_pkwt3).format("DD-MMM-YYYY");
        item.pkwtt_date =
          item.pkwtt_date === "" || item.pkwtt_date === "0000-00-00"
            ? ""
            : moment(item.pkwtt_date).format("DD-MMM-YYYY");
      });
    } else if (downloadName === "Employee Export Personal") {
      excelData?.forEach((item) => {
        delete item.no;
        var temp = item.nik;
        var temp2 = item.company;
        item.nik = item.employee_name;
        item.company = temp;
        item.employee_name = temp2;
        item.join_date = moment(item.join_date).format("DD-MMM-YYYY");
        item.birth_date = moment(item.birth_date).format("DD-MMM-YYYY");
      });
    }

    const dataSet = [];
    const dataHeader = [];
    header.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    excelData?.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: downloadName,
    });
  }

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffect />
        </Modal.Body>
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Employee Information Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-4">
          <Tabs defaultActiveKey="pkwt" id="uncontrolled-tab-example">
            <Tab eventKey="pkwt" title="PKWT">
              <div className="mt-3">
                <ReactTable
                  columns={columnsPKWT}
                  data={exportDetail?.pkwt}
                  isLoading={isLoading}
                  handleDownloadExcel={() =>
                    handleDownloadExcel(
                      columnsPKWT,
                      exportDetail?.pkwt,
                      "Employee Export PKWT"
                    )
                  }
                  handleFilter={null}
                  hiddenColumns={[]}
                />
              </div>
            </Tab>
            <Tab eventKey="personal" title="Personal">
              <div className="mt-3">
                <ReactTable
                  columns={columnsPersonal}
                  data={exportDetail?.personal}
                  isLoading={isLoading}
                  handleDownloadExcel={() =>
                    handleDownloadExcel(
                      columnsPersonal,
                      exportDetail?.personal,
                      "Employee Export Personal"
                    )
                  }
                  handleFilter={null}
                  hiddenColumns={[]}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalExportDetail;
