import "./PageNotFound.css";

function PageNetworkError(props) {
  return (
    <section className="page_404 rounded">
      <div className="container ">
        <div className="row ">
          <div style={{ width: "800px" }}>
            <div className=" text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">{props.code}</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Server Maintenance</h3>
                <div>
                  We're sorry, the server are currently under maintenance
                </div>
                <div>Please try again later</div>
                <div className="spinner-border my-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div>
                  <button
                    className="btn btn-warning col-6 text-white fw-bold"
                    onClick={(e) => window.location.reload()}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageNetworkError;
