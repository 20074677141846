import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { putMultipleApproveSpk } from "../../../data-api/resource-team";
import ReactTable from "../../../utils/ReactTable";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import ModalShowPDF from "../../../utils/ModalShowPDF";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

function ModalMultipleApprove(props) {
  const outletContext = useOutletContext();
  const [multiData, setMultiData] = useState(props.data);

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  const columnsSpk = useMemo(
    () => [
      {
        Header: "SPK ID",
        accessor: "spk_id", // accessor is the "key" in the data for react-table
      },
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Contract Title",
        accessor: "contract_title",
      },
      {
        Header: "Contract No",
        accessor: "contract_no",
      },
      {
        Header: "PDF",
        accessor: "pdf",
        Cell: (props) => (
          <button
            style={{
              padding: "0",
              border: "none",
              background: "none",
              textDecoration: "underline",
              color: "#0d6efd",
            }}
            href={`#`}
            onClick={() => handleShowPDF(`${props.value}?${Date.now()}`)}
          >
            {props.value === "" ? "" : "View PDF"}
          </button>
        ),
      },
    ],
    []
  );

  const data = multiData;
  const [isFetch, setIsFetch] = useState(false);
  const [multiApprove, setMultiApprove] = useState({});

  function approveSpk() {
    setIsFetch(true);
    const json = JSON.stringify({
      approve_by: outletContext.profile.nik,
      last_mod_by: outletContext.profile.nik,
      data: props.data.map((d) => ({ spk_id: d.spk_id })),
    });

    putMultipleApproveSpk(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
      } else {
        setMultiApprove(result);
        setMultiData(result.data);
        props.approveStatus(true);
      }
      setIsFetch(false);
    });
  }

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => delete v.spk_id);
    excelData.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = Object.keys(excelData[0]);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Multiple Approve SPK",
    });
  }

  return (
    <>
      <div className="container">
        <Modal.Header closeButton>
          <Modal.Title>
            {Object.keys(multiApprove).length === 0 ? (
              "Multiple Approve"
            ) : (
              <div className="d-flex">
                <FontAwesomeIcon
                  className="my-auto"
                  icon={faCircleCheck}
                  size="1x"
                  color="green"
                />
                <div className="ms-2">Multiple Approve Success</div>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(multiApprove).length === 0 ? (
            <>
              <ReactTable
                columns={columnsSpk}
                data={data}
                handleDownloadExcel={handleDownloadExcel}
                handleFilter={null}
                hiddenColumns={[
                  "contract_title",
                  "contract_no",
                  "spk_id",
                  "client_id",
                ]}
              />
              <div className="d-flex flex-row-reverse bd-highlight">
                <button
                  className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow col-2 btn-sm"
                  onClick={approveSpk}
                  disabled={isFetch}
                >
                  {isFetch ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              <ReactTable
                columns={columnsSpk}
                data={data}
                handleDownloadExcel={handleDownloadExcel}
                handleFilter={null}
                hiddenColumns={[
                  "contract_title",
                  "contract_no",
                  "spk_id",
                  "client_id",
                ]}
              />
            </>
          )}
        </Modal.Body>
      </div>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default ModalMultipleApprove;
