import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getTLStructure = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structures`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTLStructureDetail = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/${nik}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getPSManager = async () => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/ps-managers`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getTeamLeader = async () => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/team-leaders`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getCaptain = async () => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/captains`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postCaptain = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/captain`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateCaptainGroup = async (nik, data) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/group/${nik}`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("session")}`,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postBestCaptain = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/best-captain`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getBestCaptainHistory = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/best-captain/${nik}/history`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getDropdownPSManager = async () => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/list-dropdown/ps-manager`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getDropdownTeamLeader = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/list-dropdown/team-leader`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getDropdownCaptain = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/list-dropdown/captain`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getDropdownGroup = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/list-dropdown/group`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getUnsyncroneTL = async () => {
  const url = `${process.env.REACT_APP_API_URL}/tl-structure/unsynchrone-data`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
