import { useMemo } from "react";
import ReactTable from "../../utils/ReactTable";
import writeXlsxFile from "write-excel-file";
import moment from "moment";

function TableDashboard(props) {
  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Role",
        accessor: "position_name",
      },
      {
        Header: "End SPK",
        accessor: "end_spk",
        Cell: (props) => (
          <>
            {props.value === "-"
              ? "-"
              : `${moment(props.value).format("DD-MMM-YYYY")} ${
                  props.cell.row.original.return_spk === ""
                    ? ""
                    : `(return at ${moment(
                        props.cell.row.original.return_spk
                      ).format("DD-MMM-YYYY")})`
                }`}
          </>
        ),
      },
      {
        Header: "End PKWT",
        accessor: "end_pkwt",
        Cell: (props) =>
          props.value === "-" ? "-" : moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "Idle Days",
        accessor: "idle_days",
      },
    ],
    []
  );

  function handleDownloadExcel() {
    const columnsExcel = columns.map((item) => item.Header);

    props.data.data.forEach((item) => {
      item.end_spk =
        item.end_spk === "~" ? "~" : moment(item.end_spk).format("DD-MMM-YYYY");
      item.end_pkwt =
        item.end_pkwt === "~"
          ? "~"
          : moment(item.end_pkwt).format("DD-MMM-YYYY");
      item.idle_days = item.idle_days.toString();
    });

    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    props.data.data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Idle Employee",
    });
  }

  return (
    <>
      <div className="card mx-4 my-4">
        <div className="card-body" style={{ padding: "0" }}>
          <h5 className="card-title text-start pt-3 px-4">
            {props.data.table_name}
          </h5>
          <hr />
          <ReactTable
            columns={columns}
            data={props.data.data}
            handleDownloadExcel={handleDownloadExcel}
            handleFilter={null}
            hiddenColumns={[]}
          />
        </div>
      </div>
    </>
  );
}

export default TableDashboard;
