import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getEmployees = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/employees`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEmployeesFilter = async () => {
  const url = `${process.env.REACT_APP_API_URL}/employees/filter`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEmployeeProfile = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/employee/${nik}/profile`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEmployeeContract = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/employee/${nik}/contract`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateEmployeePhoto = async (nik, data) => {
  const url = `${process.env.REACT_APP_API_URL}/employee/${nik}/photo`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteEmployeePhoto = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/employee/${nik}/photo`;
  return axios
    .delete(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEmployeesExport = async () => {
  const url = `${process.env.REACT_APP_API_URL}/employees/export`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getEmployeesUnsyncrone = async () => {
  const url = `${process.env.REACT_APP_API_URL}/employees/unsyncrone`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListStatusEmployee = async () => {
  const url = `${process.env.REACT_APP_API_URL}/employee/status`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateEmployeeStatus = async (nik, data) => {
  const url = `${process.env.REACT_APP_API_URL}/employee/${nik}/status`;
  return axios
    .put(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
