import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { putProjectTeamDetail } from "../../../data-api/project-team";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { FormatDate } from "../../template/format";
registerLocale("en-GB", enGb);

function ModalEditDetailProject(props) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(props.data.startDate !== "" ? props.data.startDate : Date.now())
  );
  const [endDate, setEndDate] = useState(
    new Date(props.data.endDate !== "" ? props.data.endDate : Date.now())
  );

  function formEdit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setIsConfirm(true);
      } else {
        const json = JSON.stringify({
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        });
        putProjectTeamDetail(props.data.id, json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            props.handleSuccessModal(result.meta);
          } else {
            props.handleSuccessModal(result.meta);
          }
          setIsConfirm(false);
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Project Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => formEdit(e)} className="custom-text-14">
          {!isConfirm ? (
            <>
              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">Start Date</Form.Label>
                <div className="w-75">
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate !== "" ? startDate : ""}
                    onChange={(value) => {
                      setStartDate(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Start Date")
                    }
                    maxDate={endDate}
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label className="mt-2 fw-semibold">End Date</Form.Label>
                <div className="w-75">
                  <ReactDatePicker
                    locale={enGb}
                    className="w-100 form-control"
                    wrapperClassName="w-100 form-control"
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate !== "" ? endDate : ""}
                    onChange={(value) => {
                      setEndDate(value);
                    }}
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="DD/MM/YYYY"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out End Date")
                    }
                    minDate={startDate}
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </div>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Confirm
                </button>
              </div>
            </>
          ) : (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Start Date</Form.Label>
                <Form.Label className="w-75">
                  {FormatDate(startDate)}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">End Date</Form.Label>
                <Form.Label className="w-75">{FormatDate(endDate)}</Form.Label>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                  type="submit"
                  name="cancel"
                  value={"Cancel"}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}

export default ModalEditDetailProject;
