import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Navigate  , useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

function GoogleCallback() {
	const [params] = useSearchParams()
	const [url, setUrl] = useState()

	const [showModalSuccess,setShowModalSuccess]= useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
	const [message, setMessage] = useState()

	const cookies = new Cookies();

	function setToken(token, expires) {
    cookies.set("session", token, {
			path: '/',
      expires: new Date(expires),
    });
  }
	useEffect(() => {
		// redirect when cancel login with google
		if (params.get("error") == "access_denied"){
			setUrl("/login")
			return;
		}
		if (params.get("code") != null) {
			setShowModalSuccess(true);
			setModalSuccess(true);
			setMessage("Please wait in few second")
			axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/login/google/callback`,
        {code: params.get("code")},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
				setMessage("Please wait to redirect")
				setToken(res.data.data.access_token, res.data.data.expires_in);
				setTimeout(() =>{ 
					window.location.reload();
				}, 2000)
      })
      .catch((error) => {
				if(error.response === undefined) {
					setUrl("/login?code=500&error=Internal server error. "+ error.message)
				} else {
					setUrl("/login?code="+error.response.data.meta.code +"&error="+error.response.data.meta.message)
				}
      });
		} else {
			setUrl("/")
		}
  }, []);

	return (
		<>
			<Modal
				show={showModalSuccess}
				onHide={() => setShowModalSuccess(false)}
				// size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header
					closeButton
					className={modalSuccess ? "bg-success" : "bg-danger"}
				>
					<Modal.Title>
						{modalSuccess ? "Login Success" : "Login Failed"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{modalSuccess ? (
						<div className="text-center">
							<p>{message}</p>
							<div className="spinner-border" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					) : ""}
				</Modal.Body>
			</Modal>
			<Navigate to={url} />
		</>
	)
}

export default GoogleCallback;