import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getDropdownCaptain,
  getDropdownGroup,
  getDropdownTeamLeader,
  postCaptain,
} from "../../../data-api/tl-structure";
import { InvalidInput, ReactSelect } from "../../../utils/ReactSelect";
import Select from "react-select";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AddCaptain(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [listTeamLeader, setListTeamLeader] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [listCaptain, setListCaptain] = useState([]);
  const dateYear = new Date().getFullYear() - 3;
  const listYear = Array.from(new Array(20), (val, index) => index + dateYear);

  const [teamLeader, setTeamLeader] = useState("");
  const [group, setGroup] = useState("");
  const [captain, setCaptain] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  const listMonths = [
    {
      value: "1",
      label: "January",
    },
    {
      value: "2",
      label: "February",
    },
    {
      value: "3",
      label: "March",
    },
    {
      value: "4",
      label: "April",
    },
    {
      value: "5",
      label: "May",
    },
    {
      value: "6",
      label: "June",
    },
    {
      value: "7",
      label: "July",
    },
    {
      value: "8",
      label: "August",
    },
    {
      value: "9",
      label: "September",
    },
    {
      value: "10",
      label: "October",
    },
    {
      value: "11",
      label: "November",
    },
    {
      value: "12",
      label: "December",
    },
  ];

  useEffect(() => {
    fetchTeamLeader();
  }, []);

  function fetchTeamLeader() {
    getDropdownTeamLeader().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListTeamLeader([]);
      } else {
        setListTeamLeader(result.data);
        fetchGroup(result.data[0]);
      }
    });
  }

  function fetchGroup(teamLeader) {
    let params = {
      t_leader: teamLeader,
    };
    getDropdownGroup(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListGroup([]);
      } else {
        setListGroup(result.data);
        fetchCaptain(teamLeader, result.data[0].value);
      }
      setIsLoading(false);
    });
  }

  function fetchCaptain(teamLeader, group) {
    let params = {
      t_leader: teamLeader,
      sub_group: group,
    };
    getDropdownCaptain(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListCaptain([]);
      } else {
        setListCaptain(result.data);
      }
      setIsLoading(false);
    });
  }

  function fetchAddCaptain(event) {
    event.preventDefault();
    if (!isConfirm) {
      setIsConfirm(true);
    } else {
      const json = JSON.stringify({
        team_leader_nik: teamLeader.value,
        group: group.value,
        captain_nik: captain.value,
        month_periode: month.value,
        year_periode: year.value.toString(),
      });

      setIsFetch(true);

      postCaptain(json).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        if (result.meta.status === "success") {
          props.handleSuccessModal(true, message.message);
        } else {
          props.handleSuccessModal(false, message.message);
        }
        setIsFetch(false);
      });
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {isConfirm ? "Confirm Set Captain" : "Set Captain"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={(e) => fetchAddCaptain(e)}>
          {!isConfirm ? (
            <>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Team Leader</Form.Label>
                <Select
                  className="w-100 fw-normal custom-text-14 z-3"
                  options={listTeamLeader.map((item) => ({
                    value: item.nik,
                    label: item.name,
                  }))}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={teamLeader}
                  isClearable
                  placeholder={"-- Select Team Leader --"}
                  defaultValue={teamLeader}
                  onChange={(e) => {
                    setGroup("");
                    setCaptain("");
                    if (e !== null) {
                      setListGroup([]);
                      setListCaptain([]);
                      setTeamLeader(e);
                      fetchGroup(e.value);
                    } else {
                      setTeamLeader("");
                      setListGroup([]);
                      setListCaptain([]);
                    }
                  }}
                  ariaLiveMessages={"Please Select Team Leader"}
                  components={{
                    Input: invalidInput,
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>GROUP</Form.Label>
                <Select
                  className="w-100 fw-normal custom-text-14 z-3"
                  options={listGroup.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  isClearable
                  value={group}
                  placeholder={"-- Select Group --"}
                  defaultValue={group}
                  onChange={(e) => {
                    setCaptain("");
                    if (e !== null) {
                      setGroup(e);
                      fetchCaptain(teamLeader.value, e.value);
                    } else {
                      setGroup("");
                      setCaptain("");
                      setListCaptain([]);
                    }
                  }}
                  ariaLiveMessages={"Please Select Group"}
                  components={{
                    Input: invalidInput,
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Captain</Form.Label>
                <Select
                  className="w-100 fw-normal custom-text-14 z-3"
                  options={
                    !isLoading &&
                    listCaptain.map((item) => ({
                      value: item.nik,
                      label: item.name,
                    }))
                  }
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  isClearable
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  placeholder={"-- Select Captain --"}
                  value={captain}
                  defaultValue={captain}
                  onChange={(e) => {
                    if (e !== null) {
                      setCaptain(e);
                    } else {
                      setCaptain("");
                    }
                  }}
                  ariaLiveMessages={"Please Select Captain"}
                  components={{
                    Input: InvalidInput,
                  }}
                ></Select>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Month</Form.Label>
                <ReactSelect
                  value={month}
                  setValue={setMonth}
                  data={listMonths}
                  placeholder={"-- Select Month --"}
                  validationMessage={"Please Select Month"}
                />
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Year</Form.Label>

                <ReactSelect
                  value={year}
                  setValue={setYear}
                  data={listYear.map((data) => ({
                    label: data,
                    value: data,
                  }))}
                  placeholder={"-- Select Year --"}
                  validationMessage={"Please Select Year"}
                />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Team Leader</Form.Label>
                <Form.Label className="mt-2">{teamLeader.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Group</Form.Label>
                <Form.Label className="mt-2">{group.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Captain</Form.Label>
                <Form.Label className="mt-2">{captain.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Month</Form.Label>
                <Form.Label className="mt-2">{month.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="mt-2">Year</Form.Label>
                <Form.Label className="mt-2">
                  {year.label.toString()}
                </Form.Label>
              </Form.Group>
            </>
          )}

          <div className="d-flex flex-row-reverse bd-highlight">
            <button
              disabled={isFetch}
              className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
            >
              {isFetch ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              ) : isConfirm ? (
                "Confirm"
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
}

export default AddCaptain;
