import { Form, Modal } from "react-bootstrap";
import { ReactSelect } from "../../../../utils/ReactSelect";
import { useState } from "react";

function FilterTicket(props) {
  const [type, setType] = useState(props.data.type);
  const [section, setSection] = useState(props.data.section);
  const [status, setStatus] = useState(props.data.status);
  function submitFilter(event) {
    event.preventDefault();
    event.stopPropagation();

    props.submitFilter(type, section, status);
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <div className="p-3">
          <form onSubmit={submitFilter}>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="w-25 text-start">Type</Form.Label>
              <div className="w-75">
                <ReactSelect
                  value={type}
                  setValue={setType}
                  data={[
                    {
                      value: "1",
                      label: "Request",
                    },
                    {
                      value: "2",
                      label: "Case",
                    },
                  ]}
                  isLoading={false}
                  placeholder={"-- Select Type --"}
                  required={false}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="w-25 text-start">Section</Form.Label>
              <div className="w-75">
                <ReactSelect
                  value={section}
                  setValue={setSection}
                  data={[
                    {
                      value: "1",
                      label: "OTP",
                    },
                    {
                      value: "2",
                      label: "Login",
                    },
                    {
                      value: "3",
                      label: "Dashboard",
                    },
                    {
                      value: "4",
                      label: "Employee",
                    },
                    {
                      value: "5",
                      label: "Idle",
                    },
                    {
                      value: "6",
                      label: "Resign",
                    },
                    {
                      value: "7",
                      label: "SPK",
                    },
                    {
                      value: "8",
                      label: "PO",
                    },
                    {
                      value: "9",
                      label: "New Access User",
                    },
                    {
                      value: "10",
                      label: "Timesheet",
                    },
                    {
                      value: "11",
                      label: "Talent Review",
                    },
                    {
                      value: "12",
                      label: "BAST",
                    },
                    {
                      value: "13",
                      label: "Internal Contract",
                    },
                  ]}
                  isLoading={false}
                  placeholder={"-- Select Section --"}
                  required={false}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
              <Form.Label className="w-25 text-start">Status</Form.Label>
              <div className="w-75">
                <ReactSelect
                  value={status}
                  setValue={setStatus}
                  data={[
                    {
                      value: "1",
                      label: "New Received",
                    },
                    {
                      value: "2",
                      label: "In Progress",
                    },
                    {
                      value: "3",
                      label: "Done",
                    },
                    {
                      value: "4",
                      label: "Test",
                    },
                    {
                      value: "5",
                      label: "Cancelled",
                    },
                  ]}
                  isLoading={false}
                  placeholder={"-- Select Status --"}
                  required={false}
                />
              </div>
            </Form.Group>
            <div className="mt-4">
              <button
                className="btn btn-danger w-100 btn-sm"
                type="submit"
                name="submit"
                value={"Submit"}
              >
                Filter
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </>
  );
}

export default FilterTicket;
