import { Modal } from "react-bootstrap";
import { deleteApprovalPersonnel } from "../../../../data-api/master-user";

function ModalDeleteApproval(props) {
  function deleteUser() {
    deleteApprovalPersonnel(props.data.original.id).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        props.handlePost(false, result.meta.message);
      } else {
        const message = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.handlePost(true, message.message);
      }
    });
  }
  return (
    <>
      <Modal.Body>
        <div className="text-center py-4 custom-text-14">
          <img src="/icons/cross.png" alt="" style={{ width: "200px" }} />
          <h5 className="mt-5">Are you sure?</h5>
          <div className="mt-2">
            <p>Do you really want to delete</p>
            <p style={{ marginTop: "-15px" }}>
              {props.data.original.employee_name}
            </p>
            <p style={{ marginTop: "-15px" }}>
              account ? This process cannot be undone.
            </p>
          </div>
          <div className="text-center">
            <button
              className="btn btn-secondary mt-3 col-4 btn-sm"
              onClick={props.onHide}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger mt-3 col-4 ms-4 btn-sm"
              onClick={deleteUser}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalDeleteApproval;
