import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import * as xlsx from "xlsx";

function ModalUploadCalculate(props) {
  const onFileChange = (files) => {};
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState(props.uploadData);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile.type === "text/csv") {
      const updatedList = [newFile];
      setFileList(updatedList);
      props.setUploadData(updatedList);
      onFileChange(updatedList);

      if (e.target.files) {
        const reader = new FileReader();
        var jsonParse;
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "csv" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_csv(worksheet, { header: 1 });
          jsonParse = JSON.parse(convertToJson(json));
          if (
            jsonParse[0].hasOwnProperty("nik") &&
            jsonParse[0].hasOwnProperty("number") &&
            jsonParse[0].hasOwnProperty("laptop")
          ) {
            // setImportData(jsonParse);
            props.setData(jsonParse);
          } else {
            showAlertErrorUploadCSV(
              false,
              "Please upload right CSV format file"
            );
          }
          //   Object.keys(jsonParse).forEach((key) => {
          //     jsonParse[key].nik = jsonParse[key].nik.padStart(7, "0");
          //   });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } else {
      showAlertErrorUploadCSV(false, "Please upload CSV format");
    }
  };
  const [showAlertUploadCSV, setShowAlertUploadCSV] = useState(false);
  const [AlertMessageUploadCSV, setAlertMessageUploadCSV] = useState("");

  function showAlertErrorUploadCSV(success, message) {
    setShowAlertUploadCSV(true);
    setAlertMessageUploadCSV(message);
  }

  function convertToJson(csv) {
    csv = csv.replaceAll(";", ",");
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        if (currentline[j].includes("/")) {
          var date = currentline[j].split("/");
          obj[headers[j]] = `20${date[2]}-${date[1].padStart(
            2,
            "0"
          )}-${date[0].padStart(2, "0")}`;
        } else {
          obj[headers[j]] = currentline[j];
        }
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.setUploadData(updatedList);
    onFileChange(updatedList);
    // setImportData([]);
    props.setData([]);
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Upload CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlertUploadCSV ? (
          <div
            className={"alert alert-danger alert-dismissible fade show"}
            role="alert"
          >
            <strong>{AlertMessageUploadCSV}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlertUploadCSV(false)}
            ></button>
          </div>
        ) : null}
        <div className="mb-4">
          <div className="custom-file-upload btn btn-outline-danger">
            <label>Browse</label>
            <input type="file" value="" onChange={onFileDrop} />
          </div>
          <button
            className="btn btn-danger float-end col-4 mb-4"
            onClick={props.handleCloseUpload}
          >
            Submit
          </button>
        </div>
        {fileList.length === 0 ? (
          <div
            ref={wrapperRef}
            className="drop-file-input mt-4"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            <div className="drop-file-input__label">
              <img alt="" />
              <p>Drag & Drop your files here</p>
            </div>
            <input type="file" value="" onChange={onFileDrop} />
          </div>
        ) : null}
        {fileList.length > 0 ? (
          <div className="drop-file-preview">
            {fileList.map((item, index) => (
              <div key={index} className="drop-file-preview__item">
                <img alt="" />
                <div className="drop-file-preview__item__info">
                  <p>{item.name}</p>
                  <p>{item.size}B</p>
                </div>
                <span
                  className="drop-file-preview__item__del"
                  onClick={() => fileRemove(item)}
                >
                  x
                </span>
              </div>
            ))}
          </div>
        ) : null}
      </Modal.Body>
    </>
  );
}

export default ModalUploadCalculate;
