import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getAddSpkData,
  putReturnSpk,
  putUpdateSpk,
} from "../../../data-api/resource-team";
import { useOutletContext } from "react-router-dom";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import { ReactSelect } from "../../../utils/ReactSelect";
import ModalShowPDF from "../../../utils/ModalShowPDF";
import LoadingEffectForm from "../../template/loadingEffectForm";
registerLocale("en-US", enUs);

function ModalEditSpk(props) {
  const formData = props.data;

  const [addModal, setAddModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);
  const [addSuccess, setAddSuccess] = useState({});

  const spkId = formData.spk_id;
  const [nik, setNik] = useState(formData.nik);
  const [placement, setPlacement] = useState("");
  const [startDate, setStartDate] = useState(
    !isNaN(new Date(formData.start_date))
      ? new Date(formData.start_date)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    !isNaN(new Date(formData.end_date))
      ? new Date(formData.end_date)
      : new Date()
  );
  const [approveBy, setApproveBy] = useState("");
  const [isReturn, setIsReturn] = useState(
    formData.return_date === "" ||
      formData.return_date === undefined ||
      formData.return_date === null
      ? false
      : true
  );
  const [returnDate, setReturnDate] = useState(
    !isNaN(new Date(formData.return_date))
      ? new Date(formData.return_date)
      : new Date()
  );

  const returnReasonData = [
    {
      value: "Project Done",
      label: "Project Done",
    },
    {
      value: "Performance",
      label: "Performance",
    },
    {
      value: "Attitude",
      label: "Attitude",
    },
    {
      value: "Performance & Attitude",
      label: "Performance & Attitude",
    },
    {
      value: "Client Budget",
      label: "Client Budget",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];

  const [returnReason, setReturnReason] = useState(
    formData.return_reason === ""
      ? ""
      : {
          value: returnReasonData.find(
            (item) => item.label === formData.return_reason
          )
            ? formData.return_reason
            : "Others",
          label: returnReasonData.find(
            (item) => item.label === formData.return_reason
          )
            ? formData.return_reason
            : "Others",
        }
  );

  const [returnReasonOthers, setReturnReasonOthers] = useState(
    returnReasonData.find((item) => item.label !== formData.return_reason)
      ? formData.return_reason
      : ""
  );
  const contractTitle =
    "Perjanjian Kerja Sama Pengadaan Jasa Penyediaan Tenaga Ahli Profesional TI BPJS Kesehatan";
  const contractNo = "470/KTR/1022";
  const outletContext = useOutletContext();

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  useEffect(() => {
    getAddSpkData().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
      } else {
        setAddModal(result.data);
        formData.client_id !== "0" &&
          setPlacement(
            result.data.placement.find((x) => x.value === formData.client_id)
          );
      }
      setIsLoading(false);
    });
  }, []);

  function formAddSpk(event) {
    event.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      setIsFetch(true);
      if (isReturn) {
        returnSPK();
      } else {
        updateSPK();
      }
      if (props.setActionEdit !== undefined) {
        props.setActionEdit(true);
      }
    }
  }

  function updateSPK() {
    const json = JSON.stringify({
      spk_id: spkId,
      start_date: moment(startDate).format("yyyy-MM-DD"),
      end_date: moment(endDate).format("yyyy-MM-DD"),
      client_id: placement.value,
      approve_by: approveBy.value,
      contract_title: contractTitle,
      contract_no: contractNo,
      created_by: outletContext.profile.nik,
    });

    putUpdateSpk(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
      } else {
        setAddSuccess(result);
        setNik("-1");
        setPlacement("-1");
        setStartDate("");
        setEndDate("");
        props.editStatus(true);
      }
      setIsFetch(false);
    });
  }

  function returnSPK() {
    const json = JSON.stringify({
      return_date: moment(returnDate).format("yyyy-MM-DD"),
      return_reason:
        returnReason.label === "Others"
          ? returnReasonOthers
          : returnReason.label,
    });

    putReturnSpk(nik, json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
      } else {
        setAddSuccess(result);
        setReturnDate("");
        setReturnReason("");
        props.editStatus(true);
      }
      setIsFetch(false);
    });
  }

  if (isLoading)
    return (
      <>
        <LoadingEffectForm row={8} />
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit SPK</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-text-14">
          <Form onSubmit={(e) => formAddSpk(e)}>
            {Object.keys(addSuccess).length === 0 && !addConfirm ? (
              <div className="container">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Type</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    aria-describedby="basic-addon1"
                    defaultValue={formData.spk_type}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Employee</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    aria-describedby="basic-addon1"
                    defaultValue={formData.employee_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">No. SPK</Form.Label>
                  <Form.Control
                    defaultValue={nik.slice(-3)}
                    className="w-75 form-control-sm"
                    aria-describedby="basic-addon1"
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Placement</Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={placement}
                      setValue={setPlacement}
                      data={addModal.placement}
                      isLoading={isLoading}
                      placeholder={"-- Select Placement --"}
                      validationMessage={"Please Select Placement"}
                      disabled={isReturn}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-between">
                  <Form.Label className="mt-2 fw-semibold">
                    Start Date
                  </Form.Label>
                  <div className="w-75 ">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate ? startDate : ""}
                      onChange={(e) => setStartDate(e)}
                      placeholderText="DD/MM/YYYY"
                      onInvalid={(e) => false}
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                      isClearable
                      disabled={isReturn}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-between">
                  <Form.Label className="mt-2 fw-semibold">End Date</Form.Label>
                  <div className="w-75 fw-semibold">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={endDate ? endDate : ""}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                      placeholderText="DD/MM/YYYY"
                      onInvalid={(e) => false}
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                      isClearable
                      disabled={isReturn}
                    />
                  </div>
                </Form.Group>
                {placement.value === "5" ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Contract No</Form.Label>
                      <Form.Control
                        className="w-75 form-control-sm"
                        aria-describedby="basic-addon1"
                        defaultValue={contractNo}
                        required
                        disabled={isReturn}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Contract Title</Form.Label>
                      <Form.Control
                        className="w-75 form-control-sm"
                        aria-describedby="basic-addon1"
                        defaultValue={contractTitle}
                        required
                        disabled={isReturn}
                      />
                    </Form.Group>
                  </>
                ) : null}
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Approve By</Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={approveBy}
                      setValue={setApproveBy}
                      data={addModal.approve_by}
                      isLoading={isLoading}
                      placeholder={"-- Select Approve By --"}
                      validationMessage={"Please select Approve By"}
                      disabled={isReturn}
                    />
                  </div>
                </Form.Group>
                {formData.spk_type === formData.last_spk &&
                props.menu === "detail" &&
                props.accessMenu.is_delete ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Return</Form.Label>
                      <div className="w-75">
                        <input
                          className={`form-check-input fs-5 border-danger ${
                            isReturn ? "bg-danger" : "bg-white"
                          }`}
                          type="checkbox"
                          value={isReturn}
                          onChange={() => setIsReturn(!isReturn)}
                          id="flexCheckDefault"
                          defaultChecked={isReturn}
                          disabled={
                            formData.return_date === "" ||
                            formData.return_date === undefined ||
                            formData.return_date === null
                              ? false
                              : true
                          }
                        />
                      </div>
                    </Form.Group>
                  </>
                ) : null}

                {isReturn &&
                formData.spk_type === formData.last_spk &&
                props.menu === "detail" &&
                props.accessMenu.is_delete ? (
                  <>
                    <Form.Group className="mb-3 d-flex justify-content-between">
                      <Form.Label className="mt-2 fw-semibold">
                        Return Date
                      </Form.Label>
                      <div className="w-75 ">
                        <ReactDatePicker
                          locale={enUs}
                          showMonthDropdown
                          showYearDropdown
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          className="w-100 form-control"
                          wrapperClassName="w-100 form-control"
                          dateFormat="dd/MM/yyyy"
                          selected={returnDate}
                          onChange={(e) => setReturnDate(e)}
                          placeholderText="DD/MM/YYYY"
                          onInvalid={(e) => false}
                          onInput={(e) => e.target.setCustomValidity("")}
                          required
                          isClearable
                          minDate={startDate}
                          maxDate={endDate}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Return Reason</Form.Label>
                      <div className="w-75">
                        <ReactSelect
                          value={returnReason}
                          setValue={setReturnReason}
                          data={returnReasonData}
                          placeholder={"-- Select Return Reason --"}
                          validationMessage={"Please select return reason"}
                        />
                      </div>
                    </Form.Group>
                    {returnReason.value === "Others" && (
                      <Form.Group className="mb-3 fw-semibold d-flex justify-content-end">
                        {/* <Form.Label className="mt-2">Return Reason</Form.Label> */}
                        <div className="d-flex w-75">
                          <Form.Control
                            as="textarea"
                            style={{ height: "70px" }}
                            className="w-100 form-control-sm"
                            aria-describedby="basic-addon1"
                            defaultValue={returnReasonOthers}
                            onChange={(e) =>
                              setReturnReasonOthers(e.target.value)
                            }
                            required
                          />
                        </div>
                      </Form.Group>
                    )}
                  </>
                ) : null}
              </div>
            ) : Object.keys(addSuccess).length === 0 && addConfirm ? (
              <div className="container">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Type</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {formData.spk_type}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Employee</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {formData.employee_name}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">No. SPK</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {nik.slice(-3)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Placement</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {placement.label}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {moment(startDate).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {moment(endDate).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                {placement.value === "5" ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Contract No</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {contractNo}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Contract Title</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {contractTitle}
                      </Form.Label>
                    </Form.Group>
                  </>
                ) : null}
                {isReturn &&
                formData.spk_type === formData.last_spk &&
                props.menu === "detail" &&
                props.accessMenu.is_delete ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Return Date</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {moment(returnDate).format("DD-MMM-YYYY")}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Return Reason</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {returnReason.label === "Others"
                          ? returnReasonOthers
                          : returnReason.label}
                      </Form.Label>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Approve By</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {approveBy.label}
                      </Form.Label>
                    </Form.Group>
                  </>
                )}
              </div>
            ) : Object.keys(addSuccess).length !== 0 &&
              addSuccess.meta.status === "success" ? (
              <div className="container d-flex flex-column py-4">
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
                {isReturn ? (
                  <>
                    <div className="w-100 text-center fs-5 fw-semibold">
                      <span className="mx-auto">SPK Has Been Returned</span>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      style={{
                        padding: "0",
                        border: "none",
                        background: "none",
                      }}
                      className="m-auto mt-4"
                      href={`#`}
                      onClick={() =>
                        handleShowPDF(`${addSuccess.data.pdf}?${Date.now()}`)
                      }
                    >
                      <input
                        className="btn btn-success"
                        type="button"
                        value="View PDF"
                      />
                    </button>
                  </>
                )}
              </div>
            ) : Object.keys(addSuccess).length !== 0 &&
              addSuccess.meta.status !== "success" ? (
              <div className="container d-flex flex-column">
                <FontAwesomeIcon icon={faCircleXmark} />
              </div>
            ) : null}
            {Object.keys(addSuccess).length === 0 ? (
              <div className="d-flex flex-row-reverse bd-highlight">
                <button
                  className="btn btn-danger btn-sm mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5"
                  disabled={isFetch}
                >
                  {isFetch ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : addConfirm ? (
                    "Confirm"
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : null}
          </Form>
        </div>
      </Modal.Body>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default ModalEditSpk;
