import {
  faAnglesLeft,
  faAnglesRight,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { saveCostInsurance } from "../../../data-api/cost-to-company";

function ModalEditInsurance(props) {
  const [data, setData] = useState(props.data);
  const [showEditConfirm, setShowEditConfirm] = useState(false);
  var moneyFormatter = new Intl.NumberFormat();

  const columnsins = useMemo(
    () => [
      {
        Header: "Jenis Plan",
        accessor: "plan_type", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Rawat Inap",
        accessor: "inpatient",
      },
      {
        Header: "Rawat Jalan",
        accessor: "outpatient",
      },
      {
        Header: "Total Premi ",
        accessor: "premi_total",
      },
      {
        Header: "Kode",
        accessor: "code",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Rawat Inap",
        id: "inpatient_edit",
        Cell: ({ row }) => (
          <>
            <Form.Control
              type="number"
              className="w-100 form-control-sm"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                const updatedList = [...data];
                updatedList[row.id].inpatient = parseInt(e.target.value);
                updatedList[row.id].premi_total =
                  parseInt(updatedList[parseInt(row.id)].inpatient) +
                  parseInt(updatedList[parseInt(row.id)].outpatient);
                setData(updatedList);
              }}
              defaultValue={row.values.inpatient}
              required
            />
          </>
        ),
      },
      {
        Header: "Rawat Jalan",
        id: "outpatient_edit",
        Cell: ({ row }) => (
          <Form.Control
            type="number"
            className="w-100 form-control-sm"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              const updatedList = [...data];
              updatedList[parseInt(row.id)].outpatient = parseInt(
                e.target.value
              );
              updatedList[parseInt(row.id)].premi_total =
                parseInt(updatedList[parseInt(row.id)].inpatient) +
                parseInt(updatedList[parseInt(row.id)].outpatient);
              setData(updatedList);
            }}
            defaultValue={row.values.outpatient}
            required
          />
        ),
      },
      {
        Header: "Total Premi ",
        id: "premi_total_edit",
        Cell: ({ row }) => (
          <>
            <div>
              {moneyFormatter.format(data[parseInt(row.id)].premi_total)}
            </div>
          </>
        ),
      },
      {
        Header: "Kode",
        id: "kode_edit",
        Cell: ({ row }) => (
          <>
            <div>{row.values.code}</div>
          </>
        ),
      },
    ]);
  };

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: columnsins,
      data: props.data,
      initialState: {
        pageIndex: 0,
        pageSize: 12,
        hiddenColumns: ["inpatient", "outpatient", "premi_total", "code"],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    tableHooks
  );

  function editInsurance() {
    const json = JSON.stringify(data);
    saveCostInsurance(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message = {
        code: result.meta.code,
        message: result.meta.message,
      };
      if (result.meta.code === 200) {
        props.handleOpenModalSuccess(message);
      } else {
        props.handleOpenModalSuccess(message);
      }
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Insurance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-x-scroll px-3 mt-3">
          <table className="table table table-sm custom-table align-middle">
            <thead className="text-danger text-center align-middle custom-text-14 nowrap">
              <tr>
                <th className="text-danger" colSpan={columnsins.length}>
                  Insurance
                </th>
              </tr>
            </thead>
            <thead className="text-danger align-middle custom-text-14 nowrap">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      key={index}
                      className="text-danger"
                    >
                      {column.render("Header")}
                      <span className="ms-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
                className="custom-text-14"
              >
                <td colSpan={headers.length}>No data available in table</td>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className="custom-text-14">
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="text-start"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>{" "}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div className="d-flex justify-content-end">
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
        <div className="mx-3 d-flex flex-row-reverse bd-highlight mb-3 mt-3">
          <button
            className="btn btn-sm btn-danger ms-3 col-3"
            onClick={() => setShowEditConfirm(true)}
          >
            Save
          </button>
        </div>
      </Modal.Body>

      <Modal
        show={showEditConfirm}
        onHide={() => setShowEditConfirm(false)}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Edit Insurance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {/* <img src="/icons/question.png" alt="" style={{ width: "100px" }} /> */}
            <h5>Are you sure?</h5>
            <p>Do you really want to update this insurance ?</p>
            <div className="text-center">
              <button
                className="btn btn-secondary mt-3 col-4 btn-sm"
                onClick={() => setShowEditConfirm(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mt-3 col-4 ms-4 btn-sm"
                onClick={editInsurance}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalEditInsurance;
