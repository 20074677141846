import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getListSpk = async (params) => {
  const link = `${process.env.REACT_APP_API_URL}/spk`;
  return axios
    .get(link, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListEmployees = async (params) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/employee`;
  return axios
    .get(link, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSpkDetail = async (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/${nik}`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getAddSpkData = async () => {
  const link = `${process.env.REACT_APP_API_URL}/spk/data`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postAddSpk = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk`;
  return axios
    .post(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSpkApproveReject = async () => {
  const link = `${process.env.REACT_APP_API_URL}/spk/approve-reject`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getWaitingSpk = async (params) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/waiting`;
  return axios
    .get(link, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putApproveSpk = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/waiting`;
  return axios
    .put(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putMultipleApproveSpk = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/waiting/multiple-approve`;
  return axios
    .put(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getWaitingSpkHistory = async (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/waiting/${nik}/history`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getWaitingSpkDetail = async (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/waiting/${nik}/detail`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getRejectedSpk = async (params) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/reject`;
  return axios
    .get(link, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putUpdateSpk = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/reject`;
  return axios
    .put(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const putReturnSpk = async (nik, data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/${nik}/return`;
  return axios
    .put(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postSendEmail = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/sendemail`;
  return axios
    .post(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postImportCreateSpk = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/import-create`;
  return axios
    .post(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postImportUpdateSpk = async (data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/import-update`;
  return axios
    .post(link, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSignImage = async (nik) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/${nik}/sign-image`;
  return axios
    .get(link, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateSignImage = async (nik, data) => {
  const link = `${process.env.REACT_APP_API_URL}/spk/${nik}/sign-image`;
  return axios
    .put(link, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
