import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getResign = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/resign`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postResign = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/resign`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getResignModal = async () => {
  const url = `${process.env.REACT_APP_API_URL}/resign/modal`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getResignDetail = async (nik) => {
  const url = `${process.env.REACT_APP_API_URL}/resign/${nik}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateResign = async (nik, data) => {
  const url = `${process.env.REACT_APP_API_URL}/resign/${nik}`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
