import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./idle.css";
import { Form, Modal } from "react-bootstrap";
import { getIdleFilter, getIdleOthers } from "../../data-api/idle";
import ReactTable from "../../utils/ReactTable";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";

function TableIdleOthers() {
  const [othersFilter, setOthersFilter] = useState([]);
  const [clients, setClients] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterStatusTemp, setFilterStatusTemp] = useState("");
  const [filterClients, setFilterClients] = useState("");
  const [filterClientsTemp, setFilterClientsTemp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getIdleFilter().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setOthersFilter([]);
        setClients([]);
      } else {
        setOthersFilter(result.data.filter_status);
        setClients(result.data.filter_client);
      }
    });
    fetchFilterOthers();
  }, []);

  const [others, setOthers] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);

  //func fetch filter Idle others
  function fetchFilterOthers(client, status) {
    setIsLoading(true);
    getIdleOthers({ client: client, status: status }).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setOthers([])
        : setOthers(result.data);
      setIsLoading(false);
    });
  }
  function handleFilter() {
    setModalFilter(true);
  }
  function submitFilter(e) {
    e.preventDefault();
    setFilterClients(
      filterClientsTemp === "" || filterClientsTemp === null
        ? ""
        : filterClientsTemp
    );
    setFilterStatus(
      filterStatusTemp === "" || filterStatusTemp === null
        ? ""
        : filterStatusTemp
    );
    fetchFilterOthers(
      filterClientsTemp === "" || filterClientsTemp === null
        ? ""
        : filterClientsTemp.label,
      filterStatusTemp === "" || filterStatusTemp === null
        ? ""
        : filterStatusTemp.value
    );
    setModalFilter(false);
  }
  function removeFilter(type) {
    if (type === "status") {
      setFilterStatus("");
      setFilterStatusTemp("");
      fetchFilterOthers(filterClients.label, "");
    } else if (type === "client") {
      setFilterClients("");
      setFilterClientsTemp("");
      fetchFilterOthers("", filterStatus.value);
    }
  }

  //initiate column name for employee table
  const columnsIdleOthers = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Role",
        accessor: "position",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Client",
        accessor: "client",
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => (
          <span className="nowrap">
            {moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "End PKWT",
        accessor: "end_pkwt",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "~"
              ? "~"
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);
  const data = others === null ? EmptyData : others;
  function handleDownloadExcel() {
    const columnsExcel = columnsIdleOthers.map((item) => item.Header);
    data.forEach((item) => {
      item.join_date = moment(item.join_date).format("DD-MMM-YYYY");
      item.end_pkwt =
        item.end_pkwt === "~"
          ? "~"
          : moment(item.end_pkwt).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Idle Others",
    });
  }

  return (
    <>
      <div className="tabs-content">
        <div className="d-flex flex-row bd-highlight mb-3">
          {filterStatus !== null && filterStatus !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {filterStatus.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => removeFilter("status")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterClients !== null && filterClients !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              {filterClients.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => removeFilter("client")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>

        {/* table idle */}
        <ReactTable
          columns={columnsIdleOthers}
          data={others}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={handleFilter}
          isLoading={isLoading}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>Filter</Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form onSubmit={(e) => submitFilter(e)}>
              <div className="w-80 ms-4">
                <Form.Group className="mb-3 fw-semibold ">
                  <p>Select Status</p>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={othersFilter.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={filterStatusTemp}
                    placeholder={"-- Select Status --"}
                    defaultValue={{
                      label: filterStatus.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setFilterStatusTemp(e);
                      } else {
                        setFilterStatusTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold ">
                  <p>Select Clients </p>
                  <Select
                    className="w-100 fw-normal custom-text-14"
                    options={clients.map((data) => {
                      return { value: data.value, label: data.label };
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 35,
                        minHeight: 35,
                      }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: "14px",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isClearable
                    value={filterClientsTemp}
                    placeholder={"-- Select Placement --"}
                    defaultValue={{
                      label: filterClients.label,
                    }}
                    onChange={(e) => {
                      if (e !== null) {
                        setFilterClientsTemp(e);
                      } else {
                        setFilterClientsTemp("");
                      }
                    }}
                  ></Select>
                </Form.Group>
                <div className="d-grid gap-2">
                  <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow">
                    FILTER
                  </button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default TableIdleOthers;
