import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faFilter,
  faFolderOpen,
  faMagnifyingGlass,
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ModalRenewalSpk from "../modal/ModalRenewalSpk";
import ModalDetailSpk from "../modal/ModalDetailSpk";
import moment from "moment";
import { getListSpk } from "../../../data-api/resource-team";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import writeXlsxFile from "write-excel-file";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import { ReactSelect } from "../../../utils/ReactSelect";
registerLocale("en-US", enUs);

function TableSPK(props) {
  const accessMenu = props.accessMenu;
  const [spk, setSpk] = useState([]);
  const [actionEdit, setActionEdit] = useState(false);

  useEffect(() => {
    if (props.data.length === 0) {
      getListSpk().then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          setSpk([]);
        } else {
          setSpk(result.data);
        }
        setIsLoading(false);
      });
    } else {
      setSpk(props.data);
      setIsLoading(false);
    }
  }, []);

  const [renewalStatus, setRenewalStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [nik, setNik] = useState("");

  const [modalFilter, setModalFilter] = useState(false);
  const [groupEmployee, setGroupEmployee] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [filterStartDateTemp, setFilterStartDateTemp] = useState("");
  const [filterEndDateTemp, setFilterEndDateTemp] = useState("");
  const [filterGroupTemp, setFilterGroupTemp] = useState("");

  const [formData, setFormData] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => {
    if (actionEdit) {
      getListSpk().then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          setSpk([]);
        } else {
          setSpk(result.data);
        }
        setIsLoading(false);
      });
      setActionEdit(false);
    }
    setShowDetail(false);
  };
  function handleShowDetail(nik) {
    setNik(nik);
    setShowDetail(true);
  }

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    if (renewalStatus) {
      props.resetTable(props.tabKey);
    }
    setShowEdit(false);
  };
  function handleShowEdit(
    nik,
    employee_name,
    client_id,
    start_date,
    end_date,
    spk_type
  ) {
    spk_type = spk_type.split(" ")[1];
    setFormData({
      nik: nik,
      employee_name: employee_name,
      client_id: client_id,
      start_date: start_date,
      end_date: end_date,
      spk_type: spk_type,
    });
    setShowEdit(true);
  }

  const columnsSpk = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => (
          <>{`${moment(props.value).format("DD-MMM-YYYY")} ${
            props.cell.row.original.return_date === ""
              ? ""
              : `(return at ${moment(
                  props.cell.row.original.return_date
                ).format("DD-MMM-YYYY")})`
          }`}</>
        ),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Renewal Days",
        accessor: "renewal_days",
      },
      {
        Header: "",
        accessor: "client_id",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  // add custom new cell for button detail & renewal
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => handleShowDetail(row.values.nik)}
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
      {
        id: "renewal",
        Header: "Renewal",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEdit(
                  row.values.nik,
                  row.values.employee_name,
                  row.values.client_id,
                  row.values.start_date,
                  row.values.end_date,
                  row.values.spk_type
                )
              }
              disabled={!accessMenu.is_create}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  const data = spk.length === 0 ? EmptyData : spk;

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => {
      delete v.spk_id;
      delete v.client_id;
    });
    excelData.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = columnsSpk
      .filter((item) => item.Header !== "Company")
      .map((item) => item.Header);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    const dataTemp = data;
    dataTemp.forEach((v) => {
      delete v.company;
    });

    //append body table
    dataTemp.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "SPK",
    });
  }

  function filterSpk(event) {
    event.preventDefault();

    setGroupEmployee(filterGroupTemp);

    setFilterStartDate(
      filterStartDateTemp === "" || filterStartDateTemp === null
        ? ""
        : moment(filterStartDateTemp).format("yyyy-MM-DD")
    );
    setFilterEndDate(
      filterEndDateTemp === "" || filterEndDateTemp === null
        ? ""
        : moment(filterEndDateTemp).format("yyyy-MM-DD")
    );
    fetchFilterSpk(
      filterStartDateTemp === "" || filterStartDateTemp === null
        ? ""
        : moment(filterStartDateTemp).format("yyyy-MM-DD"),
      filterEndDateTemp === "" || filterEndDateTemp === null
        ? ""
        : moment(filterEndDateTemp).format("yyyy-MM-DD"),
      filterGroupTemp.value
    );
    setModalFilter(false);
    document.getElementById("search-reacttable-spk").reset();
  }

  function fetchFilterSpk(startDate, endDate, group) {
    setIsLoading(true);
    let params = {
      start_date: startDate,
      end_date: endDate,
      employee: group,
    };
    getListSpk(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setSpk([]);
      } else {
        setSpk(result.data);
      }
      setIsLoading(false);
    });
  }

  function removeFilter(type) {
    if (type === "group") {
      fetchFilterSpk(
        filterStartDateTemp === "" || filterStartDateTemp === null
          ? ""
          : moment(filterStartDateTemp).format("yyyy-MM-DD"),
        filterEndDateTemp === "" || filterEndDateTemp === null
          ? ""
          : moment(filterEndDateTemp).format("yyyy-MM-DD"),
        ""
      );
      setGroupEmployee("");
      setFilterGroupTemp("");
    } else if (type === "date") {
      fetchFilterSpk("", "", groupEmployee.value);
      setFilterStartDate("");
      setFilterStartDateTemp("");
      setFilterEndDate("");
      setFilterEndDateTemp("");
    }
  }

  function handleFilter() {
    setModalFilter(true);
  }

  const [searchVal, setSearchVal] = useState("");

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns: columnsSpk,
      data: data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ["client_id"],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    tableHooks
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="tabs-content">
        <div className="d-flex flex-row bd-highlight mb-3">
          {groupEmployee.length !== 0 ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Group : {groupEmployee.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => removeFilter("group")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterStartDate !== "" || filterEndDate !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              End Date :{" "}
              {filterStartDate === ""
                ? "Min Date"
                : moment(filterStartDate).format("DD-MMM-YYYY")}
              {` - `}
              {filterEndDate === ""
                ? "Max Date"
                : moment(filterEndDate).format("DD-MMM-YYYY")}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "100px",
                }}
                onClick={(e) => removeFilter("date")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>

        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>

            <div className=" ms-4">
              <Form.Select
                className="form-select-sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttable-spk"
              onSubmit={(e) => e.preventDefault()}
              className="input-group w-50 flex border border-danger rounded"
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchVal(e.target.value);
                    searchItem();
                  }
                }}
                onChange={(e) => setSearchVal(e.target.value)}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>

            {props.handleFilter !== null ? (
              <>
                <button
                  className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
                  onClick={() => handleFilter(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    style={{ marginRight: "10px" }}
                    className="fa-cloud-arrow-down"
                  />
                  Filter
                </button>
              </>
            ) : null}

            <button
              className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={handleDownloadExcel}
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ marginRight: "10px" }}
                className="fa-cloud-arrow-down"
              />
              Download
            </button>
          </div>
        </div>

        <div className="overflow-x-scroll px-3 mt-3">
          <table className="table table table-sm custom-table align-middle">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      key={index}
                      className="text-danger"
                    >
                      {column.render("Header")}
                      <span className="ms-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <td colSpan={headers.length}>No data available in table</td>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="text-start"
                            style={{
                              backgroundColor:
                                row.original.renewal_days < 0
                                  ? "#fca5a5"
                                  : row.original.renewal_days < 1
                                  ? "#fde047"
                                  : "#FFFFFF",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>{" "}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1  btn btn-outline-danger btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div className="d-flex justify-content-end">
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        size="l"
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form onSubmit={(e) => filterSpk(e)}>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Group Employee</Form.Label>
                <div className="w-100 ">
                  <ReactSelect
                    value={filterGroupTemp}
                    setValue={setFilterGroupTemp}
                    data={[
                      { value: "internal", label: "Internal Employee" },
                      { value: "vendor", label: "Vendor Employee" },
                    ]}
                    isLoading={isLoading}
                    placeholder={"-- Select Group --"}
                    required={false}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semibold">Range End Date</Form.Label>
                <div className="d-flex">
                  <div className="w-50">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={filterStartDateTemp}
                      onChange={(e) => setFilterStartDateTemp(e)}
                      placeholderText="DD/MM/YYYY"
                      isClearable
                    />
                  </div>
                  <div className="px-2 mt-1"> - </div>
                  <div className="w-50">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={filterEndDateTemp}
                      onChange={(e) => setFilterEndDateTemp(e)}
                      placeholderText="DD/MM/YYYY"
                      isClearable
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="d-grid gap-2">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow">
                  FILTER
                </button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        //  size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalRenewalSpk data={formData} renewalStatus={setRenewalStatus} />
      </Modal>

      <Modal
        show={showDetail}
        onHide={handleCloseDetail}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalDetailSpk
          data={nik}
          alert={props.error}
          accessMenu={accessMenu}
          setActionEdit={setActionEdit}
        />
      </Modal>
    </>
  );
}
export default TableSPK;
