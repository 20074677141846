import {
  faAnglesLeft,
  faAnglesRight,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function TableImportFailed(props) {
  const columnsImport = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Client Name",
        accessor: "client_name",
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
      },
      {
        Header: "End Date",
        accessor: "end_date",
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Error",
        accessor: "error_message",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  const data = props.data === null ? EmptyData : props.data;
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: columnsImport,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="overflow-x-scroll">
        <table className="table table table-sm custom-table align-middle">
          <thead className="text-danger align-middle nowrap">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    key={index}
                    className="text-danger"
                  >
                    {column.render("Header")}
                    <span className="ms-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {props.data === null ? (
            <tbody style={{ backgroundColor: "#ececec", textAlign: "center" }}>
              <td colSpan={columnsImport.length}>No data available in table</td>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()} className="text-start">
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td {...cell.getCellProps()} key={index}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination d-flex justify-content-center">
        <button
          className="ms-1 me-2 btn btn-danger btn-sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            className="mt-1 me-1"
            icon={faAnglesLeft}
            style={{ color: "#fff" }}
          />
          First
        </button>
        {""}
        <button
          className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"< Prev"}
        </button>{" "}
        {pageIndex > 4 ? (
          <span className="align-text-bottom bg-danger me-4">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        {pageOptions
          .slice(
            pageIndex < 5
              ? 0
              : pageIndex >= pageCount - 4
              ? pageCount - 9
              : pageIndex - 4,
            pageIndex < 5
              ? 9
              : pageIndex >= pageCount - 4
              ? pageCount
              : pageIndex + 5
          )
          .map((index, i) => {
            if (index === pageIndex) {
              return (
                <button
                  className="ms-1 btn btn-danger btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            } else {
              return (
                <button
                  className="ms-1 btn btn-outline-danger btn-border btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage && index === pageCount}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            }
          })}
        {pageIndex + 1 < pageOptions.length - 3 ? (
          <span className="align-text-bottom bg-danger ms-3 me-3">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        <button
          className="ms-3 btn btn-outline-danger btn-border btn-sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {"Next >"}
        </button>{" "}
        <button
          className="ms-2 me-1 btn btn-danger fa btn-sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
          <FontAwesomeIcon
            className="mt-1 ms-1"
            icon={faAnglesRight}
            style={{ color: "#fff", float: "right" }}
          />
        </button>{" "}
      </div>
      <div className="d-flex justify-content-end">
        Page{" "}
        <strong className="ms-1">
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </div>
    </>
  );
}

export default TableImportFailed;
