import { Tab, Tabs } from "react-bootstrap";
import Ticket from "./tabs/Ticket";
import LoginHistory from "./tabs/LoginHistory";
import { useOutletContext } from "react-router-dom";

function Tickets() {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Master User"
  ).sub_menu;
  const accessMenu = access.find((data) => data.sub_menu === "Tickets");
  return (
    <>
      <div className="w-100">
        <Tabs>
          <Tab eventKey={1} title="Ticket">
            <Ticket accessMenu={accessMenu} />
          </Tab>
          <Tab eventKey={2} title="Login History">
            <LoginHistory accessMenu={accessMenu} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default Tickets;
