import { Alert, Button, Modal } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faMagnifyingGlass,
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { getTimesheetHistory } from "../../../data-api/timesheet";
import ModalEditUpload from "./ModalEditUpload";
import { FormatDate } from "../../template/format";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import ModalShowPDF from "../../../utils/ModalShowPDF";

function ModalHistory(props) {
  const [modalTitle, setModalTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setModalData] = useState([]);

  const [searchVal, setSearchVal] = useState("");

  const [Msg, setMsg] = useState("");

  // Show/Close Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  useEffect(() => {
    DataHistory();
  }, []);

  const handleShowEdit = async (title, id) => {
    await setModalTitle("");
    const data = {
      title: title,
      id: id,
    };
    setModalData(data);
    setModalTitle(title);
    handleClose();
  };

  function splitLink(url) {
    const splitUrl = url.split(".pdf");
    let newUrl = splitUrl[0] + ".pdf";
    return newUrl;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id", // accessor is the "key" in the data
        id: "id",
      },
      {
        Header: "Periode",
        accessor: "periode", // accessor is the "key" in the data
      },
      {
        Header: "Submitted Date",
        accessor: "submitted_date", // accessor is the "key" in the data
        Cell: (props) => FormatDate(props.value),
      },
      {
        Header: "Timesheet",
        accessor: "timesheet_url", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <div style={{ "text-align": "center" }}>
            <button
              style={{
                padding: "0",
                border: "none",
                background: "none",
                textDecoration: "underline",
                color: "#0d6efd",
              }}
              href={`#`}
              onClick={() =>
                handleShowPDF(
                  `${splitLink(row.values.timesheet_url)}? ${Date.now()}`
                )
              }
            >
              {row.values.timesheet_url ? "Preview" : null}
            </button>
          </div>
        ),
      },
      {
        Header: "BAST",
        accessor: "bast_url",
        Cell: ({ row }) => (
          <div style={{ "text-align": "center" }}>
            <button
              style={{
                padding: "0",
                border: "none",
                background: "none",
                textDecoration: "underline",
                color: "#0d6efd",
              }}
              href={`#`}
              onClick={() =>
                handleShowPDF(
                  `${splitLink(row.values.bast_url)}? ${Date.now()}`
                )
              }
            >
              {row.values.bast_url ? "Preview" : null}
            </button>
          </div>
        ),
      },
      {
        Header: "Overtime",
        accessor: "overtime_url",
        Cell: ({ row }) => (
          <div style={{ "text-align": "center" }}>
            <button
              style={{
                padding: "0",
                border: "none",
                background: "none",
                textDecoration: "underline",
                color: "#0d6efd",
              }}
              href={`#`}
              onClick={() =>
                handleShowPDF(
                  `${splitLink(row.values.overtime_url)}? ${Date.now()}`
                )
              }
            >
              {row.values.overtime_url ? "Preview" : null}
            </button>
          </div>
        ),
      },
      {
        Header: "Leave",
        accessor: "leave_url",
        Cell: ({ row }) => (
          <div style={{ "text-align": "center" }}>
            <button
              style={{
                padding: "0",
                border: "none",
                background: "none",
                textDecoration: "underline",
                color: "#0d6efd",
              }}
              href={`#`}
              onClick={() =>
                handleShowPDF(
                  `${splitLink(row.values.leave_url)}? ${Date.now()}`
                )
              }
            >
              {row.values.leave_url ? "Preview" : null}
            </button>
          </div>
        ),
      },
      {
        Header: "Work Days",
        accessor: "work_days", // accessor is the "key" in the data
      },
      {
        Header: "Sick Leave",
        accessor: "sick_leave", // accessor is the "key" in the data
      },
      {
        Header: "Normal Leave",
        accessor: "normal_leave", // accessor is the "key" in the data
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: "edit-timesheet-upload",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEdit("Edit Uploaded Timesheet", row.values.id)
              }
              disabled={!props.accessMenu.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  const [dataTable, setDataTable] = useState([]);

  const DataHistory = () => {
    setIsLoading(true);
    setModalTitle(props.data.title);
    getTimesheetHistory(props.data.nik).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code !== 200) {
        setMsg(result.meta.message);
        setIsLoading(false);
        return;
      }

      setDataTable(result.data.history_timesheets);
      setIsLoading(false);
    });
    handleShow();
  };

  // func export to excel xls
  function handleDownloadExcel() {
    const columnsExcel = [
      "Periode",
      "Submitted Date",
      "Timesheet",
      "BAST",
      "Overtime",
      "Leave",
      "Work Days",
      "Sick Leave",
      "Normal Leave",
    ];
    data.forEach((item) => {
      delete item.id;
      item.submitted_date = FormatDate(item.submitted_date);
      item.timesheet_url =
        item.timesheet_url === "" ? "" : splitLink(item.timesheet_url);
      item.bast_url = item.bast_url === "" ? "" : splitLink(item.bast_url);
      item.overtime_url =
        item.overtime_url === "" ? "" : splitLink(item.overtime_url);
      item.leave_url = item.leave_url === "" ? "" : splitLink(item.leave_url);
    });

    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "History_Timesheet_" + props.data.nik + "_" + props.data.name,
    });
  }

  //initiate empty employee
  const EmptyData = useMemo(() => [], []);

  //catching the data. Purpose is to only have 1 instance.
  const data =
    dataTable === null || dataTable === undefined ? EmptyData : dataTable;
  const {
    rows,
    headers,

    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,

    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ["id"] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        {Msg === "" ? null : (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <strong>{Msg}</strong>
          </Alert>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle} {props.data.nik} - {props.data.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="d-flex justify-content-between mt-3 mb-4 custom-text-14">
              <div className="container text-start text-danger fw-semibold">
                {rows.length} records
              </div>
              <div className="col-8 bd-highlight d-flex justify-content-end">
                <form
                  id="search-reacttable"
                  className="input-group w-50 flex border border-danger rounded"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    type="text"
                    className="form-control from-control-sm"
                    placeholder="Search ..."
                    value={searchVal}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                      if (e.target.value === "") {
                        setGlobalFilter(e.target.value);
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setSearchVal(e.target.value);
                        searchItem();
                      }
                    }}
                  />
                  {searchVal !== "" ? (
                    <button
                      className="btn btn-outline-light text-danger btn-sm"
                      onClick={() => {
                        setSearchVal("");
                        setGlobalFilter("");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: "#848484" }}
                        size="sm"
                      />
                    </button>
                  ) : null}
                  <button
                    className="btn btn-danger"
                    type="button"
                    id="button-addon2"
                    onClick={() => searchItem()}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ color: "#fff", float: "right" }}
                    />
                  </button>
                </form>
                <button
                  className="btn btn-outline-danger ms-3 col-3 fa btn-sm"
                  onClick={handleDownloadExcel}
                >
                  <FontAwesomeIcon
                    icon={faCloudArrowDown}
                    style={{ marginRight: "10px" }}
                    className="fa-cloud-arrow-down"
                  />
                  Download
                </button>
              </div>
            </div>

            <div className="overflow-x-scroll custom-table custom-text-14">
              <table className="table table table-sm align-middle">
                <thead className="text-danger align-middle nowrap">
                  {headerGroups.map((headerGroup, key) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                      {headerGroup.headers.map((column, key) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                          key={key}
                          className="text-danger"
                        >
                          {column.render("Header")}
                          <span className="ms-2">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} />
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {isLoading ? (
                  <>
                    <tbody
                      style={{
                        backgroundColor: "#ececec",
                        textAlign: "center",
                      }}
                      className="custom-text-14"
                    >
                      <td colSpan={headers.length}>
                        <div className="w-100">
                          <div className="shine-photo shine"></div>
                        </div>
                      </td>
                    </tbody>
                  </>
                ) : rows.length === 0 ? (
                  <tbody
                    style={{
                      backgroundColor: "#ececec",
                      textAlign: "center",
                    }}
                  >
                    <td colSpan={headers.length}>No data available in table</td>
                  </tbody>
                ) : (
                  <tbody
                    {...getTableBodyProps()}
                    className="nowrap"
                    style={{ textAlign: "center" }}
                  >
                    {page.map((row, key) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={key}>
                          {row.cells.map((cell, key) => {
                            return (
                              <td {...cell.getCellProps()} key={key}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
            <div className="pagination d-flex justify-content-center">
              <button
                className="ms-1 me-2 btn btn-danger btn-sm"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon
                  className="mt-1 me-1"
                  icon={faAnglesLeft}
                  style={{ color: "#fff" }}
                />
                First
              </button>{" "}
              <button
                className="ms-1 me-3 btn btn-outline-danger  btn-border btn-sm"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"< Prev"}
              </button>{" "}
              {pageIndex > 4 ? (
                <span className="align-text-bottom bg-danger me-4">
                  <p className="mt-3" style={{ position: "absolute" }}>
                    ...
                  </p>
                </span>
              ) : null}
              {pageOptions
                .slice(
                  pageIndex < 5
                    ? 0
                    : pageIndex >= pageCount - 4
                    ? pageCount - 9
                    : pageIndex - 4,
                  pageIndex < 5
                    ? 9
                    : pageIndex >= pageCount - 4
                    ? pageCount
                    : pageIndex + 5
                )
                .map((index, i) => {
                  if (index === pageIndex) {
                    return (
                      <button
                        className="ms-1  btn btn-danger btn-sm"
                        onClick={() => gotoPage(index)}
                        disabled={!canNextPage}
                        key={i}
                      >
                        {index + 1}
                      </button>
                    );
                  } else {
                    return (
                      <button
                        className="ms-1  btn btn-outline-danger  btn-border btn-sm"
                        onClick={() => gotoPage(index)}
                        disabled={!canNextPage && index === pageCount}
                        key={i}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                })}
              {pageIndex + 1 < pageOptions.length - 3 ? (
                <span className="align-text-bottom bg-danger ms-3 me-3">
                  <p className="mt-3" style={{ position: "absolute" }}>
                    ...
                  </p>
                </span>
              ) : null}
              <button
                className="ms-3  btn btn-outline-danger  btn-border btn-sm"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {"Next >"}
              </button>{" "}
              <button
                className="ms-2 me-1 btn btn-danger fa btn-sm"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Last
                <FontAwesomeIcon
                  className="mt-1 ms-1"
                  icon={faAnglesRight}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>{" "}
            </div>
            <div className="d-flex justify-content-end">
              Page{" "}
              <strong className="ms-1">
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </div>
          </>
        </Modal.Body>
      </Modal>
      {modalTitle === "Edit Uploaded Timesheet" ? (
        <ModalEditUpload data={modalData} click={DataHistory} />
      ) : null}

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default ModalHistory;
