import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ModalEditDetailProject from "../modal/ModalEditDetailProject";
import ModalMessage from "../../template/ModalMessage";
import moment from "moment";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "NIK",
    accessor: "nik",
  },
  {
    Header: "Client",
    accessor: "placement",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
  },
  {
    Header: "Project Start",
    accessor: "project_start",
    Cell: (props) =>
      props.value === "0000-00-00" || props.value === ""
        ? ""
        : moment(props.value).format("DD-MMM-YYYY"),
  },
  {
    Header: "Project End",
    accessor: "project_end",
    Cell: (props) =>
      props.value === "0000-00-00" || props.value === ""
        ? ""
        : moment(props.value).format("DD-MMM-YYYY"),
  },
];

function TableProjectHistory(props) {
  const [project, setProject] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  function handleCloseModalMessage() {
    setShowModalMessage(false);
    props.refreshData();
  }

  useEffect(() => {
    setProject(props.data);
    setIsLoading(false);
  }, []);

  function handleShowEdit(id, startDate, endDate) {
    setFormData({
      id: id,
      startDate: startDate,
      endDate: endDate,
    });
    setShowEdit(true);
  }

  // for handle list columns table
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEdit(
                  row.values.id,
                  row.values.project_start,
                  row.values.project_end
                )
              }
              disabled={!props.access.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  function handleDownloadExcel() {
    const excelData = data;
    const columnsExcel = [
      "NIK",
      "Client",
      "Project Name",
      "Project Start",
      "Project End",
    ];
    excelData.forEach((v) => delete v.id);
    excelData.forEach((item) => {
      item.project_start =
        item.project_start === ""
          ? ""
          : moment(item.project_start).format("DD-MMM-YYYY");
      item.project_end =
        item.project_end === ""
          ? ""
          : moment(item.project_end).format("DD-MMM-YYYY");
    });
    const dataSet = [];
    const dataHeader = [];
    //append header table

    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excelData.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: `Project Team History ${props.header.nik} - ${props.header.name}`,
    });
  }

  const EmptyData = useMemo(() => [], []);

  const data = project.length === 0 ? EmptyData : project;

  function handleSuccessModal(data) {
    setModalMessage(data);
    setShowModalMessage(true);
    setShowEdit(false);
  }

  return (
    <>
      <div className="mt-4">
        <ReactTableCustomHooks
          columns={columns}
          data={data}
          isLoading={isLoading}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          tableHooks={tableHooks}
          hiddenColumns={["id"]}
        />
      </div>

      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditDetailProject
          data={formData}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      {showModalMessage ? (
        <ModalMessage data={modalMessage} click={handleCloseModalMessage} />
      ) : null}
    </>
  );
}

export default TableProjectHistory;
