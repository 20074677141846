import TableImportFailed from "./TableImportFailed";
import TableImportSuccess from "./TableImportSuccess";

function TableImport(props) {
  return (
    <>
      <h3>Success Import Internal Contract</h3>
      <TableImportSuccess data={props.data.data.success_import} />
      {/* <TableImportSuccess data={[]} /> */}
      <h3>Failed Import Internal Contract</h3>
      <TableImportFailed data={props.data.data.failed_import} />
      {/* <TableImportFailed data={[]} /> */}
    </>
  );
}

export default TableImport;
