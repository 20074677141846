import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getDropdownPSManager,
  getDropdownTeamLeader,
} from "../../../data-api/tl-structure";
import Select from "react-select";

function TLStructureFilter(props) {
  const [listPSManager, setListPSManager] = useState([]);
  const [listTeamLeader, setListTeamLeader] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [psManager, setPsManager] = useState(props.filterPSManager);
  const [teamLeader, setTeamLeader] = useState(props.filterTeamLeader);

  useEffect(() => {
    fetchPsManager();
    if (props.filterPSManager !== "") {
      fetchTeamLeader(props.filterPSManager.value);
    }
  }, []);

  function fetchPsManager() {
    getDropdownPSManager().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListPSManager([]);
      } else {
        setListPSManager(result.data);
      }
      if (props.filterTeamLeader === "") {
        setIsLoading(false);
      }
    });
  }

  function fetchTeamLeader(psManager) {
    let params = {
      ps_manager: psManager,
    };
    getDropdownTeamLeader(params).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListTeamLeader([]);
      } else {
        setListTeamLeader(result.data);
      }
      setIsLoading(false);
    });
  }

  function filter(event) {
    event.preventDefault();
    props.filter(psManager, teamLeader);
  }

  if (isLoading)
    return (
      <>
        <div className="container  mt-4">
          <div className="align-items-center justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>TL Structure Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={(event) => filter(event)}>
          <Form.Group className="mb-3 fw-semibold">
            <Form.Label>PS Manager</Form.Label>
            <Select
              className="w-100 fw-normal custom-text-14 z-3"
              options={listPSManager.map((item) => ({
                value: item.nik,
                label: item.name,
              }))}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 35,
                  minHeight: 35,
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: "14px",
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              isClearable
              placeholder={"-- Select PS Manager --"}
              defaultValue={psManager}
              onChange={(e) => {
                setTeamLeader("");
                if (e !== null) {
                  setPsManager(e);
                  fetchTeamLeader(e.value);
                } else {
                  setTeamLeader("");
                  setListTeamLeader([]);
                  setPsManager("");
                  setListPSManager([]);
                }
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold">
            <Form.Label>Team Leader</Form.Label>
            <Select
              className="w-100 fw-normal custom-text-14 z-3"
              options={
                !isLoading &&
                listTeamLeader.map((data) => ({
                  label: data.name,
                  value: data.nik,
                }))
              }
              styles={{
                control: (base) => ({
                  ...base,
                  height: 35,
                  minHeight: 35,
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: "14px",
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              placeholder={"-- Select Team Leader --"}
              value={teamLeader}
              defaultValue={teamLeader}
              isClearable
              onChange={(e) => {
                if (e !== null) {
                  setTeamLeader(e);
                } else {
                  setTeamLeader("");
                }
              }}
            ></Select>
          </Form.Group>
          <div className="d-grid gap-2">
            <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow btn-sm">
              Filter
            </button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
}

export default TLStructureFilter;
