import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { components } from "react-select";
import Select from "react-select";

function InvalidInput(props) {
  return (
    <components.Input
      {...props}
      required={
        props.selectProps.value === null || props.selectProps.value === ""
          ? true
          : false
      }
      onInvalid={(e) =>
        e.target.setCustomValidity(props.selectProps.ariaLiveMessages)
      }
      onInput={(e) => e.target.setCustomValidity("")}
    />
  );
}

function ReactSelect(props) {
  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  const [required, setRequired] = useState(true);
  const [value, setValue] = useState("");

  useEffect(() => {
    let getValue = props.data?.find((item) => item.label === props.value.label);
    props.setValue(getValue === undefined ? props.value : getValue);
    setValue(getValue === undefined ? props.value : getValue);
    setRequired(props.required === undefined ? true : props.required);
  }, []);

  if (!required) {
    return (
      <>
        <Select
          className="w-100 fw-normal custom-text-14 z-3"
          options={!props.isLoading && props.data}
          styles={{
            control: (base) => ({
              ...base,
              height: 35,
              minHeight: 35,
            }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: "14px" }),
          }}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          isClearable
          // value={type}
          placeholder={props.placeholder}
          defaultValue={value}
          onChange={(e) => {
            if (e !== null) {
              props.setValue(e);
            } else {
              props.setValue("");
            }
          }}
          isDisabled={props.disabled === undefined ? false : props.disabled}
        ></Select>
      </>
    );
  } else {
    return (
      <>
        <Select
          className="w-100 fw-normal custom-text-14 z-3"
          options={!props.isLoading && props.data}
          styles={{
            control: (base) => ({
              ...base,
              height: 35,
              minHeight: 35,
            }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: "14px" }),
          }}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          isClearable
          // value={type}
          placeholder={props.placeholder}
          defaultValue={props.value}
          onChange={(e) => {
            if (e !== null) {
              props.setValue(e);
            }
          }}
          ariaLiveMessages={props.validationMessage}
          components={{
            Input: invalidInput,
          }}
          isDisabled={props.disabled === undefined ? false : props.disabled}
        ></Select>
      </>
    );
  }
}

export { InvalidInput, ReactSelect };
