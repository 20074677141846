import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FormatDate } from "../../template/format";
import writeXlsxFile from "write-excel-file";
import { Form } from "react-bootstrap";
import { getContractReview } from "../../../data-api/talent-review";

const columns = [
  {
    Header: "NIK",
    accessor: "nik",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Work Status",
    accessor: "work_status",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Grade",
    accessor: "grade",
  },
  {
    Header: "End Spk",
    accessor: "end_spk",
    Cell: (props) => FormatDate(props.value),
  },
  {
    Header: "Start PKWT",
    accessor: "start_pkwt",
    Cell: (props) => FormatDate(props.value),
  },
  {
    Header: "End PKWT",
    accessor: "end_pkwt",
    Cell: (props) => FormatDate(props.value),
  },
  {
    Header: "Renewal",
    accessor: "renewal",
  },
  {
    Header: "CR Date",
    accessor: "cr_date",
    Cell: (props) => FormatDate(props.value),
  },
];

function TableContractReview(props) {
  const [searchVal, setSearchVal] = useState("");
  const [ContractReview, setContractReview] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refreshDataContract();
  }, []);

  const refreshDataContract = async () => {
    getContractReview().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data === null ? setContractReview([]) : setContractReview(res.data);
      } else {
        setContractReview([]);
      }
      setIsLoading(false);
    });
  };

  function handleDownloadExcel() {
    data.forEach((item) => {
      item.end_spk = FormatDate(item.end_spk);
      item.start_pkwt = FormatDate(item.start_pkwt);
      item.end_pkwt = FormatDate(item.end_pkwt);
      item.cr_date = FormatDate(item.cr_date);
    });

    const dataSet = [];
    const dataHeader = [];
    columns.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Contract_Review",
    });
  }

  const EmptyData = useMemo(() => [], []);

  // for handle list columns table
  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [...columns]);
  };

  const data = ContractReview.length === 0 ? EmptyData : ContractReview;
  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  return (
    <div className="tabs-content">
      <div className="row custom-text-14 w-100 ms-1 mb-3">
        <div className="col-4 text-start text-danger fw-semibold d-flex">
          <div className={"mt-1"}>{rows.length} records</div>
          <div className="ms-4">
            <Form.Select
              className="form-select-sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="col-8 bd-highlight d-flex justify-content-end">
          <form
            id="search-reacttable"
            className="input-group w-50 flex border border-danger rounded"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search ..."
              value={searchVal}
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              onChange={(e) => {
                setSearchVal(e.target.value);
                if (e.target.value === "") {
                  setGlobalFilter(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setGlobalFilter(e.target.value);
                }
              }}
            />
            {searchVal !== "" ? (
              <button
                className="btn btn-outline-light text-danger btn-sm"
                onClick={() => {
                  setSearchVal("");
                  setGlobalFilter("");
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ color: "#848484" }}
                  size="sm"
                />
              </button>
            ) : null}
            <button
              className="btn btn-danger"
              id="button-addon2"
              onClick={() => setGlobalFilter(searchVal)}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </form>
          <button
            className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
            onClick={handleDownloadExcel}
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ marginRight: "10px" }}
            />
            Download
          </button>
        </div>
      </div>
      <div className="overflow-x-scroll">
        <table className="table table table-sm align-middle custom-table">
          <thead className="text-danger align-middle nowrap">
            {headerGroups.map((headerGroup, key) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    key={key}
                    className="text-danger"
                  >
                    {column.render("Header")}
                    <span className="ms-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isLoading ? (
            <>
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
                className="custom-text-14"
              >
                <td colSpan={headers.length}>
                  <div className="w-100">
                    <div className="shine-photo shine"></div>
                  </div>
                </td>
              </tbody>
            </>
          ) : rows.length === 0 ? (
            <tbody style={{ backgroundColor: "#ececec", textAlign: "center" }}>
              <td colSpan={headers.length}>No data available in table</td>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()} className="text-start">
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={key}>
                    {row.cells.map((cell, key) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={key}
                          style={{
                            backgroundColor:
                              row.original.renewal <= 0 ? "#FFC167" : "#FFFFFF",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination d-flex justify-content-center">
        <button
          className="ms-1 me-2 btn btn-danger btn-sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            className="mt-1 me-1"
            icon={faAnglesLeft}
            style={{ color: "#fff" }}
          />
          First
        </button>
        {""}
        <button
          className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"< Prev"}
        </button>{" "}
        {pageIndex > 4 ? (
          <span className="align-text-bottom bg-danger me-4">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        {pageOptions
          .slice(
            pageIndex < 5
              ? 0
              : pageIndex >= pageCount - 4
              ? pageCount - 9
              : pageIndex - 4,
            pageIndex < 5
              ? 9
              : pageIndex >= pageCount - 4
              ? pageCount
              : pageIndex + 5
          )
          .map((index, i) => {
            if (index === pageIndex) {
              return (
                <button
                  className="ms-1 btn btn-danger btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            } else {
              return (
                <button
                  className="ms-1 btn btn-outline-danger btn-border btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage && index === pageCount}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            }
          })}
        {pageIndex + 1 < pageOptions.length - 3 ? (
          <span className="align-text-bottom bg-danger ms-3 me-3">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        <button
          className="ms-3 btn btn-outline-danger btn-border btn-sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {"Next >"}
        </button>{" "}
        <button
          className="ms-2 me-1 btn btn-danger fa btn-sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
          <FontAwesomeIcon
            className="mt-1 ms-1"
            icon={faAnglesRight}
            style={{ color: "#fff", float: "right" }}
          />
        </button>{" "}
      </div>
      <div
        className="d-flex justify-content-end mb-2 me-2"
        style={{ color: "#525555" }}
      >
        Page{"."}
        <strong className="ms-1">
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </div>
    </div>
  );
}

export default TableContractReview;
