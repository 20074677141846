import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getListDropdownTalentP,
  postTalentPartnershipDetail,
} from "../../../data-api/talent-partnership";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FormatDate } from "../../template/format";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import { ReactSelect } from "../../../utils/ReactSelect";
registerLocale("en-GB", enGb);

function ModalAddTalentProject(props) {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getListDropdownTalentP().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        setClients(res.data.placements);
      } else {
        showAlertError(true, res.meta.message);
      }
      setIsLoading(false);
    });
  }, []);

  const [client, setClient] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [addConfirm, setAddConfirm] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const handleAddTalentProject = (e) => {
    e.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      setIsFetch(true);

      const data = {
        nik: props.data.nik,
        client_id: parseInt(clients[client].value),
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };

      postTalentPartnershipDetail(data)
        .then((res) => {
          if (res.meta.code === 401) {
            window.location.reload();
            return;
          }
          const message = {
            code: res.meta.code,
            message: res.meta.message,
          };

          props.message(message);
          props.close();
          setIsFetch(false);
        })
        .catch(() => {
          setIsFetch(false);
        });
    }
  };

  const ModalAdd = () => {
    return (
      <Form onSubmit={handleAddTalentProject} className="custom-text-14">
        <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
          <Form.Label className="mt-2">Employee</Form.Label>
          <Form.Label className="mt-2 w-75">
            {props.data.nik} - {props.data.name}
          </Form.Label>
          {/* <Form.Control
            
            defaultValue=
            disabled
          /> */}
        </Form.Group>
        <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
          <Form.Label className="mt-2">Client</Form.Label>
          <div className="w-75">
            <ReactSelect
              value={client}
              setValue={setClient}
              data={clients.map((data) => ({
                label: data.label,
                value: data.value,
              }))}
              isLoading={isLoading}
              placeholder={"-- Select Client --"}
              validationMessage={"Please Select Client"}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between">
          <Form.Label className="mt-2 fw-semibold">Start Date</Form.Label>
          <div className="w-75">
            <ReactDatePicker
              locale={enGb}
              className="w-100 form-control"
              wrapperClassName="w-100 form-control"
              todayButton="Today"
              dateFormat="dd/MM/yyyy"
              selected={startDate ? startDate : ""}
              onChange={(value) => {
                setStartDate(value);
              }}
              isClearable
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              placeholderText="DD/MM/YYYY"
              onInvalid={(e) =>
                e.target.setCustomValidity("Please fill out Start Date")
              }
              maxDate={endDate}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between">
          <Form.Label className="mt-2 fw-semibold">End Date</Form.Label>
          <div className="w-75">
            <ReactDatePicker
              locale={enGb}
              className="w-100 form-control"
              wrapperClassName="w-100 form-control"
              todayButton="Today"
              dateFormat="dd/MM/yyyy"
              selected={endDate ? endDate : ""}
              onChange={(value) => {
                setEndDate(value);
              }}
              isClearable
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              placeholderText="DD/MM/YYYY"
              onInvalid={(e) =>
                e.target.setCustomValidity("Please fill out End Date")
              }
              minDate={startDate}
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </div>
        </Form.Group>
        <div className="d-flex flex-row-reverse bd-highlight">
          <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm">
            Confirm
          </button>
        </div>
      </Form>
    );
  };

  const ModalConfirm = () => {
    return (
      <>
        <div className="container custom-text-14">
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Employee</Form.Label>
            <Form.Label className="mt-2">
              {props.data.nik} - {props.data.name}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Placement</Form.Label>
            <Form.Label className="mt-2">{clients[client].label}</Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">Start Date</Form.Label>
            <Form.Label className="mt-2">{FormatDate(startDate)}</Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
            <Form.Label className="mt-2">End Date</Form.Label>
            <Form.Label className="mt-2">{FormatDate(endDate)}</Form.Label>
          </Form.Group>
          <div className="d-flex flex-row-reverse bd-highlight">
            <button
              className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              onClick={handleAddTalentProject}
              disabled={isFetch}
            >
              {isFetch ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
              onClick={() => setAddConfirm(false)}
            >
              Back
            </button>
          </div>
        </div>
      </>
    );
  };

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) return <span>Loading...</span>;

  return (
    <>
      {showAlert ? (
        <div
          className={
            AlertSuccess
              ? "alert alert-success alert-dismissible fade show"
              : "alert alert-danger alert-dismissible fade show"
          }
          role="alert"
        >
          <strong>{AlertMessage}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title>
          {!addConfirm ? "Add New Project" : "Confirm Add New Project"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {!addConfirm ? <ModalAdd /> : <ModalConfirm />}
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalAddTalentProject;
