import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faAnglesLeft,
  faAnglesRight,
  faCircleCheck,
  faCircleXmark,
  faList,
  faMagnifyingGlass,
  faPlusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import ModalEditRole from "./ModalEditRole";
import { useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import moment from "moment";
import { axiosError } from "../../../utils/util";
import ModalDetailRole from "./ModalDetailRole";

function IntegralsRole() {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Master User"
  ).sub_menu;
  const accessMenu = access.find((data) => data.sub_menu === "Integrals Role");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [showAddAccess, setShowAddAccess] = useState(false);

  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [addRole, setAddRole] = useState("");

  const [showEdit, setShowEdit] = useState(false);
  const [roleDetail, setRoleDetail] = useState({});

  const [showDetail, setShowDetail] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    fetchUserRole();
  }, []);

  function fetchUserRole() {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/super-admin/role`, {
        headers: {
          Authorization: `Bearer ${cookies.get("session")}`,
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then((res) => {
        if (res.data.data !== null) {
          setData(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // let errorResponse = axiosError(error);
        // handleSuccessModal(false, errorResponse.meta);
      });
  }

  function resetTable() {
    fetchUserRole();
    setShowAddAccess(false);
    setShowEdit(false);
    setShowSuccess(false);
  }

  function handleSuccessModal(isSuccess, message) {
    setIsPostSuccess(isSuccess);
    setPostSuccessMessage(message);
    setShowAddAccess(false);
    setShowEdit(false);
    setShowSuccess(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 10,
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Role",
        accessor: "role_name",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "",
        accessor: "space",
        Cell: (props) => {
          return (
            <span
              className="table-custom-superadmin-name"
              style={{ width: "300px" }}
            >
              {props.value}
            </span>
          );
        },
        maxWidth: 200,
      },
    ],
    []
  );

  // add custom new cell for button detail & renewal
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "menu",
        Header: "",
        maxWidth: 20,
        Cell: ({ row }) => (
          <div className="text-end">
            <button
              className="btn"
              onClick={() => {
                setRoleDetail(row.original);
                setShowDetail(true);
              }}
              disabled={!accessMenu.is_read}
            >
              <FontAwesomeIcon
                size="xl"
                icon={faList}
                style={{ color: "#dc3545" }}
              />
            </button>
            <button
              className="btn"
              onClick={() => {
                setRoleDetail(row.original);
                setShowEdit(true);
              }}
              disabled={
                !accessMenu.is_update ||
                (row.original.role_name === "Superadmin" &&
                  outletContext.profile.role_id !== "1")
              }
            >
              <FontAwesomeIcon
                size="xl"
                icon={faPenToSquare}
                style={{ color: "#dc3545" }}
              />
            </button>
          </div>
        ),
      },
    ]);
  };

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination,
    tableHooks
  );

  function AddNewRole(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setIsConfirm(true);
      } else {
        const json = JSON.stringify({
          role_name: addRole,
        });

        axios
          .post(`${process.env.REACT_APP_API_URL}/super-admin/role`, json, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookies.get("session")}`,
              XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
            },
          })
          .then((res) => {
            const message = {
              code: res.data.meta.code,
              message: res.data.meta.message,
            };
            handleSuccessModal(true, message.message);
          })
          .catch((error) => {
            let errorResponse = axiosError(error);
            handleSuccessModal(false, errorResponse.meta);
          });
        setIsConfirm(false);
      }
    } else {
      setIsConfirm(false);
    }
  }

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="w-100 tabs-content rounded">
        <div className="row custom-text-14 w-100 ms-1">
          <div className="col-4 text-start text-danger fw-semibold d-flex">
            <div className={"mt-1"}>{rows.length} records</div>
          </div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <form
              id="search-reacttablecustom"
              className="input-group w-50 flex border border-danger rounded"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search ..."
                value={searchVal}
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  if (e.target.value === "") {
                    searchItem();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchVal(e.target.value);
                    searchItem();
                  }
                }}
              />
              {searchVal !== "" ? (
                <button
                  className="btn btn-outline-light text-danger btn-sm"
                  onClick={() => {
                    setSearchVal("");
                    setGlobalFilter("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#848484" }}
                    size="sm"
                  />
                </button>
              ) : null}
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => searchItem()}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>
            </form>
            <button
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={() => setShowAddAccess(true)}
              disabled={!accessMenu.is_create}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginRight: "10px" }}
              />
              Add Role
            </button>
          </div>
        </div>

        <div className="overflow-x-scroll">
          <table className="table-custom-superadmin">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                  {headerGroup.headers.map((column, key) => (
                    <th
                      {...column.getHeaderProps(column)}
                      scope="col"
                      key={key}
                      className="text-start text-danger"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <tr>
                  <td colSpan={headers.length}>No data available in table</td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()} className="nowrap text-start">
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={key}>
                      {row.cells.map((cell, key) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={key}
                            style={{
                              maxWidth: cell.column.maxWidth,
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>
          {""}
          <button
            className="ms-1 me-3 btn btn-outline-danger btn-border"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1 btn btn-danger"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1 btn btn-outline-danger btn-border"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3 btn btn-outline-danger btn-border"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div
          className="d-flex justify-content-end mb-2 me-2"
          style={{ color: "#525555" }}
        >
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>

      <Modal
        show={showAddAccess}
        onHide={() => setShowAddAccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={AddNewRole} className="mt-3 custom-text-14">
            {!isConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">Role</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    onChange={(e) => setAddRole(e.target.value)}
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Role")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Role</Form.Label>
                  <Form.Label className="w-75">{addRole}</Form.Label>
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-outline-danger mt-3 col-4 me-3"
                    type="submit"
                    name="cancel"
                    value={"Cancel"}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="container d-flex flex-column py-4 ">
            {isPostSuccess ? (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleXmark}
                  size="10x"
                  color="red"
                />
              </>
            )}

            <h4 style={{ textAlign: "center" }}>{postSuccessMessage}</h4>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary mt-2 col-3 btn-sm"
                onClick={() => resetTable()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalEditRole
          data={roleDetail}
          handleSuccessModal={handleSuccessModal}
        />
      </Modal>

      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <ModalDetailRole detailData={roleDetail} />
      </Modal>
    </>
  );
}

export default IntegralsRole;
