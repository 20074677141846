import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Modalresign.css";
import { Tabs, TabPanel } from "react-tabs";
import { getResignDetail } from "../../../data-api/resign";
import LoadingEffectForm from "../../template/loadingEffectForm";

function Detail(props) {
  const [items, setitems] = useState([]);
  const [DataisLoaded, setDataisLoaded] = useState(true);

  useEffect(() => {
    getResignDetail(props.data.original.nik).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setitems([])
        : setitems(result.data[0]);
      setDataisLoaded(false);
    });
  }, []);

  if (DataisLoaded)
    return (
      <>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Personal Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2 custom-text-14">
          <LoadingEffectForm row={10} />
        </Modal.Body>
      </>
    );

  return (
    <>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Personal Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-2 custom-text-14">
        <td style={{ fontWeight: "bold", fontSize: "22px" }}>
          {items.nik} - {items.employee_name}
        </td>
        <Tabs>
          <TabPanel>
            <div className="data-resign">
              <table>
                <tbody>
                  <tr>
                    <th>Birth Place / Date</th>
                    <td>
                      {items.birth_place} / {items.birth_date}
                    </td>
                  </tr>
                  <tr>
                    <th>Bank Acc</th>
                    <td>
                      {items.bank_acc_number} - {items.bank_acc_name}
                    </td>
                  </tr>
                  <tr>
                    <th>BPJS Kesehatan</th>
                    <td>{items.no_bpjs_ketenagakerjaan}</td>
                  </tr>
                  <tr>
                    <th>Phone No.</th>
                    <td>{items.phone_number}</td>
                  </tr>
                  <tr>
                    <th>Golongan Darah</th>
                    <td>{items.blood_type}</td>
                  </tr>
                  <tr>
                    <th>Marital Status</th>
                    <td>{items.marital_status}</td>
                  </tr>
                  <tr>
                    <th>Personal Email</th>
                    <td>{items.personal_email}</td>
                  </tr>
                  <tr>
                    <th>NPWP No.</th>
                    <td>{items.npwp_no}</td>
                  </tr>
                  <tr>
                    <th>KK No.</th>
                    <td>{items.kk_no}</td>
                  </tr>
                  <tr>
                    <th>Last Education</th>
                    <td>{items.last_education}</td>
                  </tr>
                  <tr>
                    <th>Major</th>
                    <td>{items.educational_major}</td>
                  </tr>
                  <tr>
                    <th>Degree</th>
                    <td>{items.educational_background}</td>
                  </tr>
                  <tr>
                    <th>Religion</th>
                    <td>{items.religion}</td>
                  </tr>
                  <tr>
                    <th>Marital Tax Status</th>
                    <td className="text-nowrap">{items.marital_tax_status}</td>
                  </tr>
                  <tr>
                    <th>Client</th>
                    <td>{items.client_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-danger" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </>
  );
}

export default Detail;
