import { useMemo } from "react";
import ReactTable from "../../../utils/ReactTable";
import { FormatDate } from "../../template/format";
import writeXlsxFile from "write-excel-file";

function TableImportFailed(props) {
  const columnsImport = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => FormatDate(props.value),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => (props.values !== "" ? FormatDate(props.value) : "-"),
      },
      {
        Header: "No. PKWT",
        accessor: "no_pkwt",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Error",
        accessor: "error_message",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  const data = props.data === null ? EmptyData : props.data;
  function handleDownloadExcel() {
    const excelData = data;
    const columnsExcel = columnsImport.map((item) => item.Header);

    const dataSet = [];
    const dataHeader = [];
    //append header table
    // const columnsExcel = Object.keys(excelData[0]);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excelData.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: `PKWT Import Failed`,
    });
  }

  return (
    <>
      <ReactTable
        columns={columnsImport}
        data={data}
        handleDownloadExcel={handleDownloadExcel}
        handleFilter={null}
        hiddenColumns={[]}
      />
    </>
  );
}

export default TableImportFailed;
