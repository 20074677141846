import { Modal } from "react-bootstrap";
import Chart from "react-apexcharts";

function ZoomChart(props) {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{props.data.options.chart.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Chart
          options={props.data.options}
          series={props.data.series}
          type={props.data.chart_type}
          width="100%"
          height="500px"
        />
      </Modal.Body>
    </>
  );
}

export default ZoomChart;
