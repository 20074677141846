import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { getAddSpkData, postAddSpk } from "../../../data-api/resource-team";
import { useOutletContext } from "react-router-dom";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import { ReactSelect } from "../../../utils/ReactSelect";
import ModalShowPDF from "../../../utils/ModalShowPDF";
import LoadingEffectForm from "../../template/loadingEffectForm";
import ModalMessage from "../../template/ModalMessage";
registerLocale("en-US", enUs);

function ModalRenewalSpk(props) {
  const formData = props.data;

  const [addModal, setAddModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);
  const [addSuccess, setAddSuccess] = useState({});

  const [nik, setNik] = useState(formData.nik);
  const [placement, setPlacement] = useState("");
  const [startDate, setStartDate] = useState(
    !isNaN(new Date(formData.start_date))
      ? new Date(formData.start_date)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    !isNaN(new Date(formData.end_date))
      ? new Date(formData.end_date)
      : new Date()
  );
  const [approveBy, setApproveBy] = useState("");
  const contractTitle =
    "Perjanjian Kerja Sama Pengadaan Jasa Penyediaan Tenaga Ahli Profesional TI BPJS Kesehatan";
  const contractNo = "470/KTR/1022";
  const outletContext = useOutletContext();

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  useEffect(() => {
    getAddSpkData().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setAddModal({});
      } else {
        setAddModal(result.data);
        setPlacement(
          result.data.placement.find((x) => x.value === formData.client_id)
        );
      }
      setIsLoading(false);
    });
  }, []);

  function formAddSpk(event) {
    event.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      setIsFetch(true);
      fetchCreateSpk();
    }
  }

  function fetchCreateSpk() {
    const json = JSON.stringify({
      nik: nik,
      start_date: moment(startDate).format("yyyy-MM-DD"),
      end_date: moment(endDate).format("yyyy-MM-DD"),
      client_id: placement.value,
      approve_by: approveBy.value,
      contract_title: contractTitle,
      contract_no: contractNo,
      created_by: outletContext.profile.nik,
    });

    postAddSpk(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        handleOpenModalSuccess(result);
        setAddConfirm(false);
      } else {
        setAddSuccess(result);
        setNik("-1");
        setPlacement("-1");
        setStartDate("");
        setEndDate("");
        setIsFetch(false);
        props.renewalStatus(true);
      }
      setIsFetch(false);

      setIsLoading(false);
    });
  }

  const [modalSuccessData, setModalSuccessData] = useState({});
  const [modalSuccess, setModalSuccess] = useState(false);

  function handleOpenModalSuccess(data) {
    setModalSuccessData(data);
    setModalSuccess(true);
  }

  function handleCloseModalSuccess() {
    setModalSuccess(false);
  }

  if (isLoading)
    return (
      <>
        <LoadingEffectForm row={8} />
      </>
    );

  return (
    <>
      <div className="custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>
            {Object.keys(addSuccess).length === 0 && !addConfirm
              ? "Renewal SPK"
              : Object.keys(addSuccess).length === 0 && addConfirm
              ? "Confirm Renewal SPK"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status === "success"
              ? "Success Renewal SPK"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status !== "success"
              ? "Failed Renewal SPK"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => formAddSpk(e)}>
            {Object.keys(addSuccess).length === 0 && !addConfirm ? (
              <div className="container">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2 custom-text-14">Type</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    aria-describedby="basic-addon1"
                    defaultValue={"SPK " + (parseInt(formData.spk_type) + 1)}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2 custom-text-14">
                    Employee
                  </Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    aria-describedby="basic-addon1"
                    defaultValue={formData.employee_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2 custom-text-14">
                    No. SPK
                  </Form.Label>
                  <Form.Control
                    defaultValue={nik.slice(-3)}
                    className="w-75 form-control-sm"
                    aria-describedby="basic-addon1"
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2 custom-text-14">
                    Placement
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={placement}
                      setValue={setPlacement}
                      data={addModal.placement}
                      isLoading={isLoading}
                      placeholder={"-- Select Placement --"}
                      validationMessage={"Please Select Placement"}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-between">
                  <Form.Label className="mt-2 fw-semibold custom-text-14">
                    Start Date
                  </Form.Label>
                  <div className="w-75">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate ? startDate : ""}
                      onChange={(e) => setStartDate(e)}
                      placeholderText="DD/MM/YYYY"
                      onInvalid={(e) => false}
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                      isClearable
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-between">
                  <Form.Label className="mt-2 fw-semibold custom-text-14">
                    End Date
                  </Form.Label>
                  <div className="w-75">
                    <ReactDatePicker
                      locale={enUs}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={endDate ? endDate : ""}
                      onChange={(e) => setEndDate(e)}
                      placeholderText="DD/MM/YYYY"
                      onInvalid={(e) => false}
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                      isClearable
                    />
                  </div>
                </Form.Group>
                {placement.value === "5" ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2 custom-text-14">
                        Contract No
                      </Form.Label>
                      <Form.Control
                        className="w-75 form-control-sm"
                        aria-describedby="basic-addon1"
                        defaultValue={contractNo}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2 custom-text-14">
                        Contract Title
                      </Form.Label>
                      <Form.Control
                        className="w-75 form-control-sm"
                        aria-describedby="basic-addon1"
                        defaultValue={contractTitle}
                        required
                      />
                    </Form.Group>
                  </>
                ) : null}
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2 custom-text-14">
                    Approve By
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={approveBy}
                      setValue={setApproveBy}
                      data={addModal.approve_by}
                      isLoading={isLoading}
                      placeholder={"-- Select Approve By --"}
                      validationMessage={"Please Select Approve By"}
                    />
                  </div>
                </Form.Group>
              </div>
            ) : Object.keys(addSuccess).length === 0 && addConfirm ? (
              <div className="container">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Type</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {"SPK-" + (parseInt(formData.spk_type) + 1)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Employee</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {formData.employee_name}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">No. SPK</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {nik.slice(-3)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Placement</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {placement.label}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {moment(startDate).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {moment(endDate).format("DD-MMM-YYYY")}
                  </Form.Label>
                </Form.Group>
                {placement.value === "5" ? (
                  <>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Contract No</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {contractNo}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                      <Form.Label className="mt-2">Contract Title</Form.Label>
                      <Form.Label className="mt-2 text-end">
                        {contractTitle}
                      </Form.Label>
                    </Form.Group>
                  </>
                ) : null}
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Approve By</Form.Label>
                  <Form.Label className="mt-2 text-end">
                    {approveBy.label}
                  </Form.Label>
                </Form.Group>
              </div>
            ) : Object.keys(addSuccess).length !== 0 &&
              addSuccess.meta.status === "success" ? (
              <div className="container d-flex flex-column py-4">
                <FontAwesomeIcon
                  className="mb-4"
                  icon={faCircleCheck}
                  size="10x"
                  color="green"
                />
                <button
                  style={{
                    padding: "0",
                    border: "none",
                    background: "none",
                  }}
                  className="m-auto mt-4"
                  href={`#`}
                  onClick={() =>
                    handleShowPDF(`${addSuccess.data.pdf}?${Date.now()}`)
                  }
                >
                  <input
                    className="btn btn-success"
                    type="button"
                    value="View PDF"
                  />
                </button>
              </div>
            ) : Object.keys(addSuccess).length !== 0 &&
              addSuccess.meta.status !== "success" ? (
              <div className="container d-flex flex-column">
                <FontAwesomeIcon icon={faCircleXmark} />
              </div>
            ) : null}
            {Object.keys(addSuccess).length === 0 ? (
              <div className="d-flex flex-row-reverse bd-highlight">
                <button
                  className="btn btn-danger btn-sm mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5"
                  disabled={isFetch}
                >
                  {isFetch ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : addConfirm ? (
                    "Confirm"
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : null}
          </Form>
        </Modal.Body>
      </div>

      {modalSuccess ? (
        <ModalMessage data={modalSuccessData} click={handleCloseModalSuccess} />
      ) : null}

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default ModalRenewalSpk;
