import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { getBestCaptainHistory } from "../../../data-api/tl-structure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import writeXlsxFile from "write-excel-file";

function TabCaptainHistory(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    if (props.historyData.length === 0) {
      fetchHistory();
    } else {
      setData(props.historyData);
      setIsLoading(false);
    }
  }, []);

  function fetchHistory() {
    getBestCaptainHistory(props.data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setData([])
        : setData(result.data);
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Month",
        accessor: "month_periode",
      },
      {
        Header: "Year",
        accessor: "year_periode",
      },
    ],
    []
  );

  const {
    rows,
    headers,

    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,

    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: props.historyData.length === 0 ? data : props.historyData,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function handleDownloadExcel() {
    const columnsExcel = columns.map((item) => item.Header);

    const dataSet = [];
    const dataHeader = [];

    //append header table
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName:
        data.length === 0
          ? "Best Captain History"
          : `Best Captain History ${data[0].nik} -  ${data[0].name}`,
    });
  }

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="mt-3 custom-text-14">
        <div className="mx-3  d-flex justify-content-between">
          <div className=" text-start text-danger fw-semibold mt-1 d-flex">
            <div className="mt-1">{rows.length} records</div>
          </div>
          <div className="d-flex justify-content-end flex-wrap-reverse flex-grow-1 bd-highlight">
            <div className="col-4">
              <form
                id="search-reacttable"
                className="input-group flex border border-danger rounded"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search ..."
                  value={searchVal}
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchVal(e.target.value);
                      searchItem();
                    }
                  }}
                  onChange={(e) => setSearchVal(e.target.value)}
                />
                {searchVal !== "" ? (
                  <button
                    className="btn btn-outline-light text-danger btn-sm"
                    onClick={() => {
                      setSearchVal("");
                      setGlobalFilter("");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ color: "#848484" }}
                      size="sm"
                    />
                  </button>
                ) : null}
                <button
                  className="btn btn-danger btn-sm"
                  type="button"
                  id="button-addon2"
                  onClick={() => searchItem()}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    style={{ color: "#fff", float: "right" }}
                  />
                </button>
              </form>
            </div>
            <button
              className="btn btn-outline-danger ms-3 col-3 fa btn-sm"
              onClick={handleDownloadExcel}
            >
              Download
            </button>
          </div>
        </div>
        <div className="overflow-x-scroll px-3 mt-3">
          <table className="table table table-sm custom-table align-middle">
            <thead className="text-danger align-middle nowrap">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      key={index}
                      className="text-danger"
                    >
                      {column.render("Header")}
                      <span className="ms-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <>
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  className="custom-text-14"
                >
                  <td colSpan={headers.length}>
                    <div className="w-100">
                      <div className="shine-photo shine"></div>
                    </div>
                  </td>
                </tbody>
              </>
            ) : rows.length === 0 ? (
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
              >
                <td colSpan={headers.length}>No data available in table</td>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="text-start"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="pagination d-flex justify-content-center">
          <button
            className="ms-1 me-2 btn btn-danger btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              className="mt-1 me-1"
              icon={faAnglesLeft}
              style={{ color: "#fff" }}
            />
            First
          </button>{" "}
          <button
            className="ms-1 me-3 btn btn-outline-danger  btn-border btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"< Prev"}
          </button>{" "}
          {pageIndex > 4 ? (
            <span className="align-text-bottom bg-danger me-4">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          {pageOptions
            .slice(
              pageIndex < 5
                ? 0
                : pageIndex >= pageCount - 4
                ? pageCount - 9
                : pageIndex - 4,
              pageIndex < 5
                ? 9
                : pageIndex >= pageCount - 4
                ? pageCount
                : pageIndex + 5
            )
            .map((index, i) => {
              if (index === pageIndex) {
                return (
                  <button
                    className="ms-1  btn btn-danger btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              } else {
                return (
                  <button
                    className="ms-1  btn btn-outline-danger  btn-border btn-sm"
                    onClick={() => gotoPage(index)}
                    disabled={!canNextPage && index === pageCount}
                    key={i}
                  >
                    {index + 1}
                  </button>
                );
              }
            })}
          {pageIndex + 1 < pageOptions.length - 3 ? (
            <span className="align-text-bottom bg-danger ms-3 me-3">
              <p className="mt-3" style={{ position: "absolute" }}>
                ...
              </p>
            </span>
          ) : null}
          <button
            className="ms-3  btn btn-outline-danger  btn-border btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next >"}
          </button>{" "}
          <button
            className="ms-2 me-1 btn btn-danger fa btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
            <FontAwesomeIcon
              className="mt-1 ms-1"
              icon={faAnglesRight}
              style={{ color: "#fff", float: "right" }}
            />
          </button>{" "}
        </div>
        <div className="d-flex justify-content-end">
          Page{" "}
          <strong className="ms-1">
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </div>
      </div>
    </>
  );
}

export default TabCaptainHistory;
