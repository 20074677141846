import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./idle.css";
import { Modal, Form } from "react-bootstrap";
import { getIdle, getIdleFilter } from "../../data-api/idle";
import ReactTable from "../../utils/ReactTable";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";

function TableIdle() {
  const [modalFilter, setModalFilter] = useState(false);
  const [idle, setIdle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);

  // initiate store filter
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysTemp, setFilterDaysTemp] = useState("");
  const [filterClient, setFilterClient] = useState("");
  const [filterClientTemp, setFilterClientTemp] = useState("");

  useEffect(() => {
    setIsLoading(true);
    fetchIdle();
    getIdleFilter().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setClients([])
        : setClients(result.data.filter_client);
    });
    fetchIdle();
  }, []);
  //func onclick button filter
  const formFilterHandler = () => {
    if (filterDaysTemp !== "") {
      setFilterDays(filterDaysTemp);
    } else {
      setFilterDays("");
    }
    if (filterClientTemp !== "") {
      setFilterClient(filterClientTemp);
    } else {
      setFilterClient("");
    }

    fetchIdle(
      filterDaysTemp,
      filterClientTemp === "" ? "" : filterClientTemp.label
    );
    setModalFilter(false);
  };

  //func fetch filter idle
  function fetchIdle(days, client) {
    setIsLoading(true);
    getIdle({ days: days, client: client }).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setIdle([])
        : setIdle(result.data);
      setIsLoading(false);
    });
  }

  //initiate column name for employee table
  const columnsIdle = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.nik}</span>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Role",
        accessor: "position",
      },
      {
        Header: "End SPK",
        accessor: "end_spk",
        maxWidth: 150,
        Cell: (props) => (
          <>
            {props.value === "-"
              ? "-"
              : `${moment(props.value).format("DD-MMM-YYYY")} ${
                  props.cell.row.original.return_spk === ""
                    ? ""
                    : `(return at ${moment(
                        props.cell.row.original.return_spk
                      ).format("DD-MMM-YYYY")})`
                }`}
          </>
        ),
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End PKWT",
        accessor: "end_pkwt",
        Cell: (props) =>
          props.value === "~" ? "~" : moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "Client",
        accessor: "client",
      },
      {
        Header: "Idle Day(s)",
        accessor: "idle_days",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.idle_days}</span>
          </div>
        ),
      },
    ],
    []
  );

  //initiate empty employee
  const EmptyData = useMemo(() => [], []);
  const data = idle === null ? EmptyData : idle;
  // func export to excel xls

  function handleDownloadExcel() {
    const columnsExcel = columnsIdle.map((item) => item.Header);
    data.forEach((item) => {
      item.join_date =
        item.join_date === "~"
          ? "~"
          : moment(item.join_date).format("DD-MMM-YYYY");
      item.end_spk =
        item.end_spk === "~" ? "~" : moment(item.end_spk).format("DD-MMM-YYYY");
      item.end_pkwt =
        item.end_pkwt === "~"
          ? "~"
          : moment(item.end_pkwt).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    data.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Idle",
    });
  }

  //func onClick button filter h+21 / h+45
  function customFilterClick(type) {
    if (type === "21") {
      if (filterDaysTemp === "21") {
        setFilterDaysTemp("");
      } else {
        setFilterDaysTemp("21");
      }
    } else if (type === "45") {
      if (filterDaysTemp === "45") {
        setFilterDaysTemp("");
      } else {
        setFilterDaysTemp("45");
      }
    } else {
      setFilterDaysTemp("");
    }
  }

  //func remove filter on click
  function removeFilter(type) {
    if (type === "days") {
      fetchIdle("", filterClient.label);
      setFilterDays("");
      setFilterDaysTemp("");
    }
    if (type === "client") {
      fetchIdle(filterDays, "");
      setFilterClient("");
      setFilterClientTemp("");
    }
  }

  function handleFilter() {
    setModalFilter(true);
  }

  return (
    <>
      {/* <div className="table-content-function-idle"> */}
      <div className="tabs-content">
        {/* show state filter */}
        <div className="ms-3 d-flex flex-row bd-highlight mb-3 mt-2">
          {filterDays !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Filter Days : H + {filterDays}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("days")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
          {filterClient !== "" ? (
            <div className="p-2 bd-highlight card me-2 d-flex flex-row">
              Client: {filterClient.label}
              <button
                type="button"
                className="btn btn-danger col-2 ms-2"
                style={{ width: "25px", height: "25px", borderRadius: "100px" }}
                onClick={(e) => removeFilter("client")}
              >
                <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
              </button>
            </div>
          ) : null}
        </div>

        <ReactTable
          columns={columnsIdle}
          data={idle}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={handleFilter}
          isLoading={isLoading}
          hiddenColumns={[]}
          paging={[10, 20, 50, 100]}
        />
      </div>
      {/* show modal filter */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>Filter</Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form.Group className="mb-4 fw-semibold ">
              <p>Select Clients </p>
              <Select
                className="w-100 fw-normal custom-text-14"
                options={clients.map((data) => {
                  return { value: data.value, label: data.label };
                })}
                isClearable
                value={filterClientTemp}
                placeholder={"-- Select Clients --"}
                defaultValue={{
                  label: filterClient.label,
                }}
                onChange={(e) => {
                  if (e !== null) {
                    setFilterClientTemp(e);
                  } else {
                    setFilterClientTemp("");
                  }
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 35,
                    minHeight: 35,
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: "14px",
                  }),
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              ></Select>
            </Form.Group>
            <div
              className="d-flex justify-content-start mb-3"
              style={{ top: "30px" }}
            >
              {/* <button onClick={() => fetchFilterEmployeeButton("non-ps")}  className="btn btn-light col-4 fw-semibold shadow " type="button">Non-PS</button> */}
              <button
                onClick={() => customFilterClick("21")}
                className={`btn btn-sm btn-light col-4 fw-semibold shadow  ${
                  filterDaysTemp === "21" ? "btn-custom-filter" : ""
                }`}
                type="button"
              >
                H + 21
              </button>
              <button
                onClick={() => customFilterClick("45")}
                className={`btn btn-sm btn-light col-4 fw-semibold shadow ms-4 ${
                  filterDaysTemp === "45" ? "btn-custom-filter" : ""
                }`}
                type="button"
              >
                H + 45
              </button>
            </div>
            <div className="d-grid gap-2">
              <button
                className="btn btn-sm btn-danger mb-3 ms-1 me-1 mt-4 fw-bold shadow"
                onClick={formFilterHandler}
              >
                FILTER
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default TableIdle;
