import { Modal, Tab, Tabs } from "react-bootstrap";
import TableSPK from "./table/TableSPK";
import TableWaitingSPK from "./table/TableWaitingSPK";
import TableRejectedSPK from "./table/TableRejectedSPK";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFileImport,
  faPlusCircle,
  faSignature,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import ModalAddSpk from "./modal/ModalAddSpk";
import { useRef } from "react";
import ModalEmailSpk from "./modal/ModalEmailSpk";
import ModalImportSpk from "./modal/ModalImportSpk";
import { error } from "jquery";
import { useOutletContext } from "react-router-dom";
import {
  getListSpk,
  getRejectedSpk,
  getSignImage,
  getSpkApproveReject,
  getWaitingSpk,
  updateSignImage,
} from "../../data-api/resource-team";
import LoadingEffect from "../template/loadingEffectTable";

function ResourceTeam() {
  //get context for access menu
  const outletContext = useOutletContext();
  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Resource Team"
  ).sub_menu;
  const spkAccess = access.find((item) => item.sub_menu === "SPK");
  const waitingSpkAccess = access.find(
    (data) => data.sub_menu === "Waiting SPK"
  );
  const rejectedSpkAccess = access.find(
    (data) => data.sub_menu === "Rejected SPK"
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);

  const [image, setImage] = useState({
    imageSrc: "...",
    imageHash: Date.now(),
  });

  const [signImage, setSignImage] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [addStatus, setAddStatus] = useState(false);

  const [spk, setSpk] = useState([]);
  const [spkWaiting, setSpkWaiting] = useState([]);
  const [spkRejected, setSpkRejected] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchWaitingSPK();
    getSignImage(outletContext.profile.nik).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      setSignImage(result);
      if (result.data === null || result.data === undefined) {
        setImage(null);
      } else {
        setImage({
          imageSrc: result.data.sign_img,
          imageHash: Date.now(),
        });
      }
      setIsLoading(false);
    });
  }, []);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    if (addStatus) {
      fetchTabSpk("");
    }
    setShowAdd(false);
  };

  //upload modal
  const [showUpload, setShowUpload] = useState(false);

  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => setShowUpload(true);

  const onFileChange = (files) => {
    // console.log(files);
  };

  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile.type !== "image/png") {
      setAlertMessageSignature("Please upload PNG format");
      setSuccessUpload(false);
      setShowAlertSignature(true);
    } else if (newFile.size > 500000) {
      setAlertMessageSignature("File must be less than 500KB");
      setSuccessUpload(false);
      setShowAlertSignature(true);
    } else {
      const updatedList = [newFile];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  const [showAlertSignature, setShowAlertSignature] = useState(false);
  const [AlertMessageSignature, setAlertMessageSignature] = useState("");
  const [successUpload, setSuccessUpload] = useState(false);
  function uploadSignImage() {
    if (fileList.length === 0) {
      setShowAlertSignature(true);
      setAlertMessageSignature("Image cant be empty");
    } else {
      setSuccessUpload(false);
      setIsFetch(true);
      const formData = new FormData();
      formData.append("file", fileList[0]);

      updateSignImage(outletContext.profile.nik, formData).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        setSignImage({ data: result.data, imageHash: Date.now() });
        if (result.data === null || result.data === undefined) {
          setShowAlertSignature(true);
          setAlertMessageSignature(error.message);
        } else {
          setImage({
            imageSrc: result.data.sign_img,
            imageHash: Date.now(),
          });
          setFileList([]);
          setSuccessUpload(true);
          setShowAlertSignature(true);
          setAlertMessageSignature("Success update signature image");
        }
        setIsFetch(false);
      });
    }
  }

  const [tabKey, setTabKey] = useState("1");

  function handleSelect(key) {
    setTabKey(key);
  }

  const [emailTitle, setEmailTitle] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const handleCloseEmail = () => {
    setShowEmail(false);
  };
  const handleShowEmail = (title) => {
    setEmailTitle(title);
    setShowEmail(true);
  };
  const handleShowEmailtoHC = (title) => {
    getSpkApproveReject().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setAlertMessage("All SPK has been send to HC");
        setAlertSuccess(false);
        setShowAlert(true);
      } else {
        handleShowEmail(title);
      }
    });
  };

  const handleShowEmailtoPS = (title) => {
    getWaitingSpk().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setAlertMessage("Waiting SPK is Empty");
        setAlertSuccess(false);
        setShowAlert(true);
      } else {
        handleShowEmail(title);
      }
    });
  };

  // const handleShowEmailtoHC = (title) => {
  //   getSpkApproveReject().then((result) => {
  //     if (result.data === null || result.data === undefined) {
  //       setAlertMessage("All SPK has been send to HC");
  //       setAlertSuccess(false);
  //       setShowAlert(true);
  //     } else {
  //       handleShowEmail(title);
  //     }
  //   });
  // };

  const [importAction, setImportAction] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const handleCloseImport = () => {
    if (importAction) {
      fetchTabSpk("");
    }
    setShowImport(false);
  };

  function fetchTabSpk(key) {
    if (key !== "") {
      setTabKey(key);
    }
    setIsLoading(true);
    getListSpk()
      .then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          setSpk([]);
        } else {
          setSpk(result.data);
        }
      })
      .then(() => {
        getWaitingSpk()
          .then((result) => {
            if (result.meta.code === 401) {
              window.location.reload();
              return;
            }
            if (result.data === null || result.data === undefined) {
              setSpkWaiting([]);
            } else {
              setSpkWaiting(result.data);
            }
          })
          .then(() => {
            getRejectedSpk().then((result) => {
              if (result.meta.code === 401) {
                window.location.reload();
                return;
              }
              if (result.data === null || result.data === undefined) {
                setSpkRejected([]);
              } else {
                setSpkRejected(result.data);
              }
              setIsLoading(false);
            });
          });
      });
  }

  function fetchWaitingSPK() {
    getWaitingSpk().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setSpkWaiting([]);
      } else {
        setSpkWaiting(result.data);
      }
    });
  }

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  if (isLoading) {
    return (
      <div className="w-100 tabs-content rounded">
        <LoadingEffect />
      </div>
    );
  }

  return (
    <>
      <div className="w-100">
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}
        <div className="d-flex flex-row-reverse bd-highlight mt-3">
          {tabKey === "1" ? (
            <>
              <button
                className="btn btn-danger ms-3 col-2 btn-sm"
                onClick={() => setShowAdd(true)}
                disabled={!spkAccess.is_create}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ marginRight: "10px" }}
                />
                Add SPK
              </button>
              <button
                className="btn btn-danger ms-3 col-2 btn-sm"
                onClick={() => setShowImport(true)}
                disabled={!spkAccess.is_create || !spkAccess.is_update}
              >
                <FontAwesomeIcon
                  icon={faFileImport}
                  style={{ marginRight: "10px" }}
                />
                Import SPK
              </button>
              <button
                className="btn btn-danger ms-3 col-2 btn-sm"
                onClick={() => handleShowEmailtoPS("ps")}
                disabled={!spkAccess.is_create || !spkAccess.is_update}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px" }}
                  className="fa-cloud-arrow-down"
                />
                Send SPK for Approval
              </button>
            </>
          ) : tabKey === "2" ? (
            <>
              <button
                className="btn btn-danger ms-3 col-2 fa btn-sm"
                onClick={handleShowUpload}
                disabled={
                  !waitingSpkAccess.is_create || !waitingSpkAccess.is_update
                }
              >
                <FontAwesomeIcon
                  icon={faSignature}
                  style={{ marginRight: "10px" }}
                  className="fa-cloud-arrow-down"
                />
                Upload Signature
              </button>
              <button
                className="btn btn-danger ms-3 col-2 fa btn-sm"
                onClick={() => handleShowEmailtoHC("hc")}
                disabled={
                  !waitingSpkAccess.is_create || !waitingSpkAccess.is_update
                }
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px" }}
                  className="fa-cloud-arrow-down btn-sm"
                />
                Send SPK to HC
              </button>
            </>
          ) : null}
        </div>

        <Tabs
          defaultActiveKey={tabKey}
          id="uncontrolled-tab-example"
          onSelect={(e) => handleSelect(e)}
        >
          {spkAccess.is_read ? (
            <Tab eventKey={1} title="SPK">
              <TableSPK
                data={spk}
                isLoading={isLoading}
                resetTable={fetchTabSpk}
                tabKey={tabKey}
                error={showAlertError}
                accessMenu={spkAccess}
              />
            </Tab>
          ) : null}

          {waitingSpkAccess.is_read ? (
            <Tab
              eventKey={2}
              title={spkWaiting.length === 0 ? "Waiting SPK" : "Waiting SPK *"}
            >
              <TableWaitingSPK
                data={spkWaiting}
                isLoading={isLoading}
                resetTable={fetchTabSpk}
                tabKey={tabKey}
                error={showAlertError}
                accessMenu={waitingSpkAccess}
                signature={image}
              />
            </Tab>
          ) : null}

          {rejectedSpkAccess.is_read ? (
            <Tab eventKey={3} title="Rejected SPK">
              <TableRejectedSPK
                data={spkRejected}
                isLoading={isLoading}
                resetTable={fetchTabSpk}
                tabKey={tabKey}
                error={showAlertError}
                accessMenu={rejectedSpkAccess}
              />
            </Tab>
          ) : null}
        </Tabs>
      </div>

      <Modal
        show={showAdd}
        onHide={handleCloseAdd}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalAddSpk addStatus={setAddStatus} />
      </Modal>

      <Modal show={showUpload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {showAlertSignature ? (
              <div
                className={
                  successUpload
                    ? "alert alert-success alert-dismissible fade show"
                    : "alert alert-danger alert-dismissible fade show"
                }
                role="alert"
              >
                <strong>{AlertMessageSignature}</strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setShowAlertSignature(false)}
                ></button>
              </div>
            ) : null}
          </>
          <div className="mb-4">
            <div className="custom-file-upload btn btn-outline-danger">
              <label>Browse</label>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
            <button
              className="btn btn-danger float-end col-4 mb-4"
              onClick={uploadSignImage}
            >
              {isFetch ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
          {fileList.length === 0 ? (
            <div
              ref={wrapperRef}
              className="drop-file-input mt-4"
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div className="drop-file-input__label">
                <img alt="" />
                <p>Drag & Drop your files here</p>
              </div>
              <input type="file" value="" onChange={onFileDrop} />
            </div>
          ) : null}
          {fileList.length > 0 ? (
            <>
              <div className="drop-file-preview">
                <p className="p-2">
                  <strong>Preview Signature</strong>
                </p>
                {fileList.map((item, index) => (
                  <div key={index} className="drop-file-preview__item">
                    <img
                      alt=""
                      src={URL.createObjectURL(item)}
                      className="w-50"
                    />
                    <div className="drop-file-preview__item__info">
                      <p>{item.name}</p>
                      <p>{item.size}B</p>
                    </div>
                    <span
                      className="drop-file-preview__item__del"
                      onClick={() => fileRemove(item)}
                    >
                      x
                    </span>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          <div className="mt-2 text-danger">
            <p>*File must be less than 500KB</p>
            <p style={{ marginTop: "-20px" }}>*Allowed file types: PNG</p>
          </div>
          <div className="mt-4">
            {signImage.data !== null ? (
              <div className="mt-2">
                <p>
                  <strong>Old Signature</strong>
                </p>
                <img
                  src={`${image.imageSrc}?${image.imageHash}`}
                  className="img-thumbnail w-50"
                  alt="..."
                ></img>
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEmail}
        onHide={handleCloseEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEmailSpk
          title={emailTitle}
          alert={showAlertError}
          closeModal={handleCloseEmail}
        />
      </Modal>

      <Modal
        show={showImport}
        onHide={handleCloseImport}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalImportSpk data={""} setImportSuccess={setImportAction} />
      </Modal>
    </>
  );
}
export default ResourceTeam;
