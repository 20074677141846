import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { postBestCaptain } from "../../../data-api/tl-structure";
import { ReactSelect } from "../../../utils/ReactSelect";

function AddBestCaptain(props) {
  const dateYear = new Date().getFullYear() - 3;
  const listYear = Array.from(new Array(20), (val, index) => index + dateYear);

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const listMonths = [
    {
      value: "1",
      label: "January",
    },
    {
      value: "2",
      label: "February",
    },
    {
      value: "3",
      label: "March",
    },
    {
      value: "4",
      label: "April",
    },
    {
      value: "5",
      label: "May",
    },
    {
      value: "6",
      label: "June",
    },
    {
      value: "7",
      label: "July",
    },
    {
      value: "8",
      label: "August",
    },
    {
      value: "9",
      label: "September",
    },
    {
      value: "10",
      label: "October",
    },
    {
      value: "11",
      label: "November",
    },
    {
      value: "12",
      label: "December",
    },
  ];

  function setAddCaptain(event) {
    event.preventDefault();
    const json = JSON.stringify({
      nik: props.data.nik,
      month_periode: month.value,
      year_periode: year.value.toString(),
    });

    postBestCaptain(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message = {
        code: result.meta.code,
        message: result.meta.message,
      };
      if (result.meta.code === 200) {
        props.handleSuccessModal(true, message.message);
      } else {
        props.handleSuccessModal(false, message.message);
      }
    });
  }

  return (
    <>
      <Modal.Header
        closeButton
        style={{ marginTop: "25px", marginBottom: "25px" }}
      >
        <Modal.Title>Set as Best Captain?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={(e) => setAddCaptain(e)}>
          <Form.Group className="mb-3 fw-semibold">
            <Form.Label>
              {props.data.nik} - {props.data.name}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold">
            <Form.Label>Month</Form.Label>
            <div className="w-100">
              <ReactSelect
                value={month}
                setValue={setMonth}
                data={listMonths}
                placeholder={"-- Select Month --"}
                validationMessage={"Please Select Month"}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 fw-semibold">
            <Form.Label>Year</Form.Label>
            <div className="w-100">
              <ReactSelect
                value={year}
                setValue={setYear}
                data={listYear.map((data) => ({
                  label: data,
                  value: data,
                }))}
                placeholder={"-- Select Year --"}
                validationMessage={"Please Select Year"}
              />
            </div>
          </Form.Group>
          <div className="d-flex flex-row-reverse bd-highlight">
            <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm">
              Submit
            </button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
}

export default AddBestCaptain;
