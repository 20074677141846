import {
  faAnglesLeft,
  faAnglesRight,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { getSuperadminHelpdeskLogLogin } from "../../../../data-api/master-user";

function LoginHistory(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    fetchUserRole();
  }, []);

  function fetchUserRole() {
    setIsLoading(true);
    getSuperadminHelpdeskLogLogin().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">#{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-email">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Department",
        accessor: "department",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Position",
        accessor: "position",
        Cell: (props) => {
          return (
            <span className="table-custom-superadmin-name">{props.value}</span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => {
          return (
            <span
              className="table-custom-superadmin-name"
              style={{ width: "300px" }}
            >
              {props.value}
            </span>
          );
        },
        maxWidth: 200,
      },
      {
        Header: "Login Date",
        accessor: "login_date",
        Cell: (props) => {
          return (
            <span
              className="table-custom-superadmin-name"
              style={{ width: "300px" }}
            >
              {moment(props.value).format("YYYY-MM-DD hh:mm")}
            </span>
          );
        },
        maxWidth: 200,
      },
    ],
    []
  );

  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      <div className="tabs-content">
        {isLoading ? (
          <>
            <div className="w-100">
              <div className="shine-photo shine"></div>
            </div>
          </>
        ) : (
          <>
            <div className="row custom-text-14 w-100 ms-1">
              <div className="col-4 text-start text-danger fw-semibold d-flex">
                <div className={"mt-1"}>{rows.length} records</div>
              </div>
              <div className="col-8 bd-highlight d-flex justify-content-end">
                <form
                  id="search-reacttablecustom"
                  className="input-group w-50 flex border border-danger rounded"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search ..."
                    value={searchVal}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                      if (e.target.value === "") {
                        searchItem();
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setSearchVal(e.target.value);
                        searchItem();
                      }
                    }}
                  />
                  {searchVal !== "" ? (
                    <button
                      className="btn btn-outline-light text-danger btn-sm"
                      onClick={() => {
                        setSearchVal("");
                        setGlobalFilter("");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: "#848484" }}
                        size="sm"
                      />
                    </button>
                  ) : null}
                  <button
                    className="btn btn-danger btn-sm"
                    type="button"
                    id="button-addon2"
                    onClick={() => searchItem()}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ color: "#fff", float: "right" }}
                    />
                  </button>
                </form>
              </div>
            </div>

            <div className="overflow-x-scroll">
              <table className="table-custom-superadmin">
                <thead className="text-danger align-middle nowrap">
                  {headerGroups.map((headerGroup, key) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                      {headerGroup.headers.map((column, key) => (
                        <th
                          {...column.getHeaderProps(column)}
                          scope="col"
                          key={key}
                          className="text-start text-danger"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {rows.length === 0 ? (
                  <tbody
                    style={{ backgroundColor: "#ececec", textAlign: "center" }}
                  >
                    <tr>
                      <td colSpan={headers.length}>
                        No data available in table
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody {...getTableBodyProps()} className="nowrap text-start">
                    {page.map((row, key) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={key}>
                          {row.cells.map((cell, key) => {
                            return (
                              <td {...cell.getCellProps()} key={key}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
            <div className="pagination d-flex justify-content-center">
              <button
                className="ms-1 me-2 btn btn-danger btn-sm"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon
                  className="mt-1 me-1"
                  icon={faAnglesLeft}
                  style={{ color: "#fff" }}
                />
                First
              </button>
              {""}
              <button
                className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"< Prev"}
              </button>{" "}
              {pageIndex > 4 ? (
                <span className="align-text-bottom bg-danger me-4">
                  <p className="mt-3" style={{ position: "absolute" }}>
                    ...
                  </p>
                </span>
              ) : null}
              {pageOptions
                .slice(
                  pageIndex < 5
                    ? 0
                    : pageIndex >= pageCount - 4
                    ? pageCount - 9
                    : pageIndex - 4,
                  pageIndex < 5
                    ? 9
                    : pageIndex >= pageCount - 4
                    ? pageCount
                    : pageIndex + 5
                )
                .map((index, i) => {
                  if (index === pageIndex) {
                    return (
                      <button
                        className="ms-1 btn btn-danger btn-sm"
                        onClick={() => gotoPage(index)}
                        disabled={!canNextPage}
                        key={i}
                      >
                        {index + 1}
                      </button>
                    );
                  } else {
                    return (
                      <button
                        className="ms-1 btn btn-outline-danger btn-border btn-sm"
                        onClick={() => gotoPage(index)}
                        disabled={!canNextPage && index === pageCount}
                        key={i}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                })}
              {pageIndex + 1 < pageOptions.length - 3 ? (
                <span className="align-text-bottom bg-danger ms-3 me-3">
                  <p className="mt-3" style={{ position: "absolute" }}>
                    ...
                  </p>
                </span>
              ) : null}
              <button
                className="ms-3 btn btn-outline-danger btn-border btn-sm"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {"Next >"}
              </button>{" "}
              <button
                className="ms-2 me-1 btn btn-danger fa btn-sm"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Last
                <FontAwesomeIcon
                  className="mt-1 ms-1"
                  icon={faAnglesRight}
                  style={{ color: "#fff", float: "right" }}
                />
              </button>{" "}
            </div>
            <div
              className="d-flex justify-content-end mb-2 me-2"
              style={{ color: "#525555" }}
            >
              Page{" "}
              <strong className="ms-1">
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LoginHistory;
