import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  addSuperadminUser,
  getSuperadminDropdown,
} from "../../../../data-api/master-user";
import { ReactSelect } from "../../../../utils/ReactSelect";

function ModalAddAccess(props) {
  const [data, setData] = useState([]);
  const [employeeIndex, setEmployeeIndex] = useState("");
  const [postStatus, setPostStatus] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    fetchModalDropdown();
  }, []);

  function fetchModalDropdown() {
    getSuperadminDropdown().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
    });
  }

  function formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setIsConfirm(true);
      } else {
        const json = JSON.stringify({
          email: employeeIndex.email,
          nik: employeeIndex.value,
          status: Boolean(postStatus.value),
          role_id: 1,
        });

        addSuperadminUser(json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            props.handleSuccessModal(false, result.meta.message);
          } else {
            const message = {
              code: result.meta.code,
              message: result.meta.message,
            };
            props.handleSuccessModal(true, message.message);
          }
          setIsConfirm(false);
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add New Superadmin</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={formSubmit}>
          {!isConfirm ? (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Employee</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={employeeIndex}
                    setValue={setEmployeeIndex}
                    data={data.map((data) => ({
                      label: data.name,
                      value: data.nik,
                      email: data.email,
                    }))}
                    isLoading={false}
                    placeholder={"-- Select Employee --"}
                    validationMessage={"Please Select Employee"}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Email</Form.Label>
                <Form.Control
                  defaultValue={employeeIndex.email}
                  className="w-75 form-control-sm"
                  aria-describedby="basic-addon1"
                  disabled
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Status</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={postStatus}
                    setValue={setPostStatus}
                    data={[
                      {
                        value: 0,
                        label: "Non-Active",
                      },
                      {
                        value: 1,
                        label: "Active",
                      },
                    ]}
                    isLoading={false}
                    placeholder={"-- Select Status --"}
                    validationMessage={"Please Select Status"}
                  />
                </div>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Employee</Form.Label>
                <Form.Label className="w-75">{employeeIndex.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Email</Form.Label>
                <Form.Label className="w-75">{employeeIndex.email}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Status</Form.Label>
                <Form.Label className="w-75">{postStatus.label}</Form.Label>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                  type="submit"
                  name="cancel"
                  value={"Cancel"}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}
export default ModalAddAccess;
