import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TableResign from "./table/TableResign";

function Resign() {
  return (
    <div className="w-100 tabs-content rounded">
      <TableResign />
    </div>
  );
}
export default Resign;
