import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import TableDashboard from "../TableDashboard";
import DashboardItem from "./components/DashboardItem";
import DashboardItemCarousel from "./components/DashboardItemCarousel";
import DashboardChart from "./components/DashboardCharts";

function DashboardPSManager(props) {
  const talentMovement = props.charts?.find(
    (item) => item.options.chart.id === "Talent Movement"
  );

  const idleChart = props.charts.find(
    (item) => item.options.chart.id === "Idle Chart"
  );

  const talentOnboardStatus = props.charts.find(
    (item) => item.options.chart.id === "Talent Onboard Status"
  );

  const talentMovementInOut = props.charts.find(
    (item) =>
      item.options.chart.id === "Talent Movement In & Out (Q1 - Q2 - Q3 - Q4)"
  );

  const talentOnClient = props.charts.find(
    (item) => item.options.chart.id === "Talent on Client Map (TOP 7)"
  );

  const talentPartnership = props.charts.find(
    (item) => item.options.chart.id === "Vendor Employee"
  );

  const talentPartnershipSourceTalent = props.charts.find(
    (item) => item.options.chart.id === "Vendor Employee Source Talent"
  );

  const talentPartnershipClient = props.charts.find(
    (item) => item.options.chart.id === "Vendor Employee Client"
  );

  return (
    <>
      <div className="row w-100 my-3">
        <div className="flex col-sm-4">
          {Object.keys(talentMovement).length !== 0 ? (
            <>
              <div className="align-items-start d-flex flex-row h-50">
                <DashboardItem
                  className={"me-3 card h-75"}
                  title={talentMovement.options.xaxis.categories[0]}
                  onClick={() =>
                    props.detailChart(
                      talentMovement.options.chart.id,
                      talentMovement.chart_detail,
                      { dataPointIndex: 0, seriesIndex: 0 }
                    )
                  }
                  data={talentMovement.series[0].data[0]}
                />
                <DashboardItemCarousel
                  id={"Resign"}
                  data={[
                    {
                      title: talentMovement.options.xaxis.categories[2],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 2, seriesIndex: 2 }
                        ),
                      data: talentMovement.series[0].data[2],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[4],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 4, seriesIndex: 4 }
                        ),
                      data: talentMovement.series[0].data[4],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[5],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 5, seriesIndex: 5 }
                        ),
                      data: talentMovement.series[0].data[5],
                    },
                  ]}
                />
              </div>
              <div className="align-items-end d-flex flex-row h-50">
                <DashboardItem
                  className={"me-3 card h-75"}
                  title={talentMovement.options.xaxis.categories[1]}
                  onClick={() =>
                    props.detailChart(
                      talentMovement.options.chart.id,
                      talentMovement.chart_detail,
                      { dataPointIndex: 1, seriesIndex: 1 }
                    )
                  }
                  data={talentMovement.series[0].data[1]}
                />
                <DashboardItemCarousel
                  id={"TotalPS"}
                  data={[
                    {
                      title: talentMovement.options.xaxis.categories[3],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 3, seriesIndex: 3 }
                        ),
                      data: talentMovement.series[0].data[3],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[6],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 6, seriesIndex: 6 }
                        ),
                      data: talentMovement.series[0].data[6],
                    },
                    {
                      title: talentMovement.options.xaxis.categories[7],
                      onClick: () =>
                        props.detailChart(
                          talentMovement.options.chart.id,
                          talentMovement.chart_detail,
                          { dataPointIndex: 7, seriesIndex: 7 }
                        ),
                      data: talentMovement.series[0].data[7],
                    },
                  ]}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex col-sm-4">
          <DashboardChart chart={talentOnboardStatus} />
        </div>
        <div className="flex col-sm-4">
          <DashboardChart chart={idleChart} />
        </div>
      </div>

      <div className="row w-100 my-3">
        <div className="flex col-sm-8">
          <DashboardChart chart={talentPartnershipSourceTalent} />
        </div>
        <div className="flex col-sm-4">
          <DashboardChart chart={talentPartnership} />
        </div>
      </div>

      <div className="row w-100 my-3">
        <div className="flex col-sm-8">
          <DashboardChart chart={talentMovementInOut} />
        </div>
        <div className="flex col-sm-4">
          <DashboardChart chart={talentPartnershipClient} />
        </div>
      </div>

      <div className="row w-100 my-3">
        <div className="flex col-sm-12">
          <DashboardChart chart={talentOnClient} height="500px" />
        </div>
      </div>

      {props.dataTable.length !== 0 ? (
        <div className="d-flex flex-row mt-2" style={{ width: "100%" }}>
          <div className="m-auto w-100">
            <TableDashboard data={props.dataTable[0]} />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DashboardPSManager;
