import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

//endpoint superadmin menu
export const getSuperadminMenu = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/menu`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addSuperadminMenu = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/menu`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateSuperadminMenuStatus = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/menu/${id}`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addSuperadminSubmenu = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/sub-menu`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

//endpoint superadmin role
export const getSuperadminRole = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/role`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSuperadminRoleDetail = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/role/${id}`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addSuperadminRole = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/role`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateSuperadminRolePermission = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/role`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

//endpoint superadmin
export const getSuperadmin = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSuperadminDropdown = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/list-dropdown`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addSuperadminUser = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

//endpoint superadmin user
export const getSuperadminUser = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateSuperadminUserStatus = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user/${id}/status`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateSuperadminUserRole = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user/${id}/role`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteSuperadminUser = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user/${id}`;
  return axios
    .delete(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSuperadminUserDropdown = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user/list-dropdown`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postSuperadminSendEmail = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/user/send-email`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

//master user helpdesk
export const getSuperadminHelpdeskLog = async (params) => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk-logs`;
  return axios
    .get(url, {
      params: params,
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateSuperadminHelpdeskLog = async (id, json) => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk-log/${id}`;
  return axios
    .put(url, json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postSuperadminHelpdeskSendEmail = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk-log/send-email/${id}`;
  return axios
    .post(url, "", {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getSuperadminHelpdeskLogLogin = async () => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk-log/login`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getModalAddApproval = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getApprovalPersonnel = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateApprovalPersonnelStatus = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve/${id}/status`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateApprovalPersonnelCompany = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve/${id}/company`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getApprovalPersonnelModal = async () => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve/modal`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const addApprovalPersonnel = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve`;
  return axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const deleteApprovalPersonnel = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/super-admin/approve/${id}`;
  return axios
    .delete(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
