import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  addSuperadminUser,
  getSuperadminRole,
  getSuperadminUserDropdown,
} from "../../../../data-api/master-user";
import { useOutletContext } from "react-router-dom";
import { ReactSelect } from "../../../../utils/ReactSelect";

function ModalAddUser(props) {
  const outletContext = useOutletContext();
  const [data, setData] = useState([]);
  const [employeeIndex, setEmployeeIndex] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [roleIndex, setRoleIndex] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchModalDropdown();
  }, []);

  function fetchModalDropdown() {
    setIsLoading(true);
    getSuperadminUserDropdown().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
    });

    getSuperadminRole().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setRoleData([]);
      } else {
        if (outletContext.profile.role_id === "1") {
          setRoleData(result.data);
        } else {
          const tempData = result.data.filter(function (obj) {
            return obj.id !== 1;
          });
          setRoleData(tempData);
        }
      }
      setIsLoading(false);
    });
  }

  function formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setIsConfirm(true);
      } else {
        const json = JSON.stringify({
          email: employeeIndex.email,
          nik: employeeIndex.value,
          role_id: roleIndex.value,
          status: true,
        });

        addSuperadminUser(json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            props.handleSuccessModal(false, result.meta.message);
          } else {
            const message = {
              code: result.meta.code,
              message: result.meta.message,
            };
            props.handleSuccessModal(true, message.message);
          }
          setIsConfirm(false);
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add New Integrals User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formSubmit} className="custom-text-14">
          {!isConfirm ? (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Employee</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={employeeIndex}
                    setValue={setEmployeeIndex}
                    data={data.map((data) => ({
                      label: data.name,
                      value: data.nik,
                      email: data.email,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Employee --"}
                    validationMessage={"Please Select Employee"}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Email</Form.Label>
                <Form.Control
                  defaultValue={employeeIndex === "" ? "" : employeeIndex.email}
                  className="w-75 form-control-sm"
                  aria-describedby="basic-addon1"
                  disabled
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Role</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={roleIndex}
                    setValue={setRoleIndex}
                    data={roleData.map((data) => ({
                      label: data.role_name,
                      value: data.id,
                    }))}
                    isLoading={isLoading}
                    placeholder={"-- Select Role --"}
                    validationMessage={"Please Select Role"}
                  />
                </div>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Employee</Form.Label>
                <Form.Label className="w-75">{employeeIndex.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Email</Form.Label>
                <Form.Label className="w-75">{employeeIndex.email}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Role</Form.Label>
                <Form.Label className="w-75">{roleIndex.label}</Form.Label>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                  type="submit"
                  name="cancel"
                  value={"Cancel"}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}

export default ModalAddUser;
