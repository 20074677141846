import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import { getResignModal, updateResign } from "../../../data-api/resign";
import { useOutletContext } from "react-router-dom";
import { ReactSelect } from "../../../utils/ReactSelect";
import LoadingEffectForm from "../../template/loadingEffectForm";

function Editres(props) {
  const [items, setitems] = useState([]);
  const [DataisLoaded, setDataisLoaded] = useState(true);
  const [Options, setOptions] = useState({
    label: props.data.reason,
    value: props.data.reason,
  });
  const [clientoptions, setclientoptions] = useState({
    label: props.data.client,
    value: props.data.client,
  });
  const [positionoptions, setpositionoptions] = useState({
    label: props.data.position,
    value: props.data.position,
  });
  const [departmentoptions, setdepartmentoptions] = useState({
    label: props.data.department,
    value: props.data.department,
  });
  const [addConfirm, setAddConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getResignModal().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setitems([]);
      } else {
        setitems(result.data);
      }
      setDataisLoaded(false);
      setIsLoading(false);
    });
  }, []);

  function formAddResign(event) {
    event.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      submit();
    }
  }

  // const outletContext = useOutletContext();
  const submit = async (data) => {
    const json = JSON.stringify({
      reason: Options.value,
      client: clientoptions.value,
      position: positionoptions.value,
      // updated_by: outletContext.profile.nik,
      department: departmentoptions.value,
    });
    updateResign(props.data.nik, json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        const message2 = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.close();
        props.message(message2);
      } else {
        const message2 = {
          code: result.meta.code,
          message: result.meta.message,
        };
        props.close();
        props.message(message2);
      }
    });
  };

  if (isLoading)
    return (
      <>
        <LoadingEffectForm row={5} />
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <div className="fw-semibold custom-text-20">
          {!addConfirm
            ? "Edit Resign"
            : addConfirm
            ? "Edit confitmation"
            : null}
        </div>
      </Modal.Header>

      <Modal.Body className="custom-text-14">
        <Form onSubmit={(e) => formAddResign(e)}>
          {!addConfirm ? (
            <>
              <div className="ms-2 mb-3">
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Name
                  </Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    disabled
                    defaultValue={props.data.name}
                  />
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Reason
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={Options.label === "" ? "" : Options}
                      setValue={setOptions}
                      data={items.resign_reason}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Reason --"}
                      validationMessage={"Please Select Reason"}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Placement
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={clientoptions.label === "" ? "" : clientoptions}
                      setValue={setclientoptions}
                      data={items.placement}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Placement --"}
                      validationMessage={"Please Select Placement"}
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Position
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={
                        positionoptions.label === "" ? "" : positionoptions
                      }
                      setValue={setpositionoptions}
                      data={items.position}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Position --"}
                      validationMessage={"Please Select Position"}
                    />
                  </div>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Department
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={
                        departmentoptions.label === "" ? "" : departmentoptions
                      }
                      setValue={setdepartmentoptions}
                      data={items.department}
                      isLoading={DataisLoaded}
                      placeholder={"-- Select Department --"}
                      validationMessage={"Please Select Department"}
                    />
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  className="btn btn-danger mb-2 fw-bold shadow btn-sm"
                  //  onClick={submit}
                  style={{ width: "100px" }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Name
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {props.data.name}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Reason
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {Options.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Client
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {clientoptions.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Position
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {positionoptions.label}
                  </Form.Label>
                </div>
                <div className="d-flex py-2">
                  <Form.Label className="fw-semibold w-25 my-auto custom-text-14">
                    Department
                  </Form.Label>
                  <Form.Label className="w-75 my-auto custom-text-14">
                    {departmentoptions.label}
                  </Form.Label>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <button
                    className="col-5 me-2 btn btn-danger btn-sm"
                    style={{ width: "100px" }}
                    onClick={(e) => setAddConfirm(false)}
                  >
                    Back{" "}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger col-2  btn btn-danger btn-sm"
                    style={{ width: "100px" }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}

export default Editres;
