import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  addApprovalPersonnel,
  getApprovalPersonnelModal,
} from "../../../../data-api/master-user";
import { ReactSelect } from "../../../../utils/ReactSelect";

function ModalAddApproval(props) {
  const [data, setData] = useState([]);
  const [postEmployee, setPostEmployee] = useState("");
  const [postCompany, setPostCompany] = useState("");
  const [postStatus, setPostStatus] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchModalDropdown();
  }, []);

  function fetchModalDropdown() {
    setIsLoading(true);
    getApprovalPersonnelModal().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setData([]);
      } else {
        setData(result.data);
      }
      setIsLoading(false);
    });
  }

  function formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setIsConfirm(true);
      } else {
        const json = JSON.stringify({
          nik: postEmployee.value,
          status: postStatus.value,
          company: postCompany.value,
        });

        addApprovalPersonnel(json).then((result) => {
          if (result.meta.code === 401) {
            window.location.reload();
            return;
          }
          if (result.data === null || result.data === undefined) {
            props.handleSuccessModal(false, result.meta.message);
          } else {
            const message = {
              code: result.meta.code,
              message: result.meta.message,
            };
            props.handleSuccessModal(true, message.message);
          }
          setIsConfirm(false);
        });
      }
    } else {
      setIsConfirm(false);
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add New Approval User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form onSubmit={formSubmit}>
          {!isConfirm ? (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Employee</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={postEmployee}
                    setValue={setPostEmployee}
                    data={data.employee}
                    isLoading={isLoading}
                    placeholder={"-- Select Employee --"}
                    validationMessage={"Please Select Employee"}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Company</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={postCompany}
                    setValue={setPostCompany}
                    data={data.company}
                    isLoading={isLoading}
                    placeholder={"-- Select Company --"}
                    validationMessage={"Please Select Company"}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Status</Form.Label>
                <div className="w-75">
                  <ReactSelect
                    value={postStatus}
                    setValue={setPostStatus}
                    data={data.status}
                    isLoading={isLoading}
                    placeholder={"-- Select Status --"}
                    validationMessage={"Please Select Status"}
                  />
                </div>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Employee</Form.Label>
                <Form.Label className="w-75">{postEmployee.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Company</Form.Label>
                <Form.Label className="w-75">{postCompany.label}</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                <Form.Label className="w-25">Status</Form.Label>
                <Form.Label className="w-75">{postStatus.label}</Form.Label>
              </Form.Group>
              <div className="text-end">
                <button
                  className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                  type="submit"
                  name="cancel"
                  value={"Cancel"}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger mt-3 col-4 btn-sm"
                  type="submit"
                  name="submit"
                  value={"Submit"}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}
export default ModalAddApproval;
