import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ModalEditSpk from "../modal/ModalEditSpk";
import moment from "moment";
import { getRejectedSpk } from "../../../data-api/resource-team";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";
import ModalShowPDF from "../../../utils/ModalShowPDF";

function TableRejectedSPK(props) {
  const accessMenu = props.accessMenu;
  useEffect(() => {
    if (props.data.length === 0) {
      getRejectedSpk().then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          setSpk([]);
        } else {
          setSpk(result.data);
        }
        setIsLoading(false);
      });
    } else {
      setSpk(props.data);
      setIsLoading(false);
    }
  }, []);

  const [spk, setSpk] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editStatus, setEditStatus] = useState(false);
  const [formData, setFormData] = useState({});
  const [showEditSpk, setShowEditSpk] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  const handleCloseEditSpk = () => {
    if (editStatus) {
      props.resetTable(props.tabKey);
    }
    setShowEditSpk(false);
  };

  const columnsSpk = useMemo(
    () => [
      {
        Header: "SPK ID",
        accessor: "spk_id",
      },
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Contract Title",
        accessor: "contract_title",
      },
      {
        Header: "Contract No",
        accessor: "contract_no",
      },
      {
        Header: "Reject Reason",
        accessor: "reject_reason",
      },
      {
        Header: "Reject Date",
        accessor: "reject_date",
      },
      {
        Header: "PDF",
        accessor: "pdf",
        Cell: (props) => (
          <button
            style={{
              padding: "0",
              border: "none",
              background: "none",
              textDecoration: "underline",
              color: "#0d6efd",
            }}
            href={`#`}
            onClick={() => handleShowPDF(`${props.value}?${Date.now()}`)}
          >
            {props.value === "" ? "" : "View PDF"}
          </button>
        ),
      },
      {
        Header: "Client ID",
        accessor: "client_id",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  function editSpk(
    spk_id,
    spk_type,
    nik,
    employee_name,
    start_date,
    end_date,
    client_id,
    contract_title,
    contract_no
  ) {
    setFormData({
      spk_id: spk_id,
      spk_type: spk_type,
      nik: nik,
      employee_name: employee_name,
      start_date: start_date,
      end_date: end_date,
      client_id: client_id,
      contract_title: contract_title,
      contract_no: contract_no,
    });
    setShowEditSpk(true);
  }

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                editSpk(
                  row.values.spk_id,
                  row.values.spk_type,
                  row.values.nik,
                  row.values.employee_name,
                  row.values.start_date,
                  row.values.end_date,
                  row.values.client_id,
                  row.values.contract_title,
                  row.values.contract_no
                )
              }
              disabled={!accessMenu.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  const data = spk.length === 0 ? EmptyData : spk;

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => {
      delete v.spk_id;
      delete v.client_id;
      delete v.company;
    });
    excelData.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
      item.reject_date =
        item.reject_date === ""
          ? ""
          : moment(item.reject_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = columnsSpk
      .filter(
        (item) =>
          item.Header !== "SPK ID" &&
          item.Header !== "Client ID" &&
          item.Header !== "Company"
      )
      .map((item) => item.Header);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Rejected SPK",
    });
  }

  if (isLoading) return <span>Loading...</span>;

  return (
    <>
      <div className="tabs-content">
        <ReactTableCustomHooks
          columns={columnsSpk}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          tableHooks={tableHooks}
          isLoading={isLoading}
          hiddenColumns={[
            "contract_title",
            "contract_no",
            "spk_id",
            "client_id",
          ]}
          paging={[10, 20, 50, 100]}
        />
      </div>

      <Modal
        show={showEditSpk}
        onHide={handleCloseEditSpk}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditSpk
          data={formData}
          editStatus={setEditStatus}
          accessMenu={accessMenu}
          menu="reject"
        />
      </Modal>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}
export default TableRejectedSPK;
