import { useEffect, useState } from "react";
import { getTalentPartnership } from "../../data-api/talent-partnership";
import TableTalentP from "./table/TableTalentP";
import { useOutletContext } from "react-router-dom";
import { getRejectedSpk, getWaitingSpk } from "../../data-api/resource-team";
import { Modal, Tab, Tabs } from "react-bootstrap";
import TableRejectedSPK from "./table/TableRejectedSPK";
import TableWaitingSPK from "./table/TableWaitingSPK";
import ModalAddTalentP from "./modal/ModalAddTalentP";
import ModalMessage from "../template/ModalMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import LoadingEffect from "../template/loadingEffectTable";
import ModalSetEmailPO from "./modal/ModalSetEmailPO";

function TalentPartnership() {
  const [tabKey, setTabKey] = useState("1");

  const [isLoading, setIsLoading] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const outletContext = useOutletContext();

  useEffect(() => {
    refreshData();
  }, []);

  const accessVendorEmployee = outletContext.accessMenu.find(
    (data) => data.menu === "Vendor Employee"
  ).sub_menu;

  const accessVendor = accessVendorEmployee.find(
    (data) => data.sub_menu === "Vendor"
  );

  const access = outletContext.accessMenu.find(
    (data) => data.menu === "Resource Team"
  ).sub_menu;

  const waitingSpkAccess = access.find(
    (data) => data.sub_menu === "Waiting SPK"
  );

  const rejectedSpkAccess = access.find(
    (data) => data.sub_menu === "Rejected SPK"
  );

  const [talentP, setTalentP] = useState([]);
  const [spkWaiting, setSpkWaiting] = useState([]);
  const [spkRejected, setSpkRejected] = useState([]);

  function handleSelect(key) {
    setTabKey(key);
  }

  function refreshData(key) {
    setIsLoading(true);
    if (key !== "") {
      setTabKey("1");
    }
    const param = {
      group: "talent",
    };
    getTalentPartnership()
      .then((res) => {
        if (res.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (res.meta.code === 200) {
          res.data !== null ? setTalentP(res.data) : setTalentP([]);
        } else {
          showAlertError(false, res.meta.message);
          setTalentP([]);
        }
      })
      .then(() => {
        getWaitingSpk(param)
          .then((result) => {
            if (result.meta.code === 401) {
              window.location.reload();
              return;
            }
            if (result.data === null || result.data === undefined) {
              setSpkWaiting([]);
            } else {
              setSpkWaiting(result.data);
            }
          })
          .then(() => {
            getRejectedSpk(param).then((result) => {
              if (result.meta.code === 401) {
                window.location.reload();
                return;
              }
              if (result.data === null || result.data === undefined) {
                setSpkRejected([]);
              } else {
                setSpkRejected(result.data);
              }
              setIsLoading(false);
            });
          });
      });
  }

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalData, setModalData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };

  const [showSetEmail, setShowSetEmail] = useState(false);
  const handleCloseSetEmail = () => {
    setShowSetEmail(false);
  };

  const handleShowModalMessage = (modalData) => {
    setModalData(modalData);
    setShowModalMessage(true);
  };

  const handleCloseModalMessage = () => {
    setShowModalMessage(false);
    refreshData();
  };

  if (isLoading) {
    return (
      <div className="w-100 tabs-content rounded">
        <LoadingEffect />
      </div>
    );
  }

  return (
    <>
      <div className="w-100">
        {showAlert ? (
          <div
            className={
              AlertSuccess
                ? "alert alert-success alert-dismissible fade show"
                : "alert alert-danger alert-dismissible fade show"
            }
            role="alert"
          >
            <strong>{AlertMessage}</strong>
            <button
              className="btn btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </div>
        ) : null}

        <div className="d-flex flex-row-reverse bd-highlight mt-3">
          {tabKey === "1" ? (
            <>
              <button
                className="btn btn-danger ms-3 col-2 btn-sm"
                onClick={() => setShowAdd(true)}
                disabled={!accessVendor.is_create}
              >
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  style={{ marginRight: "10px" }}
                />{" "}
                New
              </button>
              <button
                className="btn btn-danger ms-3 col-2 btn-sm"
                onClick={() => setShowSetEmail(true)}
                disabled={!accessVendor.is_update}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{ marginRight: "10px" }}
                />{" "}
                Set Notification Email
              </button>
            </>
          ) : null}
        </div>

        <Tabs
          defaultActiveKey={tabKey}
          id="uncontrolled-tab-example"
          onSelect={(e) => handleSelect(e)}
        >
          {accessVendor.is_read ? (
            <Tab eventKey={1} title="Vendor">
              <TableTalentP
                tabKey={tabKey}
                data={talentP}
                isLoding={isLoading}
                refreshTable={handleCloseModalMessage}
                error={showAlertError}
              />
            </Tab>
          ) : null}

          {waitingSpkAccess.is_read ? (
            <Tab
              eventKey={2}
              title={spkWaiting.length === 0 ? "Waiting SPK" : "Waiting SPK *"}
            >
              <TableWaitingSPK
                data={spkWaiting}
                resetTable={handleCloseModalMessage}
                isLoding={isLoading}
                tabKey={tabKey}
                error={showAlertError}
                accessMenu={waitingSpkAccess}
              />
            </Tab>
          ) : null}

          {rejectedSpkAccess.is_read ? (
            <Tab eventKey={3} title="Rejected SPK">
              <TableRejectedSPK
                data={spkRejected}
                resetTable={handleCloseModalMessage}
                isLoding={isLoading}
                tabKey={tabKey}
                error={showAlertError}
                accessMenu={rejectedSpkAccess}
              />
            </Tab>
          ) : null}
        </Tabs>

        <Modal
          show={showAdd}
          onHide={handleCloseAdd}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <ModalAddTalentP
            data={modalData}
            refreshTable={handleCloseModalMessage}
            close={handleCloseAdd}
            message={handleShowModalMessage}
          />
        </Modal>

        <Modal
          show={showSetEmail}
          onHide={handleCloseSetEmail}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <ModalSetEmailPO
            data={modalData}
            refreshTable={handleCloseModalMessage}
            close={handleCloseSetEmail}
            alert={showAlertError}
            message={handleShowModalMessage}
          />
        </Modal>

        {showModalMessage ? (
          <ModalMessage data={modalData} click={handleCloseModalMessage} />
        ) : null}
      </div>
    </>
  );
}

export default TalentPartnership;
