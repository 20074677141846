import { useState } from "react";
import TableProject from "./table/TableProject";

function ProjectTeam() {
  const [showAlert, setShowAlert] = useState(false);
  const [AlertSuccess, setAlertSuccess] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");

  function showAlertError(success, message) {
    setAlertSuccess(success);
    setAlertMessage(message);
    setShowAlert(true);
  }

  return (
    <>
      <div className="w-100 tabs-content rounded">
        <div>
          {showAlert ? (
            <div
              className={
                AlertSuccess
                  ? "alert alert-success alert-dismissible fade show"
                  : "alert alert-danger alert-dismissible fade show"
              }
              role="alert"
            >
              <strong>{AlertMessage}</strong>
              <button
                className="btn btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
          ) : null}
          <div className="custom-text-14">
            <TableProject error={showAlertError} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectTeam;
