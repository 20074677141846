import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = `Bearer ${cookies.get("session")}`;

export const getHistory = async (email, status) => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk/${email}/history`;
  return axios
    .get(url, {
      params: { status: status },
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListDropdownTicket = async () => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk/list-dropdown/ticket`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const getListDropdownUser = async () => {
  const url = `${process.env.REACT_APP_API_URL}/list-dropdown/user`;
  return axios
    .get(url, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const postTicketing = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk/ticket`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const updateStatusHelpdesk = async (id, data) => {
  const url = `${process.env.REACT_APP_API_URL}/helpdesk/${id}`;
  return axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
