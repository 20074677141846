import {
  faAnglesLeft,
  faAnglesRight,
  faCloudArrowDown,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ModalMessage from "../../template/ModalMessage";
import { Button, Modal } from "react-bootstrap";
import ModalAddTalentProject from "../modal/ModalAddTalentProject";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import ModalEmailSpk from "../../resource-team/modal/ModalEmailSpk";
import ModalShowPDF from "../../../utils/ModalShowPDF";
import { getSpkDetail } from "../../../data-api/resource-team";

function TableDetailTalentP(props) {
  const [talentP, setTalentP] = useState([]);

  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setIsLoading(true);
    getSpkDetail(props.data.nik).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        res.data !== null ? setTalentP(res.data) : setTalentP([]);
      } else {
        setTalentP([]);
      }
      setIsLoading(false);
    });
  }

  const [searchVal, setSearchVal] = useState("");

  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };

  const [showModalMessage, setShowModalMessage] = useState(false);

  const handleShowModalMessage = (modalData) => {
    setModalData(modalData);
    setShowModalMessage(true);
  };

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => {
      delete v.spk_id;
      delete v.client_id;
      delete v.spk_no;
      delete v.contract_title;
      delete v.contract_no;
      delete v.email_office;
      delete v.position;
    });
    let excel = [];
    excelData.forEach((v) => {
      excel.push({
        nik: v.nik,
        employee_name: v.employee_name,
        start_date: v.start_date,
        end_date: v.end_date,
        client_name: v.client_name,
        spk_type: v.spk_type,
        // contract_title: v.contract_title,
        // contract_no: v.contract_no,
        pdf: v.pdf,
      });
    });

    excel.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = [
      "NIK",
      "Name",
      "Start Date",
      "End Date",
      "Client",
      "SPK Type",
      // "Contract Title",
      // "Contract No",
      "PDF",
    ];
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    excel.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName:
        "Detail_Talent_Project_" + props.data.nik + "_" + props.data.name,
    });
  }

  const handleCloseEmail = () => {
    setShowEmail(false);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "SPK ID",
      //   accessor: "spk_id",
      // },
      {
        Header: "NIK",
        accessor: "nik",
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Email",
        accessor: "email_office",
      },
      {
        Header: "Client ID",
        accessor: "client_id",
      },
      {
        Header: "Client",
        accessor: "client_name",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Contract Title",
        accessor: "contract_title",
      },
      {
        Header: "Contract No",
        accessor: "contract_no",
      },
      {
        Header: "PDF",
        accessor: "pdf",
        Cell: (props) => (
          <a
            href={`#`}
            onClick={() => handleShowPDF(`${props.value}?${Date.now()}`)}
          >
            {props.value === "" ? "" : "View PDF"}
          </a>
        ),
      },
    ],
    []
  );

  const [emailData, setEmailData] = useState({});
  const [showEmail, setShowEmail] = useState(false);

  const handleShowEmail = (
    employee_email,
    nik,
    employee_name,
    position,
    placement,
    spk_type,
    start_date,
    end_date,
    pdf
  ) => {
    setEmailData({
      email: employee_email,
      nik: nik,
      employee_name: employee_name,
      position: position,
      placement: placement,
      spk_type: spk_type,
      start_date: start_date,
      end_date: end_date,
      pdf: pdf,
    });
    setShowEmail(true);
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "send_email",
        Header: "Send Email",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                handleShowEmail(
                  row.values.email_office,
                  row.values.nik,
                  row.values.employee_name,
                  row.values.position,
                  row.values.client_name,
                  row.values.spk_type,
                  row.values.start_date,
                  row.values.end_date,
                  row.values.pdf
                )
              }
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  const EmptyData = useMemo(() => [], []);

  const data = talentP.length === 0 ? EmptyData : talentP;
  const {
    rows,
    headers,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: [
          "spk_id",
          "nik",
          "employee_name",
          "employee_email",
          "client_id",
          "position",
          "email_office",
          "contract_title",
          "contract_no",
          "id",
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
  );

  const [isLoading, setIsLoading] = useState(true);

  function searchItem() {
    if (
      moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD") !==
      "Invalid date"
    ) {
      setGlobalFilter(
        moment(searchVal, "DD-MMM-YYYY", true).format("YYYY-MM-DD")
      );
    } else {
      setGlobalFilter(searchVal);
    }
  }

  return (
    <>
      {props.isLoading || isLoading ? (
        <>
          <div className="w-100">
            <div className="shine-photo shine"></div>
          </div>
        </>
      ) : (
        <>
          <div className="mx-3 d-flex justify-content-between">
            <div className="text-start text-danger fw-semibold mt-1 d-flex">
              {rows.length} records
            </div>
            <div className="col-8 bd-highlight d-flex justify-content-end">
              <form
                id="search-reacttablecustom"
                className="input-group w-50 flex border border-danger rounded"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search ..."
                  value={searchVal}
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  onChange={(e) => {
                    setSearchVal(e.target.value);
                    if (e.target.value === "") {
                      searchItem();
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchItem();
                    }
                  }}
                />
                {searchVal !== "" ? (
                  <button
                    className="btn btn-outline-light text-danger btn-sm"
                    onClick={() => {
                      setSearchVal("");
                      setGlobalFilter("");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ color: "#848484" }}
                      size="sm"
                    />
                  </button>
                ) : null}
                <button
                  className="btn btn-danger btn-sm"
                  id="button-addon2"
                  onClick={() => searchItem()}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    style={{ color: "#fff", float: "right" }}
                  />
                </button>
              </form>
              <button
                className="btn btn-outline-danger btn-sm ms-3 col-3"
                onClick={handleDownloadExcel}
              >
                <FontAwesomeIcon
                  icon={faCloudArrowDown}
                  style={{ marginRight: "10px" }}
                />
                Download
              </button>
            </div>
          </div>
          <div className="overflow-x-scroll mt-3">
            <table className="table table-sm custom-table align-middle">
              <thead className="text-danger align-middle nowrap">
                {headerGroups.map((headerGroup, key) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                    {headerGroup.headers.map((column, key) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        key={key}
                        className="text-danger"
                      >
                        {column.render("Header")}
                        <span className="ms-2">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {rows.length === 0 ? (
                <tbody
                  style={{ backgroundColor: "#ececec", textAlign: "center" }}
                >
                  <td colSpan={headers.length}>No data available in table</td>
                </tbody>
              ) : (
                <tbody {...getTableBodyProps()} className="nowrap">
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={key}>
                        {row.cells.map((cell, key) => {
                          return (
                            <td {...cell.getCellProps()} key={key}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          <div className="pagination d-flex justify-content-center">
            <button
              className="ms-1 me-2 btn btn-danger btn-sm"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon
                className="mt-1 me-1"
                icon={faAnglesLeft}
                style={{ color: "#fff" }}
              />
              First
            </button>
            {""}
            <button
              className="ms-1 me-3 btn btn-outline-danger btn-border btn-sm"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"< Prev"}
            </button>{" "}
            {pageIndex > 4 ? (
              <span className="align-text-bottom bg-danger me-4">
                <p className="mt-3" style={{ position: "absolute" }}>
                  ...
                </p>
              </span>
            ) : null}
            {pageOptions
              .slice(
                pageIndex < 5
                  ? 0
                  : pageIndex >= pageCount - 4
                  ? pageCount - 9
                  : pageIndex - 4,
                pageIndex < 5
                  ? 9
                  : pageIndex >= pageCount - 4
                  ? pageCount
                  : pageIndex + 5
              )
              .map((index, i) => {
                if (index === pageIndex) {
                  return (
                    <button
                      className="ms-1 btn btn-danger btn-sm"
                      onClick={() => gotoPage(index)}
                      disabled={!canNextPage}
                      key={i}
                    >
                      {index + 1}
                    </button>
                  );
                } else {
                  return (
                    <button
                      className="ms-1 btn btn-outline-danger btn-border btn-sm"
                      onClick={() => gotoPage(index)}
                      disabled={!canNextPage && index === pageCount}
                      key={i}
                    >
                      {index + 1}
                    </button>
                  );
                }
              })}
            {pageIndex + 1 < pageOptions.length - 3 ? (
              <span className="align-text-bottom bg-danger ms-3 me-3">
                <p className="mt-3" style={{ position: "absolute" }}>
                  ...
                </p>
              </span>
            ) : null}
            <button
              className="ms-3 btn btn-outline-danger btn-border btn-sm"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {"Next >"}
            </button>{" "}
            <button
              className="ms-2 me-1 btn btn-danger fa btn-sm"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Last
              <FontAwesomeIcon
                className="mt-1 ms-1"
                icon={faAnglesRight}
                style={{ color: "#fff", float: "right" }}
              />
            </button>{" "}
          </div>
          <div
            className="d-flex justify-content-end mb-2 me-2"
            style={{ color: "#525555" }}
          >
            Page{"."}
            <strong className="ms-1">
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </div>
        </>
      )}

      <Modal
        show={showAdd}
        onHide={handleCloseAdd}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalAddTalentProject
          data={props.dataModal}
          closeDetail={props.close}
          close={handleCloseAdd}
          message={handleShowModalMessage}
        />
      </Modal>

      <Modal
        show={showEmail}
        onHide={handleCloseEmail}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEmailSpk
          title={"talent"}
          data={emailData}
          alert={props.error}
          closeModal={handleCloseEmail}
        />
      </Modal>

      {showModalMessage ? (
        <ModalMessage data={modalData} click={props.refreshTable} />
      ) : null}

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default TableDetailTalentP;
