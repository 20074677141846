import "../dashboard/dashboard.css";

const LoadingEffect = (props) => {
  return (
    <>
      <div className="w-100">
        <div className="row">
          <div className="d-flex flex-row-reverse" style={{ height: "50px" }}>
            <div className="shine-div shine w-25 rounded"></div>
            <div className="shine-div shine w-25 me-3 rounded"></div>
          </div>
        </div>
        <div className="row">
          <div className="flex col-sm-12">
            <div className="shine-photo shine"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingEffect;
