import { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getSuperadminRole,
  updateSuperadminUserRole,
} from "../../../../data-api/master-user";
import { useOutletContext } from "react-router-dom";
import { ReactSelect } from "../../../../utils/ReactSelect";
import LoadingEffectForm from "../../../template/loadingEffectForm";

function ModalEditRole(props) {
  const outletContext = useOutletContext();
  const [listRole, setListRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [postData, setPostData] = useState({});
  const [postRole, setPostRole] = useState("");

  useEffect(() => {
    fetchDropdown();
  }, []);

  function fetchDropdown() {
    setIsLoading(true);
    getSuperadminRole().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setListRole([]);
      } else {
        if (outletContext.profile.role_id === "1") {
          setListRole(result.data);
        } else {
          const tempData = result.data.filter(function (obj) {
            return obj.id !== 1;
          });
          setListRole(tempData);
        }
      }
      // console.log(
      //   result.data.find((item) => item.role_name === props.data.original.role)
      // );
      setPostRole({
        label: result.data.find(
          (item) => item.role_name === props.data.original.role
        )?.role_name,
      });
      setIsLoading(false);
    });
  }

  function postEditRole(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!isConfirm) {
      setPostData({
        role_id: postRole.value,
      });
      setIsConfirm(true);
    } else {
      const json = JSON.stringify(postData);

      updateSuperadminUserRole(props.data.original.id, json).then((result) => {
        if (result.meta.code === 401) {
          window.location.reload();
          return;
        }
        if (result.data === null || result.data === undefined) {
          props.handleSuccessModal(false, result.meta.message);
        } else {
          const message = {
            code: result.meta.code,
            message: result.meta.message,
          };
          props.handleSuccessModal(true, message.message);
        }
      });
    }
  }

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffectForm row={3} />
        </Modal.Body>
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit User Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3 custom-text-14">
          <Form onSubmit={postEditRole}>
            {!isConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">Email</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {props.data.original.email}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">
                    Department
                  </Form.Label>
                  <Form.Label className="w-75 text-start">
                    {props.data.original.department}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Role</Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={postRole}
                      setValue={setPostRole}
                      data={listRole.map((data) => ({
                        label: data.role_name,
                        value: data.id,
                      }))}
                      isLoading={isLoading}
                      placeholder={"-- Select Role --"}
                      validationMessage={"Please Select Role"}
                    />
                  </div>
                </Form.Group>
                <div className="text-end">
                  <button className="btn btn-danger mt-4 w-100 btn-sm">
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">Email</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {props.data.original.email}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">
                    Department
                  </Form.Label>
                  <Form.Label className="w-75 text-start">
                    {props.data.original.department}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25">Role</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {postRole.label}
                  </Form.Label>
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-outline-danger mt-4 col-5 me-4 btn-sm"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsConfirm(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-danger mt-4 col-5 btn-sm">
                    Submit
                  </button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal.Body>
    </>
  );
}

export default ModalEditRole;
