import { Modal, Form } from "react-bootstrap";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Addresign.css";
import "react-date-range/dist/styles.css";
import Select from "react-select";

function FilterResign(props) {
  const [year, setyear] = useState(
    props.filterData.year === "" ? "" : props.filterData.year
  );

  const [month, setmonth] = useState(
    props.filterData.month === "" ? "" : props.filterData.month
  );
  const [company, setcompany] = useState(
    props.filterData.company === "" ? "" : props.filterData.company
  );

  const dateYear = new Date().getFullYear() - 3;
  const listYear = Array.from(new Array(20), (val, index) => index + dateYear);

  const MonthOption = [
    { key: "1", value: "Januari" },
    { key: "2", value: "Febuari" },
    { key: "3", value: "Maret" },
    { key: "4", value: "April" },
    { key: "5", value: "Mei" },
    { key: "6", value: "Juni" },
    { key: "7", value: "July" },
    { key: "8", value: "agustus" },
    { key: "9", value: "September" },
    { key: "10", value: "Oktober" },
    { key: "11", value: "November" },
    { key: "12", value: "Desember" },
  ];
  const CompanyOption = [
    { key: "1", value: "MTK" },
    { key: "2", value: "IDStar" },
    { key: "3", value: "Drife" },
  ];

  function Filter() {
    props.filter(month, year, company);
    props.onHide();
  }
  return (
    <>
      <Modal.Header closeButton>
        <div className="fw-semibold custom-text-20">Filter</div>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <Form.Group className="mb-3 fw-semibold">
          <Form.Label>Month</Form.Label>
          <Select
            className="w-100 fw-normal custom-text-14 z-3"
            styles={{
              control: (base) => ({
                ...base,
                height: 35,
                minHeight: 35,
              }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                fontSize: "14px",
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={MonthOption.map((data) => ({
              label: data.value,
              value: data.key,
            }))}
            isClearable
            placeholder={"-- Select Month --"}
            value={month}
            onChange={(e) => {
              if (e !== null) {
                setmonth(e);
              } else {
                setmonth("");
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3 fw-semibold">
          <Form.Label>Year</Form.Label>
          <Select
            className="w-100 fw-normal custom-text-14 z-3"
            styles={{
              control: (base) => ({
                ...base,
                height: 35,
                minHeight: 35,
              }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                fontSize: "14px",
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={listYear.map((data) => ({
              label: data,
              value: data,
            }))}
            isClearable
            placeholder={"-- Select Year --"}
            value={year}
            onChange={(e) => {
              if (e !== null) {
                setyear(e);
              } else {
                setyear("");
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3 fw-semibold">
          <Form.Label>Company</Form.Label>
          <Select
            className="w-100 fw-normal custom-text-14 z-3"
            styles={{
              control: (base) => ({
                ...base,
                height: 35,
                minHeight: 35,
              }),
              menu: (base) => ({ ...base, zIndex: 9999 }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                fontSize: "14px",
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={CompanyOption.map((data) => ({
              label: data.value,
              value: data.key,
            }))}
            isClearable
            placeholder={"-- Select Company --"}
            value={company}
            onChange={(e) => {
              if (e !== null) {
                setcompany(e);
              } else {
                setcompany("");
              }
            }}
          />
        </Form.Group>
        <div className="d-grid gap-2">
          <button
            className="btn btn-danger mb-4 ms-5 me-5 mt-3 fw-bold shadow btn-sm"
            onClick={Filter}
          >
            FILTER
          </button>
        </div>
      </Modal.Body>
    </>
  );
}

export default FilterResign;
