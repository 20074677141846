import { useMemo } from "react";
import ReactTable from "../../../utils/ReactTable";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowDown,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import ModalUploadCalculate from "../modal/ModalUploadCalculate";
import {
  getTotalVar,
  importCostCalculate,
  saveCostCalculate,
} from "../../../data-api/cost-to-company";
import ModalMessage from "../../template/ModalMessage";
import writeXlsxFile from "write-excel-file";
import { useEffect } from "react";

function TabCalculate(props) {
  const [data, setData] = useState([]);
  const [totalVar, setTotalVar] = useState({});
  const [calculateData, setCalculateData] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const handleShowUpload = () => setShowUpload(true);
  const handleCloseUpload = () => setShowUpload(false);
  var moneyFormatter = new Intl.NumberFormat();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchTotalVar();
  }, []);

  function calculateResult(data) {
    setData(data);
    const json = JSON.stringify(data);
    calculateCost(json);
  }

  function fetchTotalVar() {
    setIsLoading(true);
    getTotalVar().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setTotalVar({})
        : setTotalVar(result.data);
      setIsLoading(false);
    });
  }

  function calculateCost(data) {
    importCostCalculate(data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setCalculateData([])
        : setCalculateData(result.data);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Number",
        accessor: "number",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.number)}</span>
          </div>
        ),
      },
      {
        Header: "Laptop",
        accessor: "laptop",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{row.values.laptop}</span>
          </div>
        ),
      },
    ],
    []
  );

  const columnsCalculate = useMemo(
    () => [
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Married",
        accessor: "married",
      },
      {
        Header: "Kids",
        accessor: "kids",
      },
      {
        Header: "Total Var",
        accessor: "total_var",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.total_var)}</span>
          </div>
        ),
      },
      {
        Header: "Laptop",
        accessor: "laptop",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.laptop)}</span>
          </div>
        ),
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.insurance)}</span>
          </div>
        ),
      },
      {
        Header: "L&D",
        accessor: "lnd",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.lnd)}</span>
          </div>
        ),
      },
      {
        Header: "Result",
        accessor: "result",
        Cell: ({ row }) => (
          <div className="text-center">
            <span>{moneyFormatter.format(row.values.result)}</span>
          </div>
        ),
      },
    ],
    []
  );

  function handleDownloadExcel() {
    const dataSet = [];
    const dataHeader = [];
    columnsCalculate.forEach((item) => {
      dataHeader.push({
        value: item.Header,
        // type: typeof value === "number" ? "number" : "string",
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    // FILTERED ROWS
    calculateData.forEach((value) => {
      const dataRow = [];

      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          // type: typeof value === "number" ? "number" : "string",
          type: typeof item === "string" ? String : Number,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      //columns, // (optional) column widths, etc.
      fileName: "Cost_Calculate",
    });

    // const config = {
    //   filename: "Cost_Calculate",
    //   sheet: {
    //     data: dataSet,
    //   },
    // };

    // zipcelx(config);
  }

  function saveCost() {
    const json = JSON.stringify(calculateData);
    saveCostCalculate(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message = {
        code: result.meta.code,
        message: result.meta.message,
      };
      if (result.meta.code === 200) {
        handleOpenModalSuccess(message);
        setUploadData([]);
        setData([]);
      } else {
        handleOpenModalSuccess(message);
      }
    });
  }

  const [modalSuccessData, setModalSuccessData] = useState({});
  const [modalSuccess, setModalSuccess] = useState(false);
  function handleCloseModalSuccess() {
    setModalSuccess(false);
    window.location.reload();
  }
  function handleOpenModalSuccess(data) {
    setModalSuccessData(data);
    setModalSuccess(true);
  }

  return (
    <>
      <div className="tabs-content">
        <div className="row custom-text-14 w-100 ms-1 mb-3">
          <div className="col-4 text-start text-danger fw-semibold d-flex"></div>
          <div className="col-8 bd-highlight d-flex justify-content-end">
            <a
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              href="https://integrals-public.s3.ap-southeast-1.amazonaws.com/ctc_example/cost_calculate_example.csv"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ color: "#fff", marginRight: "10px" }}
              />
              Format File
            </a>

            <button
              className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
              onClick={handleShowUpload}
              disabled={
                !props.accessMenu.is_read || !props.accessMenu.is_update
              }
            >
              <FontAwesomeIcon
                icon={faFileImport}
                style={{ marginRight: "10px" }}
              />
              Upload Files
            </button>
          </div>
        </div>

        <ReactTable
          columns={columns}
          data={data}
          handleDownloadExcel={null}
          isLoading={isLoading}
          handleFilter={null}
          hiddenColumns={[]}
        />
        <div className="text-start container">
          <p className="fw-semibold">Total Variable : {totalVar.value}</p>
        </div>
        {data.length !== 0 ? (
          <>
            <div className="card mt-5 p-3">
              <h5>Result</h5>

              <ReactTable
                columns={columnsCalculate}
                data={calculateData}
                handleDownloadExcel={handleDownloadExcel}
                handleFilter={null}
                hiddenColumns={[]}
              />

              <div className="container d-flex flex-row-reverse bd-highlight mt-3 mb-4">
                <button
                  className="btn btn-danger ms-3 col-3"
                  onClick={saveCost}
                  disabled={calculateData.length === 0 ? true : false}
                >
                  Save Result to DB
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <Modal
        show={showUpload}
        onHide={handleCloseUpload}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalUploadCalculate
          handleCloseUpload={handleCloseUpload}
          setData={calculateResult}
          uploadData={uploadData}
          setUploadData={setUploadData}
        />
      </Modal>

      {modalSuccess ? (
        <ModalMessage data={modalSuccessData} click={handleCloseModalSuccess} />
      ) : null}
    </>
  );
}

export default TabCalculate;
