import moment from "moment";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { updateSuperadminHelpdeskLog } from "../../../../data-api/master-user";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGb from "date-fns/locale/en-GB"; // the locale you want
import { ReactSelect } from "../../../../utils/ReactSelect";
registerLocale("en-GB", enGb);

function UpdateTicket(props) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [postData, setPostData] = useState({});
  const [date, setDate] = useState(new Date());
  const [postStatus, setPostStatus] = useState("");

  function formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent.submitter.name === "submit") {
      if (!isConfirm) {
        setPostData({
          status: parseInt(postStatus.value),
          solved_by: event.target[4].value,
          solve_method: event.target[3].value,
          done_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        });
        setIsConfirm(true);
      } else {
        const json = JSON.stringify(postData);

        updateSuperadminHelpdeskLog(props.data.original.id, json).then(
          (result) => {
            if (result.meta.code === 401) {
              window.location.reload();
              return;
            }
            if (result.data === null || result.data === undefined) {
              props.handleSuccessModal(false, result.meta.message);
            } else {
              const message = {
                code: result.meta.code,
                message: result.meta.message,
              };
              props.handleSuccessModal(true, message.message);
            }
          }
        );
      }
    } else {
      setIsConfirm(false);
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Update Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-14">
        <div className="p-3">
          <form onSubmit={formSubmit}>
            {!isConfirm ? (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex flex-column">
                  <Form.Label className="w-100 text-start fw-bold">
                    Message
                  </Form.Label>
                  <Form.Label className="w-100 text-start">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.original.message,
                      }}
                    ></div>
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-between">
                  <Form.Label className="w-25 text-start fw-semibold">
                    Date
                  </Form.Label>
                  <div className="w-75">
                    <ReactDatePicker
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showTimeInput
                      locale={enGb}
                      className="w-100 form-control"
                      wrapperClassName="w-100 form-control"
                      todayButton="Today"
                      dateFormat="dd/MM/yyyy HH:mm"
                      selected={date}
                      onChange={(value) => setDate(value)}
                      isClearable
                      placeholderText="DD/MM/YYYY HH:mm"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Please fill out Date")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">
                    Status
                  </Form.Label>
                  <div className="w-75">
                    <ReactSelect
                      value={postStatus}
                      setValue={setPostStatus}
                      data={[
                        {
                          value: "2",
                          label: "In Progress",
                        },
                        {
                          value: "3",
                          label: "Done",
                        },
                        {
                          value: "4",
                          label: "Test",
                        },
                      ]}
                      isLoading={false}
                      placeholder={"-- Select Status --"}
                      validationMessage={"Please select Status"}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">
                    Solution
                  </Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out Solution")
                    }
                    defaultValue={
                      postData.solve_method !== undefined
                        ? postData.solve_method
                        : ""
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start mt-2">By</Form.Label>
                  <Form.Control
                    className="w-75 form-control-sm"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out by")
                    }
                    defaultValue={
                      postData.solved_by !== undefined ? postData.solved_by : ""
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  />
                </Form.Group>

                <div className="mt-4">
                  <button
                    className="btn btn-danger w-100 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <>
                <Form.Group className="mb-3 fw-semibold d-flex flex-column">
                  <Form.Label className="w-100 text-start fw-bold">
                    Message
                  </Form.Label>
                  <Form.Label className="w-100 text-start">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.original.message,
                      }}
                    ></div>
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">Date</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {moment(postData.done_date).format("DD-MMM-YYYY HH:mm")}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">Status</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {postData.status === 2
                      ? "In Progress"
                      : postData.status === 3
                      ? "Done"
                      : postData.status === 4
                      ? "Test"
                      : ""}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">Solution</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {postData.solve_method}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="w-25 text-start">By</Form.Label>
                  <Form.Label className="w-75 text-start">
                    {postData.solved_by}
                  </Form.Label>
                </Form.Group>
                <div className="text-end">
                  <button
                    className="btn btn-outline-danger mt-3 col-4 me-3 btn-sm"
                    type="submit"
                    name="cancel"
                    value={"Cancel"}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger mt-3 col-4 btn-sm"
                    type="submit"
                    name="submit"
                    value={"Submit"}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </Modal.Body>
    </>
  );
}

export default UpdateTicket;
