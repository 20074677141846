import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getAddSpkData,
  getListEmployees,
  postAddSpk,
} from "../../../data-api/resource-team";
import { useOutletContext } from "react-router-dom";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enUs from "date-fns/locale/en-US"; // the locale you want
import { InvalidInput, ReactSelect } from "../../../utils/ReactSelect";
import ModalShowPDF from "../../../utils/ModalShowPDF";
import Select from "react-select";
import LoadingEffectForm from "../../template/loadingEffectForm";
registerLocale("en-US", enUs);

function ModalAddSpk(props) {
  const [addModal, setAddModal] = useState({});
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);
  const [addSuccess, setAddSuccess] = useState({});

  const [nik, setNik] = useState("");
  const [placement, setPlacement] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [approveBy, setApproveBy] = useState("");
  const contractTitle =
    "Perjanjian Kerja Sama Pengadaan Jasa Penyediaan Tenaga Ahli Profesional TI BPJS Kesehatan";

  const contractNo = "470/KTR/1022";
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const outletContext = useOutletContext();

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  useEffect(() => {
    getAddSpkData().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setShowAlert(true);
        setErrorMessage(result.meta.message);
      } else {
        setAddModal(result.data);
      }
      setIsLoading(false);
    });
  }, []);

  const fetchDataEmployee = (type) => {
    const data = {
      filter: type.value,
    };

    getListEmployees(data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data !== null) {
        setEmployees(result.data);
      }
    });
  };

  function formAddSpk(event) {
    event.preventDefault();
    if (!addConfirm) {
      setAddConfirm(true);
    } else {
      setIsFetch(true);
      fetchCreateSpk();
    }
  }

  //send json to backend
  function fetchCreateSpk() {
    const json = JSON.stringify({
      nik: nik.value,
      start_date: moment(startDate).format("yyyy-MM-DD"),
      end_date: moment(endDate).format("yyyy-MM-DD"),
      client_id: placement.value,
      approve_by: approveBy.value,
      contract_title: contractTitle,
      contract_no: contractNo,
      created_by: outletContext.profile.nik,
    });

    postAddSpk(json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setIsFetch(false);
        setIsLoading(false);
        setShowAlert(true);
        setErrorMessage(result.meta.message);
      } else {
        setAddSuccess(result);
        setNik("");
        setPlacement("");
        setStartDate("");
        setEndDate("");
        setIsFetch(false);
      }
      props.addStatus(true);
      setIsLoading(false);
    });
  }

  if (isLoading)
    return (
      <>
        <LoadingEffectForm row={8} />
      </>
    );

  return (
    <>
      <div className="container custom-text-14">
        <Modal.Header closeButton>
          <span className="custom-text-20 fw-semibold">
            {Object.keys(addSuccess).length === 0 && !addConfirm
              ? "Add New Resource"
              : Object.keys(addSuccess).length === 0 && addConfirm
              ? "Confirm Add SPK"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status === "success"
              ? "Success Add SPK"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status !== "success"
              ? "Failed Add SPK"
              : null}
          </span>
        </Modal.Header>
        <Modal.Body>
          {/* {showAlert ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{errorMessage}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
          ) : null} */}
          <Form onSubmit={(e) => formAddSpk(e)}>
            {
              // form input
              Object.keys(addSuccess).length === 0 && !addConfirm ? (
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Type</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      defaultValue={"SPK 1"}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Group</Form.Label>
                    <div className="w-75">
                      <Select
                        className="w-100 fw-normal custom-text-14 z-3"
                        options={!isLoading && addModal.employee}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minHeight: 35,
                          }),
                          menu: (base) => ({ ...base, zIndex: 9999 }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            fontSize: "14px",
                          }),
                        }}
                        isClearable
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        placeholder={"-- Select Group --"}
                        defaultValue={""}
                        onChange={(e) => {
                          setNik("");
                          if (e !== null) {
                            fetchDataEmployee(e);
                          } else {
                            setNik("");
                            setEmployees([]);
                          }
                        }}
                        ariaLiveMessages={"Please Select Group"}
                        components={{
                          Input: InvalidInput,
                        }}
                      ></Select>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Employee</Form.Label>
                    <div className="w-75">
                      <Select
                        className="w-100 fw-normal custom-text-14 z-3"
                        options={!isLoading && employees}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minHeight: 35,
                          }),
                          menu: (base) => ({ ...base, zIndex: 9999 }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            fontSize: "14px",
                          }),
                        }}
                        isClearable
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        placeholder={"-- Select Employee --"}
                        value={nik}
                        defaultValue={nik}
                        onChange={(e) => {
                          if (e !== null) {
                            setNik(e);
                          } else {
                            setNik("");
                          }
                        }}
                        ariaLiveMessages={"Please Select Employee"}
                        components={{
                          Input: InvalidInput,
                        }}
                      ></Select>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">No. SPK</Form.Label>
                    <Form.Control
                      defaultValue={nik.value?.slice(-3)}
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Company</Form.Label>
                    <Form.Control
                      defaultValue={nik === "-1" ? "" : nik.company}
                      className="w-75 form-control-sm"
                      aria-describedby="basic-addon1"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Placement</Form.Label>
                    <div className="w-75">
                      <ReactSelect
                        value={placement}
                        setValue={setPlacement}
                        data={addModal.placement}
                        isLoading={isLoading}
                        placeholder={"-- Select Placement --"}
                        validationMessage={"Please Select Placement"}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Start Date
                    </Form.Label>
                    <div className="w-75 ">
                      <ReactDatePicker
                        locale={enUs}
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate ? startDate : ""}
                        onChange={(e) => setStartDate(e)}
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) => false}
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                        isClearable
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      End Date
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        locale={enUs}
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate ? endDate : ""}
                        onChange={(e) => setEndDate(e)}
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) => false}
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                        isClearable
                      />
                    </div>
                  </Form.Group>
                  {/* if placement bpjs */}
                  {placement.value === "5" ? (
                    <>
                      <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                        <Form.Label className="mt-2">Contract No</Form.Label>
                        <Form.Control
                          className="w-75 form-control-sm"
                          aria-describedby="basic-addon1"
                          defaultValue={contractNo}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                        <Form.Label className="mt-2">Contract Title</Form.Label>
                        <Form.Control
                          className="w-75 form-control-sm"
                          aria-describedby="basic-addon1"
                          defaultValue={contractTitle}
                          required
                        />
                      </Form.Group>
                    </>
                  ) : null}
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Approve By</Form.Label>
                    <div className="w-75">
                      <ReactSelect
                        value={approveBy}
                        setValue={setApproveBy}
                        data={addModal.approve_by}
                        isLoading={isLoading}
                        placeholder={"-- Select Approve by --"}
                        validationMessage={"Please Select Approve by"}
                      />
                    </div>
                  </Form.Group>
                </div>
              ) : // add spk confirmation
              Object.keys(addSuccess).length === 0 && addConfirm ? (
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Type</Form.Label>
                    <Form.Label className="mt-2 text-end">SPK-1</Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Employee</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {nik.label}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">No. SPK</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {nik.value.slice(-3)}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Company</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {nik.company}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Placement</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {placement.label}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Start Date</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {moment(startDate).format("DD-MMM-YYYY")}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">End Date</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {moment(endDate).format("DD-MMM-YYYY")}
                    </Form.Label>
                  </Form.Group>
                  {/* if placement bpjs */}
                  {placement.value === "5" ? (
                    <>
                      <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                        <Form.Label className="mt-2">Contract No</Form.Label>
                        <Form.Label className="mt-2 text-end">
                          {contractNo}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                        <Form.Label className="mt-2">Contract Title</Form.Label>
                        <Form.Label className="mt-2 text-end">
                          {contractTitle}
                        </Form.Label>
                      </Form.Group>
                    </>
                  ) : null}
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Approve By</Form.Label>
                    <Form.Label className="mt-2 text-end">
                      {approveBy.label}
                    </Form.Label>
                  </Form.Group>
                </div>
              ) : // if add spk success
              Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status === "success" ? (
                <div className="container d-flex flex-column py-4">
                  <FontAwesomeIcon
                    className="mb-4"
                    icon={faCircleCheck}
                    size="10x"
                    color="green"
                  />
                  <button
                    style={{
                      padding: "0",
                      border: "none",
                      background: "none",
                    }}
                    className="m-auto mt-4"
                    href={`#`}
                    onClick={() =>
                      handleShowPDF(`${addSuccess.data.pdf}?${Date.now()}`)
                    }
                  >
                    <input
                      className="btn btn-success"
                      type="button"
                      value="View PDF"
                    />
                  </button>
                </div>
              ) : // if add spk failed
              Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status !== "success" ? (
                <div className="container d-flex flex-column">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </div>
              ) : null
            }
            {Object.keys(addSuccess).length === 0 ? (
              <div className="d-flex flex-row-reverse bd-highlight">
                <button
                  className="btn btn-danger btn-sm mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5"
                  disabled={isFetch}
                >
                  {isFetch ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : addConfirm ? (
                    "Confirm"
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : null}
          </Form>
        </Modal.Body>
      </div>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}

export default ModalAddSpk;
