import { useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import TabTLStructureDetail from "../tabs/TabTLStructureDetail";
import TabCaptainHistory from "../tabs/TabCaptainHistory";
import { getBestCaptainHistory } from "../../../data-api/tl-structure";

function TLStructureDetail(props) {
  const data = props.data;
  // const [showAlert, setShowAlert] = useState(false);
  // const [AlertSuccess, setAlertSuccess] = useState(false);
  // const [AlertMessage, setAlertMessage] = useState("");

  const [historyData, setHistoryData] = useState([]);
  function showAlertError(success, message) {
    // setAlertSuccess(success);
    // setAlertMessage(message);
    // setShowAlert(true);
    resetHistory();
  }

  function resetHistory() {
    getBestCaptainHistory(props.data).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setHistoryData([]);
      } else {
        setHistoryData(result.data);
      }
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "16px" }}>Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-text-12">
        <Tabs defaultActiveKey="detail" id="uncontrolled-tab-example">
          <Tab eventKey="detail" title="Detail">
            <TabTLStructureDetail
              data={data}
              setAlert={showAlertError}
              handleSuccessModal={props.handleSuccessModal}
              accessMenu={props.accessMenu}
            />
          </Tab>
          <Tab
            eventKey="history"
            title="Best Captain History"
            style={{
              marginTop: "0px",
              marginBottom: "20px",
              width: "102%",
              left: "-15px",
              position: "relative",
            }}
          >
            <TabCaptainHistory data={data} historyData={historyData} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </>
  );
}

export default TLStructureDetail;
