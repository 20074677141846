import { Modal } from "react-bootstrap";
import DetailChart from "./DetailChart";
import { useEffect, useState } from "react";
import DetailChartMultiFilter from "./DetailChartMultiFilter";
import { getChartsFilter } from "../../data-api/dashboard";
import "./dashboard.css";
import LoadingEffect from "../template/loadingEffectTable";
function ModalChartDetail(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [listDropdown, setListDropdown] = useState([]);

  useEffect(() => {
    getChartsFilter().then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      result.data === null || result.data === undefined
        ? setListDropdown([])
        : setListDropdown(result.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffect />
        </Modal.Body>
      </>
    );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{props?.data?.title} Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props?.data?.title === "Talent Movement" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.talent_movement}
          />
        ) : props?.data?.title === "Idle Chart" ? (
          <DetailChart data={props?.data} listDropdown={listDropdown?.idle} />
        ) : props?.data?.title === "Talent Onboard Status" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.talent_onboard_status}
          />
        ) : props?.data?.title === "Religion Chart" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.religion}
          />
        ) : props?.data?.title === "Length Of Work" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.lenght_of_work}
          />
        ) : props?.data?.title === "Employee by Department" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.employee_department}
          />
        ) : props?.data?.title === "Vendor Employee" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.talent_partnership}
          />
        ) : props?.data?.title === "Vendor Employee Source Talent" ? (
          <DetailChartMultiFilter
            data={props?.data}
            listDropdown={listDropdown?.talent_partnership_source}
          />
        ) : props?.data?.title === "Vendor Employee Client" ? (
          <DetailChart
            data={props?.data}
            listDropdown={listDropdown?.talent_partnership_client}
          />
        ) : props?.data?.title ===
          "Talent Movement In & Out (Q1 - Q2 - Q3 - Q4)" ? (
          <DetailChartMultiFilter
            data={props?.data}
            listDropdown={listDropdown?.talent_movement_inout}
          />
        ) : props?.data?.title === "Talent on Client Map (TOP 7)" ? (
          <DetailChartMultiFilter
            data={props?.data}
            listDropdown={listDropdown?.talent_client}
          />
        ) : props?.data?.title === "Talent Role Map" ? (
          <DetailChartMultiFilter
            data={props?.data}
            listDropdown={listDropdown?.talent_role}
          />
        ) : props?.data?.title === "Talent Position Map" ? (
          <DetailChartMultiFilter
            data={props?.data}
            listDropdown={listDropdown?.talent_position}
          />
        ) : props?.data?.title === "Employee by Status" ? (
          <DetailChartMultiFilter
            data={props?.data}
            listDropdown={listDropdown?.employee_status}
          />
        ) : null}
      </Modal.Body>
    </>
  );
}

export default ModalChartDetail;
