import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { putContract } from "../../../data-api/pkwt";
import { FormatDate } from "../../template/format";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import enGb from "date-fns/locale/en-GB";
import moment from "moment";
import LoadingEffectForm from "../../template/loadingEffectForm";
registerLocale("en-GB", enGb);

function ModalEditPkwt(props) {
  const formData = props.data;

  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);
  const [addSuccess, setAddSuccess] = useState({});

  const [startDate, setStartDate] = useState(new Date(formData.start_pkwt));
  const [endDate, setEndDate] = useState(new Date(formData.end_pkwt));
  const [contractNo, setContractNo] = useState(formData.no_pkwt);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  function formEditPkwt(event) {
    event.preventDefault();
    if (!addConfirm) {
      setContractNo(event.target.nopkwt.value);
      setAddConfirm(true);
    } else {
      setIsFetch(true);
      fetchEditPkwt();
      // handleCloseAdd()
    }
  }

  function handleBack() {
    setAddSuccess({});
    setAddConfirm(false);
  }

  function fetchEditPkwt() {
    const data = {
      start_pkwt: moment(startDate).format("YYYY-MM-DD"),
      end_pkwt: moment(endDate).format("YYYY-MM-DD"),
      no_pkwt: contractNo,
    };

    putContract(formData.id, data).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      setAddSuccess(res);
      setIsFetch(false);
    });
  }

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffectForm row={5} />
        </Modal.Body>
      </>
    );

  return (
    <>
      <div className="container custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>
            {Object.keys(addSuccess).length === 0 && !addConfirm
              ? "Edit Contract"
              : Object.keys(addSuccess).length === 0 && addConfirm
              ? "Confirm Edit Contract"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status === "success"
              ? "Success Edit Contract"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status !== "success"
              ? "Failed Edit Contract"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(addSuccess).length === 0 ? (
            !addConfirm ? (
              // form input
              <Form onSubmit={(e) => formEditPkwt(e)}>
                <div className="container">
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Employee</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      id="employee"
                      name="employee"
                      aria-describedby="basic-addon1"
                      defaultValue={formData.nik + " - " + formData.name}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">Type</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      id="type"
                      name="type"
                      aria-describedby="basic-addon1"
                      defaultValue={formData.type}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Start Date
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate ? startDate : ""}
                        onChange={(value) => {
                          setStartDate(value);
                        }}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please fill out Start Date"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      End Date
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate ? endDate : ""}
                        onChange={(value) => {
                          setEndDate(value);
                        }}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        placeholderText="DD/MM/YYYY"
                        setCustomValidity="Please fill out End Date"
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Please fill out End Date")
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">No. PKWT</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      id="nopkwt"
                      name="nopkwt"
                      aria-describedby="basic-addon1"
                      defaultValue={formData.no_pkwt}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Please fill out No. PKWT")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </Form.Group>
                  <div className="d-flex flex-row-reverse bd-highlight">
                    <button
                      className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                      disabled={isFetch}
                    >
                      {isFetch ? (
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                      ) : (
                        "Confirm"
                      )}
                    </button>
                    {/* button untuk konfirmasi */}
                    {/* <button className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5" 
									onClick={props.close}
									>
										Close
									</button>  */}
                  </div>
                </div>
              </Form>
            ) : // edit pkwt confirmation
            addConfirm ? (
              <div className="container">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Employee</Form.Label>
                  <Form.Label className="mt-2">
                    {formData.nik + " - " + formData.name}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Type</Form.Label>
                  <Form.Label className="mt-2">{formData.type}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(startDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(endDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">No. PKWT</Form.Label>
                  <Form.Label className="mt-2">{contractNo}</Form.Label>
                </Form.Group>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={(e) => formEditPkwt(e)}
                    disabled={isFetch}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  {/* button untuk konfirmasi */}
                  <button
                    className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>
              </div>
            ) : null
          ) : // if edit pkwt success
          Object.keys(addSuccess).length !== 0 &&
            addSuccess.meta.status === "success" ? (
            <div className="container d-flex flex-column py-2">
              <FontAwesomeIcon
                className="mb-4"
                icon={faCircleCheck}
                size="10x"
                color="green"
              />
              <h4 style={{ textAlign: "center" }}>{addSuccess.meta.message}</h4>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-success mt-5 col-3"
                  onClick={props.close}
                >
                  Close
                </button>
              </div>
            </div>
          ) : // if add pkwt failed
          Object.keys(addSuccess).length !== 0 &&
            addSuccess.meta.status !== "success" ? (
            <div className="container d-flex flex-column text-center">
              <FontAwesomeIcon
                className="mb-4"
                icon={faCircleXmark}
                size="10x"
                color="red"
              />
              <h4 style={{ textAlign: "center" }}>{addSuccess.meta.message}</h4>
              {/* <span>Please try again later</span>
							<span>If still error please create ticket in helpdesk page</span> */}
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-danger mt-5 col-3"
                  onClick={() => props.close}
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalEditPkwt;
