import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import ModalNewTicket from "./modal/ModalNewTicket";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import {
  faAnglesLeft,
  faAnglesRight,
  faFilter,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { getHistory, updateStatusHelpdesk } from "../../data-api/helpdesk";
import { useOutletContext } from "react-router-dom";
import ModalMessage from "../template/ModalMessage";
import Select from "react-select";

const columns = [
  {
    Header: "ID",
    accessor: "id", // accessor is the "key" in the data
    id: "id",
  },
  {
    Header: "Type",
    accessor: "type", // accessor is the "key" in the data
    id: "type",
  },
  {
    Header: "Section",
    accessor: "section", // accessor is the "key" in the data
    id: "section",
  },
  {
    Header: "Message",
    accessor: "message", // accessor is the "key" in the data
    id: "message",
  },
  {
    Header: "Status",
    accessor: "status", // accessor is the "key" in the data
    id: "status",
  },
  {
    Header: "Request Time",
    accessor: "request_time", // accessor is the "key" in the data
    id: "request_time",
  },
];

function History(props) {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Helpdesk"
  );
  const accessMenu = access.sub_menu.find((data) => (data.menu = "Helpdesk"));

  // variable untuk search tabel
  const [searchVal, setSearchVal] = useState("");
  // save title modal
  const [modalTitle, setModalTitle] = useState("");
  // save data untuk dikirim ke beda file
  const [modalData, setModalData] = useState([]);
  // for giving loading if still load the data
  const [isLoading, setIsLoading] = useState(true);

  // save data history helpdesk
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchDataHistory("");
  }, []);

  // Show/Close Modal
  const [modalFilter, setModalFilter] = useState(false);

  // Untuk menghandle click button new
  const handleShowNew = async (title) => {
    await setModalTitle("");
    const data = {
      title: title,
    };
    setModalData(data);
    setModalTitle(title);
  };

  // save value status filter
  const [filterStatus, setFilterStatus] = useState([]);
  // // save text status filter
  const [filterStatusText, setFilterStatusText] = useState("");

  const filterHelpdesk = (e) => {
    e.preventDefault();
    setFilterStatusText(filterStatus.label);
    fetchDataHistory(filterStatus.value);
    setModalFilter(false);
  };

  const removeFilter = () => {
    setFilterStatus([]);
    setFilterStatusText("");
    fetchDataHistory("");
  };

  const fetchDataHistory = (status) => {
    setIsLoading(true);
    getHistory(outletContext.profile.employee_email, status).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.meta.code === 200) {
        result.data === null ? setHistory([]) : setHistory(result.data);
      } else {
        setHistory([]);
        props.error(false, result.meta.message);
      }
      setIsLoading(false);
    });
  };

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessData, setmodalSuccessData] = useState({});

  const [ticketId, setTicketId] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const handleShowCancel = async (id) => {
    setTicketId(id);
    setShowCancel(true);
  };
  const cancelTicket = () => {
    const json = JSON.stringify({
      status: "5",
      // last_mod_by: "Test FE",
    });
    updateStatusHelpdesk(ticketId, json).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      const message = {
        code: result.meta.code,
        message: result.meta.message,
      };
      setShowCancel(false);
      setmodalSuccessData(message);
      setModalSuccess(true);
      fetchDataHistory("");
    });
  };

  // Untuk menghandle list kolom dan data table
  const tableHooks = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: "history",
        Cell: ({ row }) => (
          <Card style={{ textAlign: "left" }}>
            <div className="d-flex flex-row-reverse me-2">
              <span>{row.values.request_time}</span>
            </div>
            <Card.Body>
              <span className="fw-bold custom-text-18 ">
                {row.values.type} - {row.values.section}
              </span>
              <Card.Text
                dangerouslySetInnerHTML={{ __html: row.values.message }}
              ></Card.Text>
              <Row>
                <Col className="custom-text-14 fw-bold">
                  {row.values.status === "New Recieved" ? (
                    <span style={{ color: "#6069CD" }}>
                      {row.values.status}
                    </span>
                  ) : row.values.status === "In Progress" ? (
                    <span style={{ color: "#F8B52B" }}>
                      {row.values.status}
                    </span>
                  ) : row.values.status === "Done" ? (
                    <span style={{ color: "#1FAE2B" }}>
                      {row.values.status}
                    </span>
                  ) : row.values.status === "Test" ? (
                    <span style={{ color: "black" }}>{row.values.status}</span>
                  ) : row.values.status === "Cancelled" ? (
                    <span style={{ color: "#dc3545" }}>
                      {row.values.status}
                    </span>
                  ) : null}
                </Col>
                <Col>
                  <div className="d-flex flex-row-reverse bd-highlight">
                    <button
                      className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 custom-text-14 btn-sm"
                      onClick={() => handleShowCancel(row.values.id)}
                      disabled={
                        row.values.status === "Cancelled" ? true : false
                      }
                    >
                      Cancel
                    </button>
                    {/* <button
                      className="btn btn-outline-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                      disabled
                    >
                      Edit
                    </button> */}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ),
      },
    ]);
  };

  const EmptyData = useMemo(() => [], []);

  //catching the data. Purpose is to only have 1 instance.
  const data = history === null || history === undefined ? EmptyData : history;
  const {
    getTableBodyProps,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: [
          "id",
          "type",
          "section",
          "message",
          "status",
          "request_time",
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    tableHooks
    // useResizeColumns,
    // useFlexLayout,
  );

  return (
    <div className="ms-3 me-3">
      <div className="row custom-text-14 w-100 ms-1 mb-3">
        <div className="col-4 text-start text-danger fw-semibold d-flex"></div>
        <div className="col-8 bd-highlight d-flex justify-content-end">
          <form
            className="input-group w-50 flex border border-danger rounded"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search ..."
              value={searchVal}
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              onChange={(e) => {
                setSearchVal(e.target.value);
                if (e.target.value === "") {
                  setGlobalFilter(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setGlobalFilter(e.target.value);
                  setSearchVal(e.target.value);
                }
              }}
            />
            {searchVal !== "" ? (
              <button
                className="btn btn-outline-light text-danger btn-sm"
                // type="button"
                // id="button-addon2"
                onClick={() => {
                  setSearchVal("");
                  setGlobalFilter("");
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ color: "#848484" }}
                  size="sm"
                />
              </button>
            ) : null}
            <button
              className="btn btn-danger"
              type="button"
              id="button-addon2"
              onClick={() => setGlobalFilter(searchVal)}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ color: "#fff", float: "right" }}
              />
            </button>
          </form>

          <button
            className="btn btn-outline-danger btn-sm ms-3 col-3 fa nowrap"
            onClick={() => setModalFilter(true)}
          >
            <FontAwesomeIcon
              icon={faFilter}
              style={{ marginRight: "10px" }}
              className="fa-cloud-arrow-down"
            />
            Filter
          </button>
          <button
            className="btn btn-danger btn-sm ms-3 col-3 fa nowrap"
            onClick={() => handleShowNew("New Ticket")}
            disabled={!accessMenu.is_create}
          >
            <FontAwesomeIcon
              icon={faSquarePlus}
              style={{ color: "#FFFFFF", marginRight: "10px" }}
            />
            New Ticket
          </button>
        </div>
      </div>
      {/* <div className="container card mt-2 px-10 py-2 fs-24"> */}
      {filterStatusText !== "" ? (
        <div className="d-flex flex-row bd-highlight mb-3 mt-4">
          <div className="p-2 bd-highlight card me-2 d-flex flex-row">
            {filterStatusText}
            <button
              type="button"
              className="btn btn-danger col-2 ms-2"
              style={{ width: "25px", height: "25px", borderRadius: "100px" }}
              onClick={() => removeFilter()}
            >
              <p style={{ marginTop: "-7px", marginLeft: "-3px" }}>x</p>
            </button>
          </div>
        </div>
      ) : null}
      <div>
        <table className="table table-borderless table-sm  align-middle">
          {isLoading ? (
            <>
              <tbody
                style={{ backgroundColor: "#ececec", textAlign: "center" }}
                className="custom-text-14"
              >
                <td colSpan={columns.length}>
                  <div className="w-100">
                    <div className="shine-photo shine"></div>
                  </div>
                </td>
              </tbody>
            </>
          ) : data.length === 0 ? (
            <tbody style={{ backgroundColor: "#ececec", textAlign: "center" }}>
              <td>No data available in table</td>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.minWidth,
                              width: cell.width,
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination d-flex justify-content-center">
        <button
          className="ms-1 me-2 btn btn-danger btn-sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon
            className="mt-1 me-1"
            icon={faAnglesLeft}
            style={{ color: "#fff" }}
          />
          First
        </button>{" "}
        <button
          className="ms-1 me-3 btn btn-outline-danger  btn-border btn-sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"< Prev"}
        </button>{" "}
        {pageIndex > 4 ? (
          <span className="align-text-bottom bg-danger me-4">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        {pageOptions
          .slice(
            pageIndex < 5
              ? 0
              : pageIndex >= pageCount - 4
              ? pageCount - 9
              : pageIndex - 4,
            pageIndex < 5
              ? 9
              : pageIndex >= pageCount - 4
              ? pageCount
              : pageIndex + 5
          )
          .map((index, i) => {
            if (index === pageIndex) {
              return (
                <button
                  className="ms-1  btn btn-danger btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            } else {
              return (
                <button
                  className="ms-1  btn btn-outline-danger  btn-border btn-sm"
                  onClick={() => gotoPage(index)}
                  disabled={!canNextPage && index === pageCount}
                  key={i}
                >
                  {index + 1}
                </button>
              );
            }
          })}
        {pageIndex + 1 < pageOptions.length - 3 ? (
          <span className="align-text-bottom bg-danger ms-3 me-3">
            <p className="mt-3" style={{ position: "absolute" }}>
              ...
            </p>
          </span>
        ) : null}
        <button
          className="ms-3  btn btn-outline-danger  btn-border btn-sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {"Next >"}
        </button>{" "}
        <button
          className="ms-2 me-1 btn btn-danger fa btn-sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
          <FontAwesomeIcon
            className="mt-1 ms-1"
            icon={faAnglesRight}
            style={{ color: "#fff", float: "right" }}
          />
        </button>{" "}
      </div>
      <div className="d-flex justify-content-end">
        Page{" "}
        <strong className="ms-1">
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </div>
      {/* <ListHistory/> */}
      {/* </div> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalFilter}
        onHide={() => setModalFilter(false)}
        size="sm"
        className="bg-yellow"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <div className="container">
          <Modal.Body>
            <Form onSubmit={(e) => filterHelpdesk(e)}>
              <Form.Group className="mb-3 fw-semibold">
                <Form.Label>Status</Form.Label>
                <Select
                  className="w-100 fw-normal custom-text-14"
                  options={[
                    {
                      value: 1,
                      label: "New Recieved",
                    },
                    {
                      value: 2,
                      label: "In Progress",
                    },
                    {
                      value: 3,
                      label: "Done",
                    },
                    {
                      value: 4,
                      label: "Test",
                    },
                    {
                      value: 5,
                      label: "Cancelled",
                    },
                  ]}
                  isClearable
                  value={filterStatus}
                  defaultValue={filterStatus}
                  placeholder={"-- Select Status --"}
                  onChange={(e) => {
                    setFilterStatus(e);
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 35,
                      minHeight: 35,
                    }),
                    menu: (base) => ({ ...base, zIndex: 9999 }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                ></Select>
              </Form.Group>
              <div className="d-grid gap-2">
                <button className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow btn-sm">
                  FILTER
                </button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      {modalTitle === "New Ticket" ? (
        <ModalNewTicket data={modalData} click={props.click} />
      ) : null}

      <Modal
        show={showCancel}
        onHide={() => setShowCancel(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <img src="/icons/question.png" alt="" style={{ width: "100px" }} />
            <h5>Are you sure?</h5>
            <p>Do you really want to cancel this ticket ?</p>
            <div className="text-center">
              <button
                className="btn btn-secondary mt-3 col-4 btn-sm"
                onClick={() => setShowCancel(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mt-3 col-4 ms-4 btn-sm"
                onClick={cancelTicket}
              >
                Sure
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {modalSuccess ? (
        <ModalMessage
          data={modalSuccessData}
          click={() => setModalSuccess(false)}
        />
      ) : null}
    </div>
  );
}

export default History;
