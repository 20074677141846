import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SAVED_EVENT } from "./util";

const DynamicTableModal = ({
  columnsHeader = [],
  setColumnHeader,
  setCloseModal,
  resetColumn = [],
  storageKey = "",
  hideColumn = [],
}) => {
  const [column, setColumn] = useState(columnsHeader);

  function handleTableSetting(e) {
    e.preventDefault();
    if (e.nativeEvent.submitter.name === "submit") {
      setColumnHeader(column);
      saveState(column);
      setCloseModal(false);
    } else {
      const columnTemp = [...resetColumn];
      columnTemp.forEach((item) => {
        item.active = true;
      });
      setColumn(columnTemp);
      saveState(columnTemp);
    }
  }

  const handleChangeColumn = (accessor) => {
    const columnTemp = [...column];
    columnTemp.forEach((item) => {
      if (item.accessor === accessor) {
        item.active = !item.active;
      }
    });
    setColumn(columnTemp);
  };

  const saveState = (columnTemp) => {
    const parsed = JSON.stringify(columnTemp);
    localStorage.setItem(storageKey, parsed);
    document.dispatchEvent(new Event(SAVED_EVENT));
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(column);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColumn(items);

    // const parsed = JSON.stringify(items);
    // localStorage.setItem("EMPLOYEE", parsed);
    // document.dispatchEvent(new Event("INTEGRALS"));
  }

  return (
    <>
      <form onSubmit={handleTableSetting}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className=""
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {column.map(({ accessor, Header, active }, index) => {
                  return (
                    <>
                      {!hideColumn.includes(accessor) ? (
                        <>
                          {" "}
                          <Draggable
                            key={accessor}
                            draggableId={accessor}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="border rounded d-flex p-2 my-2 bg-white"
                                key={accessor}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <FontAwesomeIcon
                                  icon={faGripVertical}
                                  style={{
                                    color: "#808080",
                                    marginTop: "4px",
                                    marginRight: "10px",
                                  }}
                                />
                                <label
                                  style={{ cursor: "move", cursor: "grab" }}
                                  className="form-check-label flex-grow-1"
                                  for="flexCheckDefault"
                                >
                                  {Header}
                                </label>
                                <input
                                  className={`form-check-input fs-5 border-danger ${
                                    active ? "bg-danger" : "bg-white"
                                  }`}
                                  type="checkbox"
                                  checked={active}
                                  onChange={() => handleChangeColumn(accessor)}
                                  id="flexCheckDefault"
                                  defaultChecked={active}
                                />
                              </div>
                            )}
                          </Draggable>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="w-100 mt-4 d-flex">
          <button
            className="btn btn-outline-danger w-100"
            type="submit"
            name="reset"
            value="reset"
          >
            Reset
          </button>
          <button
            className="btn btn-danger w-100 ms-3"
            type="submit"
            name="submit"
            value="submit"
          >
            Apply
          </button>
        </div>
      </form>
    </>
  );
};

export default DynamicTableModal;
