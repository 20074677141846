import "./App.css";
import Employee from "./components/employee/Employee";
import "bootstrap/dist/css/bootstrap.min.css";
import ResourceTeam from "./components/resource-team/ResourceTeam";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TlStructure from "./components/tl-structure/TlStructure";
import Login from "./components/login/Login";
import PrivateRoute from "./utils/PrivateRoutes";
import Timesheet from "./components/timesheet/Timesheet";
import ProjectTeam from "./components/project-team/Project-team";
import TalentReview from "./components/talent-review/Talent-review";
import Helpdesk from "./components/helpdesk/Helpdesk";
import PageNotFound from "./utils/PageNotFound";
import PageNoPermission from "./utils/PageNoPermission";
import SuperAdminUser from "./components/superadmin/superadmin-user/SuperAdminUser";
import IntegralsUser from "./components/superadmin/integrals-user/IntegralsUser";
import IntegralsMenu from "./components/superadmin/integrals-menu/IntegralsMenu";
import IntegralsRole from "./components/superadmin/integrals-role/IntegralsRole";
import Tickets from "./components/superadmin/tickets/Tickets";
import Resign from "./components/resign/Resign";
import MasterDataClient from "./components/clients/GetClients";
import UserProfile from "./components/user-profile/UserProfile";
import Pkwt from "./components/pkwt/Pkwt";
import Idle from "./components/idle/GetIdle";
import TalentPartnership from "./components/talent-partnership/Talent-partnership";
import NewDashboard from "./components/dashboard/NewDashboard";
import CostToCompany from "./components/cost-to-company/CostToCompany";
import ApprovalUser from "./components/superadmin/approval-user/ApprovalUser";
import GoogleCallback from "./components/login/GoogleCallback";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <div id="wrap">
          <div id="body">
            <div id="sidebar">
              <Sidebar
                isExpanded={isExpanded}
                setExpendState={openBar}
                setTitle={setTitle}
              />
            </div>
            <div id="header">
              <Header
                isExpanded={isExpanded}
                setExpendState={openBar}
                title={title}
              />
            </div>

            <div
              id="main"
            > */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/google/callback" element={<GoogleCallback />} />
          <Route element={<PrivateRoute />}>
            {/* <Route path="/" element={<Dashboard />} exact /> */}
            <Route path="/" element={<NewDashboard />} exact />
            <Route path="/cost-to-company" element={<CostToCompany />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/employee" element={<Employee />} />
            <Route path="/resign" element={<Resign />} />
            {/* <Route path="/po" element={<PO />} /> */}
            <Route path="/idle" element={<Idle />} />
            <Route path="/resource-team" element={<ResourceTeam />} />
            <Route path="/talent-review" element={<TalentReview />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route path="/vendor-employee" element={<TalentPartnership />} />
            <Route path="/tl-structure" element={<TlStructure />} />
            <Route path="/project-team" element={<ProjectTeam />} />
            <Route path="/clients" element={<MasterDataClient />} />
            <Route path="/internal-contract" element={<Pkwt />} />
            <Route path="/helpdesk" element={<Helpdesk />} />
            <Route path="/master-user">
              <Route index element={<SuperAdminUser />} />
              <Route path="integrals-user" element={<IntegralsUser />} />
              <Route path="approval-user" element={<ApprovalUser />} />
              <Route path="integrals-menu" element={<IntegralsMenu />} />
              <Route path="integrals-role" element={<IntegralsRole />} />
              <Route path="tickets" element={<Tickets />} />
            </Route>
          </Route>
          <Route path="*" exact={true} element={<PageNotFound />} />
          <Route
            path="/undefined"
            exact={true}
            element={<PageNoPermission />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
