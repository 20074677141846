import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  faArrowRightFromBracket,
  faPenToSquare,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { useEffect } from "react";
import "./Header.css";

// const { Component } = require("react");

function Header(props) {
  const [showProfile, setShowProfile] = useState(false);
  const location = useLocation();
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const cookies = new Cookies();
  const navigate = useNavigate();

  function logout() {
    setIsLogout(true);
    setTimeout(function () {
      cookies.remove("session");
      navigate("/login");
    }, 1000);
  }

  const [data, setData] = useState({});

  useEffect(() => {
    fetchUserDetail();
    // }, [data]);
  }, []);

  function fetchUserDetail() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${cookies.get("session")}`,
          XUserID: `${String(process.env.REACT_APP_X_USER_ID)}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          setData(res.data.data);
        } else {
          setData({});
        }
      })
      .catch((error) => {
        setData({});
        window.location.reload(false);
      });
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary bg-light custom-navbar">
        <div className="container-fluid">
          <div className="d-flex flex-row">
            <div id="menuToggle" onClick={(e) => props.setExpendState(e)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span className="navbar-brand" href="#">
                {location.pathname === "/"
                  ? "Dashboard"
                  : location.pathname === "/profile"
                  ? "Profile"
                  : location.pathname === "/cost-to-company"
                  ? "Cost To Company"
                  : props.menu.filter(
                      (data) =>
                        data.menu_path.split("/")[1] ===
                        location.pathname.split("/")[1]
                    )[0].menu}
              </span>
            </div>
          </div>
          <div
            title="Profile"
            className="rounded-circle header-btn-profile me-2"
            onClick={() => setShowProfile(true)}
          >
            <Image
              style={{ width: "2rem" }}
              roundedCircle
              src={data.avatar_url}
              alt="profile"
            />
            {/* <FontAwesomeIcon
              icon={faUser}
              style={{ color: "white", float: "right" }}
            /> */}
          </div>
        </div>
      </nav>

      <Modal
        show={showProfile}
        onHide={() => setShowProfile(false)}
        size="sm"
        dialogClassName="modal-btn-profile"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{ padding: "0" }}>
          <div className="d-flex flex-row profile-header">
            <div className="profile-picture-container">
              <Image
                style={{ width: "3.5rem" }}
                roundedCircle
                src={data.avatar_url}
                alt="profile"
              />
              {/* <div className="profile-picture">
                <FontAwesomeIcon
                  icon={faUser}
                  size="2xl"
                  style={{ color: "white" }}
                />
              </div> */}
            </div>
            <div className="profile-detail-container">
              <p className="profile-detail-name">
                {props.profile.employee_name}
              </p>
              <p className="profile-detail-email">
                {props.profile.employee_email}
              </p>
              <p className="profile-detail-email fw-semibold">
                {props.profile.company}
              </p>
            </div>
          </div>
          <div className="profile-body">
            <div
              className="profile-body-item"
              onClick={() => {
                navigate("/profile");
                setShowProfile(false);
              }}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                size="xl"
                style={{ color: "#525555" }}
              />
              <span className="profile-body-detail">Edit Profile</span>
            </div>
            <div
              className="profile-body-item"
              onClick={(e) => setShowConfirmLogout(true)}
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                size="xl"
                style={{ color: "#525555" }}
              />
              <span className="profile-body-detail">Log Out</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmLogout}
        onHide={() => setShowConfirmLogout(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to Log Out ?</div>
          <div className="container text-end mt-4">
            <button
              className="btn btn-secondary"
              onClick={() => setShowConfirmLogout(false)}
            >
              Cancel
            </button>
            <button className="btn btn-danger ms-3" onClick={logout}>
              {isLogout ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin px-3" />
              ) : (
                "Log Out"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
