import React from "react";
import TableIdle from "./TableIdle";
import TableIdleWaiting from "./TableIdleWaiting";
import TableOthers from "./TableOthers";
import { Tab, Tabs } from "react-bootstrap";
import "./idle.css";
import { useOutletContext } from "react-router-dom";

function GetIdle() {
  const outletContext = useOutletContext();
  let access = outletContext.accessMenu.find(
    (data) => data.menu === "Idle"
  ).sub_menu;
  const idleAccess = access.find((data) => data.sub_menu === "Idle");
  const waitingApprovalAccess = access.find(
    (data) => data.sub_menu === "Waiting Approval"
  );
  const othersAccess = access.find((data) => data.sub_menu === "Others");

  return (
    <div className="w-100">
      <Tabs defaultActiveKey="idle" id="uncontrolled-tab-example">
        {idleAccess.is_read ? (
          <Tab eventKey="idle" title="Idle">
            <TableIdle />
          </Tab>
        ) : null}
        {waitingApprovalAccess.is_read ? (
          <Tab eventKey="idle-waiting" title="Waiting Approval">
            <TableIdleWaiting />
          </Tab>
        ) : null}
        {othersAccess.is_read ? (
          <Tab eventKey="idle-others" title="Others">
            <TableOthers />
          </Tab>
        ) : null}
      </Tabs>
      {/* </div> */}
    </div>
  );
}

export default GetIdle;
