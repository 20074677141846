import { useEffect, useMemo, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import ModalEditPkwt from "./ModalEditPkwt";
import { getHistoryContractByNik } from "../../../data-api/pkwt";
import moment from "moment";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";

function ModalDetailPkwt(props) {
  const accessMenu = props.accessMenu;
  const nik = props.data.nik;
  const name = props.data.name;

  const [detailPkwt, setDetailPkwt] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({});
  const [showEditPkwt, setShowEditPkwt] = useState(false);

  const handleCloseEditPkwt = () => {
    fetchDetailPkwt();
    setShowEditPkwt(false);
  };

  const columnsPkwt = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        id: "id",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "0000-00-00"
              ? ""
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => (
          <span className="nowrap">
            {props.value === "0000-00-00"
              ? "-"
              : moment(props.value).format("DD-MMM-YYYY")}
          </span>
        ),
      },
      {
        Header: "No. PKWT",
        accessor: "no_pkwt",
      },
      {
        Header: "Type",
        accessor: "type",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  useEffect(() => {
    fetchDetailPkwt();
  }, []);

  function fetchDetailPkwt() {
    setIsLoading(true);
    getHistoryContractByNik(nik).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      setDetailPkwt(res.data);
      setIsLoading(false);
    });
  }

  const editPkwt = async (id, type, start_pkwt, end_pkwt, no_pkwt) => {
    const data = {
      id: id,
      nik: nik,
      name: name,
      type: type,
      start_pkwt: moment(start_pkwt).format("YYYY-MM-DD"),
      end_pkwt: moment(end_pkwt).format("YYYY-MM-DD"),
      no_pkwt: no_pkwt,
    };
    setFormData(data);
    setShowEditPkwt(true);
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="btn btn-danger btn-sm"
              onClick={() =>
                editPkwt(
                  row.values.id,
                  row.values.type,
                  row.values.start_date,
                  row.values.end_date,
                  row.values.no_pkwt
                )
              }
              disabled={!accessMenu.is_update}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#fff", float: "right" }}
              />
            </Button>
          </div>
        ),
      },
    ]);
  };

  const data = detailPkwt.length === 0 ? EmptyData : detailPkwt.DetailPkwt;

  function handleDownloadExcel() {
    const columnsExcel = columnsPkwt.map((item) => item.Header);
    data.forEach((item) => {
      item.start_date =
        item.start_date === "0000-00-00"
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "0000-00-00"
          ? ""
          : moment(item.end_date).format("DD-MMM-YYYY");
    });
    downloadExcel({
      fileName: "detail_contract_" + nik + "_" + detailPkwt.name,
      tablePayload: {
        header: columnsExcel,
        body: data,
      },
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>History PKWT - {detailPkwt.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactTableCustomHooks
          columns={columnsPkwt}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          isLoading={isLoading}
          hiddenColumns={["id"]}
          tableHooks={tableHooks}
        />
      </Modal.Body>

      <Modal
        show={showEditPkwt}
        onHide={handleCloseEditPkwt}
        //  size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <ModalEditPkwt
          data={formData}
          resetTable={fetchDetailPkwt}
          close={handleCloseEditPkwt}
        />
      </Modal>
    </>
  );
}
export default ModalDetailPkwt;
