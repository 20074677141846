import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { getListDropdownPkwt, postContract } from "../../../data-api/pkwt";
import { FormatDate } from "../../template/format";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import enGb from "date-fns/locale/en-GB";
import Select from "react-select";
import { InvalidInput } from "../../../utils/ReactSelect";
import LoadingEffectForm from "../../template/loadingEffectForm";
registerLocale("en-GB", enGb);

function ModalAddPkwt(props) {
  const [addModal, setAddModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [addConfirm, setAddConfirm] = useState(false);
  const [addSuccess, setAddSuccess] = useState({});

  const [nik, setNik] = useState("");
  const [pkwt, setPkwt] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contractNo, setContractNo] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const invalidInput = (props) => {
    return <InvalidInput {...props} />;
  };

  useEffect(() => {
    getListDropdownPkwt().then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (res.meta.code === 200) {
        setAddModal(res.data);
      } else {
        setIsLoading(false);
        setShowAlert(true);
        setErrorMessage(res.meta.message);
      }
      setIsLoading(false);
    });
  }, []);

  function formAddPkwt(event) {
    event.preventDefault();
    if (!addConfirm) {
      setContractNo(event.target.nopkwt.value);
      setAddConfirm(true);
    } else {
      setIsFetch(true);
      fetchCreatePkwt();
      // handleCloseAdd()
    }
  }

  function handleBack() {
    setAddSuccess({});
    setAddConfirm(false);
  }

  //send json to backend
  function fetchCreatePkwt() {
    const data = {
      nik: nik.value,
      start_pkwt: moment(startDate).format("YYYY-MM-DD"),
      end_pkwt: moment(endDate).format("YYYY-MM-DD"),
      pkwt_type: pkwt.value,
      no_pkwt: contractNo,
    };

    postContract(data).then((res) => {
      if (res.meta.code === 401) {
        window.location.reload();
        return;
      }
      setNik("");
      setPkwt("");
      setStartDate("");
      setEndDate("");
      setIsFetch(false);
      setAddSuccess(res);
    });
  }

  const handleClose = () => {
    props.close();
  };

  if (isLoading)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingEffectForm row={5} />
        </Modal.Body>
      </>
    );

  return (
    <>
      <div className="container custom-text-14">
        <Modal.Header closeButton>
          <Modal.Title>
            {Object.keys(addSuccess).length === 0 && !addConfirm
              ? "Add New Contract"
              : Object.keys(addSuccess).length === 0 && addConfirm
              ? "Confirm Add Contract"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status === "success"
              ? "Success Add Contract"
              : Object.keys(addSuccess).length !== 0 &&
                addSuccess.meta.status !== "success"
              ? "Failed Add Contract"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlert ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{errorMessage}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </div>
          ) : null}
          {Object.keys(addSuccess).length === 0 ? (
            !addConfirm ? (
              // form input
              <Form onSubmit={(e) => formAddPkwt(e)}>
                <div className="container">
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Employee
                    </Form.Label>
                    <div className="w-75">
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minHeight: 35,
                          }),
                          menu: (base) => ({ ...base, zIndex: 9999 }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            fontSize: "14px",
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        isClearable
                        options={addModal.employees.map((data) => {
                          return { value: data.nik, label: data.name };
                        })}
                        defaultValue={nik}
                        onChange={(e) => {
                          if (e !== null) {
                            setNik(e);
                          }
                        }}
                        placeholder={"-- Select Employee --"}
                        ariaLiveMessages="Please select Employee"
                        components={{
                          Input: invalidInput,
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">PKWT</Form.Label>
                    <Select
                      className="w-75 fw-normal custom-text-14 z-3"
                      options={addModal.add_types.map((data) => {
                        return { value: data.value, label: data.label };
                      })}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 35,
                          minHeight: 35,
                        }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: "14px",
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      isClearable
                      placeholder={"-- Select PKWT --"}
                      defaultValue={pkwt}
                      onChange={(e) => {
                        if (e !== null) {
                          setPkwt(e);
                        }
                      }}
                      ariaLiveMessages="Please select PKWT"
                      components={{
                        Input: invalidInput,
                      }}
                    ></Select>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      Start Date
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate ? startDate : ""}
                        onChange={(value) => {
                          setStartDate(value);
                        }}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please fill out Start Date"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Label className="mt-2 fw-semibold">
                      End Date
                    </Form.Label>
                    <div className="w-75">
                      <ReactDatePicker
                        locale={enGb}
                        className="w-100 form-control"
                        wrapperClassName="w-100 form-control"
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate ? endDate : ""}
                        onChange={(value) => {
                          setEndDate(value);
                        }}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        placeholderText="DD/MM/YYYY"
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Please fill out End Date")
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                    <Form.Label className="mt-2">No. PKWT</Form.Label>
                    <Form.Control
                      className="w-75 form-control-sm"
                      id="nopkwt"
                      name="nopkwt"
                      aria-describedby="basic-addon1"
                      defaultValue={contractNo}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Please fill out No. PKWT")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </Form.Group>
                  <div className="d-flex flex-row-reverse bd-highlight">
                    <button
                      className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                      disabled={isFetch}
                    >
                      {isFetch ? (
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                      ) : (
                        "Confirm"
                      )}
                    </button>
                    {/* button untuk form input add */}
                    {/* <button className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5" 
										onClick={props.close}
										>Close</button>  */}
                  </div>
                </div>
              </Form>
            ) : // add pkwt confirmation
            addConfirm ? (
              <div className="container">
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Employee</Form.Label>
                  <Form.Label className="mt-2">{nik.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">PKWT</Form.Label>
                  <Form.Label className="mt-2">{pkwt.label}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">Start Date</Form.Label>
                  <Form.Label className="mt-2">
                    {FormatDate(startDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">End Date</Form.Label>
                  <Form.Label className="mt-2">
                    {pkwt.label === "PKWTT" ? "" : FormatDate(endDate)}
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fw-semibold d-flex justify-content-between">
                  <Form.Label className="mt-2">No. PKWT</Form.Label>
                  <Form.Label className="mt-2">{contractNo}</Form.Label>
                </Form.Group>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    className="btn btn-danger mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={(e) => formAddPkwt(e)}
                    disabled={isFetch}
                  >
                    {isFetch ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  {/* button untuk form input add */}
                  <button
                    className="btn btn-outline-secondary mb-4 ms-1 me-1 mt-4 fw-bold shadow px-5 btn-sm"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>
              </div>
            ) : null
          ) : // for modal success or failed
          // if success
          Object.keys(addSuccess).length !== 0 &&
            addSuccess.meta.status === "success" ? (
            <div className="container d-flex flex-column py-2">
              <FontAwesomeIcon
                className="mb-4"
                icon={faCircleCheck}
                size="10x"
                color="green"
              />
              <h4 style={{ textAlign: "center" }}>{addSuccess.meta.message}</h4>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-success mt-5 col-3"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          ) : // if add pkwt failed
          Object.keys(addSuccess).length !== 0 &&
            addSuccess.meta.status !== "success" ? (
            <div className="container d-flex flex-column text-center">
              <FontAwesomeIcon
                className="mb-4"
                icon={faCircleXmark}
                size="10x"
                color="red"
              />
              <h4 style={{ textAlign: "center" }}>{addSuccess.meta.message}</h4>
              {/* <span>Please try again later</span>
								<span>If still error please create ticket in helpdesk page</span> */}
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-danger mt-5 col-3"
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </div>
    </>
  );
}

export default ModalAddPkwt;
