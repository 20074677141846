import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import ReactTableCustomHooks from "../../../utils/ReactTableCustomHooks";
import writeXlsxFile from "write-excel-file";
import ModalShowPDF from "../../../utils/ModalShowPDF";
import { getRejectedSpk } from "../../../data-api/resource-team";

function TableRejectedSPK(props) {
  useEffect(() => {
    if (props.data.length === 0) {
      fetchDataReject();
    } else {
      setSpk(props.data);
      setIsLoading(false);
    }
  }, []);

  const fetchDataReject = () => {
    setIsLoading(true);
    const param = {
      group: "talent",
    };
    getRejectedSpk(param).then((result) => {
      if (result.meta.code === 401) {
        window.location.reload();
        return;
      }
      if (result.data === null || result.data === undefined) {
        setSpk([]);
      } else {
        setSpk(result.data);
      }
      setIsLoading(false);
    });
  };

  const [spk, setSpk] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showPDF, setShowPDF] = useState(false);
  const [pDFData, setPDFData] = useState("");

  const handleShowPDF = (data) => {
    setPDFData(data);
    setShowPDF(true);
  };

  const columnsSpk = useMemo(
    () => [
      {
        Header: "SPK ID",
        accessor: "spk_id",
      },
      {
        Header: "NIK",
        accessor: "nik", // accessor is the "key" in the data for react-table
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (props) => moment(props.value).format("DD-MMM-YYYY"),
      },
      {
        Header: "SPK Type",
        accessor: "spk_type",
      },
      {
        Header: "Contract Title",
        accessor: "contract_title",
      },
      {
        Header: "Contract No",
        accessor: "contract_no",
      },
      {
        Header: "Reject Reason",
        accessor: "reject_reason",
      },
      {
        Header: "Reject Date",
        accessor: "reject_date",
      },
      {
        Header: "PDF",
        accessor: "pdf",
        Cell: (props) => (
          <a
            href={`#`}
            onClick={() => handleShowPDF(`${props.value}?${Date.now()}`)}
          >
            {props.value === "" ? "" : "View PDF"}
          </a>
        ),
      },
      {
        Header: "Client ID",
        accessor: "client_id",
      },
    ],
    []
  );

  const EmptyData = useMemo(() => [], []);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [...columns]);
  };

  const data = spk.length === 0 ? EmptyData : spk;

  function handleDownloadExcel() {
    const excelData = data;
    excelData.forEach((v) => {
      delete v.spk_id;
      delete v.client_id;
      delete v.company;
    });
    excelData.forEach((item) => {
      item.start_date =
        item.start_date === ""
          ? ""
          : moment(item.start_date).format("DD-MMM-YYYY");
      item.end_date =
        item.end_date === "" ? "" : moment(item.end_date).format("DD-MMM-YYYY");
      item.reject_date =
        item.reject_date === ""
          ? ""
          : moment(item.reject_date).format("DD-MMM-YYYY");
    });

    const dataSet = [];
    const dataHeader = [];

    //append header table
    const columnsExcel = columnsSpk
      .filter(
        (item) =>
          item.Header !== "SPK ID" &&
          item.Header !== "Client ID" &&
          item.Header !== "Company"
      )
      .map((item) => item.Header);
    columnsExcel.forEach((item) => {
      dataHeader.push({
        value: item,
        type: String,
        fontWeight: "bold",
      });
    });
    dataSet.push(dataHeader);

    //append body table
    data.forEach((value) => {
      const dataRow = [];
      Object.values(value).forEach((item) => {
        dataRow.push({
          value: item,
          type: String,
        });
      });
      dataSet.push(dataRow);
    });

    writeXlsxFile(dataSet, {
      fileName: "Rejected SPK",
    });
  }

  return (
    <>
      <div className="tabs-content">
        <ReactTableCustomHooks
          columns={columnsSpk}
          data={data}
          handleDownloadExcel={handleDownloadExcel}
          handleFilter={null}
          tableHooks={tableHooks}
          isLoading={isLoading}
          hiddenColumns={[
            "contract_title",
            "contract_no",
            "spk_id",
            "client_id",
          ]}
          paging={[10, 20, 50, 100]}
        />
      </div>

      {showPDF ? <ModalShowPDF click={setShowPDF} data={pDFData} /> : null}
    </>
  );
}
export default TableRejectedSPK;
